import { gql } from "@apollo/client"

export const USER_ASSIGNMENTS_FRAGMENT = gql` fragment UserAssignemntsFragment on ReviewerGroupUserAssignment {
        id
        userId
        permission
        createdAt
        updatedAt
        user {
          id
          fullName
          initials
          avatarUrl
        }
      }
`

export const REVIEWER_GROUPS_FRAGMENT = gql` fragment ReviewerGroupsFragment on ReviewerGroup {
      id
      campaignId
      displayName
      description
      userAssignments {
        ...UserAssignemntsFragment
}
}
${ USER_ASSIGNMENTS_FRAGMENT }
`
