import React from "react"

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { AudienceMetricFormDataProps } from "../AudienceMetricsForm"
import Checkbox from "../../../../Checkbox"
import * as GraphQL from "../../../../../graphql"

export default function AudienceFamilyStatusForm({ label, setSelected }: AudienceMetricFormDataProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })

  const [ selectedStatuses, setSelectedStatuses ] = React.useState<GraphQL.FamilyStatus[]>([])

  const selectFamilyStatus = (type: GraphQL.FamilyStatus) => {
    // Check to see if it already exists in results
    if (selectedStatuses.includes(type)) {
      const statuses = [ ...selectedStatuses ]
      statuses.splice(statuses.indexOf(type), 1)
      setSelected(statuses)
      setSelectedStatuses(statuses)
    } else {
      setSelected([ ...selectedStatuses, type ])
      setSelectedStatuses([ ...selectedStatuses, type ])
    }
  }

  return (
    <FormControl
      className="audience-metrics-form-metric-form"
      component="fieldset"
      fullWidth={ true }
    >
      <FormLabel component="legend" className="section-label">
        { translate(label.split(":")[0]) }
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          className="audience-family-status-checkbox-label"
          control={ (
            <Checkbox
              id="family-single-checkbox"
              className="family-status-checkbox"
              name={ translate("Single") }
              size="small"
              checked={ selectedStatuses.includes(GraphQL.FamilyStatus.Single) }
              onClick={ () => selectFamilyStatus(GraphQL.FamilyStatus.Single) }
            />
          ) }
          label={ translate("Single") }
        />
        <FormControlLabel
          className="audience-family-status-checkbox-label"
          control={ (
            <Checkbox
              id="family-married-checkbox"
              className="family-status-checkbox"
              name={ translate("Married") }
              size="small"
              checked={ selectedStatuses.includes(GraphQL.FamilyStatus.Married) }
              onClick={ () => selectFamilyStatus(GraphQL.FamilyStatus.Married) }
            />
          ) }
          label={ translate("Married") }
        />
      </FormGroup>
      <hr />
      <FormLabel component="legend" className="section-label">
        { translate(label.split(":")[1]) }
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          className="audience-family-status-checkbox-label"
          control={ (
            <Checkbox
              id="family-parent-checkbox"
              className="family-status-checkbox"
              name={ translate("Parent") }
              size="small"
              checked={ selectedStatuses.includes(GraphQL.FamilyStatus.Parent) }
              onClick={ () => selectFamilyStatus(GraphQL.FamilyStatus.Parent) }
            />
          ) }
          label={ translate("Parent") }
        />
        <FormControlLabel
          className="audience-family-status-checkbox-label"
          control={ (
            <Checkbox
              id="family-non-parent-checkbox"
              className="family-status-checkbox"
              name={ translate("Non-parent") }
              size="small"
              checked={ selectedStatuses.includes(GraphQL.FamilyStatus.NonParent) }
              onClick={ () => selectFamilyStatus(GraphQL.FamilyStatus.NonParent) }
            />
          ) }
          label={ translate("Non-parent") }
        />
      </FormGroup>
    </FormControl>
  )
}
