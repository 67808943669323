import React, {
  useState,
  JSX,
  useEffect,
  useMemo,
} from "react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp"
import { SelectChangeEvent } from "@mui/material/Select"
import { useTranslation } from "react-i18next"
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from "@mui/material"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import AffinitiesAutocomplete, { Affinities } from "./AffinitiesAutocomplete"
import Checkbox from "../Checkbox"
import DemographicScoreForm from "./DemographicScoreForm"
import ImageTagsAutocomplete from "./ImageTagsAutocomplete"
import Input from "../Input"
import Pill from "../Pill"
import Radio from "../Radio"
import Select from "../Select"
import Switch from "../Switch"
import { ListMode } from "."
import { Scope } from "../../util/types"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  ToggleOptions,
  audienceOptionList,
  contentOptionList,
  defaultInactiveTogglesOnNetwork,
  engagementToggles,
  engagementTogglesExceptAudienceAuthenticity,
  filterOptions,
  insightOptionList,
} from "./displayOptionsHelper"

import "./display-options.sass"
import {
  resetDemographics,
  setAffinities,
  setContextualRelevancy,
  setDemographicFormScoreOpen,
  setImageTags,
  setKeywords,
  setToggles,
} from "../../state/listModalFormSlice"
import {
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
  DefaultSuggestionListToggles,
  LIST_DISPLAY_TOGGLES,
} from "../../util/constant"

interface Props {
  mode: ListMode
}

function DisplayOptionsTab({ mode }: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ListFormModal" })
  const { t } = useTranslation("common")
  const { t: CROTranslations } = useTranslation("common.ContextualRelevancy")

  const dispatch = useDispatch()
  const listModalFormState = useSelector((state) => state.listModalForm)
  const listManagerState = useSelector((state) => state.listFormManager)
  const { scopes } = useSelector(({ user }) => user)

  const [ filteredInsightOptionList, filteredAudienceOptionList, filteredContentOptionList ] = useMemo(() => {
    let activeToggles: string[]
    const newInsightOptions = filterOptions(insightOptionList, listModalFormState.network, scopes)
    const newAudienceOptions = filterOptions(audienceOptionList, listModalFormState.network, scopes)
    const newContentOptions = filterOptions(contentOptionList, listModalFormState.network, scopes)

    if (listManagerState.listModalMode.isEditMode && API.isSuccess(listManagerState.originalList)) {
      activeToggles = listManagerState.originalList.payload.suggestionListById.toggles.map((toggle) => toggle.name)
    } else {
      const insightFlags = newInsightOptions.map(({ flag }) => flag)
      const audienceFlags = newAudienceOptions.map(({ flag }) => flag)
      const contentFlags = newContentOptions.map(({ flag }) => flag)

      // default all rendered toggles to active
      // set Engagement Score as default score
      activeToggles = [ "ToggleEngagementScore", ...insightFlags, ...audienceFlags, ...contentFlags ]

      // Toggle off Audience Authenticity by default
      activeToggles = activeToggles
        .filter((activeToggle) => activeToggle !== "ToggleAudienceAuthenticityScore")

      // If list is being updated, load in toggles, else
      // set default inactive toggles based on selected network
      if (listModalFormState.network) {
        const togglesToSetFalse = defaultInactiveTogglesOnNetwork[listModalFormState.network]
        activeToggles = activeToggles.filter((activeToggle) => !togglesToSetFalse.includes(activeToggle))
      } else {
        activeToggles = activeToggles.filter((activeToggle) => activeToggle !== ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED)
      }
    }

    if (listModalFormState.network === GraphQL.Network.Facebook || listModalFormState.network === GraphQL.Network.Snapchat) {
      dispatch(resetDemographics())
    }

    // Get all the toggles to set in suggestion list update form
    const updatedToggles: Omit<GraphQL.SuggestionListToggle, "id">[] = []
    activeToggles.forEach((name) => {
      const toggle = DefaultSuggestionListToggles.find((tgl) => tgl.name === name)
      if (toggle) updatedToggles.push(toggle)
    })

    // set active toggles
    dispatch(setToggles(updatedToggles))
    // these are the toggle options rendered in UI
    return [ newInsightOptions, newAudienceOptions, newContentOptions ]
  }, [ listModalFormState.network, scopes ])

  const contextualRelevancyOptions = [
    { name: CROTranslations("Art and Entertainment"), id: "cont_rel_art_and_entertainment" },
    { name: CROTranslations("Automotive and Vehicles"), id: "cont_rel_automotive_and_vehicles" },
    { name: CROTranslations("Business and Industrial"), id: "cont_rel_business_and_industrial" },
    { name: CROTranslations("Careers"), id: "cont_rel_careers" },
    { name: CROTranslations("Education"), id: "cont_rel_education" },
    { name: CROTranslations("Family and Parenting"), id: "cont_rel_family_and_parenting" },
    { name: CROTranslations("Finance"), id: "cont_rel_finance" },
    { name: CROTranslations("Food and Drink"), id: "cont_rel_food_and_drink" },
    { name: CROTranslations("Health and Fitness"), id: "cont_rel_health_and_fitness" },
    { name: CROTranslations("Hobbies and Interests"), id: "cont_rel_hobbies_and_interests" },
    { name: CROTranslations("Home and Garden"), id: "cont_rel_home_and_garden" },
    { name: CROTranslations("Government and Politics"), id: "cont_rel_law,_govt_and_politics" },
    { name: CROTranslations("News"), id: "cont_rel_news" },
    { name: CROTranslations("Pets"), id: "cont_rel_pets" },
    { name: CROTranslations("Real Estate"), id: "cont_rel_real_estate" },
    { name: CROTranslations("Religion and Spirituality"), id: "cont_rel_religion_and_spirituality" },
    { name: CROTranslations("Science"), id: "cont_rel_science" },
    { name: CROTranslations("Shopping"), id: "cont_rel_shopping" },
    { name: CROTranslations("Society"), id: "cont_rel_society" },
    { name: CROTranslations("Sports"), id: "cont_rel_sports" },
    { name: CROTranslations("Style and Fashion"), id: "cont_rel_style_and_fashion" },
    { name: CROTranslations("Technology and Computing"), id: "cont_rel_technology_and_computing" },
    { name: CROTranslations("Travel"), id: "cont_rel_travel" },
  ]
  const [ relevantPostsKeyword, setRelevantPostsKeyword ] = useState("")
  const [ selectedAffinities, setSelectedAffinities ] = useState<Affinities[]>([])

  useEffect(() => {
    // Populate Affinities for updates
    if (mode.isEditMode && API.isSuccess(listManagerState.originalList)) {
      const { affinities } = listManagerState.originalList.payload.suggestionListById
      if ((affinities && affinities.length)) {
        const existingAffinities = affinities.map((affinity) => ({
          id: affinity.id, name: affinity.name, code: affinity.code,
        }))
        setSelectedAffinities(existingAffinities)
      }
    }
  }, [ listManagerState.originalList ])

  const handleCRoptionChange = (event: SelectChangeEvent<string>): void => {
    dispatch(setContextualRelevancy(event.target.value))
  }

  const handleRemoveKeywords = (tagName: string): void => {
    const filteredKeywords = listModalFormState.keywords
      .filter((tag: string) => (tag !== tagName))

    dispatch(setKeywords(filteredKeywords))
  }

  function renderKeywordPills(): JSX.Element[] {
    return listModalFormState.keywords.map((tag) => (
      <Pill
        key={ tag }
        label={ tag }
        onDelete={ () => handleRemoveKeywords(tag) }
      />
    ))
  }

  // Event handler Keywords
  const handleAddKeywords = (): void => {
    // Split the input by commas, trim whitespace, and filter out any empty strings
    const newKeywords = relevantPostsKeyword.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)

    const filteredKeywords = newKeywords
      .filter((keyword) => !listModalFormState.keywords.includes(keyword))

    if (filteredKeywords.length > 0) {
      dispatch(setKeywords([ ...listModalFormState.keywords, ...filteredKeywords ]))
    }

    setRelevantPostsKeyword("") // Clear the input field after adding keywords
  }

  // Event handler keywords field on enter
  const handleKeywordKeyDown = (event: React.KeyboardEvent): void => {
    // When the user presses Enter, add the keywords
    if (event.key === "Enter") {
      event.preventDefault() // Prevent form submission and other default actions
      handleAddKeywords()
    }
  }

  // Affinities keywords input
  const handleRemoveAffinities = (affinityCode: string): void => {
    const filteredAffinityIds = listModalFormState.affinities
      .filter((affinity: string) => (affinity !== affinityCode))

    const filteredAffinities = selectedAffinities
      .filter((affinity: Affinities) => (affinity.code !== affinityCode))

    setSelectedAffinities(filteredAffinities)
    dispatch(setAffinities(filteredAffinityIds))
  }

  function renderAffinityPills(): JSX.Element[] {
    return selectedAffinities.map((affinity) => (
      <Pill
        key={ affinity.code }
        label={ affinity.name }
        onDelete={ () => handleRemoveAffinities(affinity.code) }
      />
    ))
  }

  const handleAffinitiesUpdate = (affinities: Affinities[]) => {
    const affinityIds = affinities.map((affinity) => (affinity.code))
    setSelectedAffinities(affinities)
    dispatch(setAffinities(affinityIds))
  }

  const handleRemoveImageTags = (tagName: string): void => {
    const filteredKeywords = listModalFormState.imageTags
      .filter((tag: string) => (tag !== tagName))

    dispatch(setImageTags(filteredKeywords))
  }

  function renderImageTagPills(): JSX.Element[] {
    return listModalFormState.imageTags.map((tag) => (
      <Pill
        key={ tag }
        label={ tag }
        onDelete={ () => handleRemoveImageTags(tag) }
      />
    ))
  }

  const setSelectedImageTags = (imageTags: string[]) => {
    dispatch(setImageTags(imageTags))
  }

  const isToggleChecked = (toggle: string) => listModalFormState.toggles.map((lmfs) => lmfs.name).includes(toggle)

  const handleEngagmentToggleUpdate = (toggle: string) => {
    // Strip out engagement toggles
    const toggles = listModalFormState.toggles.filter((aToggle) => !engagementToggles.includes(aToggle.name))
    const newToggle = DefaultSuggestionListToggles.find((tgl) => tgl.name === toggle)
    if (newToggle) toggles.push(newToggle)

    // Save to state
    dispatch(setToggles(toggles))

    // Reset contexual relevancy if selected Ad Council or I Score toggles
    if ((toggle === "ToggleAdCouncilScore" && !toggles.map((tgl) => tgl.name).includes("ToggleAdCouncilScore"))
      || (toggle === "ToggleIScore" && !toggles.map((tgl) => tgl.name).includes("ToggleIScore"))) {
      dispatch(setContextualRelevancy(null))
    }
  }

  const handleToggleUpdate = (toggle: string) => {
    let toggles = [ ...listModalFormState.toggles ]

    if (toggles.map((tgl) => tgl.name).includes(toggle)) {
      const index = toggles.map((tgl) => tgl.name).indexOf(toggle)
      toggles.splice(index, 1)
    } else {
      const newToggle = DefaultSuggestionListToggles.find((tgl) => tgl.name === toggle)
      if (newToggle) toggles.push(newToggle)
    }

    // Filter out other engagement score toggles if Audience Authenticity
    // is selected
    if (toggles.map((tgl) => tgl.name).includes("ToggleAudienceAuthenticityScore")) {
      toggles = toggles
        .filter((aToggle) => !engagementTogglesExceptAudienceAuthenticity.includes(aToggle.name))
    }

    // If Audience Authenticity is unchecked from Audience panel, it will
    // leave the right panel in a state where there are no score radio buttons
    // selected. Default to Engagement Score in this case.
    if (!toggles.map((tgl) => tgl.name).includes("ToggleAudienceAuthenticityScore")) {
      const hasAnyScoreSelected = toggles
        .some((aToggle) => engagementToggles.includes(aToggle.name))

      if (!hasAnyScoreSelected) {
        const newToggle = DefaultSuggestionListToggles.find((tgl) => tgl.name === "ToggleEngagementScore")
        if (newToggle) toggles.push(newToggle)
      }
    }

    dispatch(setToggles(toggles))
  }

  const filtersChecked = ((toggles: ToggleOptions[]) => {
    const activeToggles = toggles.filter(({ flag }) => listModalFormState.toggles.map((tgl) => tgl.name).includes(flag))
    return {
      totalToggles: toggles.length,
      activeToggles: activeToggles.length,
      allChecked: toggles.length === activeToggles.length,
    }
  })

  const renderTotalEnabledToggles = (
    currentToggles: ToggleOptions[],
  ) => {
    const filterTotals = filtersChecked(currentToggles)
    return (
      <span>{ `${ filterTotals.activeToggles }/${ filterTotals.totalToggles } ${ translate("Enabled") } ` }</span>
    )
  }

  const handleAllTogglesChange = (
    toggles: ToggleOptions[],
  ) => {
    const currentToggles = [ ...listModalFormState.toggles ]
    if (!filtersChecked(toggles).allChecked) {
      toggles.forEach((toggle: ToggleOptions) => {
        if (!isToggleChecked(toggle.flag)) {
          currentToggles.push({
            name: toggle.flag,
            type: GraphQL.SuggestionListToggleGroupType.OtherToggles,
            order: 0,
          })
        }
      })
    } else {
      toggles.forEach((toggle: ToggleOptions) => {
        if (isToggleChecked(toggle.flag)) {
          const index = currentToggles.map((tgl) => tgl.name).indexOf(toggle.flag)
          currentToggles.splice(index, 1)
        }
      })
    }
    dispatch(setToggles(currentToggles))
  }

  const shouldDisplayAudienceAuthenticityRadio = (
    listModalFormState.network === GraphQL.Network.Instagram
    || listModalFormState.network === GraphQL.Network.Tiktok
  ) && scopes.includes(Scope.FEATURE_DISPLAY_AUDIENCE_AUTHENTICITY)

  return (
    <div className="cp_component_modal-create-list_tab-display-options">
      <div className="cp_component_modal-create-list_row">
        <div className="cp_component_modal-create-list_column">
          <div className="cp_component_modal-create-list-engagement">
            <h4 className="headline-small">{ translate("Scores & Engagement Rate") }</h4>
            <FormControl className="cp_component_modal-create-list-engagement_form-control">
              <RadioGroup
                onChange={ (_, selected) => handleEngagmentToggleUpdate(selected) }
                className="cp_component_modal-create-list-engagement_radio-options"
                value={ listModalFormState.score }
              >
                <FormControlLabel
                  className="cp_radio-label"
                  value="ToggleEngagementScore"
                  control={ <Radio /> }
                  label={ translate("Engagement Score") }
                  checked={ isToggleChecked("ToggleEngagementScore") }
                />
                <small>{ translate("Engagement Score description") }</small>
                <FormControlLabel
                  className="cp_radio-label"
                  value="ToggleEngagementRate"
                  control={ <Radio /> }
                  label={ translate("Engagement Rate") }
                  checked={ isToggleChecked("ToggleEngagementRate") }
                />
                <small>{ translate("Engagement Rate description") }</small>
                { shouldDisplayAudienceAuthenticityRadio && (
                  <>
                    <FormControlLabel
                      className="cp_radio-label"
                      value="ToggleAudienceAuthenticityScore"
                      control={ <Radio /> }
                      label={ translate("Audience Authenticity Score") }
                      checked={ isToggleChecked("ToggleAudienceAuthenticityScore") }
                    />
                    <small>{ translate("Audience Authenticity Score radio description") }</small>
                  </>
                ) }
                {
                scopes.includes(Scope.FEATURE_LIST_TTCM_ENGAGEMENT_RATE)
                && listModalFormState.network === GraphQL.Network.Tiktok && (
                  <>
                    <FormControlLabel
                      className="cp_radio-label"
                      value={ LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE }
                      control={ <Radio /> }
                      label={ translate("TTCM Engagement Rate") }
                      checked={ isToggleChecked(LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE) }
                    />
                    <small>{ translate("TTCM Rate description") }</small>
                  </>
                ) }
                { scopes.includes(Scope.SCORE_AD_COUNCIL) && (
                <>
                  <FormControlLabel
                    className="cp_radio-label"
                    value="ToggleAdCouncilScore"
                    control={ <Radio /> }
                    label={ translate("Ad Council Score") }
                    checked={ isToggleChecked("ToggleAdCouncilScore") }
                  />
                  <small>{ translate("Ad Council Score description") }</small>
                  { isToggleChecked("ToggleAdCouncilScore") && (
                    <FormControl
                      className="cp_component_contextual-relevancy"
                      component="fieldset"
                      fullWidth={ true }
                    >
                      <FormLabel component="legend" className="cp_component_contextual-relevancy_label">
                        { translate("Contextual Relevancy") }
                      </FormLabel>
                      <Select
                        id="cp_component_contextual-relevancy_select"
                        label=""
                        labelId="cp_component_contextual-relevancy_id"
                        menuItems={
                          [ { name: t("None"), id: "" },
                            ...contextualRelevancyOptions,
                          ].map((option) => <MenuItem key={ option.id } value={ option.id }>{ option.name }</MenuItem>) }
                        onChange={ (e) => handleCRoptionChange(e) }
                        value={ listModalFormState.contextualRelevancy ?? "" }
                      />
                    </FormControl>
                  ) }
                </>
                ) }
                { scopes.includes(Scope.FEATURE_LIST_ISCORE) && (
                  <>
                    <FormControlLabel
                      className="cp_radio-label"
                      value="ToggleIScore"
                      control={ <Radio /> }
                      label={ translate("I-Score") }
                      checked={ isToggleChecked("ToggleIScore") }
                    />
                    <small>{ translate("I-Score description") }</small>
                    { isToggleChecked("ToggleIScore") && (
                    <FormControl
                      className="cp_component_contextual-relevancy"
                      component="fieldset"
                      fullWidth={ true }
                    >
                      <FormLabel component="legend" className="cp_component_contextual-relevancy_label">
                        { translate("Contextual Relevancy") }
                      </FormLabel>
                      <Select
                        id="cp_component_contextual-relevancy_select"
                        label=""
                        labelId="cp_component_contextual-relevancy_id"
                        menuItems={
                          [ { name: t("None"), id: "" },
                            ...contextualRelevancyOptions,
                          ].map((option) => <MenuItem key={ option.id } value={ option.id }>{ option.name }</MenuItem>) }
                        onChange={ (e) => handleCRoptionChange(e) }
                        value={ listModalFormState.contextualRelevancy ?? "" }
                      />
                    </FormControl>
                    ) }
                  </>
                ) }
                { scopes.includes(Scope.FEATURE_LIST_DEMOGRAPHIC_SCORE)
                && listModalFormState.network !== GraphQL.Network.Facebook
                && listModalFormState.network !== GraphQL.Network.Snapchat
                && (
                <>
                  <FormControlLabel
                    className="cp_radio-label"
                    onClick={ () => dispatch(setDemographicFormScoreOpen(true)) }
                    value="ToggleDemographicScore"
                    control={ <Radio /> }
                    label={ (
                      <span>
                        { translate("Demographic Score") }
                        <sup className="cp_asterisk">*</sup>
                      </span>
                  ) }
                    checked={ isToggleChecked("ToggleDemographicScore") }
                  />
                  <small>
                    { translate("Demographic score description") }
                    <span className="cp_warning-message">
                      <sup className="cp_asterisk">*</sup>
                      { translate("Demographic score warning") }
                    </span>
                  </small>
                  { listModalFormState.demographicScoreFormOpen && (
                  <DemographicScoreForm
                    handleClose={ () => dispatch(setDemographicFormScoreOpen(false)) }
                    mode={ mode }
                  />
                  ) }
                </>
                ) }
                <FormControlLabel
                  className="cp_radio-label"
                  value="ToggleNoScore"
                  control={ <Radio /> }
                  label={ t("None") }
                  checked={ isToggleChecked("ToggleNoScore") }
                />
                <small>{ translate("None description") }</small>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="cp_component_modal-create-list_column">
          <div className="cp_component_modal-create-list-accordion">
            <Accordion className="cp_component_modal-create-list-accordion_insights" elevation={ 0 }>
              <AccordionSummary
                expandIcon={ <ArrowForwardIosIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3 className="cp_component_accordion-heading">{ translate("Insights") }</h3>
                <div className="cp_component_accordion-toggle-details">
                  { renderTotalEnabledToggles(filteredInsightOptionList) }
                  <Checkbox
                    checked={ filtersChecked(filteredInsightOptionList).allChecked }
                    onClick={ (e) => { e.stopPropagation() } }
                    onChange={ () => {
                      handleAllTogglesChange(filteredInsightOptionList)
                    } }
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                { filteredInsightOptionList.map((option) => (
                  <div
                    key={ option.flag }
                    className="cp_component_accordion-content cp_component_modal-create-list-accordion_content"
                  >
                    <div className="cp_component_modal-create-list-switch-item">
                      <div className="cp_component_modal-create-list-switch-content">
                        <h4>{ translate(option.title) }</h4>
                        <p>{ translate(option.detail) }</p>
                      </div>
                      <div className="cp_component_modal-create-list-switch-control">
                        <FormControlLabel
                          value="start"
                          label=""
                          control={ (
                            <Switch
                              color="primary"
                              hasIcon={ true }
                              icon={ <CancelIcon /> }
                              checkedIcon={ <CheckCircleSharpIcon /> }
                              isChecked={ isToggleChecked(option.flag) }
                              handleChange={ () => handleToggleUpdate(option.flag) }
                            />
                          ) }
                        />
                      </div>
                    </div>
                  </div>
                ))
                }
              </AccordionDetails>
            </Accordion>
            { scopes.includes(Scope.FEATURE_DISPLAY_AUDIENCE_DATA) && (
              <Accordion
                className="cp_component_modal-create-list-accordion_audience"
                elevation={ 0 }
                id="cp_component_modal-create-list-accordion_audience"
              >
                <AccordionSummary
                  expandIcon={ <ArrowForwardIosIcon /> }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3 className="cp_component_accordion-heading">{ translate("Audience") }</h3>
                  <div className="cp_component_accordion-toggle-details">
                    { renderTotalEnabledToggles(filteredAudienceOptionList) }
                    <Checkbox
                      checked={ filtersChecked(filteredAudienceOptionList).allChecked }
                      onClick={ (e) => { e.stopPropagation() } }
                      onChange={ () => {
                        handleAllTogglesChange(audienceOptionList)
                      } }
                    />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  { filteredAudienceOptionList.map((option) => (
                    <div
                      key={ option.flag }
                      className="cp_component_accordion-content cp_component_modal-create-list-accordion_content"
                    >
                      <div className="cp_component_modal-create-list-switch-item">
                        <div className="cp_component_modal-create-list-switch-content">
                          <h4>{ translate(option.title) }</h4>
                          <p>{ translate(option.detail) }</p>
                        </div>
                        <div className="cp_component_modal-create-list-switch-control">
                          <FormControlLabel
                            value="start"
                            label=""
                            control={ (
                              <Switch
                                color="primary"
                                hasIcon={ true }
                                icon={ <CancelIcon /> }
                                checkedIcon={ <CheckCircleSharpIcon /> }
                                isChecked={ isToggleChecked(option.flag) }
                                handleChange={ () => handleToggleUpdate(option.flag) }
                              />
                          ) }
                          />
                        </div>
                        { (option.flag === "ToggleAffinities" && isToggleChecked(option.flag)) && (
                          <div className="cp_component_modal-create-list-switch-additional_fields">
                            <AffinitiesAutocomplete
                              selectedAffinities={ selectedAffinities }
                              setSelectedAffinities={ handleAffinitiesUpdate }
                              inputLabel={ translate("BRANDS, LIKES & INTERESTS, HASHTAGS, AND INFLUENCES") }
                              notFoundLabel={ translate("Affinity not found") }
                            />
                            <div className="cp_component_modal-create-list-switch-pills">
                              { renderAffinityPills() }
                            </div>
                          </div>
                        ) }

                      </div>
                    </div>
                  ))
                }
                </AccordionDetails>
              </Accordion>
            ) }
            <Accordion className="cp_component_modal-create-list-accordion_content" elevation={ 0 }>
              <AccordionSummary
                expandIcon={ <ArrowForwardIosIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3 className="cp_component_accordion-heading">{ translate("Content") }</h3>
                <div className="cp_component_accordion-toggle-details">
                  { renderTotalEnabledToggles(filteredContentOptionList) }
                  <Checkbox
                    checked={ filtersChecked(filteredContentOptionList).allChecked }
                    onClick={ (e) => { e.stopPropagation() } }
                    onChange={ () => {
                      handleAllTogglesChange(filteredContentOptionList)
                    } }
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                { filteredContentOptionList.map((option) => (
                  <div
                    key={ option.flag }
                    className="cp_component_accordion-content cp_component_modal-create-list-accordion_content"
                  >
                    <div className="cp_component_modal-create-list-switch-item">
                      <div className="cp_component_modal-create-list-switch-content">
                        <h4>{ translate(option.title) }</h4>
                        <p>{ translate(option.detail) }</p>
                      </div>
                      <div className="cp_component_modal-create-list-switch-control">
                        <FormControlLabel
                          value="start"
                          label=""
                          control={ (
                            <Switch
                              color="primary"
                              hasIcon={ true }
                              icon={ <CancelIcon /> }
                              checkedIcon={ <CheckCircleSharpIcon /> }
                              isChecked={ isToggleChecked(option.flag) }
                              handleChange={ () => handleToggleUpdate(option.flag) }
                            />
                          ) }
                        />
                      </div>
                      { (option.flag === "ToggleKeywords" && isToggleChecked(option.flag)) && (
                        <div className="cp_component_modal-create-list-switch-additional_fields">
                          <Input
                            className="create-list-long-description"
                            value={ relevantPostsKeyword }
                            placeholder={ translate("Keywords") }
                            onKeyDown={ handleKeywordKeyDown }
                            onChange={ (e) => setRelevantPostsKeyword(e.currentTarget.value) }
                            fullWidth={ true }
                            label={ translate("Keywords") }
                          />
                          <div className="cp_component_modal-create-list-switch-pills">
                            {
                              renderKeywordPills()
                            }
                          </div>
                          <div className="cp_component_modal-create-list-switch-image_tags">
                            <ImageTagsAutocomplete
                              setSelectedImageTags={ setSelectedImageTags }
                              selectedImageTags={ listModalFormState.imageTags }
                              inputLabel={ translate("IMAGE TAGS") }
                              notFoundLabel={ translate("Image Tag not found") }
                            />
                            <div className="cp_component_modal-create-list-switch-pills">
                              {
                                renderImageTagPills()
                              }
                            </div>
                          </div>
                        </div>
                      ) }
                    </div>
                  </div>
                ))
                }
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisplayOptionsTab
