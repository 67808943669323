import React, { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useTranslation } from "react-i18next"

import { copyToClipboard } from "../../util/miscHelper"

import "./style.sass"

interface CopyIconButtonProps {
  textToCopy: string
  customClass?: string
}

function CopyIconButton({ textToCopy, customClass }: CopyIconButtonProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CopyIconButton" })
  const [ copied, setCopied ] = useState(false)
  const wrapperClasses = `cp_component-copy-clipboard${ customClass ? ` ${ customClass }` : "" }`

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    copyToClipboard(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000) // Reset the tooltip after 2 seconds
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Failed to copy text: ", err)
      })
  }

  return (
    <Tooltip
      arrow={ true }
      className={ wrapperClasses }
      title={ copied ? translate("Copied") : translate("Copy to clipboard") }
    >
      <IconButton onClick={ (e) => handleCopy(e) } size="small">
        <ContentCopyIcon className="cp_component-copy-clipboard-icon" fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default CopyIconButton
