import React from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"

import "./content.sass"
import { useSelector, useDispatch } from "../../state/hooks"
import EmptySplash from "../EmptySplash"
import Button from "../Button"
import DeliverableContentNote from "./ContentNote"
import DeliverableMediaList from "./MediaList"
import Caption from "./Caption"
import MediaApprovalConfirmation from "./MediaApprovalConfirmation"
import MediaFeedback from "./MediaFeedback"
import { isError } from "../../util/apiClient"
import { openEditDeliverableModal } from "../../state/campaignDeliverableModalSlice"
import { closeDeliverableContentModal } from "../../state/campaignDeliverableContentModalSlice"
import { refreshCampaign } from "../../state/campaignSlice"
import CaptionApprovalConfirmation from "./CaptionApprovalConfirmation"
import CaptionFeedback from "./CaptionFeedback"
import { Scope } from "../../util/types"

// eslint-disable-next-line max-len
const emptyText = "There is no content available to review for this deliverable yet. Once media and/or captions are added, you can approve or leave feedback for them here."

function ModalCampaignDeliverableContentTab(): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    deliverable,
    mediaToApprove,
    captionToApprove,
    captionFeedback,
    mediaFeedback,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)
  const {
    scopes,
  } = useSelector(({ user }) => user)

  const { campaign } = useSelector(({ campaignPage }) => campaignPage)

  const availableAccounts = React.useMemo(() => {
    if (campaign === "loading" || campaign === "init" || isError(campaign)) return []
    return campaign.payload.campaign.campaignNetworkAccounts
  }, [ campaign ])

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  if (!deliverable) return <> </>
  if (!deliverable.deliverableCaption && !deliverable.deliverableMedia?.length) {
    return (
      <div className="cp_campaign-deliverable-content-tab_component empty">
        <EmptySplash
          headlineText={ translate("CONTENT FOR THIS DELIVERABLE IS AWAITING SUBMISSION") }
          bodyText={ translate(emptyText) }
        />
        {
          scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Button
            className="upload-content-button"
            isPrimary={ false }
            startIcon={ <AddIcon /> }
            label={ translate("Upload Content") }
            onClick={ () => {
              dispatch(
                openEditDeliverableModal({
                  deliverable,
                  socialAccounts: availableAccounts,
                  updateCallback: () => dispatch(refreshCampaign()),
                }),
              )
              dispatch(closeDeliverableContentModal())
            } }
          />
          ) }
      </div>
    )
  }
  return (
    <div className="cp_campaign-deliverable-content-tab_component">
      <div className="cp_campaign-deliverable-content-tab_component-container">
        <DeliverableContentNote note={ deliverable.note } />
        {
          mediaToApprove
            ? <MediaApprovalConfirmation deliverableMedia={ mediaToApprove } />
            : <DeliverableMediaList deliverableMedia={ deliverable.deliverableMedia || [] } />
        }
        {
          mediaFeedback
            ? <MediaFeedback deliverableMedia={ mediaFeedback } />
            : null
        }
        {
          captionToApprove
            ? <CaptionApprovalConfirmation caption={ captionToApprove } />
            : <Caption caption={ deliverable.deliverableCaption } />
        }
        {
          captionFeedback
            ? <CaptionFeedback caption={ captionFeedback } />
            : null
        }
      </div>
    </div>
  )
}

export default ModalCampaignDeliverableContentTab
