/* eslint-disable max-len */
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import "./status-confirmation-modal.sass"
import { Button } from "@mui/material"
import { useApolloClient, useMutation } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import Modal from "../Modal"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector, useDispatch } from "../../state/hooks"
import { closeDeliverableContentModal, closeFinalizeDeliverableConfirmModal } from "../../state/campaignDeliverableContentModalV2Slice"
import {
  DeliverableStatus,
  GET_DELIVERABLE,
  GET_DELIVERABLE_HISTORY,
  UPDATE_DELIVERABLE_STATUS,
  UpdateDeliverableStatusInput,
  UpdateDeliverableStatusOutput,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"

function FinalizeDeliverableConfirmationModal(): React.JSX.Element {
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)

  const client = useApolloClient()

  const {
    deliverable,
    finalizeDeliverableConfirmationModalOpen: modalOpen,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const dispatch = useDispatch()

  const [ searchParams, setSearchParams ] = useSearchParams()

  const closeModal = () => {
    dispatch(closeFinalizeDeliverableConfirmModal())
  }

  const closeEntireDeliverableModalFlow = () => {
    dispatch(closeFinalizeDeliverableConfirmModal())
    dispatch(closeDeliverableContentModal())
    searchParams.delete("deliverableId")
    setSearchParams(searchParams)
  }

  const [ updateStatus ] = useMutation<
    UpdateDeliverableStatusOutput,
      UpdateDeliverableStatusInput
    >(UPDATE_DELIVERABLE_STATUS)

  const refreshQueries = async () => {
    await client.refetchQueries({
      include: [
        GET_CAMPAIGN_DETAILS,
        GET_DELIVERABLE,
        GET_DELIVERABLE_HISTORY,
      ],
    })
  }

  const finalizeDeliverableHandler = async () => {
    if (!deliverable) return
    setLoadingPrimaryAction(true)
    await updateStatus({
      variables: {
        deliverableId: deliverable.id,
        status: DeliverableStatus.FINALIZED,
      },
      context: {
        apiVersion: "v2",
      },
    })
    refreshQueries()
    closeEntireDeliverableModalFlow()
    setLoadingPrimaryAction(false)
  }

  const modalIsOpen = Boolean(deliverable) && modalOpen

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return translate("Confirm")
  }, [ loadingPrimaryAction, deliverable ])

  if (!deliverable) return <> </>

  return (
    <Modal
      title={ deliverable.name }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ modalIsOpen }
      closeAction={ closeEntireDeliverableModalFlow }
      secondaryAction={ closeEntireDeliverableModalFlow }
      primaryAction={ finalizeDeliverableHandler }
      maxWidth="xl"
      className=".cp_component_deliverable-v2-go-live-modal"
      disabled={ loadingPrimaryAction }
    >
      <div
        className="cp_component_deliverable-v2-go-live-modal-container"
      >
        <div
          className="cp_component_deliverable-v2-go-live-modal-container-head"
        >
          <Button
            startIcon={ <ChevronLeftIcon /> }
            onClick={ closeModal }
          >
            { translate("Back to deliverable") }
          </Button>
        </div>
        <div
          className="cp_component_deliverable-v2-go-live-modal-container-body"
        >
          <h6>
            { translate("Please Confirm") }
          </h6>
          <p>
            { translate("Please confirm that all deliverable content") }
            <strong>
              { translate("is approved and ready to go live.") }
            </strong>
          </p>
          <p>
            { translate("Finalizing deliverable will notify all reviewers.") }
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default FinalizeDeliverableConfirmationModal
