import React from "react"
import { useTranslation } from "react-i18next"
import { useApolloClient, useMutation } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import {
  closeAssignReviewerModal,
  setAssignReviewerSearchInput,
  setSelectedReviewerGroupId,
} from "../../state/campaignDeliverableAssignReviewerModalSlice"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import SearchReviewerGroup from "./SearchReviewerGroup"
import "./style.sass"
import {
  ASSIGN_REVIEWER_GROUP_TO_DELIVERABLE,
  AssignReviewerGroupToDeliverableOutput,
  AssignReviewerGroupToDeliverableVariables,
  GET_DELIVERABLE,
  GET_DELIVERABLE_HISTORY,
  GET_DELIVERABLE_PERMISSIONS,
  GET_DELIVERABLE_USER_GROUP,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"

function ModalCampaignDeliverableAssignReviewerGroup(): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const dispatch = useDispatch()
  const { modalOpen, selectedReviewerGroupId } = useSelector(
    ({ campaignDeliverableAssignReviewerModal }) => campaignDeliverableAssignReviewerModal,
  )

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""
  const client = useApolloClient()

  const closeModal = async () => {
    dispatch(setAssignReviewerSearchInput(""))
    dispatch(setSelectedReviewerGroupId(""))
    dispatch(closeAssignReviewerModal())
  }

  const refreshQueries = async () => {
    await client.refetchQueries({
      include: [
        GET_DELIVERABLE,
        GET_DELIVERABLE_USER_GROUP,
        GET_DELIVERABLE_PERMISSIONS,
        GET_CAMPAIGN_DETAILS,
        GET_DELIVERABLE_HISTORY,
      ],
    })
  }

  const [ assignReviewerGroupToDeliverable ] = useMutation<
  AssignReviewerGroupToDeliverableOutput,
    AssignReviewerGroupToDeliverableVariables
  >(ASSIGN_REVIEWER_GROUP_TO_DELIVERABLE)

  const handleSubmit = async () => {
    if (!deliverableId) return
    await assignReviewerGroupToDeliverable({
      variables: {
        deliverableIdString: deliverableId,
        deliverableId,
        groupId: selectedReviewerGroupId,
      },
      context: {
        apiVersion: "v2",
      },
    })
    await refreshQueries()
    closeModal()
  }

  return (
    <Modal
      title={ translate("Select Next Reviewer Group") }
      subtitle={ translate("Send Content To Next Group") }
      primaryLabel={ translate("Send To Group") }
      secondaryLabel={ translate("Cancel") }
      open={ modalOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ handleSubmit }
      maxWidth="xl"
      className="cp_component_modal-deliverable-assignreviewer"
      hasPrimaryButton={ true }
      disabled={ selectedReviewerGroupId === "" }
    >
      <SearchReviewerGroup />
    </Modal>
  )
}

export default ModalCampaignDeliverableAssignReviewerGroup
