import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  Badge,
  Container,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { NotificationsNone } from "@mui/icons-material"
import Avatar from "../../../Avatar"
import "./communicationsContent.sass"
import SearchBar from "../../../SearchBar"
import LoadingIndicator from "../../../LoadingIndicator"
import * as API from "../../../../util/apiClient"
import { FilterMenuOption } from "../../../FilterMenu"
import { RootState, store } from "../../../../state/store"
import { getCommunications, getMoreCommunications } from "../../../../state/slidingPanelSlice/communications"
import { setCommunicationSearchFilter, setCommunicationSearchInput } from "../../../../state/slidingPanelSlice"
import InfiniteScroll from "../../../InfiniteScroll"
import EmptySplash from "../../../EmptySplash"
import { resetListViewState } from "../../../../state/commEmailListView"
import Tooltip from "../../../Tooltip"

type Props = {
  filterOptions: FilterMenuOption[]
  onClose: () => void
}

function CommunicationsContent({
  filterOptions,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const navigate = useNavigate()
  const { vanity } = useParams()
  const dispatch = useDispatch()
  const {
    communicationsContent, communicationsStatus, communicationSearchInput, communicationSearchFilter,
  } = useSelector((root: RootState) => root.slidingPanels)
  const [ page, setPage ] = useState(1)

  useEffect(() => {
    store.dispatch(getCommunications(communicationSearchInput, communicationSearchFilter, 1))
  }, [ communicationSearchInput, communicationSearchFilter ])

  const renderContent = () => {
    if (communicationsStatus === "init" || communicationsStatus === "loading") {
      return (
        <div className="cp_component_sliding-panels_loading-indicator-container">
          <LoadingIndicator />
        </div>
      )
    }

    if (
      API.isSuccess(communicationsStatus)
      && communicationsContent.length === 0
    ) {
      return (
        <EmptySplash
          bodyText={ translate("No communication groups found!") }
        />
      )
    }

    if (API.isSuccess(communicationsStatus)) {
      return (
        <div className="cp_component_navigation-bar-comms-container">
          <InfiniteScroll
            dataLength={ communicationsContent.length }
            next={ () => {
              store.dispatch(getMoreCommunications(communicationSearchInput, communicationSearchFilter, page + 1))
              setPage((prev) => prev + 1)
            } }
            hasMore={ communicationsStatus.payload.searchCommunicationGroup.totalCount > communicationsContent.length }
          >
            { communicationsContent.map((row) => (
              <Container
                key={ `comm-search-${ row.id }` }
                className="cp_component_navigation-bar-comm-container"
                onClick={ () => {
                  dispatch(resetListViewState())
                  navigate(`/${ vanity }/communications/group/${ row.id }/conversations`)
                  onClose()
                } }
              >
                <Badge
                  className="comm-avatar-badge"
                  variant="dot"
                  anchorOrigin={ {
                    vertical: "top",
                    horizontal: "right",
                  } }
                  overlap="circular"
                  invisible={ row.unreadCount === 0 }
                >
                  <Avatar
                    className="cp_component_navigation-bar-comm-avatar"
                  >
                    { row.name.charAt(0).toUpperCase() }
                  </Avatar>

                </Badge>
                <Tooltip title={ row.name } placement="top">
                  <Typography className="cp_component_navigation-bar-comm-name">{ row.name }</Typography>
                </Tooltip>
                { row.subscribed && <NotificationsNone /> }
              </Container>
            )) }
          </InfiniteScroll>
        </div>
      )
    }

    return (
      <EmptySplash
        bodyText={ translateCommon("An unexpected error occurred!") }
      />
    )
  }

  return (
    <>
      <SearchBar
        onChange={ (e) => dispatch(setCommunicationSearchInput(e.target.value)) }
        filterOptions={ filterOptions }
        filterValue={ communicationSearchFilter }
        setFilterValue={ (value) => dispatch(setCommunicationSearchFilter(value)) }
        lastSubmittedSearch={ communicationSearchInput }
      />
      { renderContent() }
    </>
  )
}

export default CommunicationsContent
