import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"

// List Group Slice Interface and Initial State
export interface PublicCampaignReportState {
  report: Status<GraphQL.GetPublicCampaignReportQuery>
  reportV2: Status<GraphQL.GetPublicCampaignReportV2Query>
  metricDefinitions: Status<GraphQL.GetCampaignReportMetricDefinitionsQuery>
}

const initialState: PublicCampaignReportState = {
  report: "init",
  reportV2: "init",
  metricDefinitions: "init",
}

export const PublicCampaignReport = createSlice({
  name: "PublicCampaignReport",
  initialState,
  reducers: {
    setReport: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicCampaignReportQuery>>,
    ) => ({
      ...state,
      report: action.payload,
    }),
    setReportV2: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicCampaignReportV2Query>>,
    ) => ({
      ...state,
      reportV2: action.payload,
    }),
    setMetricDefinitions: (
      state,
      action: PayloadAction<Status<GraphQL.GetCampaignReportMetricDefinitionsQuery>>,
    ) => ({
      ...state,
      metricDefinitions: action.payload,
    }),
  },
})

export const {
  setReport,
  setReportV2,
  setMetricDefinitions,
} = PublicCampaignReport.actions
export default PublicCampaignReport.reducer

export const fetchPublicCampaignReport = (
  params: GraphQL.GetPublicCampaignReportQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setReport("loading"))
  const reportResult = await API.fetchPublicCampaignReport(params)
  dispatch(setReport(reportResult))
}

export const fetchPublicCampaignReportV2 = (
  params: GraphQL.GetPublicCampaignReportQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setReport("loading"))
  const reportResult = await API.fetchPublicCampaignReportV2(params)
  dispatch(setReportV2(reportResult))
}

export const fetchCampaignReportMetricDefinitions = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setMetricDefinitions("loading"))
  const metricDefinitionsResult = await API.fetchCampaignReportMetricDefinitions()
  dispatch(setMetricDefinitions(metricDefinitionsResult))
}
