import React, { useEffect, useMemo } from "react"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import Content from "./Content"
import EditUrlCard from "./EditUrlCard"
import History from "./History"
import IconButton from "../IconButton"
import Modal from "../Modal"
import Overview from "./Overview"
import PostPreview from "./PostPreview"
import Tabs from "../Tabs"
import { isSuccess } from "../../util/apiClient"
import { openDeliverableMetricModal } from "../../state/campaignDeliverableMetricModalSlice"
import { openEditDeliverableModal } from "../../state/campaignDeliverableModalSlice"
import { openTikTokInviteModal } from "../../state/tikTokInviteLinkModalSlice"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  CampaignPermissionType,
  DeliverableStatus,
  Network,
} from "../../graphql"
import {
  closeDeliverableContentModal,
  getHistory,
  hideDeliverableContentModal,
  openFinalizeConfirmModal,
} from "../../state/campaignDeliverableContentModalSlice"
import { Scope } from "../../util/types"

import "./style.sass"

function ModalCampaignDeliverableContent(): React.JSX.Element {
  const [ tabIndex, setTabIndex ] = React.useState(0)

  const {
    campaign,
    confirmFinalize,
    deliverable,
    edited,
    edittingUrl,
    onClose,
    modalOpen,
    hidden,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const {
    scopes,
    user,
  } = useSelector(({ user: userSlice }) => userSlice)

  const availableAccounts = React.useMemo(() => campaign?.campaignNetworkAccounts || [], [ campaign ])

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const dispatch = useDispatch()

  const getDeliverableHistory = async () => {
    if (!deliverable?.id) return
    dispatch(getHistory({ deliverableId: deliverable.id }))
  }

  useEffect(() => {
    getDeliverableHistory()
  }, [ deliverable ])

  const closeModal = async () => {
    setTabIndex(0)
    dispatch(closeDeliverableContentModal())
    if (onClose) onClose(edited)
  }

  const finalizeDeliverableHandler = () => dispatch(openFinalizeConfirmModal())

  const generateTikTokLink = () => {
    if (!deliverable) return
    setTabIndex(0)
    dispatch(closeDeliverableContentModal())
    dispatch(openTikTokInviteModal({
      deliverableId: deliverable.id,
      deliverableVersion: "v1",
      onClose: () => onClose,
    }))
  }

  const isInValid = React.useMemo(() => {
    if (!deliverable) return true
    if (deliverable.deliverableMedia.length === 0 && !deliverable.deliverableCaption) return true
    if (deliverable.deliverableMedia.length > 0 && !deliverable.deliverableMedia.some((media) => media.accepted)) return true
    if (deliverable.deliverableCaption && !deliverable.deliverableCaption.accepted) return true
    return false
  }, [ deliverable ])

  const primaryButtonLabel = useMemo(() => {
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !deliverable.tiktokTcmOrder?.id
    ) return translate("Generate Invite Link")
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      || deliverable?.contentStatus === DeliverableStatus.Uploaded
    ) return translate("Go Live")
    return translate("Finalize Deliverable")
  }, [ deliverable ])

  const tabs = useMemo(() => (
    [
      { label: translate("Content") },
      { label: translate("History") },
    ]
  ), [ translate ])

  const hasPermission = React.useMemo(() => {
    if (!deliverable || !campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithApprovalPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.Approve)

    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !deliverable.tiktokTcmOrder?.id
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    /*
     Brand Managers with Approval permission can finalize deliverables
    */
    if (
      (deliverable.contentStatus === DeliverableStatus.Pending
        || deliverable.contentStatus === DeliverableStatus.AwaitingContent
      )
        // eslint-disable-next-line max-len
        && (!userTeamIsCreator && brandManagerWithApprovalPermission)
    ) {
      return true
    }

    if (!scopes.includes(Scope.CAMPAIGN_MANAGEMENT) || !scopes.includes(Scope.FEATURE_CAMPAIGN)) {
      return false
    }
    if (deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    return true
  }, [ deliverable, scopes, campaign, user ])

  const showPrimaryButton = React.useMemo(() => {
    if (!deliverable || !campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    /*
      Brand Managers with Approval permission can ONLY finalize deliverables. Hide the button if
      the deliverable is not in a state that can be finalized.
    */
    if (!userTeamIsCreator
      && (deliverable.contentStatus !== DeliverableStatus.AwaitingContent
        && deliverable.contentStatus !== DeliverableStatus.Pending)
    ) {
      return false
    }
    if (deliverable.contentStatus === DeliverableStatus.Live) return false
    if (deliverable?.contentStatus === DeliverableStatus.Uploaded
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    if (deliverable.contentStatus === DeliverableStatus.Finalized
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    if (deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && deliverable.contentStatus === DeliverableStatus.Finalized
      && !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    return true
  }, [ deliverable, scopes ])

  if (!deliverable || !campaign) return <> </>

  const deliverableMetricAvailable = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && deliverable.contentStatus === "LIVE"
    && deliverable.postFormat
    && deliverable.postType

  const source = "modal"

  return (
    <Modal
      hidden={ hidden }
      title={ deliverable.name }
      clipboardTitle={ deliverable.name }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ modalOpen && !confirmFinalize }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ deliverable.contentStatus === DeliverableStatus.Finalized
        && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
        && !deliverable.tiktokTcmOrder?.id
        ? generateTikTokLink
        : finalizeDeliverableHandler
      }
      maxWidth="xl"
      className="cp_component_modal-campaign-deliverable-content"
      hasPrimaryButton={ showPrimaryButton }
      disabled={ isInValid || !hasPermission }
      primaryToolTip={ isInValid
        ? translate("To finalize this deliverable the post media and caption must be approved.") : undefined
      }
    >
      <Overview />
      { edittingUrl && (
        <EditUrlCard
          loadedUrl={ deliverable.liveStatusUrl?.address }
          deliverableId={ deliverable.id }
        />
      ) }
      <div className="cp_component_modal-campaign-deliverable-content-tabs">
        <Tabs
          tabs={ tabs }
          controls={ (
            <>
              { deliverableMetricAvailable && (
              <Button
                label={ translate("Update Metrics") }
                variant="outlined"
                isPrimary={ false }
                onClick={ async () => {
                  dispatch(hideDeliverableContentModal(true))
                  dispatch(openDeliverableMetricModal({
                    deliverable,
                    source,
                  }))
                } }
              />
              ) }
              { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
                <IconButton
                  onClick={ () => {
                    dispatch(
                      openEditDeliverableModal({
                        deliverable,
                        socialAccounts: availableAccounts,
                        updateCallback: onClose,
                        closeCallback: () => {
                          if (onClose) onClose(edited)
                        },
                      }),
                    )
                    dispatch(closeDeliverableContentModal())
                  } }
                >
                  <EditOutlinedIcon />
                </IconButton>
              ) }
              { (deliverable.contentStatus === DeliverableStatus.Live || scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)) && (
                <IconButton
                  disabled={ !deliverable.liveStatusUrl?.address }
                  onClick={ () => {
                    window.open(deliverable.liveStatusUrl?.address || "", "_blank")
                  } }
                >
                  <OpenInNewIcon />
                </IconButton>
              ) }
              {
                deliverable.tiktokTcmOrder?.id && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT) ? (
                  <p className="cp_component_modal-campaign-deliverable-content-tabs-tctm-id">
                    { `${ translate("TTCM") }: ${ deliverable.tiktokTcmOrder?.foreignId }` }
                  </p>
                )
                  : null
              }
            </>
        ) }
          handleChange={ (index) => setTabIndex(index) }
        />
      </div>
      <div className="cp_component_modal-campaign-deliverable-content-columns">
        { tabIndex === 0 && <Content /> }
        { tabIndex === 1 && <History /> }
        <PostPreview />
      </div>
    </Modal>
  )
}

export default ModalCampaignDeliverableContent
