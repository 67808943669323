import React from "react"

import {
  debounce,
  FormControl,
  FormLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { AudienceMetricFormDataProps } from "../AudienceMetricsForm"
import Input, { Props as InputProps } from "../../../../Input"
import LoadingIndicator from "../../../../LoadingIndicator"
import Autocomplete from "../../../../Autocomplete"
import * as API from "../../../../../util/apiClient"
import * as GraphQL from "../../../../../graphql"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../../util/constant"

export default function AudienceLocationForm({
  label,
  setSelected,
  locationType = GraphQL.AudienceLocationType.City,
}: AudienceMetricFormDataProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const [ locationTags, setLocationTags ] = React.useState<GraphQL.AudienceLocation[]>([])
  const [ selectedLocation, setSelectedLocation ] = React.useState<GraphQL.AudienceLocation>()

  // Locations
  const searchLocations = async (startsWith: string) => {
    const result = await API.fetchAudienceLocations(startsWith)

    if (API.isSuccess(result)) {
      setLocationTags(result.payload.searchAudienceLocations.rows.filter((location) => location.type === locationType))
    } else {
      setLocationTags([ {
        code: "", name: translate("Location not found"), type: locationType,
      } ])
    }
  }

  const debouncedSearchLocals = React.useCallback(debounce((e, inputValue) => {
    searchLocations(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchLocations ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    value: NonNullable<string | GraphQL.AudienceLocation> | (string | GraphQL.AudienceLocation)[] | null,
  ) => {
    if (!value) return
    if (typeof value === "string") return
    if (!Array.isArray(value)) {
      setSelected(value)
      setSelectedLocation(value)
    } else {
      value.forEach((location) => {
        if (typeof location === "string") return
        setSelected(location)
        setSelectedLocation(location)
      })
    }
  }

  React.useEffect(() => {
    searchLocations("")
  }, [])

  return (
    <FormControl
      className="audience-metrics-form-metric-form"
      component="fieldset"
      fullWidth={ true }
    >
      <FormLabel component="legend" className="section-label">
        { translate(label) }
      </FormLabel>
      <Autocomplete
        value={ selectedLocation }
        filterSelectedOptions={ true }
        disableClearable={ true }
        forcePopupIcon={ true }
        getOptionLabel={ (location) => {
        // Value selected with enter, right from the input
          if (typeof location === "string") return location
          return location.name
        } }
        ListboxProps={ {
          className: "cp_component_autocomplete_tags-list",
        } }
        onInputChange={ debouncedSearchLocals }
        renderTags={ () => <></> }
        onChange={ onAutoCompleteChange }
        selectOnFocus={ true }
        handleHomeEndKeys={ true }
        id="location-autocomplete"
        options={ [ ...locationTags ].sort() }
        loading={ false }
        loadingText={ <LoadingIndicator size={ 20 } /> }
        renderOption={ (props, { name, type }, state, ownerState) => {
          if (ownerState.loading && state.index > 0) return (<></>)
          if (ownerState.loading) {
            return (
              <li
                { ...props }
              >
                <LoadingIndicator size={ 20 } />
              </li>
            )
          }
          return (
            <li
              { ...props }
              key={ name }
              id={ `locations-autocomplete-${ name.toLowerCase().replaceAll(" ", "_") }` }
            >
              { `${ type }: ${ name }` }
            </li>
          )
        } }
        freeSolo={ true }
        renderInput={ (params) => (
          <Input
            { ...params as InputProps }
            className="auto-complete-location-input"
            placeholder={ translate("Select Location") }
          />
        ) }
      />
    </FormControl>
  )
}
