import React from "react"
import "./style.sass"
import { isSuccess } from "../../../util/apiClient"
import { useDispatch, useSelector } from "../../../state/hooks"
import MetricsRibbonBanner from "./MetricsRibbonBanner"
import SocialAvatar from "../../SocialAvatar"
import AccountInsights from "./AccountInsights"
import PublicAccountDetails from "./PublicAccountDetails"
import ContentSection from "./ContentSection"
import { fetchAudiences } from "../../../state/listConfigurationSlice"
import { highlightMetrics } from "../constants"

export default function PreviewHighlights() {
  // Local variables
  const dispatch = useDispatch()

  // Pull in the list
  const list = useSelector((state) => state.list.list)
  const { highlightsMetrics } = useSelector((state) => state.listConfiguration)

  // Make sure there are insights
  const hasAccountInsights: boolean = React.useMemo(() => (
    highlightsMetrics.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Account Insights")?.metrics
      .find((hmMetric) => hmMetric.code === metric.code))
  ), [ highlightsMetrics ])

  // Make sure there are some content metrics checked
  const hasContentMetrics: boolean = React.useMemo(() => (
    highlightsMetrics.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Content")?.metrics
      .find((hmMetric) => hmMetric.code === metric.code))
  ), [ highlightsMetrics ])

  // Make sure there are public account details
  const hasPublicAccountDetails: boolean = React.useMemo(() => (
    highlightsMetrics.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Public List Account Details")?.metrics
      .find((hmMetric) => (hmMetric.code === metric.code) && ([ "Tags", "Bio", "Rationale" ].includes(hmMetric.name))))
  ), [ highlightsMetrics ])

  // Fetch all audience scores
  React.useEffect(() => {
    if (isSuccess(list)) {
      // Extract the categories to get all the accounts
      const { suggestionListCategories, publicUrl } = list.payload.suggestionListById
      const listCode = publicUrl.slice(publicUrl.lastIndexOf("/") + 1)

      // Get all the social account ids
      const socialAccountIds = suggestionListCategories
        .map((category) => category.suggestionListSocialAccounts).flat()
        .map((suggestAccount) => suggestAccount.socialAccount.id)

      // Fetch the audience values
      dispatch(fetchAudiences(listCode, socialAccountIds))
    }
  }, [ list ])

  // Extract the accounts
  const accounts = React.useMemo(() => {
    if (isSuccess(list)) {
      const { suggestionListCategories } = list.payload.suggestionListById
      return suggestionListCategories
        .map((category) => category.suggestionListSocialAccounts)
        .flat()
    }
    return []
  }, [ list ])

  return (
    <div className="cp_lcp_highlights-previews">
      <h5 className="title_large">Highlights</h5>
      { accounts.map((account) => (
        <div className="cp_lcp_highlights-previews_account-container" key={ account.id }>
          <SocialAvatar
            followers={ account.socialAccount.socialAccountStatistics.followers }
            fullName=""
            isBlacklisted={ false }
            isNSFW={ false }
            isPlaceholder={ false }
            isUnsubscribed={ false }
            network={ account.socialAccount.network }
            profilePictureUrl={ account.socialAccount.profilePictureUrl }
            username={ account.socialAccount.userName }
          />
          <MetricsRibbonBanner key={ account.id } account={ account } />
          { hasPublicAccountDetails && (
            <div>
              <PublicAccountDetails account={ account } />
            </div>
          ) }
          { hasAccountInsights && (
            <div>
              <AccountInsights key={ account.id } account={ account } />
            </div>
          ) }
          { hasContentMetrics && (
            <div>
              <ContentSection key={ account.id } account={ account } />
            </div>
          ) }
        </div>
      )) }
    </div>
  )
}
