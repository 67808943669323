import React, { JSX } from "react"
import ErrorIcon from "@mui/icons-material/Error"
import InputAdornment from "@mui/material/InputAdornment"
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField"
import { OutlinedInputProps } from "@mui/material/OutlinedInput"

import "./style.sass"

// NOTE: There is a known bug within MUI that causes labels to be stuck
// open if they are adorned, even when no value is present. Therefore,
// the Input component will enforce the use of placeholders instead
// of floating labels.
// There's a possibility this bug is fixed with the coming of MUI v6. If
// and when this project is updated to use MUI v6 in the future, this
// approach can be revisited. But for now, UX and engineering have agreed
// to forego the use of floating labels entirely. Labels on this prop
// will render above the input, in line with the Figma mockups.
export interface Props extends Omit<
  OutlinedTextFieldProps, "variant"
> {
  isValueCaps?: boolean
  mode?: "default" | "thin"
}

const errorAdornment: JSX.Element = (
  <InputAdornment position="end">
    <ErrorIcon className="cp_component_input-error-adornment" />
  </InputAdornment>
)

function getInputPropsWithoutEndAdornment(
  inputProps?: Partial<OutlinedInputProps>,
): Partial<OutlinedInputProps> {
  if (!inputProps) return {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { endAdornment, ...restInputProps } = inputProps
  return restInputProps
}

// NOTE: Inputs with adornments should use placeholder instead of
// label, labels and adornments do not play nice with MUI
export default function Input(props: Props): JSX.Element {
  const {
    className: additionalClasses,
    error,
    InputProps,
    label,
    isValueCaps = true,
    ...restInputComponentProps
  } = props

  const inputClasses: string = [
    "cp_component_input",
    props.mode === "thin" ? "cp_component_input-thin" : "",
    additionalClasses == null || additionalClasses === ""
      ? false : additionalClasses,
  ].filter(Boolean).join(" ")

  // In the event that an explicit end adornment is passed but the field
  // is in an error state, override end adornment by replacing with error
  // adornment
  const inputPropsWithErrorAdornment = {
    endAdornment: errorAdornment,
    ...getInputPropsWithoutEndAdornment(),
  }

  return (
    <div className={ inputClasses }>
      { label && <p className={ (isValueCaps) ? "label_small-caps-bold" : "label_small" }>{ label }</p> }
      <TextField
        error={ error }
        InputProps={ error ? inputPropsWithErrorAdornment : InputProps }
        variant="outlined"
        { ...restInputComponentProps }
      />
    </div>
  )
}
