/* eslint-disable max-len */
import React from "react"
import { useTranslation } from "react-i18next"

import EmptySplash from "../../EmptySplash"

import { ReactComponent as EngagementRateOverTimeIcon } from "../../../asset/visualizations/LIST_ENGAGEMENT_RATE_EX.svg"
import { ReactComponent as EngagementByPostTypeOSIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_EX.svg"
import { ReactComponent as EngagementByPostTypeRNIcon } from "../../../asset/visualizations/LIST_ENG_POST_TYPE_REEL_EX.svg"
import { ReactComponent as EngagementHeatMapIcon } from "../../../asset/visualizations/LIST_ENG_HEATMAP_EX.svg"
import { ReactComponent as RecentMediaAiAnalysisIcon } from "../../../asset/visualizations/LIST_RECENT_MEDIA_AI_EX.svg"
import { ReactComponent as BrandsMentionedIcon } from "../../../asset/visualizations/LIST_BRANDS_MENTIONED_EX.svg"
import { ReactComponent as RecentMedialStoriesIcon } from "../../../asset/visualizations/LIST_RECENT_STORIES_EX.svg"
import { ReactComponent as PersonalityTraitsIcon } from "../../../asset/visualizations/LIST_PERSONALITY_TRAITS_EX.svg"
import { hasAnyToggle } from "./AudienceHelper"
import { ACCOUNT_INSIGHTS_DISPLAY_TOGGLES } from "../../../util/constant"
import { SuggestionListToggleGroupType, SuggestionListToggleInput } from "../../../graphql"
import { useSelector } from "../../../state/hooks"

export default function AccountInsightsVisualizations() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  // Pull the list form to check toggles
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)
  if (!updateListForm) return null

  const toggles: SuggestionListToggleInput[] = React.useMemo(() => (
    (Array.isArray(updateListForm.toggles)) ? updateListForm.toggles : [ updateListForm.toggles ]
  ), [ updateListForm ])

  const selectedToggles = React.useMemo((): SuggestionListToggleInput[] => (
    toggles.filter((t) => t.type === SuggestionListToggleGroupType.AccountInsightsToggles)
      .sort((t1, t2) => t1.order - t2.order)
  ), [ updateListForm ])

  if (!hasAnyToggle(toggles, ACCOUNT_INSIGHTS_DISPLAY_TOGGLES)) {
    return (
      <div className="cp_lcp-preview_example-visualizations insights">
        <EmptySplash headlineText={ translate("You have no filters selected!") } />
      </div>
    )
  }

  const getPreviewElement = (toggle: SuggestionListToggleInput): React.JSX.Element | null => {
    switch (toggle.name) {
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME:
        return <EngagementRateOverTimeIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE:
        return <EngagementByPostTypeOSIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL:
        return <EngagementByPostTypeRNIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP:
        return <EngagementHeatMapIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS:
        return <RecentMediaAiAnalysisIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH:
        return <BrandsMentionedIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES:
        return <RecentMedialStoriesIcon />
      case ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS:
        return <PersonalityTraitsIcon />
      default:
        return null
    }
  }

  return (
    <div className="cp_lcp-preview_example-visualizations">
      { selectedToggles.map((toggle) => getPreviewElement(toggle)) }
    </div>
  )
}
