import React from "react"
import { useTranslation } from "react-i18next"

import "./content-note.sass"

interface DeliverableContentNoteProps {
  note?: string | null
}

function DeliverableContentNote({ note }: DeliverableContentNoteProps): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })
  if (!note) return <> </>
  return (
    <div id="cp_campaign-deliverable-content-note-v2" className="cp_campaign-deliverable-content-note-v2">
      <p className="body_large">{ translate("Deliverable Note") }</p>
      <p className="cp_campaign-deliverable-content-note-v2-text">{ note }</p>
    </div>
  )
}

export default DeliverableContentNote
