import * as API from "./index"
import * as SearchGQL from "../../graphql/search"

// eslint-disable-next-line import/prefer-default-export
export const search2Accounts = async (
  params: SearchGQL.Search2AccountsQueryVariables,
) => (
  API.query<
    SearchGQL.Search2AccountsQuery,
    SearchGQL.Search2AccountsQueryVariables
  >(
    SearchGQL.Search2AccountsDocument,
    params,
    undefined,
    { apiVersion: "search", timeout: 30000 },
  )
)

export const search2Posts = async (
  params: SearchGQL.Search2PostsQueryVariables,
  signal?: AbortSignal,
) => (
  API.query<
    SearchGQL.Search2PostsQuery,
    SearchGQL.Search2PostsQueryVariables
  >(
    SearchGQL.Search2PostsDocument,
    params,
    undefined,
    { apiVersion: "search", fetchOptions: { signal } },
  )
)
