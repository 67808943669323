import React, { useMemo } from "react"
import dayjs from "dayjs"

import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { useApolloClient, useMutation } from "@apollo/client"
import { Container } from "@mui/material"
import * as Constant from "../../util/constant"
import Divider from "../Divider"

import "./feedback-card.sass"
import {
  DeliverableItemFeedbackItem,
  GET_DELIVERABLE_HISTORY,
  GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
  UPDATE_DELIVERABLE_ITEM_FEEDBACK_VISIBILITY,
  UpdateDeliverableItemFeedbackVisibilityInput,
  UpdateDeliverableItemFeedbackVisibilityOutput,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import EntityInfoRow from "../EntityInfoRow"
import { useSelector } from "../../state/hooks"
import { Scope } from "../../util/types"

interface DeliverableFeedbackCardProps {
  feedback: DeliverableItemFeedbackItem
}

function DeliverableFeedbackCard({ feedback }: DeliverableFeedbackCardProps): React.JSX.Element {
  const formattedDate = useMemo(() => dayjs(parseInt(feedback.timestamp, 10))
    .format(Constant.LONGFORM_DATE_TIME), [ feedback.timestamp ])
  const formattedSubInfo = feedback.reviewerGroupName
    ? `${ feedback.reviewerGroupName } | ${ feedback.user.customerName }`
    : feedback.user.customerName

  const { scopes } = useSelector(({ user }) => user)
  const client = useApolloClient()

  const refreshQueries = async () => {
    await client.refetchQueries({
      include: [
        GET_DELIVERABLE_HISTORY,
        GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
      ],
    })
  }

  const [ updateVisibility ] = useMutation<
    UpdateDeliverableItemFeedbackVisibilityOutput,
    UpdateDeliverableItemFeedbackVisibilityInput
    >(UPDATE_DELIVERABLE_ITEM_FEEDBACK_VISIBILITY)

  const setVisibility = async (newVisibility: boolean) => {
    await updateVisibility({
      variables: {
        deliverableItemId: feedback.id,
        isHidden: !newVisibility,
      },
      context: {
        apiVersion: "v2",
      },
    })
    refreshQueries()
  }

  const renderVisibilityIcon = () => {
    if (!feedback.visibility) {
      return (
        <Container className="visibility-icon-toggle" onClick={ () => setVisibility(true) }>
          <VisibilityOffOutlined />
        </Container>
      )
    }
    return (
      <Container className="visibility-icon-toggle" onClick={ () => setVisibility(false) }>
        <VisibilityOutlined />
      </Container>
    )
  }

  return (
    <div className="cp_media-feedback-card_component">
      <div className="cp_media-feedback-card_component-header">
        <EntityInfoRow
          className="user-info-row"
          avatarSrc={ feedback.user.avatarUrl }
          name={ feedback.user.userName }
          subInfo={ formattedSubInfo }
        />
        <div className="header-right">
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && renderVisibilityIcon() }
          <div className="cp_media-feedback-card_component-header-date">
            { formattedDate }
          </div>
        </div>
      </div>
      <Divider />
      <div
        className="cp_media-feedback-card_component-body"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: feedback.content } }
      />
    </div>
  )
}

export default DeliverableFeedbackCard
