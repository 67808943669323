import React, { useMemo } from "react"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useTranslation } from "react-i18next"

import "./engagement-chart.sass"
import { Network } from "../../../graphql"
import { useSelector } from "../../../state/hooks"
import { prettyPrintDecimal } from "../../../util/miscHelper"
import { CHART_BASE_COLORS } from "../../../util/constant"
import * as GraphQL from "../../../graphql"

const baseOptions: Options = {
  chart: {
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
    },
    floor: 0,
    gridLineDashStyle: "Dash",
    allowDecimals: true,
    labels: {
      format: "{value:.2f}",
    },
  },
  tooltip: {
    pointFormatter(this: Point) {
      // eslint-disable-next-line max-len
      return `<span style="color:${ this.color }">\u25CF</span> ${ this.series.name }: <b>${ prettyPrintDecimal((this.y || 0) / 100, 3) }%</b><br/>`
    },
  },
  credits: {
    enabled: false,
  },
}

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}

export default function EngagementChart({ socialProfile } : Props) {
  const theme = useSelector(({ themeMode }) => themeMode.theme)
  const { t: translate } = useTranslation([], { keyPrefix: "component.EngagementChart" })

  const { userName, socialAccountStatistics } = socialProfile
  const stats = socialAccountStatistics.monthlyEngagementSummary
  const getOptions = () => {
    baseOptions.xAxis = {
      categories: stats.map(({ month }) => month.toUpperCase()),
    }
    baseOptions.legend = {
      borderWidth: 1,
      borderRadius: 2,
      padding: 16,
    }
    baseOptions.series = []
    baseOptions.series.push({
      name: userName,
      type: "line",
      data: stats.map(({ avgEngagementRate }) => avgEngagementRate * 100),
      color: CHART_BASE_COLORS[theme][1],
    })
    baseOptions.series.push({
      name: "Network",
      type: "line",
      data: stats.map(({ networkAvgEngagementRate }) => networkAvgEngagementRate * 100),
      marker: {
        symbol: "diamond",
      },
      color: CHART_BASE_COLORS[theme][5],
    })
    return baseOptions
  }

  const averageLikeCount = stats.map(({ avgLikeCount }) => avgLikeCount)
    .reduce((a, b) => a + b) / stats.length
  const averageCommentCount = stats.map(({ avgCommentCount }) => avgCommentCount)
    .reduce((a, b) => a + b) / stats.length
  const averageViewCount = stats.map(({ avgViewCount }) => avgViewCount)
    .reduce((a, b) => a + b) / stats.length

  const showAverageViews = useMemo(() => {
    switch (socialProfile.network) {
      case Network.Youtube:
      case Network.Tiktok:
      case Network.Snapchat:
        return true
      default:
        return false
    }
  }, [ socialProfile.network ])

  const showAverageLikes = useMemo(() => {
    switch (socialProfile.network) {
      case Network.Snapchat:
        return false
      default:
        return true
    }
  }, [ socialProfile.network ])

  const showAverageComments = useMemo(() => {
    switch (socialProfile.network) {
      case Network.Snapchat:
        return false
      default:
        return true
    }
  }, [ socialProfile.network ])

  return (
    <div className="cp_engagement-chart_component">
      <div className="cp_engagement-chart_component-title-container">
        <h6 className="cp_engagement-chart_component-title">
          { translate("Engagement Rate Over Time") }
        </h6>
        <div className="cp_engagement-chart_component-title-metrics">
          { showAverageLikes && (
          <div className="cp_engagement-chart_component-title-metric">
            <p className="cp_engagement-chart_component-title-metric-value">
              { translate("Format Number", { value: Math.round(averageLikeCount) }) }
            </p>
            <p className="cp_engagement-chart_component-title-metric-name">
              { translate("avg. likes") }
            </p>
          </div>
          ) }
          { showAverageComments && (
          <div className="cp_engagement-chart_component-title-metric">
            <p className="cp_engagement-chart_component-title-metric-value">
              { translate("Format Number", { value: Math.round(averageCommentCount) }) }
            </p>
            <p className="cp_engagement-chart_component-title-metric-name">
              { translate("avg. comments") }
            </p>
          </div>
          ) }
          { showAverageViews && (
          <div className="cp_engagement-chart_component-title-metric">
            <p className="cp_engagement-chart_component-title-metric-value">
              { translate("Format Number", { value: Math.round(averageViewCount) }) }
            </p>
            <p className="cp_engagement-chart_component-title-metric-name">
              { translate("avg. views") }
            </p>
          </div>
          ) }
        </div>
      </div>
      <div className="cp_engagement-chart_component-chart">
        <HighchartsReact
          highcharts={ Highcharts }
          options={ getOptions() }
          updateArgs={ [ true ] }
        />
      </div>
    </div>
  )
}
