import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"
import { SuggestionListMode } from "../../graphql"
import CampaignCTA from "./CampaignCTA"
import Overview from "./Overview"
import Button from "../../component/Button"
import AccountPageTabs from "./AccountPageTabs"
import { PublicListApprovalStatus } from "../../util/types"
import ApprovalOverlay from "./ApprovalOverlay"
import FeedbackOverlay from "./FeedbackOverlay"

/**
 * PageContent: Renders the content of the page
 * @returns The JSX Elements to render the content
 */
export default function PageContent() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })
  const {
    tabPath, contentTabPath,
  } = useParams()
  const navigate = useNavigate()

  // Global state
  const {
    loadedListSocialAccount,
    list,
    leftPanelApprovedStatus,
    leaveFeedback,
  } = useSelector((state) => state.publicList)

  // React hook to determine if the list is in campaign mode
  const isCampaignMode = React.useMemo((): boolean => {
    // Check the state of the list retrieval
    if (API.isSuccess(list)) {
      // Return the true if the list is in campaign mode, otherwise false
      return (list.payload.publicSuggestionListByCode.suggestionListMode === SuggestionListMode.Campaign)
    }
    // List isn't loaded, so just return false
    return false
  }, [ list ])

  // React hood to retrieve the approval state for given account
  const approveStatus = React.useMemo((): PublicListApprovalStatus => {
    // Check to make sure that account is loaded
    if (API.isSuccess(loadedListSocialAccount)) {
      // Extract the suggestion list account id
      const { id: accountId } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      // Find the approve status and return status or not approved (if not found)
      return leftPanelApprovedStatus
        .find((status) => status.accountId === accountId)?.status || PublicListApprovalStatus.NotApproved
    }
    // Return default not approved if list isn't loaded
    return PublicListApprovalStatus.NotApproved
  }, [ leftPanelApprovedStatus, loadedListSocialAccount ])

  // Check to see if list social account is loading still
  if (loadedListSocialAccount === "init" || loadedListSocialAccount === "loading") {
    return (
      <div className="cp_component_pl-account-page-loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  // Check to see if there was an error loading the list account
  if (API.isError(loadedListSocialAccount)) {
    return (
      <div className="cp_component_pl-account-page-error">
        { translate("Unable to load public list account") }
      </div>
    )
  }

  // Function to handle going back to the summary page
  const handleGoBackToSummary = () => {
    const levelsUp = contentTabPath ? 4 : tabPath ? 3 : 2
    const relativePath = "../".repeat(levelsUp) // Generate the correct relative path
    navigate(relativePath, { relative: "path" })
  }

  // Return the page content
  return (
    <div className="cp_component_pl-account-page-content">
      <div className="cp_component_pl-account-page-content-header">
        <div className="cp_component_pl-account-page-content-header-breadcrumbs">
          <Button
            isTextButton={ true }
            label={ translate("< Back to Summary") }
            onClick={ () => { handleGoBackToSummary() } }
          />
        </div>
        <div className="cp_component_pl-account-page-content-header-cta">
          { (isCampaignMode) && (
            <CampaignCTA />
          ) }
        </div>
      </div>
      {
        (leaveFeedback) ? <FeedbackOverlay /> : <Overview />
      }
      {
        (approveStatus === PublicListApprovalStatus.PendingApproval)
          ? <ApprovalOverlay />
          : (leaveFeedback)
            ? null
            : <AccountPageTabs />
      }
    </div>
  )
}
