import React, { JSX } from "react"

import {
  Collapse,
  IconButton,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material"
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from "@mui/material/OverridableComponent"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { TYPOGRAPHY } from "../../style/mui-styles"

import "./filter-panel.sass"
import Badge from "../Badge"

type MuiIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
}
type CustomIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>

interface Props {
  title?: string
  panelIcon?: MuiIcon
  customPanelIcon?: CustomIcon
  filterCount: number
  panelID: string
}

export default function AINetworkFilter({
  title = "", panelIcon, children, customPanelIcon, filterCount, panelID,
}: React.PropsWithChildren<Props>): JSX.Element {
  const [ expanded, setExpanded ] = React.useState(true)

  return (
    <Stack className="cp_component-ai-filter-panel" borderRadius="2px" id={ panelID }>
      <Stack
        className="cp_component-ai-filter-panel-head"
        padding={ 1 }
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" flex={ 1 } gap={ 1 }>
          { panelIcon && React.createElement(panelIcon, { className: `filter-panel-icon ${ expanded ? "expanded" : "" }` }) }
          { customPanelIcon
            && React.createElement(customPanelIcon, { className: `filter-panel-icon ${ expanded ? "expanded" : "" }` })
          }
          <Typography fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLD } fontSize="0.9rem">
            { title }
          </Typography>
          <Badge
            style={ {
              marginLeft: "0.5rem",
            } }
            slotProps={ {
              badge: {
                style: {
                  minWidth: "1rem",
                  height: "1rem",
                  padding: 0,
                },
              },
            } }
            badgeContent={ filterCount }
            showZero={ false }
          />
        </Stack>
        <IconButton
          id={ `${ panelID }-expand-button` }
          size="small"
          onClick={ () => setExpanded(!expanded) }
          className={ expanded ? "expanded" : "" }
        >
          <ExpandMoreIcon />
        </IconButton>
      </Stack>

      <Collapse in={ expanded }>
        { children }
      </Collapse>
    </Stack>
  )
}
