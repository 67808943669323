/* eslint-disable no-shadow */
export enum PostTrackerStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    EXPIRED = "EXPIRED",
  }

export enum ReviewerGroupStatus {
    UNDER_REVIEW = "UNDER_REVIEW",
    FEEDBACK_COMPLETE = "FEEDBACK_COMPLETE",
    GROUP_DELETED = "GROUP_DELETED",
  }

export interface DeliverableSummary{
    id: string
    name: string
    caption: string
    deliverableStatus: string
    feedbackCount: number
    networkId: string
    socialAccount: SocialAccountSummary
    ttcmCampaignName?: string
    mediaUrl: string
    postTrackerId: string
    postTracker: PostTracker
    reviewerGroupName: string
    reviewerGroupStatus: ReviewerGroupStatus
}

export interface SocialAccountSummary{
    id: string
    name: string
    username: string
    networkType: string
    profilePictureUrl: string
    followerCount: number
}

export interface PostTracker{
    id: string
    enabled: boolean
    status: PostTrackerStatus
}
