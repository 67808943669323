/* eslint-disable max-len */
import React, { useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { useTranslation } from "react-i18next"
import {
  InputAdornment,
  Badge,
  IconButton,
  FormControlLabel,
} from "@mui/material"

import Avatar from "../Avatar"
import AvatarUploader from "../AvatarUploader"
import Input from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import Modal from "../Modal"
import { FileResponse } from "../../util/types"
import {
  createCustomerUser,
  isSuccess,
  fetchCustomerUser,
  updateCustomerUser,
  updatePasswordForCustomerUser,
} from "../../util/apiClient"
import {
  uploadFile,
  emailRegex,
  userNameRegex,
} from "../../util/miscHelper"
import { useSelector, useDispatch } from "../../state/hooks"
import { RoleFragment, UpdateUserForCustomerMutationVariables } from "../../graphql"

import "./style.sass"
import { closeCustomerUserModal } from "../../state/customerUserModalSlice"
import PositionAutoComplete from "../PositionAutoComplete"
import RoleAutoComplete from "../RoleAutoComplete"
import { pushToast } from "../../state/toastSlice"
import Pill from "../Pill"
import Checkbox from "../Checkbox"

function ModalCustomerUser(): React.JSX.Element {
  const [ avatar, setAvatar ] = React.useState<FileResponse | undefined>()
  const [ fullName, setFullName ] = React.useState("")
  const [ userName, setUserName ] = React.useState("")
  const [ userNameError, setUserNameError ] = React.useState(false)
  const [ email, setEmail ] = React.useState("")
  const [ emailError, setEmailError ] = React.useState(false)
  const [ selectedPosition, selectPosition ] = React.useState<string>("")
  const [ selectedRole, selectRole ] = React.useState<RoleFragment | undefined>(undefined)
  const [ password, setPassword ] = React.useState("")
  const [ passwordError, setPasswordError ] = React.useState(false)
  const [ passwordVisible, setPasswordVisible ] = React.useState(false)
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)
  const [ fetchingUser, setFetchingUser ] = React.useState(false)
  const [ overrideEmailVerification, setOverrideEmailVerification ] = React.useState(false)

  const {
    openCustomerUserModal, isEditingUser, userToEdit, userIdToEdit, updateCallback, createCallback, customerId,
  } = useSelector(({ customerUserModal }) => customerUserModal)

  const dispatch = useDispatch()

  const resetState = () => {
    setAvatar(undefined)
    setFullName("")
    setUserName("")
    setEmail("")
    selectPosition("")
    selectRole(undefined)
    setPassword("")
    setPasswordVisible(false)
    setUserNameError(false)
    setEmailError(false)
    setOverrideEmailVerification(false)
    setOverrideEmailVerification(false)
  }

  const loadCustomerUser = async () => {
    if (!isEditingUser || !userIdToEdit) return
    setFetchingUser(true)
    const result = await fetchCustomerUser({ userId: userIdToEdit })
    setFetchingUser(false)
    if (isSuccess(result) && result.payload?.userForCustomerManagement) {
      const user = result.payload.userForCustomerManagement
      if (user.contact.avatar) setAvatar({ id: user.contact.avatar.id, url: user.contact.avatar.url.address })
      setFullName(user.contact.name)
      setUserName(user.username)
      setEmail(user.contact.email.address)
      if (user.contact.title) selectPosition(user.contact.title)
      if (user.role) selectRole(user.role)
    }
  }

  useEffect(() => {
    loadCustomerUser()
  }, [ openCustomerUserModal ])

  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCustomerUser" })

  const closeModal = () => {
    resetState()
    dispatch(closeCustomerUserModal())
  }

  const validateInputs = (): boolean => {
    let isValid = true
    if (emailRegex.test(email) === false) {
      setEmailError(true)
      isValid = false
    }
    if (userNameRegex.test(userName) === false) {
      setUserNameError(true)
      isValid = false
    }

    if (isEditingUser && password.length > 0 && password.length < 8) {
      setPasswordError(true)
      isValid = false
    }
    return isValid
  }

  const createUserHandler = async () => {
    if (!validateInputs()) return
    if (!selectedRole) return
    setLoadingPrimaryAction(true)
    const payload = {
      customerId,
      name: fullName,
      title: selectedPosition || null,
      username: userName,
      password,
      email,
      roleId: selectedRole.id,
      avatarMediaId: avatar?.id ? `${ avatar.id }` : undefined,
    }
    const mutationResult = await createCustomerUser(payload)
    setLoadingPrimaryAction(false)
    if (isSuccess(mutationResult)) {
      closeModal()
      if (createCallback) createCallback(mutationResult.payload?.createUser)
    } else {
      dispatch(
        pushToast({
          message: translate("Failed to create user"),
          additionalInfo: translate("Make sure the username is not already taken"),
          type: "error",
        }),
      )
    }
  }

  const updateUserHandler = async () => {
    if (!userToEdit || !userIdToEdit) return
    if (!validateInputs()) return
    if (!selectedRole) return
    setLoadingPrimaryAction(true)
    const payload: UpdateUserForCustomerMutationVariables = {
      userId: userIdToEdit,
      name: fullName,
      customerId,
      title: selectedPosition || null,
      username: userName,
      email,
      roleId: selectedRole.id,
      avatarMediaId: avatar?.id ? `${ avatar.id }` : undefined,
      overrideEmailVerification,
    }

    if (password.length > 0) {
      const result = await updatePasswordForCustomerUser({
        userId: userIdToEdit,
        newPassword: password,
        customerId,
      })
      if (!isSuccess(result)) {
        dispatch(
          pushToast({
            message: translate("Failed to update user"),
            additionalInfo: translate("Make sure the username is not already taken"),
            type: "error",
          }),
        )
      }
    }
    const mutationResult = await updateCustomerUser(payload)

    setLoadingPrimaryAction(false)
    if (isSuccess(mutationResult)) {
      closeModal()
      if (updateCallback) updateCallback(mutationResult.payload?.updateUserForCustomer)
    } else {
      dispatch(
        pushToast({
          message: translate("Failed to update user"),
          type: "error",
        }),
      )
    }
  }

  const isValid = React.useMemo(() => {
    switch (true) {
      case fullName.length === 0:
      case userName.length === 0:
      case email.length === 0:
      case !selectedRole:
      case !isEditingUser && password.length < 8:
        return false
      default:
        return true
    }
  }, [ fullName, userName, email, selectedRole, password ])

  const primaryButtonLabel = React.useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return translate("Save User")
  }, [ isEditingUser, loadingPrimaryAction ])

  const handleClientAvatarUpdate = async (file: File) => {
    const fileStatus = await uploadFile(file)
    if (fileStatus.id) {
      setAvatar({ id: fileStatus.id, url: fileStatus.url })
    }
  }

  return (
    <Modal
      title={ isEditingUser ? translate("Edit User") : translate("Create User") }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ openCustomerUserModal }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ isEditingUser ? updateUserHandler : createUserHandler }
      maxWidth="lg"
      className="cp_component_modal-customer-user"
      disabled={ !isValid || loadingPrimaryAction }
    >
      { fetchingUser ? (
        <LoadingIndicator size={ 30 } flexWrapperEnabled={ true } />
      ) : (
        <div className="cp_component_modal-customer-user_row">
          <div className="cp_component_modal-customer-user_column">
            <div className="cp_component_modal-customer-user-details">
              <div className="cp_component_modal-customer-user_photo">
                { avatar ? (
                  <Badge
                    className="cp_component_modal-customer-user_photo-badge"
                    badgeContent={ (
                      <IconButton
                        className="cp_avatar-removal"
                        aria-label="delete"
                        onClick={ () => setAvatar(undefined) }
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    ) }
                    anchorOrigin={ {
                      vertical: "bottom",
                      horizontal: "right",
                    } }
                  >
                    <Avatar src={ avatar.url } />
                  </Badge>
                ) : (
                  <AvatarUploader
                    customClasses="cp_component_modal-customer-user_photo-uploader"
                    title={ translate("User Thumbnail") }
                    infoText={ translate("Click to upload a photo") }
                    onAvatarSelect={ handleClientAvatarUpdate }
                  />
                ) }
              </div>
              <div className="cp_component_modal-customer-user-details-label">
                <p className="label_small-caps-bold">
                  { translate("Full Name") }
                  <sup>*</sup>
                </p>
                <p className="label_small-caps-bold counter">
                  { fullName.length }
                  /60
                </p>
              </div>
              <Input
                id="cp_component_modal-customer-user-details-input_name"
                value={ fullName }
                onChange={ (e) => setFullName(e.currentTarget.value) }
                fullWidth={ true }
                InputProps={ {
                  inputProps: { maxLength: 60 },
                } }
                placeholder={ translate("Enter Full Name") }
              />
              <div className="cp_component_modal-customer-user-details-label-username">
                <p className="label_small-caps-bold">
                  { translate("Unique Username") }
                  *
                </p>
                <p className="label_small-caps-bold counter">
                  { userName.length }
                  /20
                </p>
              </div>
              <Input
                value={ userName }
                id="cp_component_modal-customer-user-details-input_username"
                className="cp_component_modal-customer-user-details-input_username"
                onChange={ (e) => setUserName(e.currentTarget.value) }
                onBlur={ () => setUserNameError(false) }
                fullWidth={ true }
                InputProps={ {
                  inputProps: { maxLength: 20 },
                } }
                error={ userNameError }
                placeholder={ translate("Enter Code") }
                helperText={ `${ userNameError ? translate("Invalid Unique Username.") : "" }
                ${ translate(
                  "Username length must be between 1 and 20 characters, may include only upper and lower case letters (a-z, A-Z), digits (0-9) and the underscore (_) symbol.",
                ) }` }
              />
              { isEditingUser && (
                <div>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        id="cp_component_modal-customer-user-details-checkbox-email"
                        checked={ overrideEmailVerification }
                        onChange={ () => setOverrideEmailVerification(!overrideEmailVerification) }
                      />
                    ) }
                    label={ translate("Override Email Verification") }
                  />
                  <p className="cp_component_modal-customer-user-details-checkbox-email-label">
                    { translate(
                      "Checking this box will prevent the system from sending a verification email to this user.",
                    ) }
                  </p>
                </div>
              ) }
              <Input
                value={ email }
                id="cp_component_modal-customer-user-details-input_email"
                onChange={ (e) => setEmail(e.currentTarget.value) }
                onBlur={ () => setEmailError(false) }
                error={ emailError }
                helperText={ userNameError ? translate("Invalid Email Address") : undefined }
                fullWidth={ true }
                type="email"
                label={ `${ translate("Email Address") }*` }
                placeholder={ translate("Enter Email Address") }
              />
              <div className="cp_component_modal-customer-user-details-label">
                <p className="label_small-caps-bold">{ translate("Position") }</p>
              </div>
              <PositionAutoComplete
                setPositionHandler={ (position: string) => {
                  selectPosition(position)
                } }
                selectedPosition={ selectedPosition }
                customerId={ customerId }
              />
              <div className="cp_component_modal-customer-user-details-label">
                <p className="label_small-caps-bold">
                  { translate("Role") }
                  *
                </p>
              </div>
              <RoleAutoComplete
                setSelectedRoles={ (newRoles: RoleFragment[]) => {
                  selectRole(newRoles[newRoles.length - 1])
                } }
                selectedRoles={ selectedRole ? [ selectedRole ] : [] }
              />
              <div className="cp_component_modal-customer-user-details-pills">
                { selectedRole && (
                  <Pill
                    id="cp_component_modal-customer-user-details-pills-role"
                    label={ `${ selectedRole.display } (${ selectedRole.code })` }
                    key={ selectedRole.id }
                    deleteIcon={ <CloseIcon /> }
                    onDelete={ () => selectRole(undefined) }
                  />
                ) }
              </div>
              <div className="cp_component_modal-customer-user-details-label">
                <p className="label_small-caps-bold">
                  { translate("Password") }
                  *
                </p>
              </div>
              <Input
                id="cp_component_modal-customer-user-details-input_password"
                value={ password }
                onBlur={ () => setPasswordError(false) }
                error={ passwordError }
                helperText={ passwordError ? translate("Invalid Password") : undefined }
                className="cp_component_modal-customer-user-details-input-password"
                onChange={ (e) => setPassword(e.currentTarget.value) }
                fullWidth={ true }
                type={ passwordVisible ? "text" : "password" }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={ () => setPasswordVisible(!passwordVisible) }>
                        { passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                      </IconButton>
                    </InputAdornment>
                  ),
                } }
                placeholder={ translate("Enter Password") }
              />
            </div>
          </div>
        </div>
      ) }
    </Modal>
  )
}

export default ModalCustomerUser
