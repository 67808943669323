import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import "./style.sass"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { EditOutlined, Tune as TuneIcon } from "@mui/icons-material"
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import GroupAccountsLabels from "./labels"
import { useDispatch, useSelector } from "../../../state/hooks"
import { RootState } from "../../../state/store"
import * as TableContent from "./TableContent"
import {
  getCommunicationGroup,
  getGroupAccounts,
  setGroupAccountReloadStatus,
} from "../../../state/communicationsPage"
import {
  ContactModalProps,
  MountContext,
  resetContactInfoModal,
  setContactInfoModalData,
  setContactInfoModalOpen,
  setSocialAccountId,
  setSocialAvatarInfo,
} from "../../../state/accountContactInfoModal"
import { setModalOpenState as setFiltersModalState } from "../../../state/ModalGroupAccountsFilterSlice"
import { setEditCommGroupID, setCommGroupModalOpen } from "../../../state/ModalCommGroupSlice"
import SearchBar from "../../SearchBar"
import IconButton from "../../IconButton"
import { Scope } from "../../../util/types"
import Button from "../../Button"
import {
  MessageRecipient,
  resetSelectedRecipients,
  setIsBatch,
  setSelectedRecipients,
} from "../../../state/modalCreateMessageSlice"

/**
 * Type for the parameter, when clicking on a cell in the table
 */
type TableDataGridParams = GridCellParams<any, unknown, unknown, GridTreeNode>

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccounts() {
  // ************************ Local state ************************
  const [ rowSelections, setRowSelections ] = useState<GridRowSelectionModel>([])
  const [ searchInputValue, setSearchInputValue ] = useState("")

  // ************************ Component variables ************************
  const navigate = useNavigate()
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })
  const { t: translateContact } = useTranslation([], { keyPrefix: "component.GroupAccountsContactInfoModal" })
  const dispatch = useDispatch()
  const { commGroupID, vanity } = useParams()
  const tableHeaders: GridColDef[] = TableContent.getColumnHeaders(commGroupID || "", searchInputValue, translate)

  // ************************ Selectors ************************
  const { scopes } = useSelector(({ user }) => user)
  const {
    communicationGroupAccounts,
    relaodGroupAccounts: reload,
    communicationGroup: commsGroup,
    groupAccountRows: rawRows,
  } = useSelector((root: RootState) => root.communicationsPage)
  const { selectedFilters: filters } = useSelector((root: RootState) => root.groupAccountsFilters)

  // ************************ React hooks ************************
  const rows: (GridValidRowModel | null)[] = useMemo(() => rawRows
    .filter((row) => TableContent.applyFiltersToRow(row, filters))
    .map((row) => TableContent.getRowData(row)), [ rawRows, filters ])

  /**
   * Called initially to ensure that the communication group information is available in state
   */
  useEffect(() => {
    if (commsGroup === "init" && commGroupID) {
      dispatch(getCommunicationGroup({
        id: commGroupID,
      }))
    }

    return () => {
      dispatch(resetSelectedRecipients())
      dispatch(setIsBatch(false))
    }
  }, [])

  /**
   * Function to load the group accounts for this particular communication group
   */
  useEffect(() => {
    // Check to see if reloading the content after updating contact information
    if (reload) {
      dispatch(setGroupAccountReloadStatus(false))
      dispatch(getGroupAccounts({
        startsWith: searchInputValue,
        communicationGroupId: `${ commGroupID }`,
      }))
    }
  }, [ reload ])

  /**
   * Function to load the group accounts when search input value has changed
   */
  useEffect(() => {
    dispatch(getGroupAccounts({
      startsWith: searchInputValue,
      communicationGroupId: `${ commGroupID }`,
    }))
  }, [ searchInputValue ])

  // ************************ functions ************************
  /**
   * Update the state value for search input, which should trigger a reload of the group accounts
   * @param event The element that contains the information on the search bar
   */
  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Placeholder
    setSearchInputValue(event.target.value)
  }

  /**
   * Open the communication group modal for edit
   */
  const onEditClick = () => {
    // Verify comm group id is valid
    if (commGroupID) {
      // Set the edit com gorup mode
      dispatch(setEditCommGroupID(commGroupID))

      // Open the modal
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const onAddAccountClick = () => {
    // Placeholder
    navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts/search`)
  }

  /**
   * Handles any clicks on cells in the table
   * @param param0 The cell and row that was clicked on in the table
   */
  const cellClickAction = ({ field, row }: TableDataGridParams) => {
    // Check to see if the contact info column was clicked on
    if (field === "contactInfo") {
      // Reset the form
      dispatch(resetContactInfoModal())

      // Set account id
      dispatch(setSocialAccountId(row.account.id))

      // Set the social avatar information
      dispatch(setSocialAvatarInfo({
        ...row.account,
      }))

      // Set modal data
      const modalData: ContactModalProps = {
        firstName: row.contactInfo.firstName,
        lastName: row.contactInfo.lastName,
        primaryEmail: row.contactInfo.email,
        websiteUrl: row.account.websiteUrl,
        emails: row.account.emails,
        emailsFromTeam: row.account.emailsFromTeam,
      }

      // Set required fields
      dispatch(setContactInfoModalData(modalData))

      // Display the modal
      dispatch(setContactInfoModalOpen({
        mountContext: MountContext.CommsGroup,
        onUpdateSuccessText: translateContact("Account contact information has been successfully updated!"),
        onUpdateErrorText: translateContact("Failed to update group account contact information!"),
        onUpdateWarningText: translateContact("No update was made to name, first and last names should both be present!"),
      }))
    }
  }

  const openFilterModal = () => {
    // Open the modal
    dispatch(setFiltersModalState(true))
  }

  const gridStateChange = () => {
    if (rowSelections.length === 0) {
      dispatch(resetSelectedRecipients())
      dispatch(setIsBatch(false))
    } else {
      // Get valid rows
      const validAccounts = rawRows.filter((row) => {
        const hasEmail = row.socialAccount.emails.length + row.socialAccount.emailsSourcedFromTeam.length
        return (hasEmail > 0 && rowSelections.includes(row.id))
      })

      // Get emails addresses
      const result: MessageRecipient[] = []
      const addEmail = (email: string, accountName: string, socialAccountId: string) => {
        result.push({
          accountName,
          email,
          socialAccountId,
        })
      }

      // Add the emails
      validAccounts.forEach((networkAccount) => {
        const { socialAccount: account } = networkAccount

        if (account.primaryEmail) {
          addEmail(account.primaryEmail.address, account.userName, account.id)
        } else if (account.emailsSourcedFromTeam.length > 0) {
          addEmail(account.emailsSourcedFromTeam[0].address, account.userName, account.id)
        } else if (account.emails.length > 0) {
          addEmail(account.emails[0].address, account.userName, account.id)
        }
      })

      // Update the state value
      if (result.length > 1) {
        dispatch(setSelectedRecipients(result))
        dispatch(setIsBatch(true))
      } else {
        dispatch(setSelectedRecipients(result))
        dispatch(setIsBatch(false))
      }
    }
  }

  const noSearchResultsOverlay = () => (
    <div className="communications-body_no-search-results-overlay">
      { translate("No network accounts found") }
    </div>
  )

  const rowIsSelectable = (params: GridRowParams<any>): boolean => {
    // Get the account id
    const { id: accountId } = params.row.account

    // Search in raw rows to find all the emails
    const emails: string[] = rawRows
      .filter((row) => row.socialAccount.id === accountId)
      .flatMap((row) => {
        // Create container for emails
        const rowEmails: string[] = []

        // Add the emails
        if (row.socialAccount.primaryEmail) rowEmails.push(row.socialAccount.primaryEmail.address)
        if (row.socialAccount.emailsSourcedFromTeam.length > 0) rowEmails.push(row.socialAccount.emailsSourcedFromTeam[0].address)
        if (row.socialAccount.emails.length > 0) rowEmails.push(row.socialAccount.emails[0].address)

        // Return the emails
        return rowEmails
      })

    return emails.length > 0
  }

  // ************************ Pre-Render computations ************************

  // Render the component
  return (
    <div className="cp_component_communications-group-accounts-container">
      <div className="header-section">
        <SearchBar id="search-bar" className="search-bar" onChange={ (event) => onSearchInput(event) } />
        <div className="details-container">
          <IconButton id="group-accounts-filter-modal-btn" variant="outlined" onClick={ openFilterModal }>
            <TuneIcon />
          </IconButton>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <GroupAccountsLabels
              rowSelections={ rowSelections.map((rowId) => rowId.toString()) }
              onRowSelectionChange={ setRowSelections }
            />
          ) }
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <>
              <IconButton id="edit-comm-group-btn" variant="outlined" onClick={ onEditClick }>
                <EditOutlined />
              </IconButton>
              <Button
                id="ga-add-account-button"
                className="add-account-button"
                label={ translate("+ ADD ACCOUNT") }
                onClick={ onAddAccountClick }
              />
            </>
          ) }
        </div>
      </div>
      <div className="communications-body">
        <DataGridPro
          isRowSelectable={ rowIsSelectable }
          checkboxSelection={ true }
          onRowSelectionModelChange={ (rowSelectionModel) => setRowSelections(rowSelectionModel) }
          onStateChange={ gridStateChange }
          rowSelectionModel={ rowSelections }
          columns={ tableHeaders }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          getRowHeight={ () => "auto" }
          rows={ rows }
          loading={ (communicationGroupAccounts === "init" || communicationGroupAccounts === "loading") }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "groupsContextMenu" ],
          } }
          onCellClick={ cellClickAction }
          slots={ {
            noRowsOverlay: (searchInputValue.trim().length > 0 || TableContent.hasFilters(filters))
              ? noSearchResultsOverlay : undefined,
          } }
        />
      </div>
    </div>
  )
}
