import React from "react"
import AssignReviewersTab from "./AssignReviewers"
import ReviewerGroupListContainer from "./ReviewerGroupListContainer"

export default function AssignReviewers() {
  return (
    <div className="assign-reviewers-parent-container" style={ { display: "block" } }>
      <ReviewerGroupListContainer />
      <AssignReviewersTab />
    </div>
  )
}
