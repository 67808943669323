import React from "react"
import "./style.sass"

/**
 * ReqInfoHeader: The header for the page
 * @returns The JSX elements to create the header
 */
export default function ReqInfoHeader() {
  return (
    <div className="cp_page_required-info-header">
      <div className="cp_page_required-info-header-logo" />
    </div>
  )
}
