import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import EmptySplash from "../EmptySplash"
import Button from "../Button"
import ConfigurationPreviewTabs, { ConfigPreviewTab } from "./ConfigurationPreviewTabs"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import ConfigurationPreviewAccountTabs from "./ConfigurationPreviewAccountTabs"
import ConfigurationPreviewHeroBanner from "./ConfigurationPreviewHeroBanner"
import { toggleShowAcountSearch } from "../../state/listAddAccount"
import PreviewHighlights from "./PreviewHighlights"

export default function ConfigurationPreview() {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const dispatch = useDispatch()

  // Global state variables
  const fetchedList = useSelector(({ list }) => list.list)

  // Local state variables
  const [ tab, setTab ] = React.useState<ConfigPreviewTab>(ConfigPreviewTab.HIGHLIGHTS)
  const [ hasAccounts, setHasAccounts ] = React.useState<boolean>(false)

  // React lifecycle hooks
  /**
   * Check if the fetched list contains any social accounts
   */
  React.useEffect(() => {
    if (API.isSuccess(fetchedList)) {
      const { suggestionListCategories: categories } = fetchedList.payload.suggestionListById
      const containsAccounts = categories.some((category) => category.suggestionListSocialAccounts.length > 0)
      setHasAccounts(containsAccounts)
    }
  }, [ fetchedList ])

  /**
   * addAccountHandler: Navigate back to account management page to add accounts
   */
  const addAccountHandler = () => {
    // Navigate back to account management page
    dispatch(toggleShowAcountSearch())
  }

  return (
    <div className="cp_list-configuration-preview cp_lcp">
      <div className="cp_lcp-top">
        <h2 className="cp_lcp-heading">{ translate("PREVIEW") }</h2>
      </div>
      <div className="cp_lcp-tabs">
        <ConfigurationPreviewTabs onTabChange={ setTab } />
      </div>
      <div className="cp_lcp-preview">
        { !hasAccounts && (
          <EmptySplash
            headlineText={ translate("You have no accounts added!") }
          >
            <Button
              id="cp_lcp-add-account-button"
              className="cp_lcp-add-account-button"
              label={ translate("+ Add Accounts") }
              onClick={ addAccountHandler }
            />
          </EmptySplash>
        ) }
        { hasAccounts && (tab === ConfigPreviewTab.ACCOUNTS) && (
          <>
            <ConfigurationPreviewHeroBanner />
            <ConfigurationPreviewAccountTabs />
          </>
        ) }
        { hasAccounts && (tab === ConfigPreviewTab.HIGHLIGHTS) && (
          <PreviewHighlights />
        ) }
      </div>
    </div>
  )
}
