/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Badge,
} from "@mui/material"

import { useApolloClient, useQuery } from "@apollo/client"
import Modal from "../Modal"
import Input from "../Input"
import IconButtonClose from "../IconButtonClose"
import "../ModalCampaignDeliverable/style.sass"
import {
  CampaignNetworkAccountFragment,
  CreateDeliverableMutationVariables,
  DeliverableDocument,
  DeliverablePostFormat,
  DeliverablePostType,
  DeliverableQuery,
  DeliverableQueryVariables,
  DeliverableStatus,
  DeliveryType,
  UpdateDeliverableMutationVariables,
} from "../../graphql"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector, useDispatch } from "../../state/hooks"
import { uploadMedia, shorthandNumber } from "../../util/miscHelper"
import { isSuccess } from "../../util/apiClient"
import SocialAccountAutoComplete from "../ModalCampaignDeliverable/SocialAccountAutocomplete"
import { pushToast } from "../../state/toastSlice"
import Select from "../Select"
import MenuItem from "../MenuItem"
import DeliverableUploader from "../DeliverableUploader"
import { DeliverableCreativeThemes, FileResponse } from "../../util/types"
import NetworkAvatar from "../NetworkAvatar"
import { DELIVERABLE_CREATIVE_THEMES, DELIVERABLE_CREATIVE_THEME_SUBCATEGORIES } from "../../util/constant"
import { openConfirmationModal } from "../../state/confirmationModalSlice"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"
import {
  closeDeliverableV2Modal,
  createDeliverable,
  updateDeliverable,
} from "../../state/updateCampaignDeliverableV2Modal"
import {
  GET_DELIVERABLE,
  GET_DELIVERABLE_CAPTION_ITEMS,
  GET_DELIVERABLE_MEDIA_ITEMS,
  GET_DELIVERABLE_PERMISSIONS,
} from "../../state/campaignDeliverableContentModalV2Slice/types"

type DragMedia = {
  medium: FileResponse,
  index: number
}

function UpdateCampaignDeliverableV2Modal(): React.JSX.Element {
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)
  const [ campaignNetworkAccount, setCampaignNetworkAccount ] = React.useState<CampaignNetworkAccountFragment>()
  const [ deliverableTitle, setDeliverableTitle ] = React.useState("")
  const [ note, setNote ] = React.useState("")
  const [ theme, setTheme ] = React.useState<DeliverableCreativeThemes | "">("")
  const [ subcategory, setSubCategory ] = React.useState("")
  const [ deliveryType, setDeliveryType ] = React.useState<DeliveryType | null>(null)
  const [ postType, setPostType ] = React.useState<DeliverablePostType>()
  const [ postFormat, setPostFormat ] = React.useState<DeliverablePostFormat>()
  const [ media, setMedia ] = React.useState<FileResponse[]>([])
  const [ caption, setCaption ] = React.useState("")
  const [ uploadingMedia, setUploadingMedia ] = React.useState(false)
  const [ draggingMedia, setDraggingMedia ] = React.useState<DragMedia | undefined>()

  const {
    modalOpen,
    isEditMode,
    deliverableId,
    preSelectedSocialAccount,
  } = useSelector(({ campaignDeliverableV2UpdateContentModal }) => campaignDeliverableV2UpdateContentModal)
  const {
    campaign,
  } = useSelector(({ campaignPage }) => campaignPage)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverable" })

  const dispatch = useDispatch()
  const client = useApolloClient()
  const {
    data: deliverableData, loading, refetch,
  } = useQuery<DeliverableQuery, DeliverableQueryVariables>(
    DeliverableDocument,
    {
      variables: { deliverableId: deliverableId || "" },
      fetchPolicy: "network-only",
      skip: !isEditMode || !deliverableId,
    },
  )

  const deliverable = deliverableData?.deliverable
  const availableSocialAccounts = useMemo(() => {
    if (isSuccess(campaign)) {
      return campaign.payload.campaign.campaignNetworkAccounts
    }
    return []
  }, [ campaign ])

  const refreshCampaign = async () => {
    await client.refetchQueries({
      include: [ GET_CAMPAIGN_DETAILS ],
    })
  }

  const refreshDeliverable = async () => {
    await client.refetchQueries({
      include: [
        GET_DELIVERABLE,
        GET_DELIVERABLE_CAPTION_ITEMS,
        GET_DELIVERABLE_MEDIA_ITEMS,
        GET_DELIVERABLE_PERMISSIONS,
      ],
    })
  }

  const resetState = () => {
    // If preSelectedSocialAccount selected ie not undefined, load it as the network account
    setCampaignNetworkAccount(preSelectedSocialAccount)
    setDeliverableTitle("")
    setNote("")
    setTheme("")
    setSubCategory("")
    setDeliveryType(null)
    setPostType(undefined)
    setPostFormat(undefined)
    setMedia([])
    setCaption("")
  }

  const loadDeliverable = () => {
    resetState()
    if (!isEditMode || !deliverable) return
    if (deliverable.contentStatus === "LIVE") {
      dispatch(pushToast({
        type: "warning",
        message: translate("This deliverable is LIVE"),
        additionalInfo: translate("Some fields cannot be edited any longer."),
      }))
    }
    setCampaignNetworkAccount(deliverable.campaignNetworkAccount)
    setDeliverableTitle(deliverable.name)
    if (deliverable.note) setNote(deliverable.note)
    if (deliverable.creativeTheme) {
      const previousTheme = deliverable.creativeTheme.path[0]
      const previousSubcategory = deliverable.creativeTheme.path[1]
      if (previousTheme) setTheme(previousTheme.display as DeliverableCreativeThemes)
      if (previousSubcategory) setSubCategory(previousSubcategory.display)
    }

    if (deliverable.deliveryType) setDeliveryType(deliverable.deliveryType)
    if (deliverable.postType) {
      setPostType(deliverable.postType)
    }
    if (deliverable.postFormat) setPostFormat(deliverable.postFormat)

    const formattedMedia = deliverable
      .deliverableMedia
      .map((_media) => ({ id: _media.media.id, url: _media.media.url.address }))
    setMedia(formattedMedia)
    if (deliverable.note) setNote(deliverable.note)
    if (deliverable.deliverableCaption) setCaption(deliverable.deliverableCaption.text)
  }

  // Refreshes deliveraable data on subsequent modal openings
  useEffect(() => {
    if (modalOpen && deliverable) refetch()
  }, [ modalOpen ])

  useEffect(loadDeliverable, [ modalOpen, deliverable ])

  const closeModal = () => {
    resetState()
    dispatch(closeDeliverableV2Modal())
  }

  const updateDeliverableRequest = async () => {
    if (!isEditMode || !deliverable) return
    const formattedTheme = theme
      .toLowerCase()
      .replaceAll("_", "")
      .replaceAll(",", "")
      .trim()
      .replaceAll(" ", "_")

    let formattedSubCategory = subcategory
      .toLowerCase()
      .replaceAll("_", "")
      .replaceAll(",", "")
      .trim()
      .replaceAll(" ", "_")

    if (formattedSubCategory === "with_me") formattedSubCategory = `blank_${ formattedSubCategory }`

    const creativeTheme = [ formattedTheme, formattedSubCategory ].filter((s) => !!s).join("__")

    const params: UpdateDeliverableMutationVariables = {
      updateDeliverableId: deliverable.id,
      campaignNetworkAccountId: campaignNetworkAccount?.id,
      name: deliverableTitle,
      note: note ?? undefined,
      creativeTheme: creativeTheme ?? null,
      text: caption ?? null,
      mediaInput: media.map((medium, i) => ({ displayOrder: i + 1, mediaId: `${ medium.id }` })),
      postType: postType ?? null,
      postFormat: postFormat ?? null,
      deliveryType,
    }

    setLoadingPrimaryAction(true)
    const result = await dispatch(updateDeliverable(params))
    setLoadingPrimaryAction(false)

    if (isSuccess(result)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Deliverable Updated"),
      }))
      closeModal()
      refreshCampaign()
      refreshDeliverable()
    } else {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to update deliverable."),
      }))
    }
  }

  const createDeliverableHandler = async () => {
    if (!campaignNetworkAccount || !deliverableTitle) return

    const formattedTheme = theme
      .toLowerCase()
      .replaceAll("_", "")
      .replaceAll(",", "")
      .trim()
      .replaceAll(" ", "_")

    let formattedSubCategory = subcategory
      .toLowerCase()
      .replaceAll("_", "")
      .replaceAll(",", "")
      .trim()
      .replaceAll(" ", "_")

    if (formattedSubCategory === "with_me") formattedSubCategory = `blank_${ formattedSubCategory }`

    const creativeTheme = [ formattedTheme, formattedSubCategory ].filter((s) => !!s).join("__")

    const params: CreateDeliverableMutationVariables = {
      campaignNetworkAccountId: campaignNetworkAccount.id,
      name: deliverableTitle,
      note: note ?? undefined,
      creativeTheme: creativeTheme ?? null,
      text: caption ?? null,
      mediaInput: media.map((medium, i) => ({ displayOrder: i + 1, mediaId: `${ medium.id }` })),
      postType: postType ?? null,
      postFormat: postFormat ?? null,
      deliveryType,
    }

    setLoadingPrimaryAction(true)
    const result = await dispatch(createDeliverable(params))
    setLoadingPrimaryAction(false)

    if (isSuccess(result)) {
      dispatch(pushToast({
        type: "success",
        message: translate("Deliverable Created"),
      }))
      closeModal()
      refreshCampaign()
    } else {
      dispatch(pushToast({
        type: "error",
        message: translate("Unable to create deliverable."),
      }))
    }
  }

  const updateDeliverableHandler = async () => {
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      || deliverable?.contentStatus === DeliverableStatus.Uploaded
    ) {
      dispatch(openConfirmationModal({
        onConfirm: () => updateDeliverableRequest(),
        title: translate("Updating Finalized Deliverable"),
        subtext: translate("Updating this deliverable will remove its finalized status."),
        primaryLabel: translate("Save"),
        secondaryLabel: translate("Cancel"),
      }))
      closeModal()
    } else {
      updateDeliverableRequest()
    }
  }

  const isValid = React.useMemo(() => {
    switch (true) {
      case Boolean(campaignNetworkAccount) && Boolean(deliverableTitle):
        return true
      default:
        return false
    }
  }, [ campaignNetworkAccount, deliverableTitle ])

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    return isEditMode ? translate("Save Deliverable") : translate("Create Deliverable")
  }, [ isEditMode, loadingPrimaryAction ])

  const handleClientMediaUpdate = async (file: File) => {
    setUploadingMedia(true)
    const fileStatus = await uploadMedia(file)
    setUploadingMedia(false)
    if (fileStatus.id) {
      setMedia([
        ...media,
        { id: fileStatus.id, url: fileStatus.url },
      ])
    }
  }

  const themeOptions = useMemo(() => DELIVERABLE_CREATIVE_THEMES.map((themeOption) => (
    <MenuItem key={ themeOption } value={ themeOption }>
      { translate(themeOption) }
    </MenuItem>
  )), [ translate ])

  const subCategoryOptions = useMemo(() => {
    if (!theme) return []
    const categories = DELIVERABLE_CREATIVE_THEME_SUBCATEGORIES[theme]
    return categories.map((category) => (
      <MenuItem key={ category } value={ category }>
        { translate(category) }
      </MenuItem>
    ))
  }, [ theme, translate ])

  const postTypeOptions = useMemo(() => Object.values(DeliverablePostType).map((type) => (
    <MenuItem key={ type } value={ type }>
      { translate(type) }
    </MenuItem>
  )), [ translate ])

  const fileIsAccepted = (file: FileResponse): boolean => {
    const medium = deliverable?.deliverableMedia.find((dm) => dm.media.url.address === file.url)
    return Boolean(medium?.accepted)
  }

  /**
   * mediaDragStarted: Handles the drag start event for the media object
   * @param e Thee drag event
   * @param medium The medium object
   * @param index The index of the medium object in the media array
   */
  const mediaDragStarted = (e: React.DragEvent<HTMLSpanElement>, medium: FileResponse, index: number) => {
    // Create media object information to be passed to the drop event
    const mediumData: DragMedia = { medium, index }

    // Set the data to be passed to the drop event
    e.dataTransfer.setData("text/plain", JSON.stringify(mediumData))

    // Set the dragging media
    setDraggingMedia(mediumData)
  }

  const mediaDragOver = (e: React.DragEvent<HTMLSpanElement>, index: number) => {
    e.preventDefault()
    if (!draggingMedia) return
    if (draggingMedia.index === index) return

    // Create a new media array with the dropped media object in the new index
    const newMedia = [ ...media ]
    newMedia.splice(draggingMedia.index, 1)
    newMedia.splice(index, 0, draggingMedia.medium)

    // Update dragging media
    const newDragMedium: DragMedia = { ...draggingMedia }
    newDragMedium.index = index
    setDraggingMedia(newDragMedium)

    // Set the new media array
    setMedia(newMedia)
  }

  const mediaDrop = (e: React.DragEvent<HTMLSpanElement>) => {
    // Prevent the default behavior of the drop event
    e.preventDefault()

    // Set the new media array
    setDraggingMedia(undefined)
  }

  const renderContent = () => {
    if (loading) return <LoadingIndicatorCard />
    return (
      <div className="cp_component_modal-campaign-deliverable_row cp_component_modal-campaign-deliverable_panels">
        <div className="cp_component_modal-campaign-deliverable_column">
          <div className="cp_component_modal-campaign-deliverable-details">
            <p className="cp_component_modal-campaign-deliverable_panels-title">{ translate("Details") }</p>
            <p className="label_small-caps-bold cp_component_modal-campaign-deliverable-details-label">
              { translate("Social Account Name") }
            </p>
            {
          campaignNetworkAccount ? (
            <div className="cp_component_modal-campaign-deliverable_social-account">
              <div className="cp_component_modal-campaign-deliverable_social-account-avatar">
                <NetworkAvatar
                  src={ campaignNetworkAccount.socialAccount.profilePictureUrl }
                  network={ campaignNetworkAccount.socialAccount.network }
                  size="md"
                />
              </div>
              <div className="cp_component_modal-campaign-deliverable_social-account-right">
                <p className="cp_component_modal-campaign-deliverable_social-account-right-name">
                  { campaignNetworkAccount.socialAccount.userName }
                </p>
                <p className="cp_component_modal-campaign-deliverable_social-account-right-followers">
                  { shorthandNumber(campaignNetworkAccount.socialAccount.socialAccountStatistics.followers) }
                  { ` ${ translate("FOLLOWERS") }` }
                </p>
              </div>
              <IconButtonClose
                disabled={ isEditMode
                  && deliverable?.contentStatus === DeliverableStatus.Live
                }
                onClick={ () => setCampaignNetworkAccount(undefined) }
                className="cp_component_modal-campaign-deliverable_social-account-close"
              />
            </div>
          )
            : (
              <SocialAccountAutoComplete
                selectSocialAccount={ setCampaignNetworkAccount }
                accounts={ availableSocialAccounts }
              />
            )
        }
            <p className="cp_component_modal-campaign-deliverable-helper">
              {
          translate("A deliverable must be assigned to a single social account that has already been added to this campaign.")
          }
            </p>
            <div className="cp_component_modal-campaign-deliverable-details-label">
              <p className="label_small-caps-bold">{ translate("Title") }</p>
              <p className="label_small-caps-bold">
                { deliverableTitle.length }
                /160
              </p>
            </div>
            <Input
              className="compact"
              value={ deliverableTitle }
              onChange={ (e) => setDeliverableTitle(e.currentTarget.value) }
              fullWidth={ true }
              InputProps={ {
                inputProps: { maxLength: 160 },
              } }
              placeholder={ translate("Enter Deliverable Title") }
            />
            <p className="cp_component_modal-campaign-deliverable-helper">
              {
          translate("Add a clear and concise title that describes this deliverable.")
          }
            </p>
            <Input
              value={ note }
              onChange={ (e) => { setNote(e.currentTarget.value) } }
              fullWidth={ true }
              multiline={ true }
              rows={ 2 }
              label={ translate("Client Note (Optional)") }
              placeholder={ translate("Enter Client Note....") }
            />
            <div className="cp_component_modal-campaign-deliverable-details-themes">
              <div className="cp_component_modal-campaign-deliverable-details-themes-category">
                <div>
                  <p className="label_small-caps-bold">{ translate("Creative Theme") }</p>
                  <Select
                    onChange={ (e) => {
                      setTheme(e.target.value as DeliverableCreativeThemes)
                      setSubCategory("")
                    } }
                    fullWidth={ true }
                    value={ theme }
                    label=""
                    id="campaign-deliverable-theme"
                    labelId="campaign-deliverable-theme-label"
                    menuItems={ themeOptions }
                    displayEmpty={ true }
                    renderValue={ (value) => value ? translate(value) : translate("Select Theme") }
                  />
                </div>
              </div>
              <div className="cp_component_modal-campaign-deliverable-details-themes-subcategory">
                <div>
                  <p className="label_small-caps-bold">{ translate("Subcategory") }</p>
                  <Select
                    onChange={ (e) => setSubCategory(e.target.value) }
                    fullWidth={ true }
                    label=""
                    value={ subcategory }
                    id="campaign-deliverable-subcategory"
                    labelId="campaign-deliverable-theme-label"
                    menuItems={ subCategoryOptions }
                    displayEmpty={ true }
                    renderValue={ (value) => value ? translate(value) : translate("Select Subcategory") }
                  />
                </div>
              </div>
            </div>
            <div>
              <FormControl>
                <p className="label_small-caps-bold">
                  { translate("Delivery Type") }
                </p>
                <RadioGroup
                  onChange={ (_, value) => {
                    setDeliveryType(value as DeliveryType)
                  } }
                  className="cp_component_modal-campaign-deliverable-radios"
                  value={ deliveryType }
                >
                  <FormControlLabel
                    value={ DeliveryType.Guaranteed }
                    control={ <Radio size="small" /> }
                    label={ translate("Guaranteed") }
                    checked={ DeliveryType.Guaranteed === deliveryType }
                  />
                  <FormControlLabel
                    value={ DeliveryType.AddedValue }
                    control={ <Radio size="small" /> }
                    label={ translate("Added Value") }
                    checked={ DeliveryType.AddedValue === deliveryType }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="cp_component_modal-campaign-deliverable_column">
          <div className="cp_component_modal-campaign-deliverable-content">
            <p className="cp_component_modal-campaign-deliverable_panels-title">{ translate("Content") }</p>
            <p className="label_small-caps-bold">{ translate("Post Type") }</p>
            <Select
              onChange={ (e) => setPostType(e.target.value as DeliverablePostType) }
              fullWidth={ false }
              label=""
              value={ postType }
              id="campaign-deliverable-post-type"
              labelId="campaign-deliverable-post-type-label"
              menuItems={ postTypeOptions }
              displayEmpty={ true }
              renderValue={ () => postType ? translate(postType) : translate("Select Post Type") }
            />
            {
          postType ? (
            <div className="cp_component_modal-campaign-deliverable-content-formats">
              <FormControl>
                <p className="label_small-caps-bold">
                  { translate("Post Format") }
                </p>
                <RadioGroup
                  onChange={ (_, value) => {
                    setPostFormat(value as DeliverablePostFormat)
                  } }
                  className="cp_component_modal-campaign-deliverable-radios"
                  value={ deliveryType }
                >
                  <FormControlLabel
                    value={ DeliverablePostFormat.Static }
                    control={ <Radio size="small" /> }
                    label={ translate("Static") }
                    checked={ DeliverablePostFormat.Static === postFormat }
                  />
                  <FormControlLabel
                    value={ DeliverablePostFormat.Video }
                    control={ <Radio size="small" /> }
                    label={ translate("Video") }
                    checked={ DeliverablePostFormat.Video === postFormat }
                  />
                  {
                    postType !== DeliverablePostType.InFeed ? (
                      <FormControlLabel
                        value={ DeliverablePostFormat.Mix }
                        control={ <Radio size="small" /> }
                        label={ translate("Mix") }
                        checked={ DeliverablePostFormat.Mix === postFormat }
                      />
                    ) : null
                  }
                </RadioGroup>
              </FormControl>
            </div>
          ) : null
        }
            <div className="cp_component_modal-campaign-deliverable-content-media">
              <p className="label_small-caps-bold">{ translate("Post Media") }</p>
              <DeliverableUploader
                onAvatarSelect={ handleClientMediaUpdate }
                disabled={ deliverable?.contentStatus === DeliverableStatus.Live }
              >
                { media.map((medium, i) => (
                  <Badge
                    draggable={ true }
                    onDragStart={ (e) => mediaDragStarted(e, medium, i) }
                    onDragOver={ (e) => mediaDragOver(e, i) }
                    onDrop={ (e) => mediaDrop(e) }
                    key={ medium.id }
                    className="cp_component_modal-campaign-deliverable-content-media-badge"
                    badgeContent={ (
                      <div>
                        <IconButtonClose
                          className="cp_component_modal-campaign-deliverable-content-media-badge-btn-close"
                          disabled={ deliverable?.contentStatus === DeliverableStatus.Live }
                          onClick={ () => {
                            const newMedia = [ ...media ]
                            newMedia.splice(i, 1)
                            setMedia(newMedia)
                          } }
                          size="small"
                        />
                        { fileIsAccepted(medium) && (
                        <CheckCircleIcon
                          className="cp_component_modal-campaign-deliverable-content-media-badge-btn-approved"
                        />
                        ) }
                      </div>
                ) }
                  >
                    <Avatar
                      variant="rounded"
                      className={
                    `cp_component_modal-campaign-deliverable-content-media-img
                    ${ (draggingMedia?.index === i) ? " dragging-item" : " grab" }`
                  }
                      src={ medium.url }
                    />
                  </Badge>
                )) }
                { uploadingMedia && (
                <Badge
                  className="cp_component_modal-campaign-deliverable-content-media-badge"
                >
                  <Avatar
                    variant="rounded"
                    className="cp_component_modal-campaign-deliverable-content-media-img"
                  >
                    <LoadingIndicator size={ 50 } />
                  </Avatar>
                </Badge>
                ) }
              </DeliverableUploader>
              <p className="cp_component_modal-campaign-deliverable-helper">
                {
            translate("Supports photos or videos. Accepts .png, .jpeg, .mp4, .webm, and .mov files.")
          }
              </p>
            </div>
            <div className="cp_component_modal-campaign-deliverable-content-label">
              <p className="label_small-caps-bold">{ translate("Post Caption") }</p>
              <p className="label_small-caps-bold">
                { caption.length }
              </p>
            </div>
            <Input
              disabled={ deliverable?.contentStatus === DeliverableStatus.Live }
              fullWidth={ true }
              multiline={ true }
              onChange={ (e) => setCaption(e.currentTarget.value) }
              placeholder={ translate("Enter Post Caption Text") }
              value={ caption }
              InputProps={ {
                endAdornment: !deliverable?.deliverableCaption?.accepted ? null : (
                  <CheckCircleIcon
                    className="cp_component_modal-campaign-deliverable-content-caption-approved"
                  />
                ),
              } }
            />
            <p className="cp_component_modal-campaign-deliverable-helper">
              {
          translate("This field displays the text of the post. Paragraph formatting will be preserved.")
          }
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      title={ isEditMode ? translate("Edit Deliverable") : translate("Create Deliverable") }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ modalOpen }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      primaryAction={ isEditMode ? updateDeliverableHandler : createDeliverableHandler }
      maxWidth="xl"
      className="cp_component_modal-campaign-deliverable"
      disabled={ !isValid || loadingPrimaryAction || uploadingMedia }
    >
      { renderContent() }
    </Modal>
  )
}

export default UpdateCampaignDeliverableV2Modal
