import React from "react"
import "./style.sass"

export default function ListV2PageHeader() {
  return (
    <div className="cp_page_public-list-page-flex_header">
      <div className="cp_page_public-list-page-flex_header-logo" />
    </div>
  )
}
