import React from "react"

import { useTranslation } from "react-i18next"
import {
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { ArrowDropDown } from "@mui/icons-material"

import { AudienceMetricFormDataProps } from "../AudienceMetricsForm"
import Dropdown from "../../../../Dropdown"
import Input from "../../../../Input"
import * as GraphQL from "../../../../../graphql"
import {
  ReadableAgeRange,
  ReadableEthnicity,
  ReadableIncome,
  ReadableReligion,
} from "../../../constants"

export default function AudienceMetricDropdownForm({
  label,
  setSelected,
  options = [],
}: AudienceMetricFormDataProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })

  // Local state
  const [ selectedOption, setSelectedOption ] = React
    .useState<GraphQL.AgeRange | GraphQL.IncomeBrackets | GraphQL.Ethnicity | GraphQL.Religion>()

  const selectOption = (option: GraphQL.AgeRange | GraphQL.IncomeBrackets | GraphQL.Ethnicity | GraphQL.Religion) => {
    // Set parent value
    setSelected(option)

    // Set local state
    setSelectedOption(option)
  }

  const getReadableText = (opt: string) => {
    switch (label) {
      case "Age Range": {
        return ReadableAgeRange[GraphQL.AgeRange[opt as keyof typeof GraphQL.AgeRange]]
      }
      case "Income Range": {
        return ReadableIncome[GraphQL.IncomeBrackets[opt as keyof typeof GraphQL.IncomeBrackets]]
      }
      case "Ethnicity": {
        return ReadableEthnicity[GraphQL.Ethnicity[opt as keyof typeof GraphQL.Ethnicity]]
      }
      case "Religion": {
        return ReadableReligion[GraphQL.Religion[opt as keyof typeof GraphQL.Religion]]
      }
      default:
        return opt
    }
  }

  return (
    <FormControl
      className="audience-metrics-form-metric-form"
      component="fieldset"
      fullWidth={ true }
    >
      <FormLabel component="legend" className="section-label">
        { translate(label) }
      </FormLabel>
      <Dropdown
        key={ `dropdown-key-${ selectedOption?.toLowerCase().replaceAll(" ", "_") }` }
        id="audience-metric-dropdown"
        buttonType="custom"
        placement="bottom"
        customButtonChildren={
          (
            <Input
              id="audience-metric-dropdown-input"
              className="audience-metric-dropdown-input"
              value={ selectedOption }
              placeholder={ translate(`Select ${ label }`) }
              InputProps={ {
                endAdornment: <ArrowDropDown className="audience-metric-dropdown-arrow-drop-down" />,
              } }
              contentEditable={ false }
            />
          )
        }
      >
        <List disablePadding={ true }>
          { options.map((opt) => (
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
              key={ `list-item-key-${ opt.toLowerCase().replaceAll(" ", "_") }` }
            >
              <ListItemButton
                id={ `audience-metric-dropdown-${ getReadableText(opt).toLowerCase().replaceAll(" ", "_") }-button` }
                onClick={ () => selectOption(opt) }
              >
                <ListItemText
                  primary={ getReadableText(opt) }
                />
              </ListItemButton>
            </ListItem>
          ))
          }
        </List>
      </Dropdown>
    </FormControl>
  )
}
