import React from "react"

import { APPENDIX_AUDIENCE_DISPLAY_TOGGLES } from "../../../util/constant"

// Importing images
import { ReactComponent as FollowersOverTimeIcon } from "../../../asset/visualizations/LIST_FOLLOWERS_OVER_TIME_EX.svg"
import { ReactComponent as AudienceAuthenticityIcon } from "../../../asset/visualizations/LIST_AUDIENCE_AUTHENTICITY.svg"
import { ReactComponent as GenderFamlyStatusIcon } from "../../../asset/visualizations/LIST_GENDER_FAMILY_EX.svg"
import { ReactComponent as GenderIcon } from "../../../asset/visualizations/LIST_GENDER.svg"
import { ReactComponent as FamilyStatusIcon } from "../../../asset/visualizations/LIST_FAMILY.svg"
import { ReactComponent as AgeIncomeEducationRangeIcon } from "../../../asset/visualizations/LIST_BASIC_EX.svg"
// eslint-disable-next-line max-len
import { ReactComponent as EthinicitiesLanguagesReligionsIcon } from "../../../asset/visualizations/LIST_ETHNICITY_LANG_RELIGION_EX.svg"
import { ReactComponent as EthinicitiesLanguagesIcon } from "../../../asset/visualizations/LIST_ETHNICITY_LANGUAGES.svg"
import { ReactComponent as EthinicitiesReligionsIcon } from "../../../asset/visualizations/LIST_ETHINICTY_RELIGION.svg"
import { ReactComponent as LanguagesReligionsIcon } from "../../../asset/visualizations/LIST_LANGUAGES_RELIGION.svg"
import { ReactComponent as EthinicitiesOnlyIcon } from "../../../asset/visualizations/LIST_ETHNICITY.svg"
import { ReactComponent as LanguagesOnlyIcon } from "../../../asset/visualizations/LIST_LANGUAGES.svg"
import { ReactComponent as ReligionsOnlyIcon } from "../../../asset/visualizations/LIST_RELIGIONS.svg"
import { ReactComponent as CountriesStatesCitiesIcon } from "../../../asset/visualizations/LIST_LOCATION_EX.svg"
import { ReactComponent as VocationIcon } from "../../../asset/visualizations/LIST_VOCATION_EX.svg"
import { ReactComponent as AffinitiesIcon } from "../../../asset/visualizations/LIST_AFFINITIES_EX.svg"
import { SuggestionListToggleInput } from "../../../graphql"

type Props = {
  toggles: SuggestionListToggleInput[]
}

export function hasAnyToggle(togglesToCheck: SuggestionListToggleInput[], toggleObject: Record<string, string>): boolean {
  // Convert the object values into a Set for fast lookups
  const toggleSet = new Set(Object.values(toggleObject))

  // Use .some() to exit early if any toggle is found
  return togglesToCheck.some((toggle) => toggleSet.has(toggle.name))
}

export default function AudienceHelper({ toggles }: Props) {
  const genderFamilyActiveVisualizations = () => {
    const hasGender = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.GENDER)
    const hasFamilyStatus = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS)

    if (hasGender && hasFamilyStatus) {
      return <GenderFamlyStatusIcon />
    }
    if (hasFamilyStatus) {
      return <FamilyStatusIcon />
    }
    if (hasGender) {
      return <GenderIcon />
    }
    return null
  }

  const ageIncomeEducationRangeActiveVisualizations = () => {
    const hasAgeIncomeEducationRange = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL)

    if (hasAgeIncomeEducationRange) {
      return <AgeIncomeEducationRangeIcon />
    }
    return null
  }

  const ethnicityLanguagesReligionsActiveVisualizations = () => {
    const hasEthnicity = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES)
    const hasLanguages = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.LANGUAGES)
    const hasReligions = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS)

    if (hasEthnicity && hasLanguages && hasReligions) {
      return <EthinicitiesLanguagesReligionsIcon />
    }
    if (hasEthnicity && hasLanguages) {
      return <EthinicitiesLanguagesIcon />
    }
    if (hasEthnicity && hasReligions) {
      return <EthinicitiesReligionsIcon />
    }
    if (hasLanguages && hasReligions) {
      return <LanguagesReligionsIcon />
    }
    if (hasEthnicity) {
      return <EthinicitiesOnlyIcon />
    }
    if (hasLanguages) {
      return <LanguagesOnlyIcon />
    }
    if (hasReligions) {
      return <ReligionsOnlyIcon />
    }
    return null
  }

  const countryStateCitiesVisualizations = () => {
    const hascountryStateCities = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.STATES)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.CITIES)

    if (hascountryStateCities) {
      return <CountriesStatesCitiesIcon />
    }
    return null
  }

  const vocationVisualizations = () => {
    const hasVocations = toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS)
    || toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES)

    if (hasVocations) {
      return <VocationIcon />
    }
    return null
  }

  return (
    <>
      { (toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME)) && (
      <FollowersOverTimeIcon />
      ) }
      { (toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY)) && (
      <AudienceAuthenticityIcon />
      ) }
      { genderFamilyActiveVisualizations() }
      { ageIncomeEducationRangeActiveVisualizations() }
      { ethnicityLanguagesReligionsActiveVisualizations() }
      { countryStateCitiesVisualizations() }
      { vocationVisualizations() }
      { (toggles.map((t) => t.name).includes(APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES)) && (
      <AffinitiesIcon />
      ) }
    </>
  )
}
