import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useParams } from "react-router-dom"

import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "../../../state/hooks"
import LoadingIndicatorCard from "../../LoadingIndicatorCard"
import EmptySplash from "../../EmptySplash"
import ErrorHandler from "../../ErrorHandler"
import { setNotificationSettings } from "../../../state/campaignV2Slice"
import NotificationTogglesV2 from "./NotificationTogglesV2"
import { GET_USER_NOTIFICATIONS } from "../../../graphqlV2/queries/campaignQueries"
import { Scope } from "../../../util/types"

export default function CampaignNotificationsV2() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignNotificationsV2" })
  const dispatch = useDispatch()
  const { campaignID } = useParams()
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes, user: currentUser } = useSelector(({ user }) => user)
  const {
    data, loading, error,
  } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: {
      campaignId: campaignID || "0",
    },
    context: { apiVersion: "v2" },
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (data?.getUserNotifications.notificationPreferences) {
      dispatch(setNotificationSettings(data.getUserNotifications.notificationPreferences))
    }
  }, [ campaignID, data ])

  if (loading || campaign === "init" || campaign === "loading" || currentUser === "init" || currentUser === "loading") {
    return <LoadingIndicatorCard size={ 50 } />
  }

  if (error || campaign.status === "error" || currentUser.status === "error") {
    return <ErrorHandler />
  }

  const {
    subscribedUsers,
  } = campaign.payload.campaign

  const userId = currentUser.payload.currentUser?.id || "0"
  const isCampaignManager = subscribedUsers.some((usr) => usr.id === userId) && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  const canViewNotificationsTab = isCampaignManager || data.getUserNotifications?.notificationPreferences

  if (!canViewNotificationsTab) {
    return (
      <EmptySplash
        headlineText={ translate("Settings Not Available") }
        bodyText={ translate("Notification Settings are only available to Reviewers and Campaign Managers.") }
      />
    )
  }

  return (
    <div className="cp_component_campaign-notifications-settings">
      <h4 className="notifications-header">{ translate("Campaign Notification Settings") }</h4>
      <NotificationTogglesV2
        notificationPreferences={ data.getUserNotifications.notificationPreferences }
      />
    </div>
  )
}
