import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import {
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import {
  AccountCircleOutlined,
  CheckCircleOutline,
  EditNoteOutlined,
  RemoveCircleOutline,
} from "@mui/icons-material"
import NetworkAvatar from "../NetworkAvatar"
import { shorthandNumber } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import { DeliverableQueryVariables, Network } from "../../graphql"
import CopyIconButton from "../CopyIconButton"

import "./overview.sass"
import Dropdown from "../Dropdown"
import {
  CAST_DELIVERABLE_VOTE,
  CastDeliverableVoteInput,
  CastDeliverableVoteOutput,
  DeliverablePermissionsOutput,
  DeliverableStatus,
  DeliverableUserGroupOutput,
  DeliverableUserGroupQueryVariables,
  DeliverableVoteOutput,
  GET_DELIVERABLE,
  GET_DELIVERABLE_CAPTION_ITEMS,
  GET_DELIVERABLE_HISTORY,
  GET_DELIVERABLE_MEDIA_ITEMS,
  GET_DELIVERABLE_PERMISSIONS,
  GET_DELIVERABLE_USER_GROUP,
  GET_DELIVERABLE_VOTE,
  ReviewerGroupStatus,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import { Scope } from "../../util/types"
import Divider from "../Divider"
import {
  setFeedbackPageOpen,
  setFeedbackPagePendingVote,
  setFinalizeConfirmationModalOpen,
  setDeliverablePermissions,
} from "../../state/campaignDeliverableContentModalV2Slice"
import { setAssignReviewerModalOpen } from "../../state/campaignDeliverableAssignReviewerModalSlice"
import { openDeliverableUpdateStatusModal } from "../../state/campaignDeliverableV2UpdateStatusModal"
import { openEditDeliverableV2Modal } from "../../state/updateCampaignDeliverableV2Modal"
import { openTikTokInviteModal } from "../../state/tikTokInviteLinkModalSlice"
import { openCopyLinkModal } from "../../state/copyLinkModalSlice"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"

function Overview(): React.JSX.Element {
  const {
    deliverable,
    deliverableMedia,
    deliverableCaption,
    deliverablePermissions,
    feedbackPagePendingVote,
    modalOpen,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()
  const client = useApolloClient()
  const {
    scopes,
  } = useSelector(({ user }) => user)

  const [ copyUserHandle, setCopyUserHandle ] = useState<string | null>(null)
  const [ approveAnchorEl, setApproveAnchorEl ] = React.useState<null | HTMLElement>(null)
  const [ finalizeAnchorEl, setFinalizeAnchorEl ] = React.useState<null | HTMLElement>(null)

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const {
    data: permissionData, refetch: refetchPermissions, loading: permissionsLoading,
  } = useQuery<DeliverablePermissionsOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_PERMISSIONS,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  useEffect(() => {
    if (permissionData?.deliverablePermissions) dispatch(setDeliverablePermissions(permissionData.deliverablePermissions))
  }, [ permissionData ])

  const {
    data: deliverableUserGroupData,
    refetch: refetchDeliverableUserGroup,
  } = useQuery<DeliverableUserGroupOutput, DeliverableUserGroupQueryVariables>(
    GET_DELIVERABLE_USER_GROUP,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  const {
    data: deliverableVoteData,
    refetch: refetchVote,
    loading: voteLoading,
  } = useQuery<DeliverableVoteOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_VOTE,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId,
    },
  )

  // Note: Makes sure to refetch permissionData on subsequent modal openings
  useEffect(() => {
    if (modalOpen && permissionData) {
      refetchPermissions()
    }
    if (modalOpen && deliverableVoteData) {
      refetchVote()
    }
    if (modalOpen && deliverableUserGroupData) {
      refetchDeliverableUserGroup()
    }
  }, [ modalOpen ])

  const [ castDeliverableVote ] = useMutation<
  CastDeliverableVoteOutput,
  CastDeliverableVoteInput
  >(CAST_DELIVERABLE_VOTE, {
    awaitRefetchQueries: true,
  })

  const deliverableHasContent = (deliverableMedia !== undefined && deliverableMedia.length > 0)
  || deliverableCaption !== undefined

  const userGroupIsAssigned = useMemo(() => {
    if (deliverableUserGroupData?.currentDeliverableUserGroup?.userAssignments) {
      return deliverableUserGroupData.currentDeliverableUserGroup.userAssignments.length > 0
    }
    return false
  }, [ deliverableUserGroupData ])

  const refreshQueries = async () => {
    await client.refetchQueries({
      include: [
        GET_CAMPAIGN_DETAILS,
        GET_DELIVERABLE,
      ],
    })
  }

  const approveButtonVisible = useMemo(() => {
    if (!deliverable) return false
    if (deliverable.deliverableStatus === DeliverableStatus.LIVE) {
      if (userGroupIsAssigned) {
        return scopes.includes(Scope.CAMPAIGN_MANAGEMENT) || deliverablePermissions?.canVoteOnDeliverable
      }
      return false
    }
    if (scopes.includes(Scope.CAMPAIGN_MANAGEMENT)) return true
    if (permissionsLoading || !deliverablePermissions) return false
    return deliverablePermissions.canVoteOnDeliverable
  }, [ deliverablePermissions, scopes, permissionsLoading, deliverable, userGroupIsAssigned ])

  const approveButtonDisabled = useMemo(() => {
    if (voteLoading || !deliverableHasContent || !deliverable) return true
    return deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE
  }, [ deliverable, deliverableHasContent, voteLoading ])

  const finalizeFeebackButtonVisible = useMemo(() => {
    if (!deliverable) return false
    if (deliverable.deliverableStatus === DeliverableStatus.LIVE) {
      if (userGroupIsAssigned) {
        return scopes.includes(Scope.CAMPAIGN_MANAGEMENT) || deliverablePermissions?.canFinalizeDeliverableFeedback
      }
      return false
    }
    if (scopes.includes(Scope.CAMPAIGN_MANAGEMENT)) return true
    if (permissionsLoading || !deliverablePermissions) return false
    return deliverablePermissions.canFinalizeDeliverableFeedback
  }, [ deliverablePermissions, scopes, permissionsLoading, userGroupIsAssigned, deliverable ])

  const finalizeFeedbackButtonDisabled = useMemo(() => {
    if (scopes.includes(Scope.CAMPAIGN_MANAGEMENT)) return false
    if (!deliverable || !deliverableHasContent) return true
    return (deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE)
  }, [ deliverable, deliverableHasContent ])

  const finalizeButtonTitle = useMemo(() => {
    if (deliverable?.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return translate("Feedback Complete")
    return translate("Finalize Feedback")
  }, [ deliverable ])

  const handleCopyUserHandleMouseEnter = (text: string) => {
    setCopyUserHandle(text)
  }

  const handleCopyUserHandleMouseLeave = () => {
    setCopyUserHandle(null)
  }

  const handleApprove = () => {
    if (!deliverable) return
    castDeliverableVote({
      variables: {
        deliverableId: deliverable.id,
        voteType: UserVote.APPROVED,
        comment: "",
      },
      context: {
        apiVersion: "v2",
      },
      refetchQueries: [
        {
          query: GET_DELIVERABLE_CAPTION_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_MEDIA_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_VOTE,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_USER_GROUP,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_PERMISSIONS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_HISTORY,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
      ],
    })
    setApproveAnchorEl(null)
  }

  const handleApproveWithSuggestions = () => {
    dispatch(setFeedbackPageOpen(true))
    dispatch(setFeedbackPagePendingVote(UserVote.APPROVED_WITH_SUGGESTIONS))
    setApproveAnchorEl(null)
  }

  const handleRequestRevision = () => {
    dispatch(setFeedbackPageOpen(true))
    dispatch(setFeedbackPagePendingVote(UserVote.REQUEST_REVISION))
    setApproveAnchorEl(null)
  }

  const handleNextReviewerGroup = () => {
    dispatch(setAssignReviewerModalOpen(true))
    setFinalizeAnchorEl(null)
  }

  const handleEditDeliverable = () => {
    if (!deliverable) return
    dispatch(openEditDeliverableV2Modal({ deliverableId: deliverable.id }))
    setFinalizeAnchorEl(null)
  }

  const handleFinalize = () => {
    dispatch(setFinalizeConfirmationModalOpen(true))
    setFinalizeAnchorEl(null)
  }

  const handleUpdateDeliverableStatus = () => {
    if (!deliverable) return
    dispatch(openDeliverableUpdateStatusModal({
      deliverableId: deliverable.id,
      deliverableStatus: deliverable.deliverableStatus as DeliverableStatus,
    }))
    setFinalizeAnchorEl(null)
  }

  const handleCreateTTCMLink = () => {
    if (!deliverable) return
    dispatch(openTikTokInviteModal({
      deliverableId: deliverable.id,
      deliverableVersion: "v2",
      onSuccess: () => refreshQueries(),
    }))
  }

  const handleCopyTTCMLink = () => {
    if (!deliverable) return
    dispatch(openCopyLinkModal({
      link: deliverable.ttcmLinkUrl || "",
      title: translate("Share TTCM Invite Link"),
      subtitle: translate("Copy the invite link for the TTCM order."),
    }))
  }

  const handleTTCMLinkClick = () => {
    if (!deliverable) return

    if (deliverable.livePostUrl || deliverable.ttcmLinkUrl) {
      handleCopyTTCMLink()
    } else {
      handleCreateTTCMLink()
    }
  }

  const dropdownButtonTitle = useMemo(() => {
    const displayedStatus = feedbackPagePendingVote || deliverableVoteData?.deliverableVote?.userVote
    switch (displayedStatus) {
      case UserVote.APPROVED:
        return (
          <div className="dropdown-button-title-container">
            <CheckCircleOutline className="approved-icon" />
            <p>{ translate("Approved") }</p>
          </div>
        )
      case UserVote.APPROVED_WITH_SUGGESTIONS:
        return (
          <div className="dropdown-button-title-container">
            <CheckCircleOutline className="approved-icon" />
            <p>{ translate("Approved w/Suggestions") }</p>
          </div>
        )
      case UserVote.REQUEST_REVISION:
        return (
          <div className="dropdown-button-title-container">
            <RemoveCircleOutline className="request-revision-icon" />
            <p>{ translate("Revision Requested") }</p>
          </div>
        )
      default:
        return translate("Approve")
    }
  }, [ deliverable, feedbackPagePendingVote, deliverableVoteData ])

  const getTTCMLinkLabel = useMemo(() => {
    if (!deliverable) return ""

    if (deliverable.livePostUrl) return translate("Uploaded to TTCM")

    if (deliverable.ttcmLinkUrl) return translate("Copy TTCM Invite Link")

    return translate("Generate TTCM Invite Link")
  }, [ deliverable ])

  const isTikTokDeliverable = deliverable?.socialAccount.networkType === Network.Tiktok
  const canViewTTCMLink = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.FEATURE_CAMPAIGN)
          && isTikTokDeliverable

  if (!deliverable) return <> </>

  return (
    <div className="cp_deliverable-v2-content-overview_component">
      <div className="cp_deliverable-v2-content-overview_component-left">
        <NetworkAvatar
          size="lg"
          network={ deliverable.socialAccount.networkType as Network }
          src={ deliverable.socialAccount.profilePictureUrl }
        >
          { deliverable.socialAccount.username.charAt(0) }
        </NetworkAvatar>
        <div className="cp_deliverable-v2-content-overview_component-left-details">
          <div>
            <p
              className="cp_deliverable-v2-content-overview_component-left-details-name"
              onMouseEnter={
                () => handleCopyUserHandleMouseEnter(`@${ deliverable.socialAccount.username }`)
              }
              onMouseLeave={ handleCopyUserHandleMouseLeave }
            >
              { `@${ deliverable.socialAccount.username }` }
              { copyUserHandle && (
              <CopyIconButton textToCopy={ copyUserHandle } />
              ) }
            </p>
          </div>
          <p className="cp_deliverable-v2-content-overview_component-left-details-demographics">
            {
              [
                deliverable.socialAccount.name,
                `${ shorthandNumber(deliverable.socialAccount.followerCount) }
                ${ translate("FOLLOWERS") }`,
              ].filter((components) => !!components).join(" | ")
            }
          </p>
        </div>
      </div>
      <div className="cp_deliverable-v2-content-overview_component-review">
        {
          canViewTTCMLink && (
          <Typography component="button" onClick={ handleTTCMLinkClick } className="ttcm-link">{ getTTCMLinkLabel }</Typography>
          )
        }

        { approveButtonVisible && (
        <Dropdown
          buttonTitle={ dropdownButtonTitle }
          buttonClassName="approve-dropdown-buttons"
          buttonType="split"
          customAnchorEl={ approveAnchorEl }
          setCustomAnchorEl={ setApproveAnchorEl }
          disabled={ approveButtonDisabled }
        >
          <List className="dropdown-list-item-container" disablePadding={ true }>
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_approve-button"
                onClick={ handleApprove }
              >
                <CheckCircleOutline className="approved-icon" />
                <ListItemText primary={ translate("Approve") } />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_approve-with-suggestions-button"
                onClick={ handleApproveWithSuggestions }
              >
                <CheckCircleOutline className="approved-icon" />
                <ListItemText primary={ translate("Approve with Suggestions") } />
              </ListItemButton>
            </ListItem>
            <Divider className="dropdown-button-divider" />
            <ListItem
              disablePadding={ true }
              disableGutters={ true }
            >
              <ListItemButton
                id="cp_deliverable-v2-content-overview_request-revision-button"
                onClick={ handleRequestRevision }
              >
                <RemoveCircleOutline className="request-revision-icon" />
                <ListItemText primary={ translate("Request Revision") } />
              </ListItemButton>
            </ListItem>
          </List>
        </Dropdown>
        ) }

        { finalizeFeebackButtonVisible && (
          <Dropdown
            buttonTitle={ finalizeButtonTitle }
            buttonClassName="finalize-feedback-buttons"
            disabled={ finalizeFeedbackButtonDisabled }
            buttonType="split"
            customAnchorEl={ finalizeAnchorEl }
            setCustomAnchorEl={ setFinalizeAnchorEl }
            isPrimary={ true }
            onClickOverride={ scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? undefined : handleFinalize }
          >
            <List className="dropdown-list-item-container" disablePadding={ true }>
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
              >
                <ListItemButton
                  id="cp_deliverable-v2-content-overview_next-reviewer-group-button"
                  onClick={ handleNextReviewerGroup }
                >
                  <AccountCircleOutlined className="next-reviewer-group-icon" />
                  <ListItemText primary={ translate("Next Reviewer Group") } />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
              >
                <ListItemButton
                  id="cp_deliverable-v2-content-overview_edit-deliverable-button"
                  onClick={ handleEditDeliverable }
                >

                  <EditNoteOutlined className="edit-deliverable-icon" />
                  <ListItemText primary={ translate("Edit Deliverable") } />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
              >
                <ListItemButton
                  id="cp_deliverable-v2-content-overview_finalize-button"
                  onClick={ handleFinalize }
                  disabled={ deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE }
                >
                  <CheckCircleOutline className="finalize-feedback-icon" />
                  <ListItemText primary={ translate("Feedback Complete") } />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding={ true }
                disableGutters={ true }
              >
                <ListItemButton
                  id="cp_deliverable-v2-content-overview_update-deliverable-status-button"
                  onClick={ handleUpdateDeliverableStatus }
                >
                  <CheckCircleOutline className="update-deliverable-status-icon" />
                  <ListItemText primary={ translate("Update Deliverable Status") } />
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
        ) }
      </div>
    </div>
  )
}

export default Overview
