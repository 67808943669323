import React from "react"
import "./style.sass"
import {
  Box,
  Button,
  Slide,
  Stack,
} from "@mui/material"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"

/**
 * CardData: Data structure for the card data
 */
export type CardData = {
  card: React.JSX.Element
  name: string
  width: number
}

/**
 * CarouselProps: Props used for component
 */
interface CarouselProps {
  id?: string
  className?: string
  displayCards: CardData[]
  widthOfAllCards?: number
  advanceByPage?: boolean
}

/**
 * Carousel: Component used to scroll items left and right like a carousel
 * @param props Properties for the component
 * @returns The JSX element rendering of the component
 */
export default function Carousel({
  id,
  className,
  displayCards,
  widthOfAllCards,
  advanceByPage = false,
}: CarouselProps) {
  // Set reference for slider to find width
  const slideRef = React.useRef<HTMLDivElement>(null)
  const cardStackRef = React.useRef<HTMLDivElement>(null)

  // Local state
  const [ slideDirection, setSlideDirection ] = React.useState<"left" | "right" | undefined>("left")
  const [ currentPage, setCurrentPage ] = React.useState<number>(0)
  const [ windowSize, setWindowSize ] = React.useState<{ width: number, height: number}>({
    width: window.innerWidth, height: window.innerHeight,
  })
  const [ showNextPage, setShowNextPage ] = React.useState<boolean>(false)
  const [ widthOfSlider, setWidthOfSlider ] = React.useState<number>(0)
  const [ cards, setCards ] = React.useState<CardData[]>(displayCards)
  const [ hasSetWidths, setHasSetWidths ] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (slideRef.current) {
      // We know our current width and height
      setWidthOfSlider(slideRef.current.getBoundingClientRect().width)

      // Set show next page based on calculations of widths
      setShowNextPage(calculateCardsPerPage(0) < cards.length)
    }
  }, [ slideRef.current, windowSize ])

  React.useEffect(() => {
    // Create function to handle widow resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Set the window listender
    window.addEventListener("resize", handleResize)

    // Set show next page based on calculations of widths
    setShowNextPage(calculateCardsPerPage(0) < cards.length)

    // Remove the listender when unloading the page
    return () => { window.removeEventListener("resize", handleResize) }
  }, [])

  React.useLayoutEffect(() => {
    if (!hasSetWidths) {
      // Loop through cards and set width
      const newCards: CardData[] = []
      displayCards.forEach((card, index) => {
        if (cardStackRef.current) {
          newCards.push({
            ...card,
            width: cardStackRef.current.children.item(index)?.getBoundingClientRect().width || card.width,
          })
        }
      })
      setCards(newCards)
      setHasSetWidths(true)
    }
  }, [ cards, cardStackRef.current ])

  const calculateCardsPerPage = (startIndex: number): number => {
    // If there is a constant width of all cards, we can calculate the number of cards per page
    if (widthOfAllCards) {
      return Math.floor(widthOfSlider / widthOfAllCards)
    }

    // Calculate how many cards can fit in the slider
    let totalWidth = 0
    let cardsPerPage = 0
    cards.forEach((card, index) => {
      if (index < startIndex) return
      totalWidth += card.width
      if (totalWidth < widthOfSlider) cardsPerPage += 1
    })

    // If we are advancing by page, we need to calculate the number of cards per page
    return cardsPerPage
  }

  /**
   * slideRight: Slide the cards to the right
   */
  const slideRight = () => {
    setSlideDirection("right")
    setCurrentPage((page) => page - 1)
    const cardsPerPage = calculateCardsPerPage(currentPage - 1)
    if (advanceByPage) setShowNextPage((((currentPage - 1) * cardsPerPage) + cardsPerPage) < cards.length)
    else setShowNextPage((currentPage + cardsPerPage - 1) < cards.length)
  }

  /**
   * slideLeft: Slide the cards to the left
   */
  const slideLeft = () => {
    setSlideDirection("left")
    setCurrentPage((page) => page + 1)
    const cardsPerPage = calculateCardsPerPage(currentPage + 1)
    if (advanceByPage) setShowNextPage((((currentPage + 1) * cardsPerPage) + cardsPerPage) < cards.length)
    else setShowNextPage((currentPage + cardsPerPage + 1) < cards.length)
  }

  const renderPageCards = (startIndex: number): React.JSX.Element[] => {
    // Retrieve the number of cards per page
    const cardsPerPage = calculateCardsPerPage(startIndex)

    // Determine if advancing by page
    if (advanceByPage) {
      // Return advance by page
      return cards.slice(
        startIndex * cardsPerPage,
        startIndex * cardsPerPage + cardsPerPage,
      ).map((cardData) => cardData.card)
    }

    // Return normal advance by card
    return cards.slice(startIndex, startIndex + cardsPerPage)
      .map((cardData) => cardData.card)
  }

  // Set the base class name and include passed class name if it exists
  const classes = (className) ? `cp_component_carousel ${ className }` : "cp_component_carousel"

  // Rendering the carousel component
  return (
    <Box id={ id } className={ classes }>
      { currentPage > 0 && (
        <Button className="cp_component_carousel_nav-before-button" onClick={ slideRight }>
          <NavigateBefore />
        </Button>
      ) }
      <Box className="cp_component_carousel_card-slider" ref={ slideRef }>
        { cards.map((card, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={ `card-${ index }` }
            className={ `cp_component_carousel_card-slider_box${ (index !== currentPage) ? " no-display" : "" }` }
          >
            <Slide
              direction={ slideDirection }
              in={ currentPage === index }
              easing={ {
                enter: "ease",
                exit: "sharp",
              } }
            >
              <Stack
                className="carousel-card-stack"
                spacing={ 1 }
                direction="row"
                alignContent="center"
                gap={ 2 }
                height="100%"
                ref={ cardStackRef }
              >
                { renderPageCards(index) }
              </Stack>
            </Slide>
          </Box>
        )) }
      </Box>
      { (showNextPage) && (
        <Button className="cp_component_carousel_nav-next-button" onClick={ slideLeft }>
          <NavigateNext />
        </Button>
      ) }
    </Box>
  )
}
