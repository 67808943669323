import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../state/hooks"

import "./style.sass"
import AccountAudienceDetails from "../../ProfileTabs/PublicListTabs/AccountAudienceDetails"
import LoadingIndicator from "../../LoadingIndicator"

export default function AudienceV2() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListAccountTabsV2" })
  const {
    selectedListSocialAccount,
    toggles,
  } = useSelector(({ publicList }) => publicList)

  return (
    <div className="audience-v2">
      <h2 className="cp_account-page-tabs-content-heading">{ translate("Account Insights") }</h2>
      { selectedListSocialAccount
        ? <AccountAudienceDetails toggles={ toggles } />
        : <LoadingIndicator flexWrapperEnabled={ true } size={ 48 } /> }
    </div>
  )
}
