import React from "react"
import "./style.sass"
import PageHeader from "./PageHeader"
import PageContent from "./PageContent"

/**
 * PageShell: The shell or layout of the page
 * @returns The page layout
 */
export default function PageShell() {
  return (
    <div className="cp_component_pl-account-page-shell">
      <PageHeader />
      <PageContent />
    </div>
  )
}
