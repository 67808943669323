import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CheckCircleOutline,
  Download,
  RemoveCircleOutline,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material"
import dayjs from "dayjs"
import { useApolloClient, useMutation } from "@apollo/client"
import { Container } from "@mui/material"
import {
  DeliverableHistoryItem,
  DeliverableHistoryKind,
  DeliverableStatus,
  GET_DELIVERABLE_HISTORY,
  GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
  UPDATE_DELIVERABLE_ITEM_FEEDBACK_VISIBILITY,
  UpdateDeliverableItemFeedbackVisibilityInput,
  UpdateDeliverableItemFeedbackVisibilityOutput,
  UserVote,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import EntityInfoRow from "../EntityInfoRow"
import * as Constant from "../../util/constant"
import "./history-item.sass"
import { useSelector } from "../../state/hooks"
import { Scope } from "../../util/types"

type Props = {
  deliverableHistoryItem: DeliverableHistoryItem
}

const STATUS_HELPERS = {
  [DeliverableStatus.DRAFT]: "DRAFT",
  [DeliverableStatus.FINALIZED]: "FINALIZED",
  [DeliverableStatus.LIVE]: "LIVE",
  [DeliverableStatus.PENDING_FEEDBACK]: "PENDING FEEDBACK",
  [DeliverableStatus.PENDING_REVISION]: "PENDING REVISION",
}

// Note: deliverable history items that have isHidden filtered out and do not render in History.tsx
export default function HistoryItem({ deliverableHistoryItem }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableHistory" })

  const { scopes } = useSelector(({ user }) => user)

  const {
    agent,
    kind,
    payload,
    timestamp,
  } = deliverableHistoryItem

  const client = useApolloClient()

  const [ updateVisibility ] = useMutation<
      UpdateDeliverableItemFeedbackVisibilityOutput,
      UpdateDeliverableItemFeedbackVisibilityInput
      >(UPDATE_DELIVERABLE_ITEM_FEEDBACK_VISIBILITY, {
      })

  const refreshQueries = async () => {
    await client.refetchQueries({
      include: [
        GET_DELIVERABLE_HISTORY,
        GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
      ],
    })
  }

  const ApprovedTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Approved") }</p>
    </div>
  )

  const ApproveWSuggestionsTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Approve w/ Suggestions") }</p>
    </div>
  )

  const FinalizedFeedbackTag = (
    <div className="status-tag-container">
      <CheckCircleOutline className="approve-icon" />
      <p>{ translate("Finalized Feedback") }</p>
    </div>
  )

  const RevisionRequestedTag = (
    <div className="status-tag-container">
      <RemoveCircleOutline className="request-revision-icon" />
      <p>{ translate("Revision Requested") }</p>
    </div>
  )

  const [ historyItemContent, statusTag ] = useMemo(() => {
    switch (kind.toUpperCase() as DeliverableHistoryKind) {
      // 1. When the Reviewer user leaves feedback on Media
      case DeliverableHistoryKind.DELIVERABLE_ITEM_COMMENT_CREATED:
        if (payload?.__typename !== "DeliverableItemFeedbackHistory") return [ null, null ]
        if (payload.mediaContent) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Left feedback on media") }</p>
              <div className="history-item-details">
                <img className="small-img" src={ payload.mediaContent.imageUrl } alt="" />
                { /* eslint-disable-next-line react/no-danger */ }
                <div dangerouslySetInnerHTML={ { __html: payload.feedbackContent } } />
              </div>
            </div>
          ), null ]
        }
        // 2. When the reviewer leaves feedback on the caption
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Left feedback on caption") }</p>
            <div className="history-item-details">
              { /* eslint-disable-next-line react/no-danger */ }
              <div dangerouslySetInnerHTML={ { __html: payload.feedbackContent } } />
            </div>
          </div>
        ), null ]

      case DeliverableHistoryKind.DELIVERABLE_ITEM_VOTE_CREATED:
        // 3. Vote to approve Media
        if (payload?.__typename !== "DeliverableItemVoteHistory") return [ null, null ]
        if (payload.mediaContent) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve media") }</p>
              <div className="history-item-details">
                <div className="image-container">
                  <img className="downloadable-img" src={ payload.mediaContent.imageUrl } alt="" />
                  <a
                    className="cp_campaign-deliverable-media-card_component-avatar-download"
                    aria-label="download-deliverable-media"
                    download={ payload.mediaContent.fileName }
                    href={ `/api/media/url/${ payload.mediaContent.code || "" }` }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Download />
                  </a>
                </div>
              </div>
            </div>
          ), ApprovedTag ]
        }
        // 4. Vote to approve Caption
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Voted to approve caption") }</p>
            <div className="history-item-details">
              { payload.caption }
            </div>
          </div>
        ), ApprovedTag ]

      case DeliverableHistoryKind.DELIVERABLE_VOTE_CREATED:
        // 5. When the Reviewer user Approves the deliverable
        if (payload?.__typename !== "DeliverableVoteHistory") return [ null, null ]
        if (payload.voteType === UserVote.APPROVED) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve deliverable") }</p>
            </div>
          ), ApprovedTag ]
        }
        // 6. When the Reviewer user Approves w/Suggestions
        if (payload.voteType === UserVote.APPROVED_WITH_SUGGESTIONS) {
          return [ (
            <div className="history-item-content">
              <p className="history-item-action">{ translate("Voted to approve w/ suggestions") }</p>
              <div className="history-item-details">
                { /* eslint-disable-next-line react/no-danger */ }
                <div dangerouslySetInnerHTML={ { __html: payload.comment } } />
              </div>
            </div>
          ), ApproveWSuggestionsTag ]
        }
        // 7. When the Reviewer user Requests a Revision
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Requested Revision") }</p>
            <div className="history-item-details">
              { /* eslint-disable-next-line react/no-danger */ }
              <div dangerouslySetInnerHTML={ { __html: payload.comment } } />
            </div>
          </div>
        ), RevisionRequestedTag ]

        // 8. When the Reviewer Finalizes Feedback
      case DeliverableHistoryKind.GROUP_FEEDBACK_FINALIZED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Finalized group feedback") }</p>
          </div>
        ), FinalizedFeedbackTag ]
        // 9. Deliverable status changed
      case DeliverableHistoryKind.DELIVERABLE_STATUS_UPDATED:
        if (payload?.__typename !== "DeliverableStatusChangeHistory") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">
              { `${ translate("Deliverable") } ${ STATUS_HELPERS[payload.deliverableStatus] }` }
            </p>
          </div>
        ), null ]
      case DeliverableHistoryKind.DELIVERABLE_FINALIZED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">
              { `${ translate("Deliverable") } ${ STATUS_HELPERS.FINALIZED }` }
            </p>
          </div>
        ), null ]
      case DeliverableHistoryKind.DELIVERABLE_CREATED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Created deliverable") }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.REVIEWER_GROUP_ASSIGNED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ `${ agent.reviewerGroupName } ${ translate("assigned") }` }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.REVIEWER_GROUP_DELETED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ `${ agent.reviewerGroupName } ${ translate("deleted") }` }</p>
          </div>
        ), null ]
      case DeliverableHistoryKind.MEDIA_ACCEPTED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Accepted media") }</p>
          </div>
        ), ApprovedTag ]
      case DeliverableHistoryKind.MEDIA_CREATED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Added media") }</p>
            <div className="history-item-details">
              <div className="image-container">
                <img className="downloadable-img" src={ payload.imageUrl } alt="" />
                <a
                  className="cp_campaign-deliverable-media-card_component-avatar-download"
                  aria-label="download-deliverable-media"
                  download={ payload.fileName }
                  href={ `/api/media/url/${ payload.code || "" }` }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Download />
                </a>
              </div>
            </div>
          </div>
        ), null ]
      case DeliverableHistoryKind.MEDIA_DELETED:
        if (payload?.__typename !== "DeliverableItemMediaContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Removed media") }</p>
            <div className="history-item-details">
              <div className="image-container">
                <img className="downloadable-img" src={ payload.imageUrl } alt="" />
                <a
                  className="cp_campaign-deliverable-media-card_component-avatar-download"
                  aria-label="download-deliverable-media"
                  download={ payload.fileName }
                  href={ `/api/media/url/${ payload.code || "" }` }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Download />
                </a>
              </div>
            </div>
          </div>
        ), null ]
      case DeliverableHistoryKind.TEXT_CREATED:
        if (payload?.__typename !== "DeliverableItemCaptionContent") return [ null, null ]
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Updated caption") }</p>
            <div className="history-item-details">
              { payload.text }
            </div>
          </div>
        ), null ]
      // NOTE: Removing for now to mirror elm functionality
      // case DeliverableHistoryKind.TEXT_DELETED:
      //   if (payload?.__typename !== "DeliverableItemCaptionContent") return [ null, null ]
      //   return [ (
      //     <div className="history-item-content">
      //       <p className="history-item-action">{ translate("Deleted caption") }</p>
      //       <div className="history-item-details">
      //         { payload.text }
      //       </div>
      //     </div>
      //   ), null ]
      case DeliverableHistoryKind.TEXT_ACCEPTED:
        return [ (
          <div className="history-item-content">
            <p className="history-item-action">{ translate("Accepted caption") }</p>
          </div>
        ), ApprovedTag ]
      default:
        return [ null, null ]
    }
  }, [ deliverableHistoryItem ])

  const setVisibility = async (newVisibility: boolean, deliverableItemId: string) => {
    await updateVisibility({
      variables: {
        deliverableItemId,
        isHidden: !newVisibility,
      },
      context: {
        apiVersion: "v2",
      },
    })
    refreshQueries()
  }

  const renderVisibilityIcon = () => {
    if (payload?.__typename === "DeliverableItemFeedbackHistory") {
      if (!payload.visibility) {
        return (
          <Container className="visibility-icon-toggle" onClick={ () => setVisibility(true, payload.id) }>
            <VisibilityOffOutlined />
          </Container>
        )
      }
      return (
        <Container className="visibility-icon-toggle" onClick={ () => setVisibility(false, payload.id) }>
          <VisibilityOutlined />
        </Container>
      )
    }
    return null
  }

  // NOTE: Removing for now to mirror elm functionality
  if (kind.toUpperCase() === DeliverableHistoryKind.TEXT_DELETED) return <></>

  return (
    <div className="cp_component_deliverable_v2_history-item">
      <div className="main-info">
        <EntityInfoRow
          className="deliverable-history-entity-info-row"
          avatarSrc={ agent.avatarUrl }
          name={ agent.userName }
          subInfo={ agent.reviewerGroupName ? `${ agent.reviewerGroupName } | ${ agent.customerName }` : agent.customerName }
          copyDisabled={ true }
        />
        { statusTag }
      </div>
      <div className="sub-info">
        <div className="history-attributes">
          { (
            scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
            && kind.toUpperCase() === DeliverableHistoryKind.DELIVERABLE_ITEM_COMMENT_CREATED)
            && renderVisibilityIcon()
          }
          <p>{ dayjs(parseInt(timestamp, 10)).format(Constant.LONGFORM_DATE_TIME) }</p>
        </div>
        { historyItemContent }
      </div>
    </div>
  )
}
