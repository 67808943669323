import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import "./campaign-account-row-context-menu.sass"
import { useApolloClient, useQuery } from "@apollo/client"
import { DeleteOutlineOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from "../../../state/hooks"
import { Scope } from "../../../util/types"
import IconButton from "../../IconButton"
import { openDeliverableUpdateStatusModal } from "../../../state/campaignDeliverableV2UpdateStatusModal"
import { DeliverableSummary } from "../../../graphqlV2/types/deliverableTypes"
import {
  DeliverableDocument,
  DeliverableQuery,
  DeliverableQueryVariables,
  Network,
} from "../../../graphql"
import Divider from "../../Divider"
import { openTikTokInviteModal } from "../../../state/tikTokInviteLinkModalSlice"
import { openCopyLinkModal } from "../../../state/copyLinkModalSlice"
import { openConfirmationModal } from "../../../state/confirmationModalSlice"
import { deleteDeliverable } from "../../../state/campaignSlice"
import { pushToast } from "../../../state/toastSlice"
import { openDeliverableMetricModal } from "../../../state/campaignDeliverableMetricModalSlice"
import { openEditURLModal } from "../../../state/editURLModalSlice"
import {
  DeliverableQueryOutput,
  GET_DELIVERABLE,
  DeliverableStatus as V2DeliverableStatus,
} from "../../../state/campaignDeliverableContentModalV2Slice/types"
import { GET_CAMPAIGN_DETAILS } from "../../../graphqlV2/queries/campaignQueries"
import LoadingIndicator from "../../LoadingIndicator"
import { openEditDeliverableV2Modal } from "../../../state/updateCampaignDeliverableV2Modal"

interface CampaignDeliverableRowContextMenuProps {
  deliverableSummary: DeliverableSummary
}

export default function CampaignDeliverableRowContextMenuV2({
  deliverableSummary,
}: CampaignDeliverableRowContextMenuProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverableRowContextMenu" })
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user }) => user)

  const client = useApolloClient()

  const { data: deliverableData, loading } = useQuery<DeliverableQueryOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE,
    {
      variables: { deliverableId: deliverableSummary.id },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableSummary.id,
    },
  )

  const { data: deliverableDataV1, loading: loadingV1 } = useQuery<DeliverableQuery, DeliverableQueryVariables>(
    DeliverableDocument,
    {
      variables: { deliverableId: deliverableSummary.id },
      skip: !anchorEl || !deliverableSummary.id,
    },
  )

  const deliverable = deliverableData?.deliverable
  const v1Deliverable = deliverableDataV1?.deliverable

  const deliverableMetricAvailable = React.useMemo(
    () => deliverable
    && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && scopes.includes(Scope.CAMPAIGN_REPORTING)
    && deliverable.deliverableStatus === "LIVE",
    [ deliverable ],
  )

  const showCreateTTCMLink = React.useMemo(
    () => deliverable
    && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    && deliverable.socialAccount.networkType === Network.Tiktok
    && !deliverable.ttcmLinkUrl,
    [ deliverable ],
  )

  const showCopyTTCMLink = React.useMemo(
    () => deliverable
    && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    && deliverable.socialAccount.networkType === Network.Tiktok
    && deliverable.ttcmLinkUrl,
    [ deliverable ],
  )

  const showEditURL = React.useMemo(
    () => deliverable
    && scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    && deliverable.deliverableStatus === "LIVE",
    [ deliverable ],
  )

  const refreshCampaign = async () => {
    await client.refetchQueries({
      include: [ GET_CAMPAIGN_DETAILS ],
    })
  }

  const renderContent = () => {
    if (!deliverable || !v1Deliverable) return null
    if (loading || loadingV1) return <LoadingIndicator />
    return (
      <MenuList
        dense={ true }
        className="cp_campaign-account-row-context-menu_component-menu"
      >
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
        <MenuItem
          onClick={ () => {
            dispatch(openEditDeliverableV2Modal({ deliverableId: deliverable.id }))
            setAnchorEl(null)
          } }
          className="cp_campaign-account-row-context-menu_component-menu-item"
        >
          <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
            { translate("Edit Deliverable") }
          </ListItemText>
        </MenuItem>
        ) }
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
        <MenuItem
          className="cp_campaign-account-row-context-menu_component-menu-item"
          onClick={ () => {
            dispatch(openConfirmationModal({
              onConfirm: () => dispatch(deleteDeliverable({
                variables: { id: deliverable.id },
                onSuccess: () => {
                  refreshCampaign()
                  dispatch(pushToast({
                    message: translate("Delete Success!"),
                    type: "success",
                  }))
                },
              })),
              title: translate("Delete Deliverable"),
              subtext: translate("Are you sure you want to delete this deliverable?"),
            }))
            setAnchorEl(null)
          } }
        >
          <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
            { translate("Delete Deliverable") }
          </ListItemText>
          <DeleteOutlineOutlined fontSize="small" />
        </MenuItem>
        ) }
        { deliverableMetricAvailable && (
        <>
          <Divider />
          <MenuItem
            onClick={ () => {
              dispatch(openDeliverableMetricModal({
                deliverable: v1Deliverable,
                source: "menu",
                updateCallback: () => refreshCampaign(),
              }))
              setAnchorEl(null)
            } }
            className="cp_campaign-account-row-context-menu_component-menu-item"
          >
            <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
              { translate("Update Metrics") }
            </ListItemText>
          </MenuItem>
        </>
        ) }
        { showEditURL ? (
          <>
            { !scopes.includes(Scope.CAMPAIGN_REPORTING) ? <Divider /> : null }
            <MenuItem
              onClick={ () => {
                dispatch(openEditURLModal({
                  deliverableId: deliverable.id,
                  url: deliverable.livePostUrl || "",
                  updateCallback: () => refreshCampaign(),
                }))
                setAnchorEl(null)
              } }
              className="cp_campaign-account-row-context-menu_component-menu-item"
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Edit URL") }
              </ListItemText>
            </MenuItem>
          </>
        ) : null }
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
        <MenuItem
          onClick={ () => {
            dispatch(openDeliverableUpdateStatusModal({
              deliverableId: deliverable.id,
              deliverableStatus: deliverableSummary.deliverableStatus as V2DeliverableStatus,
            }))
            setAnchorEl(null)
          } }
          className="cp_campaign-account-row-context-menu_component-menu-item"
        >
          <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
            { translate("Update Status") }
          </ListItemText>
        </MenuItem>
        ) }
        { showCreateTTCMLink
       && (
       <>
         <Divider />
         <MenuItem
           className="cp_campaign-account-row-context-menu_component-menu-item"
           onClick={ () => {
             if (!deliverable) return
             dispatch(openTikTokInviteModal({
               deliverableId: deliverable.id,
               deliverableVersion: "v2",
             }))
             setAnchorEl(null)
           } }
         >
           <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
             { translate("Generate TTCM Invite Link") }
           </ListItemText>
         </MenuItem>
       </>
       ) }
        { showCopyTTCMLink && (
        <>
          <Divider />
          <MenuItem
            className="cp_campaign-account-row-context-menu_component-menu-item"
            onClick={ () => {
              if (!deliverable.ttcmLinkUrl) return
              dispatch(openCopyLinkModal({
                link: deliverable.ttcmLinkUrl,
                title: translate("Share TTCM Invite Link"),
                subtitle: translate("Copy the invite link for the TTCM order."),
              }))
              setAnchorEl(null)
            }
            }
          >
            <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
              { translate("Copy TTCM Invite Link") }
            </ListItemText>
          </MenuItem>
        </>
        ) }
      </MenuList>
    )
  }

  return (
    <>
      <IconButton
        className="cp_campaign-account-row-context-menu_component-button"
        id="cp_campaign_deliverable-v2_context_menu_button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_campaign-account-row-context-menu_component-menu-popover"
      >
        { renderContent() }
      </Menu>
    </>
  )
}
