/* eslint-disable react/no-unused-prop-types */
import React, { JSX } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import {
  AccountCircle,
  AddCircleOutline,
  MoreHoriz,
  OpenInNew,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import Dropdown from "../Dropdown"
import { Scope } from "../../util/types"
import { useDispatch, useSelector } from "../../state/hooks"
import { setSelectedAccountIds, setSelectedNetwork } from "../../state/modalAddToLists"
import {
  MountContext,
  setContactInfoModalOpen,
  setSocialAccountId,
  setSocialAvatarInfo,
} from "../../state/accountContactInfoModal"

// NOTE: Ellipsis menu on both Account and Content Search Results will
// use the same styles
import "./ellipsisMenuCellStyle.sass"

type Props = {
  hasPersonality: boolean,
  network: GraphQL.Network,
  socialAccountId: string,
  socialAccountUrl: string,
  followers: number,
  isBlacklisted: boolean,
  isNSFW: boolean,
  isPlaceholder: boolean,
  lastPostedDate: any,
  profilePictureUrl: string,
  username: string,
  oauthed: boolean,
}

// TODO: Begin enabling these onClick actions as related features come
// into existence
export default function AccountEllipsisMenuCell(props: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AccountEllipsisMenuCell" })
  const { t: translateContact } = useTranslation([], { keyPrefix: "component.GroupAccountsContactInfoModal" })

  const { vanity } = useParams()
  const dispatch = useDispatch()
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  // Note: some menu items will be hidden temporarily: ID-2200 - item 2
  const displayNavItem = false

  const handleQueueAccountForLists = () => {
    dispatch(setSelectedAccountIds([ props.socialAccountId ]))
    dispatch(setSelectedNetwork(props.network))
  }

  return (
    <div className="cp_component_search-results-ellipsis-menu-cell">
      <Dropdown
        buttonType="custom"
        customButtonChildren={ <MoreHoriz /> }
        placement="left"
      >
        <List disablePadding={ true }>
          { displayNavItem && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={ () => {} }>
              <ListItemIcon>
                <AccountCircle className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Similar") } />
            </ListItemButton>
          </ListItem>
          ) }
          { scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
            <ListItem disablePadding={ true } disableGutters={ true }>
              <ListItemButton onClick={ handleQueueAccountForLists }>
                <ListItemIcon>
                  <AddCircleOutline className="list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Add to List") } />
              </ListItemButton>
            </ListItem>
          ) }
          { displayNavItem && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={ () => {} }>
              <ListItemIcon>
                <AddCircleOutline className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Add to Vetting") } />
            </ListItemButton>
          </ListItem>
          ) }
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton
              onClick={ () => {
                const url = props.hasPersonality
                  ? Misc.generateProfileUrl(props.socialAccountId, vanity || "")
                  : Misc.generateSearchSocialAccountUrl(props.socialAccountId, vanity || "")

                window.open(url, "_blank")
              } }
            >
              <ListItemIcon>
                <OpenInNew className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Profile") } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={
              () => {
                const url = Misc.generateSearchSocialAccountContentUrl(
                  props.socialAccountId,
                  vanity || "",
                )
                window.open(url, "_blank")
              } }
            >
              <ListItemIcon>
                <OpenInNew className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Matching Posts") } />
            </ListItemButton>
          </ListItem>
          { props.socialAccountUrl && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={ () => { window.open(props.socialAccountUrl, "_blank") } }>
              <ListItemIcon>
                <OpenInNew className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Network Profile") } />
            </ListItemButton>
          </ListItem>
          ) }
          { scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO) && (
          <ListItem disablePadding={ true } disableGutters={ true }>
            <ListItemButton onClick={ async () => {
              dispatch(setSocialAvatarInfo({
                ...props,
                id: props.socialAccountId,
                fullName: "",
                isUnsubscribed: false,
              }))
              dispatch(setSocialAccountId(props.socialAccountId))
              dispatch(setContactInfoModalOpen({
                mountContext: MountContext.Search2,
                onUpdateSuccessText: translateContact("Account contact information has been successfully updated!"),
                onUpdateErrorText: translateContact("Failed to update account contact information!"),
                // eslint-disable-next-line max-len
                onUpdateWarningText: translateContact("No update was made to name, first and last names should both be present!"),
              }))
            } }
            >
              <ListItemIcon>
                <AddCircleOutline className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("View Contact Info") } />
            </ListItemButton>
          </ListItem>
          ) }
        </List>
      </Dropdown>
    </div>
  )
}
