/* eslint-disable react/jsx-closing-tag-location */
import React from "react"
import { Link, useParams } from "react-router-dom"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useTranslation } from "react-i18next"
import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"

import NetworkIcon from "../NetworkIcon"
import Input from "../Input"
import Button from "../Button"
import LoadingIndicator from "../LoadingIndicator"
import NetworkAvatar from "../NetworkAvatar"
import * as GraphQL from "../../graphql"
import Avatar from "../Avatar"
import { pushToast } from "../../state/toastSlice"
import {
  createPlaceholderAccount,
  setConnection,
  setOpenModal,
} from "../../state/profileSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import { Toast, Scope } from "../../util/types"

type SocialAccountStatus = "Init" | "NotFound" | "Created" | "AlreadyExists"

interface AccountNotFoundProps {
  createPlaceholderAccountHandler?: () => Promise<void>
}

function AccountNotFound({ createPlaceholderAccountHandler }: AccountNotFoundProps) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalProfile" })

  if (!createPlaceholderAccountHandler) {
    return (
      <span className="body_small cp_component_modal-add-profile_network-input-helper">
        { translate("Account not found.") }
      </span>

    )
  }
  return (
    <span className="body_small cp_component_modal-add-profile_network-input-helper">
      { `${ translate("Account not found.") } ` }
      { `${ translate("This account is not a professional account.") } ` }
      <button
        onClick={ createPlaceholderAccountHandler }
        type="button"
        className="cp_component_modal-add-profile_create-placeholder"
      >
        { translate("Create placeholder") }

      </button>
    </span>
  )
}

interface AddConnectionProps {
  network: GraphQL.Network
  inputLabel: string
}

function AddConnection({
  inputLabel,
  network,
}: AddConnectionProps) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalProfile" })
  const [ input, setInput ] = React.useState("")
  const [ accountStatus, setAccountStatus ] = React.useState<SocialAccountStatus>("Init")
  const [ searchResults, setSearchResults ] = React.useState<GraphQL.SocialZombieNetworkAccount[]>([])
  const [ searching, setSearching ] = React.useState(false)
  const [ menuOpen, setMenuOpen ] = React.useState(false)
  const dispatch = useDispatch()
  const { vanity } = useParams()
  const { scopes } = useSelector(({ user }) => user)
  const anchorRef = React.useRef(null)

  const createdInputProps = {
    endAdornment: <InputAdornment position="end">
      <CheckCircleOutlineIcon className="cp_component_input-success-adornment" />
    </InputAdornment>,
  }

  const alreadyExistsToast = async (account: GraphQL.SocialZombieNetworkAccount) => {
    // perform api call to fetch social account
    const toast: Toast = {
      message: translate("User has already been added to a Profile"),
      type: "error",
    }
    if (!account.socialAccountId) {
      dispatch(pushToast(toast))
      return
    }

    const res = await API.fetchSocialProfile(account.socialAccountId)

    if (API.isSuccess(res) && res.payload.socialAccount.personality) {
      toast.additionalInfo = (
        <Link
          onClick={ () => dispatch(setOpenModal(false)) }
          className="cp-toast-link"
          to={ `/${ vanity }/profile/${ account.socialAccountId }` }
        >
          { translate("VIEW PROFILE") }
        </Link>
      )
      dispatch(pushToast(toast))
    }
  }

  const connect = (zombieAccount: GraphQL.SocialZombieNetworkAccount) => {
    dispatch(setConnection({
      network,
      connection: {
        foreignUserId: zombieAccount.networkId,
        userName: zombieAccount.foreignUsername,
        profilePictureUrl: zombieAccount.profilePictureUrl,
        id: zombieAccount.socialAccountId,
        network,
      },
    }))
  }

  const searchAccounts = async () => {
    setAccountStatus("Init")
    setSearching(true)
    const result = await API.searchSocialNetwork({ name: input, network })
    setSearching(false)
    if (API.isError(result)) return
    const accounts = result.payload.searchSocialZombie.rows
    switch (true) {
      case accounts.length > 1:
        setSearchResults(accounts)
        setMenuOpen(true)
        break
      case accounts.length === 1 && accounts[0].isMappedToCurrentUser:
        setAccountStatus("AlreadyExists")
        alreadyExistsToast(accounts[0])
        break
      case accounts.length === 1:
        connect(accounts[0])
        break
      default:
        setAccountStatus("NotFound")
        break
    }
  }

  const createPlaceholderAccountHandler = async () => {
    setSearching(true)
    dispatch(pushToast({
      message: translate("Creating placeholder account"),
      type: "warning",
      additionalInfo: translate("Note: no data will be processed for this account unless they switch to a professional account"),
    }))
    const result = await dispatch(createPlaceholderAccount({ network, username: input }))
    if (API.isSuccess(result)) {
      dispatch(pushToast({
        message: translate("Placeholder account created!"),
        type: "success",
        additionalInfo: `${ translate("For") } @${ input }`,
      }))
      await searchAccounts()
    } else {
      dispatch(pushToast({
        message: translate("Failed to create placeholder account"),
        additionalInfo: result.message,
        type: "error",
      }))
      setSearching(false)
    }
  }

  const helperText = React.useMemo(() => {
    switch (accountStatus) {
      case "NotFound":
        return (
          <AccountNotFound
            createPlaceholderAccountHandler={
            (network !== GraphQL.Network.Instagram && network !== GraphQL.Network.Tiktok && network !== GraphQL.Network.Snapchat)
              ? undefined
              : createPlaceholderAccountHandler }
          />
        )
      case "AlreadyExists":
        return (
          <span className="body_small cp_component_modal-add-profile_network-input-helper">
            { translate("This account has already been added to a Profile") }
          </span>
        )
      case "Created":
        return (
          <span
            className="body_small cp_component_modal-add-profile_network-input-helper
            cp_component_modal-add-profile_network-input-helper_success"
          >
            { translate("Placeholder created!") }
          </span>
        )
      default:
        return " "
    }
  }, [ accountStatus ])

  const hasScopeToConnect = React.useMemo(() => {
    const scopeMap = {
      [GraphQL.Network.Facebook]: Scope.SOCIAL_ACCOUNT_FACEBOOK_CREATE,
      [GraphQL.Network.Instagram]: Scope.SOCIAL_ACCOUNT_INSTAGRAM_CREATE,
      [GraphQL.Network.Snapchat]: Scope.SOCIAL_ACCOUNT_SNAPCHAT_CREATE,
      [GraphQL.Network.Tiktok]: Scope.SOCIAL_ACCOUNT_TIKTOK_CREATE,
      [GraphQL.Network.Youtube]: Scope.SOCIAL_ACCOUNT_YOUTUBE_CREATE,
      [GraphQL.Network.Twitter]: "",
    }
    return scopes.includes(scopeMap[network])
  }, [ scopes ])

  if (!hasScopeToConnect) return null

  return (
    <>
      { " " }
      <p className="cp_component_modal-add-profile_network-label">
        { inputLabel }
      </p>
      <div className="cp_component_modal-add-profile_row cp_component_modal-add-profile_connection">
        <div className="cp_component_modal-add-profile_network">
          { accountStatus !== "Created" && <NetworkIcon network={ network } isActive={ false } /> }
          { accountStatus === "Created" && <NetworkAvatar size="lg" network={ network } /> }
        </div>
        <div>
          <Menu
            id="lock-menu"
            anchorEl={ anchorRef.current }
            open={ menuOpen }
            onClose={ () => setMenuOpen(false) }
            MenuListProps={ {
              "aria-labelledby": "lock-button",
              role: "listbox",
            } }
            className="cp_component_modal-add-profile_youtube-selection"
          >
            { searchResults.map(({
              networkId, profilePictureUrl, foreignUsername,
            }, i) => (
              <MenuItem
                onClick={ () => {
                  if (searchResults[i].isMappedToCurrentUser) {
                    setAccountStatus("AlreadyExists")
                    alreadyExistsToast(searchResults[i])
                    setMenuOpen(false)
                  } else {
                    connect(searchResults[i])
                  }
                } }
                key={ networkId }
              >
                <ListItemIcon>
                  <Avatar src={ profilePictureUrl } />
                </ListItemIcon>
                <ListItemText>{ foreignUsername }</ListItemText>
              </MenuItem>
            )) }
          </Menu>
          <div ref={ anchorRef }>
            <Input
              error={ accountStatus === "NotFound" || accountStatus === "AlreadyExists" }
              className="cp_component_modal-add-profile_input"
              value={ input }
              onChange={ (e) => setInput(e.currentTarget.value) }
              fullWidth={ true }
              required={ true }
              InputProps={ accountStatus === "Created" ? createdInputProps : undefined }
              helperText={ helperText }
            />
          </div>
        </div>
        <div>
          <Button
            onClick={ searchAccounts }
            isEnabled={ !searching }
            isPrimary={ true }
            label={ searching ? <LoadingIndicator size={ 20 } /> : translate("Connect") }
          />
        </div>
      </div>
    </>
  )
}

export default AddConnection
