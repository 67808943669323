import React, { useEffect, useState } from "react"
import { Search } from "@mui/icons-material"
import {
  createFilterOptions,
  debounce,
  FilterOptionsState,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { useParams } from "react-router-dom"
import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input, { Props as InputProps } from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import Autocomplete from "../../../Autocomplete"

import "./customer-autocomplete.sass"
import EntityInfoRow from "../../../EntityInfoRow"

type Customer = GraphQL.CustomerFragment;

interface Props {
  setSelectedCustomer: (customer: Customer | null) => void;
  selectedCustomer: Customer | null;
}

function CustomersAutocomplete({
  setSelectedCustomer,
  selectedCustomer,
}: Props) {
  const [ customers, setCustomers ] = useState<Customer[]>([])
  const [ customersLoading, setCustomersLoading ] = useState(true)
  const { campaignID } = useParams()
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewersModal" })

  const searchCustomers = async () => {
    const result = await API.fetchCustomerByCampaign({
      campaignId: campaignID as string,
    })

    if (API.isSuccess(result)) {
      setCustomersLoading(false)
      setCustomers(result.payload.campaign.customers)
    } else {
      setCustomers([])
    }
  }

  const debouncedSearchCustomers = debounce(async () => {
    setCustomersLoading(true)
    await searchCustomers()
    setCustomersLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  const filter = createFilterOptions<Customer>()

  const autoCompleteFilter = (
    options: Customer[],
    params: FilterOptionsState<Customer>,
  ) => filter(options, params)

  useEffect(() => {
    searchCustomers()
  }, [])

  const handleAutocompleteSelect = (
    customer: string
    | GraphQL.CustomerFragment
    | (string | GraphQL.CustomerFragment)[]
    | null,
  ): void => {
    if (
      customer == null
      || typeof customer === "string"
      || Array.isArray(customer)
    ) {
      return
    }
    setSelectedCustomer(customer)
  }

  return (
    <Autocomplete
      value={ selectedCustomer }
      onChange={ (_, newValue) => handleAutocompleteSelect(newValue) }
      id="customer-autocomplete"
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ true }
      handleHomeEndKeys={ true }
      options={ customers }
      getOptionLabel={ (o) => typeof o === "string" ? o : o.vanity }
      renderOption={ (props, customer, { index }) => (
        <li { ...props }>
          <EntityInfoRow
            key={ customer.id }
            id={ `customer-auto-complete-row-${ index }` }
            className="customer-auto-complete-row"
            name={ `${ customer.vanity }(${ customer.company?.name }) | User Count: ${ customer.userCount }` }
            avatarSrc={ customer.company?.logo?.url.address }
          />
        </li>
      )
      }
      renderInput={ (params) => (
        <Input
          { ...(params as InputProps) }
          placeholder={ translate("Select a Customer") }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <Search />,
          } }
        />
      ) }
      onInputChange={ debouncedSearchCustomers }
      loading={ customersLoading }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      className="cp_component_autocomplete_lists"
    />
  )
}

export default CustomersAutocomplete
