import React, { useMemo } from "react"
import CampaignIcon from "@mui/icons-material/Campaign"
import CheckIcon from "@mui/icons-material/Check"
import DownloadIcon from "@mui/icons-material/Download"
import ImageIcon from "@mui/icons-material/Image"
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo"
import { Avatar, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import Badge from "../Badge"
import Tooltip from "../Tooltip"
import {
  setSelectedMediaForFeedback,
  setMediaToApprove,
  setEdited,
  setCaptionFeedback,
} from "../../state/campaignDeliverableContentModalV2Slice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./media-card.sass"
import { DeliverableMediaItem, ReviewerGroupStatus } from "../../state/campaignDeliverableContentModalV2Slice/types"
import { Scope } from "../../util/types"

interface DeliverableMediaCardProps {
  deliverableMedia: DeliverableMediaItem
}

// TODO: Implement approval and control in future ticket
function DeliverableMediaCard({ deliverableMedia }: DeliverableMediaCardProps): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    selectedMediaForFeedback,
    deliverablePermissions,
    deliverable,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const {
    scopes,
  } = useSelector(({ user: userSlice }) => userSlice)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const canApprove = useMemo(() => {
    if (!deliverablePermissions) return false
    return deliverablePermissions.canVoteOnDeliverable || deliverablePermissions.canFinalizeDeliverableFeedback
    || scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  }, [ deliverablePermissions ])

  const approveDisabled = useMemo(() => {
    if (!deliverablePermissions) return true
    if (!deliverable) return true
    if (deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return true
    return deliverableMedia.approved
  }, [ deliverablePermissions, deliverable ])

  const feedbackDisabled = useMemo(() => {
    if (!deliverablePermissions) return true
    if (!deliverable) return true
    if (deliverable.currentGroupStatus === ReviewerGroupStatus.FEEDBACK_COMPLETE) return true
    return (!deliverablePermissions.canUpdateDeliverable && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT))
  }, [ deliverablePermissions, deliverable ])

  const canViewFeedback = useMemo(() => {
    if (!deliverablePermissions) return false
    if (!deliverable) return false
    return (deliverablePermissions.canUpdateDeliverable || scopes.includes(Scope.CAMPAIGN_MANAGEMENT))
  }, [ deliverablePermissions, deliverable ])

  const showFeedbackCount = scopes.includes(Scope.CAMPAIGN_MANAGEMENT)

  return (
    <div className="cp_campaign-deliverable-media-card_component">
      <div className="cp_campaign-deliverable-media-card_component-avatar">
        <Avatar variant="square" src={ deliverableMedia.imageUrl } />
        <a
          className="cp_campaign-deliverable-media-card_component-avatar-download"
          aria-label="download-deliverable-media"
          download={ deliverableMedia.fileName }
          href={ `/api/media/url/${ deliverableMedia?.code || "" }` }
          target="_blank"
          rel="noreferrer"
        >
          <DownloadIcon />
        </a>
        { deliverableMedia.mediaType === "VIDEO" ? <OndemandVideoIcon /> : <ImageIcon /> }
      </div>
      <div className="cp_campaign-deliverable-media-card_component-controls">
        { canApprove && (
          <IconButton
            className={ `btn-approve-media ${ deliverableMedia.approved ? "approved" : "" }` }
            onClick={ () => {
              dispatch(setMediaToApprove({ media: deliverableMedia }))
              dispatch(setEdited(true))
              dispatch(setSelectedMediaForFeedback({ media: undefined }))
            } }
            disabled={ approveDisabled }
          >
            <CheckIcon />
          </IconButton>
        ) }
        { canViewFeedback && (
        <Tooltip title={ translate("Leave Feedback") }>
          <div>
            <Badge badgeContent={ deliverableMedia.feedbackCount } invisible={ !showFeedbackCount }>
              <IconButton
                className={ `btn-feedback ${ selectedMediaForFeedback?.id === deliverableMedia.id ? "active" : "" }` }
                disabled={ feedbackDisabled }
                onClick={ () => {
                  dispatch(setMediaToApprove({ media: undefined }))
                  dispatch(setSelectedMediaForFeedback({ media: deliverableMedia }))
                  dispatch(setCaptionFeedback({ caption: undefined }))
                } }
              >
                <CampaignIcon />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
        ) }
      </div>
    </div>
  )
}

export default DeliverableMediaCard
