import React from "react"
import { Add } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { setModalOpen } from "../../../../state/assignReviewersModalSlice"
import { useDispatch } from "../../../../state/hooks"
import "./style.sass"

export default function AssignReviewers() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewerTab" })
  const dispatch = useDispatch()
  const onButtonClick = () => {
    dispatch(setModalOpen({ modalOpen: true, isAssignReviewerGroup: true }))
  }

  return (
    <div className="cp_component-assign-reviewers-container">
      <div className="header">
        <h4>{ translate("Add Reviewer Group") }</h4>
        <IconButton
          onClick={ onButtonClick }
          className="add-btn"
        >
          <Add />
        </IconButton>
      </div>
    </div>
  )
}
