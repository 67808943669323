import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { IconButton } from "@mui/material"
import {
  Add,
  Circle,
  NotificationsNone,
  Close,
} from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import NetworkIcon from "../NetworkIcon"
import { useDispatch, useSelector } from "../../state/hooks"
import Input from "../Input"
import Modal from "../Modal"
import Pill from "../Pill"
import ListsAutoComplete from "../ModalCampaign/ListsAutocomplete"
import CampaignAutocomplete from "./CampaignAutocomplete"
import Avatar from "../Avatar"
import TeamMembersAutocomplete, { AutocompleteLabels } from "../ListFormModal/TeamMembersAutocomplete"
import { COMM_GROUP_LABELS } from "../../util/constant"
import { Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import {
  fetchAutoCompleteCampaigns,
  setCommGroupModalOpen,
  setSubscribedUserIds,
  submitCommunicationGroup,
  resetCreateCommGroup,
  editCommunicationGroup,
  resetUpdateCommGroup,
  resetEditCommGroupID,
} from "../../state/ModalCommGroupSlice"
import { getCommunicationGroup } from "../../state/communicationsPage"

import "./style.sass"
import LoadingIndicator from "../LoadingIndicator"
import LoadingIndicatorCard from "../LoadingIndicatorCard"

export default function ModalCommGroup(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCommGroup" })
  const { t } = useTranslation("common")
  const { vanity } = useParams()
  const [ name, setName ] = React.useState<string>("")
  const [ labelValue, setLabelValue ] = React.useState("")
  const [ labels, setLabels ] = React.useState<string[]>([ ...COMM_GROUP_LABELS ])
  const [ selectedLists, selectLists ] = React.useState<Array<GraphQL.SuggestionListRowFragment>>([])
  const [ selectedCampaign, selectCampaign ] = React.useState<GraphQL.AutoCompleteCampaignFragment | null>(null)
  const [ selectedTeamMembers, setSelectedTeamMembers ] = React.useState<AutocompleteLabels[]>([])
  const [ isLoading, setIsLoading ] = React.useState(false)

  const dispatch = useDispatch()
  const {
    commGroupModalOpen,
    createCommunicationGroup,
    editCommGroupID,
    updateCommGroup,
  } = useSelector(({ commGroupModal }) => commGroupModal)
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)

  useEffect(() => {
    dispatch(fetchAutoCompleteCampaigns(""))
  }, [])

  const clearForm = () => {
    setName("")
    setLabels([ ...COMM_GROUP_LABELS ])
    selectLists([])
    selectCampaign(null)
    setSelectedTeamMembers([])
  }

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(createCommunicationGroup)) {
      const commId = createCommunicationGroup.payload?.createCommunicationGroup?.id
      toast = {
        type: "success",
        message: translate("Communication Group is successfully created!"),
        additionalInfo: commId ? (
          <Link className="cp-toast-link" to={ `/${ vanity }/communications/group/${ commId }/conversations` }>
            { translate("VIEW COMMUNICATION GROUP") }
          </Link>
        ) : undefined,
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCreateCommGroup())
      dispatch(setCommGroupModalOpen(false))
    } else if (API.isError(createCommunicationGroup)) {
      toast = {
        type: "error",
        message: translate("Failed to create Communication Group"),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCreateCommGroup())
      dispatch(setCommGroupModalOpen(false))
    }
  }, [ createCommunicationGroup ])

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(updateCommGroup)) {
      const commId = updateCommGroup.payload?.updateCommunicationGroup?.id
      if (commId) {
        toast = {
          type: "success",
          message: translate("Communication Group is successfully updated!"),
        }
        dispatch(pushToast(toast))
        clearForm()
        dispatch(resetUpdateCommGroup())
        dispatch(getCommunicationGroup({ id: commId })) // refresh the comm page component
      }
    } else if (API.isError(createCommunicationGroup)) {
      toast = {
        type: "error",
        message: translate("Failed to update Communication Group"),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetUpdateCommGroup())
    }
  }, [ updateCommGroup ])

  // Edit Comm Group
  useEffect(() => {
    if (editCommGroupID) {
      updateFormDataForEdit()
    }
  }, [ editCommGroupID ])

  const updateFormDataForEdit = () => {
    if (API.isSuccess(communicationGroup)) {
      const commGroup = communicationGroup.payload?.communicationGroup
      if (commGroup) {
        setName(commGroup.name)
        // Filter out default labels and set custom labels
        const customLabels = commGroup.labels.filter((labelObj) => !COMM_GROUP_LABELS.includes(labelObj.label))
        if (customLabels.length) {
          const updatedLabels = [ ...labels, ...customLabels.map((labelObj) => labelObj.label) ]
          setLabels(updatedLabels)
        }
        // Set selected lists
        selectLists(commGroup.suggestionLists)
        // Set selected campaign
        if (commGroup.campaign) {
          selectCampaign(commGroup.campaign)
        }
        // Set subscribed team members
        if (commGroup.subscribedUsers.length) {
          const teamMembers = commGroup.subscribedUsers.map((member) => ({
            id: member.id,
            name: member.username,
          }))
          setSelectedTeamMembers(teamMembers)
        }
      }
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleSubscribedUsersUpdate = (teamMembers: AutocompleteLabels[]) => {
    const teamMemberIds = teamMembers.map((member) => member.id)
    dispatch(setSubscribedUserIds(teamMemberIds))
    setSelectedTeamMembers(teamMembers)
  }

  const handleRemoveTeamMember = (tmember: AutocompleteLabels): void => {
    const filteredTeamMembers = selectedTeamMembers
      .filter((member: AutocompleteLabels) => (member.name !== tmember.name))

    const updatedTeamIds = filteredTeamMembers.map((member) => member.id)
    dispatch(setSubscribedUserIds(updatedTeamIds))
    setSelectedTeamMembers(filteredTeamMembers)
  }

  function renderTeamMemberPills(): JSX.Element[] {
    return selectedTeamMembers.map((member) => (
      <Pill
        key={ member.id }
        label={ member.name }
        onDelete={ () => handleRemoveTeamMember(member) }
      />
    ))
  }

  function renderLabelPills(): JSX.Element[] {
    return labels.map((label) => {
      if (COMM_GROUP_LABELS.includes(label)) {
        return (
          <Pill className={ `pill-custom-color-${ label }` } key={ label } label={ label } />
        )
      }
      return (
        <Pill
          key={ label }
          label={ label }
          onDelete={ () => setLabels(labels.filter((l) => l !== label)) }
        />
      )
    })
  }

  function addCustomLabel(label: string): void {
    const currentLabels = [ ...labels ]
    if (label && !currentLabels.includes(label)) {
      setLabels([ ...labels, label ])
    }
  }

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelValue(event.target.value)
  }

  const handleLabelAddClick = () => {
    if (labelValue.trim()) {
      addCustomLabel(labelValue)
      setLabelValue("")
    }
  }

  const handleLabelKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && labelValue.trim()) {
      addCustomLabel(labelValue)
      setLabelValue("")
    }
  }

  const handleCommSubmission = async () => {
    setIsLoading(true)
    await dispatch(submitCommunicationGroup(
      name,
      labels,
      selectedLists.map((list) => list.id),
      selectedCampaign?.id || null,
      selectedTeamMembers.map((member) => member.id),
    ))
    setIsLoading(false)
  }

  const handleEditCommSubmission = async () => {
    if (editCommGroupID) {
      setIsLoading(true)
      await dispatch(editCommunicationGroup(
        editCommGroupID,
        name,
        labels,
        selectedLists.map((list) => list.id),
        selectedCampaign?.id || null,
        selectedTeamMembers.map((member) => member.id),
      ))
      dispatch(resetEditCommGroupID()) // clear previous editCommGroupID
      dispatch(setCommGroupModalOpen(false))
      setIsLoading(false)
    }
  }

  const handleCancelAction = () => {
    if (editCommGroupID) {
      dispatch(resetEditCommGroupID())
    }
    clearForm()
    dispatch(setCommGroupModalOpen(false))
  }

  const removableListIds = useMemo(() => {
    if (API.isSuccess(communicationGroup)) {
      const commGroup = communicationGroup.payload?.communicationGroup
      if (commGroup) {
        return commGroup.suggestionLists.map((list) => list.id)
      }
    }
    return []
  }, [ communicationGroup ])

  const isRemovableList = (id: string): boolean => !removableListIds.includes(id)

  const getPrimaryLabel = () => {
    if (isLoading) return <LoadingIndicator />
    if (editCommGroupID) {
      return translate("SAVE GROUP")
    }
    return translate("Create Group")
  }

  const renderContents = (): JSX.Element => {
    if (editCommGroupID && communicationGroup === "loading") return <LoadingIndicatorCard />
    return (
      <>
        <section className="cp_component_modal-comm-group-form">
          <h5>{ translate("Details") }</h5>
          <div className="cp_component_modal-comm-group-form-name-label">
            <p className="label_small-caps-bold">{ translate("Name") }</p>
            <p className="label_small-caps-bold counter">
              { name.length }
              /255
            </p>
          </div>
          <Input
            value={ name }
            className="cp_component_modal-comm-group-input"
            fullWidth={ true }
            placeholder={ translate("Enter Group Name") }
            InputProps={ {
              inputProps: { maxLength: 255 },
            } }
            onChange={ handleNameChange }
          />
          <h5>{ translate("Labels") }</h5>
          <Input
            label={ translate("Custom Label") }
            value={ labelValue }
            onChange={ handleLabelChange }
            onKeyPress={ handleLabelKeyPress }
            placeholder={ translate("Type Vertical Name") }
            InputProps={ {
              endAdornment: (
                <IconButton onClick={ handleLabelAddClick }>
                  <Add />
                </IconButton>
              ),
            } }
          />
          <p className="body_small input_description">
            { translate("Default labels are listed below") }
          </p>
          <div className="cp_component_modal-comm-group-labels">
            { renderLabelPills() }
          </div>
          <h5>{ translate("Lists") }</h5>
          <p>
            { translate("Attach lists to this communication group") }
          </p>
          <p className="body_small">
            { translate("When you attach a list") }
          </p>
          <div className="cp_component_modal-comm-group-form-autocomplete">
            <ListsAutoComplete
              setSelectedLists={ selectLists }
              selectedLists={ selectedLists }
            />
          </div>
          <div className="cp_component_modal-comm-group-form-autocomplete-list">
            { selectedLists.map((list) => (
              <div key={ list.id }>
                <p className="cp-modal-comm-list-info">
                  <NetworkIcon network={ list.network } isActive={ true } />
                  <span>{ list.name }</span>
                </p>
                { isRemovableList(list.id) && (
                <IconButton
                  size="small"
                  onClick={ () => selectLists(selectedLists.filter((l) => l.id !== list.id)) }
                >
                  <Close />
                </IconButton>
                ) }
              </div>
            ))
              }
          </div>
        </section>
        <section className="cp_component_modal-comm-side-options">
          <article>
            <h5>{ translate("Campaign") }</h5>
            <p>
              { translate("Attach a campaign to this communication group") }
            </p>
            <p className="body_small">
              { translate("When you attach a campaign") }
            </p>
            { (!editCommGroupID || (editCommGroupID && !selectedCampaign)) && (
            <CampaignAutocomplete setSelectedCampaign={ selectCampaign } selectedCampaign={ selectedCampaign } />
            ) }
            { selectedCampaign && (
            <div className="cp_component_modal-comm-group-campaign-info">
              <Avatar size="lg">
                { selectedCampaign?.name[0] }
              </Avatar>
              <p>
                { selectedCampaign?.name }
              </p>
              { (!editCommGroupID) && (
                <IconButton
                  size="small"
                  onClick={ () => selectCampaign(null) }
                >
                  <Close />
                </IconButton>
              ) }
            </div>
            ) }
          </article>
          <article>
            <aside>
              <NotificationsNone />
              <h5>{ translate("Notifications") }</h5>
            </aside>
            <div className="cp_component_modal-comm-group-notifications-info">
              <p>
                { translate("Notify team members when there is activity") }
              </p>
              <ul>
                <li>
                  <Circle />
                  { translate("Replies to conversation") }
                </li>
                <li>
                  <Circle />
                  { translate("Content uploaded") }
                </li>
                <li>
                  <Circle />
                  { translate("Deliverable created") }
                </li>
              </ul>
              <p className="sub-support-text">{ translate("subcribers support text") }</p>
            </div>
            <TeamMembersAutocomplete
              className="cp_component_modal-comm-group-notifications-autocomplete"
              setSelectedTeamMembers={ handleSubscribedUsersUpdate }
              selectedTeamMembers={ selectedTeamMembers }
              notFoundLabel={ t("No results found") }
              fullWidth={ true }
              label={ translate("Subscribe Team Members") }
              placeholder={ translate("Type Team Member Name") }
            />
            <div className="cp_component_modal-comm-group--notifications_pills">
              {
                renderTeamMemberPills()
              }
            </div>
          </article>
        </section>
      </>
    )
  }

  return (
    <Modal
      title={ editCommGroupID
        ? translate("Edit Group")
        : translate("Create Group")
      }
      disabled={ !name || isLoading }
      primaryLabel={ getPrimaryLabel() }
      secondaryLabel={ translate("Cancel") }
      open={ commGroupModalOpen }
      closeAction={ handleCancelAction }
      secondaryAction={ handleCancelAction }
      primaryAction={ editCommGroupID
        ? handleEditCommSubmission
        : handleCommSubmission
      }
      maxWidth="xl"
      className="cp_component_modal-comm-group"
    >
      { renderContents() }
    </Modal>
  )
}
