import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { InputAdornment, Stack } from "@mui/material"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import SocialAvatar from "../SocialAvatar"
import { RootState } from "../../state/store"
import Input from "../Input"
import Pill from "../Pill"
import {
  setEmailFromTeamModalOpen,
  setContactInfoModalOpen,
  setContactInfoModalData,
  ContactModalProps,
  MountContext,
} from "../../state/accountContactInfoModal"
import "./style.sass"
import IconButton from "../IconButton"
import { validEmailFormat } from "../../util/miscHelper"

type EmailSourcedFromTeam = {
  id: string
  address: string
}

export default function ModalEditEmailsByTeam() {
  // Set up translation
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalEditEmailsByTeam" })
  const { t: translateContact } = useTranslation([], { keyPrefix: "component.GroupAccountsContactInfoModal" })
  const dispatch = useDispatch()

  // Extract all variables from the contact info state
  const {
    ContactInfoSocialAvatarInfo: socialAvatarInfo,
    EditEmailFromTeamModalOpen: open,
    ContactInfoModalData: modalData,
  } = useSelector((root: RootState) => root.contactInfoModal)

  // Local state variables
  const [ emails, setEmails ] = useState<EmailSourcedFromTeam[]>(modalData.emailsFromTeam.map((email) => {
    const item: EmailSourcedFromTeam = {
      id: email.id,
      address: email.address,
    }

    return item
  }))
  const [ newEmail, setNewEmail ] = useState("")
  const [ existingEmailsToDelete, setExistingEmailsToDelete ] = useState<EmailSourcedFromTeam[]>([])
  const [ emailError, setEmailError ] = useState<boolean>(false)

  useEffect(() => {
    setEmails(modalData.emailsFromTeam.map((email) => {
      const item: EmailSourcedFromTeam = {
        id: email.id,
        address: email.address,
      }

      return item
    }))
  }, [ open ])

  const clearForm = () => {
    setEmails([])
    setNewEmail("")
  }

  const onClose = () => {
    // Close the modal
    dispatch(setEmailFromTeamModalOpen(false))
    // Display the modal
    dispatch(setContactInfoModalOpen({
      mountContext: MountContext.CommsGroup,
      onUpdateSuccessText: translateContact("Account contact information has been successfully updated!"),
      onUpdateErrorText: translateContact("Failed to update group account contact information!"),
      onUpdateWarningText: translateContact("No update was made to name, first and last names should both be present!"),
    }))
    clearForm()
  }

  const handleUpdate = async () => {
    // Update the edited emails from team in the state value
    const md: ContactModalProps = {
      firstName: modalData.firstName,
      lastName: modalData.lastName,
      websiteUrl: modalData.websiteUrl,
      emails: modalData.emails,
      emailsFromTeam: emails,
      primaryEmail: modalData.primaryEmail,
      emailsToBeSaved: emails.filter((email) => email.id === ""),
      emailsToBeDeleted: existingEmailsToDelete,
    }
    dispatch(setContactInfoModalData(md))

    // Close dialog box
    dispatch(setEmailFromTeamModalOpen(false))
    dispatch(setContactInfoModalOpen({
      mountContext: MountContext.CommsGroup,
      onUpdateSuccessText: translateContact("Account contact information has been successfully updated!"),
      onUpdateErrorText: translateContact("Failed to update group account contact information!"),
      onUpdateWarningText: translateContact("No update was made to name, first and last names should both be present!"),
    }))
  }

  const addNewEmail = () => {
    // Return if the email is already empty
    if (newEmail.trim() === "") return
    if (!validEmailFormat(newEmail.trim())) {
      setEmailError(true)
      return
    }

    // Return if the email already exists
    const found = emails.find((em) => em.address === newEmail.trim())
    if (found) {
      setNewEmail("")
      return
    }

    // Add to main list
    const ne: EmailSourcedFromTeam = {
      id: "",
      address: newEmail.trim(),
    }

    // Update local state
    setEmails([ ...emails, ne ])

    // Clear field
    setNewEmail("")
    setEmailError(false)
  }

  const handleDeleteEmail = (index: number) => {
    // Does this exist in the database
    if (emails[index].id !== "") {
      // Add to list
      const dlist = [ ...existingEmailsToDelete, emails[index] ]
      setExistingEmailsToDelete(dlist)
    }

    // Pop the email from existing email list
    const uemails = emails.slice(0, index).concat(emails.slice(index + 1))
    setEmails(uemails)
  }

  return (
    <Modal
      className=""
      open={ open }
      customTitle={ <SocialAvatar { ...socialAvatarInfo } /> }
      closeAction={ onClose }
      primaryLabel={ translate("DONE") }
      secondaryLabel={ translate("BACK") }
      secondaryAction={ onClose }
      primaryAction={ handleUpdate }
      disabled={ false }
    >
      <div className="cp_component-eebt-content">
        <Stack className="cp_component-eebt-content_stack" alignItems="center" direction="row" gap={ 1 }>
          <MailOutlineIcon />
          <p className="label_small">{ translate("Emails Added By Your Team") }</p>
        </Stack>
        <p className="body_small">{ translate("Add and remove emails for this account below") }</p>

        <Input
          className="cp_component-eebt-content_new-email-input"
          id="new-email-input"
          isValueCaps={ false }
          label={ translate("Add a New Email") }
          placeholder={ translate("Enter New Email") }
          onChange={ (event) => setNewEmail(event.currentTarget.value) }
          onKeyUp={ (e) => (e.key === "Enter" || e.code === "13") ? addNewEmail() : undefined }
          fullWidth={ true }
          value={ newEmail }
          error={ emailError }
          helperText={ (emailError) ? "Invalid format for email" : undefined }
          InputProps={ {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={ addNewEmail }>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          } }
        />
        <div className="cp_component-eebt-content_emails-chips">
          { emails.map((email, idx) => {
            const key = `pill-email-key-${ idx }`
            return (
              <Pill
                id={ key }
                className="email-chip"
                key={ key }
                label={ email.address }
                deleteIcon={ <CloseIcon /> }
                onDelete={ () => handleDeleteEmail(idx) }
              />
            )
          }) }
        </div>
      </div>
    </Modal>
  )
}
