/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useMutation } from "@apollo/client"
import StatCard from "./StatCard"
import { useDispatch, useSelector } from "../../../../state/hooks"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import ClientsCard from "./UserListCards/ClientsCard"
import CampaignManagersCard from "./UserListCards/CampaignManagersCard"
import CampaignLinksCard from "./UserListCards/CampaignLinksCard"
import CampaignCategoriesCard from "./UserListCards/CampaignCategoriesCard"
import Button from "../../../Button"
import { Scope } from "../../../../util/types"
import { openNewDeliverableModal } from "../../../../state/campaignDeliverableModalSlice"
import { fetchCampaign } from "../../../../state/campaignSlice"
import RecentDeliverablesTableV2 from "./RecentDeliverablesTableV2"
import Alert from "../../../Alert"
import { HREF_UPGRADED_CAMPAIGN_GUIDE } from "../../../../util/constant"
import { REVERT_CAMPAIGN_UPGRADE } from "../../../../graphqlV2/queries/campaignQueries"
import { pushToast } from "../../../../state/toastSlice"

export default function CampaignOverviewDetailsV2() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignOverviewDetails" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const { scopes } = useSelector(({ user }) => user)
  const [ showBanner, setShowBanner ] = useState<Boolean>(true)
  const {
    numberOfCampaignNetworkAccounts,
    numberOfAllDeliverables,
    numberOfDeliverablesWithoutContent,
    numberOfFinalizedDeliverables,
    numberOfLiveDeliverables,
    numberOfPendingFeedBackDeliverables,
    numberOfPendingRevisionDeliverables,
  } = useSelector(({ campaignV2 }) => campaignV2.campaignDetails)
  const [ revertToV1 ] = useMutation(REVERT_CAMPAIGN_UPGRADE)

  const dispatch = useDispatch()

  const canViewCampaignCategories = scopes.includes("campaign_management")
  const canViewBanner = showBanner && scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && scopes.includes(Scope.DEVELOPMENT)
  const canViewRevertCampaign = scopes.includes(Scope.ADMIN)
  if (campaign === "init" || campaign === "loading") {
    return <LoadingIndicatorCard size={ 50 } />
  }

  if (campaign.status === "error") {
    return <p>{ translateCommon("An unexpected error occurred!") }</p>
  }
  const closeBanner = () => setShowBanner(false)
  const {
    id: campaignId,
    description,
    campaignNetworkAccounts,
  } = campaign.payload.campaign

  const revertCampaignUpgrade = async () => {
    if (campaignId) {
      try {
        await revertToV1({
          variables: {
            campaignId,
          },
          context: { apiVersion: "v2" },
        })

        dispatch(pushToast({
          message: translate("Campaign Successfully Reverted!"),
          type: "success",
        }))
        dispatch(fetchCampaign({ campaignId }))
      } catch (error) {
        dispatch(pushToast({
          message: translate("Failed to revert campaign!"),
          type: "error",
        }))
      }
    }
  }

  return (
    <div className="cp_component-campaign-overview-details">
      {
  canViewBanner && (
    <Alert
      severity="success"
      message={ translate("Campaign is up to date with the latest version of approvals.") }
      linkPath={ HREF_UPGRADED_CAMPAIGN_GUIDE }
      linkText={ translate("See What’s New") }
      onClose={ closeBanner }
      secondaryLinkText={ canViewRevertCampaign ? translate("Revert to V1") : undefined }
      secondaryLinkAction={ revertCampaignUpgrade }
    />
  )

}
      <div className="stat-container">
        <StatCard stat={ numberOfCampaignNetworkAccounts || 0 } title={ translate("Accounts") } />
        <StatCard stat={ numberOfAllDeliverables || 0 } title={ translate("Deliverables") } />
        <StatCard stat={ numberOfDeliverablesWithoutContent || 0 } title={ translate("Draft") } />
        <StatCard
          stat={ numberOfPendingFeedBackDeliverables || 0 }
          title={ translate("Pending Feedback") }
        />
        <StatCard
          stat={ numberOfPendingRevisionDeliverables || 0 }
          title={ translate("Pending Revision") }
        />
        <StatCard stat={ numberOfFinalizedDeliverables || 0 } title={ translate("Finalized") } />
        <StatCard stat={ numberOfLiveDeliverables || 0 } title={ translate("Live") } />
      </div>
      { description && (
        <div className="description-container">
          <h5 className="description-header">{ translate("Campaign Description") }</h5>
          <p className="description">{ description }</p>
        </div>
      ) }
      <div className="user-cards-container">
        <ClientsCard />
        <CampaignManagersCard />
        <CampaignLinksCard />
        { canViewCampaignCategories && <CampaignCategoriesCard /> }
      </div>
      <div className="recent-deliverables-container">
        <div className="header-container">
          <h5 className="header">{ translate("Recent Deliverables") }</h5>
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
            <Button
              label={ translate("Add Deliverable") }
              onClick={ () => {
                dispatch(
                  openNewDeliverableModal({
                    socialAccounts: campaignNetworkAccounts,
                    createCallback: () => dispatch(fetchCampaign({ campaignId })),
                  }),
                )
              } }
              size="small"
            />
          ) }
        </div>
        <RecentDeliverablesTableV2 campaign={ campaign } />
      </div>
    </div>
  )
}
