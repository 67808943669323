import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Campaign Modal Slice Interface and Initial State
export interface UpdateCampaignDeliverableV2ModalState {
  isEditMode: boolean
  modalOpen: boolean
  deliverableId?: string
  preSelectedSocialAccount?: GraphQL.CampaignNetworkAccountFragment
}

const initialState: UpdateCampaignDeliverableV2ModalState = {
  isEditMode: false,
  modalOpen: false,
  preSelectedSocialAccount: undefined,
}

// Deliverable Modal Slice
export const UpdateCampaignDeliverableV2ModalSlice = createSlice({
  name: "UpdateCampaignDeliverableV2ModalSlice",
  initialState,
  reducers: {
    openNewDeliverableV2Modal: (
      state,
      action: PayloadAction<{
        preSelectedSocialAccount?: GraphQL.CampaignNetworkAccountFragment
      }>,
    ) => ({
      ...state,
      isEditMode: false,
      deliverableId: undefined,
      modalOpen: true,
      preSelectedSocialAccount: action.payload.preSelectedSocialAccount,
    }),
    openEditDeliverableV2Modal: (
      state,
      action: PayloadAction<{
        deliverableId: string,
      }>,
    ) => {
      const newState = {
        ...state,
        deliverableId: action.payload.deliverableId,
        isEditMode: true,
        modalOpen: true,
      }
      return newState
    },
    closeDeliverableV2Modal: (
      state,
    ) => ({
      ...state,
      modalOpen: false,
      isEditMode: false,
      deliverableId: undefined,
    }),
  },
})

export const {
  openEditDeliverableV2Modal,
  openNewDeliverableV2Modal,
  closeDeliverableV2Modal,
} = UpdateCampaignDeliverableV2ModalSlice.actions
export default UpdateCampaignDeliverableV2ModalSlice.reducer

// Profile Slice Thunks
export const createDeliverable = (
  params: GraphQL.CreateDeliverableMutationVariables,
) => async () => {
  const result = await API.createDeliverable(params)
  return result
}

export const updateDeliverable = (
  params: GraphQL.UpdateDeliverableMutationVariables,
) => async () => {
  const result = await API.updateDeliverable(params)
  return result
}
