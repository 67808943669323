import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

import { useSelector } from "../../../state/hooks"
import * as GraphQL from "../../../graphql"
import { isSuccess } from "../../../util/apiClient"
import {
  highlightMetrics,
  populateRibbonBannerMetrics,
  RibbonMetric,
  RibbonMetricGroup,
  RibbonMetricToggle,
} from "../constants"
import {
  HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES,
  LIST_DISPLAY_TOGGLES,
  PUBLIC_DETAILS_DISPLAY_TOGGLES,
} from "../../../util/constant"

interface MetricsRibbonBannerProps {
  // eslint-disable-next-line max-len
  account: GraphQL.SearchSuggestionListByIdQuery["suggestionListById"]["suggestionListCategories"][number]["suggestionListSocialAccounts"][number]
}

export default function MetricsRibbonBanner({
  account,
}: MetricsRibbonBannerProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ConfigurationHighlightsTab" })
  const ribbonRef = React.useRef<HTMLDivElement>(null)

  const [ groups, setGroups ] = React.useState<RibbonMetricGroup[]>([])

  const {
    updateListForm,
    audiences,
  } = useSelector(({ listConfiguration }) => listConfiguration)
  const { iScores, list } = useSelector((state) => state.list)

  const metrics: RibbonMetric[] = React.useMemo((): RibbonMetric[] => {
    if (updateListForm && isSuccess(iScores) && isSuccess(list)) {
      // Extract the audience data
      const audience = audiences.find((a) => a.socialAccountId === account.socialAccount.id)

      // public details toggles
      const publicListDetailMetrics: RibbonMetricToggle[] | undefined = highlightMetrics
        .find((group) => group.groupName === "Public List Account Details")?.metrics
        .filter((m) => (
          m.code === PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE
          || m.code === PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER
          || m.code === PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION
        ))
        .map((m) => (
          {
            code: m.code,
            label: m.name,
          }
        ))

      // Followers
      const followers: RibbonMetricToggle = { code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME, label: "Followers" }

      // Demographic score
      const demographicScoreToggles: RibbonMetricToggle[] | undefined = highlightMetrics
        .find((group) => group.groupName === "Scores & Engagement Rate")?.metrics
        .filter((m) => (
          m.code === LIST_DISPLAY_TOGGLES.ENG_SCORE
          || m.code === LIST_DISPLAY_TOGGLES.ENG_RATE
          || m.code === LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE
          || m.code === LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO
          || m.code === LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE
          || m.code === LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE
          || m.code === LIST_DISPLAY_TOGGLES.ISCORE
        ))
        .map((m) => ({
          code: m.code,
          label: m.name,
        }))

      // Audience
      const audienceToggles: RibbonMetricToggle[] | undefined = highlightMetrics
        .find((group) => group.groupName === "Audience Metrics (up to 5)")?.metrics
        .filter((m) => m.code !== HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME)
        .map((m) => ({
          code: m.code,
          label: "Audience",
        }))

      const ribbonData: RibbonMetric[] = populateRibbonBannerMetrics(
        (Array.isArray(updateListForm.toggles)) ? updateListForm.toggles.map((t) => t.name) : [ updateListForm.toggles.name ],
        [ ...publicListDetailMetrics || [], followers, ...demographicScoreToggles || [], ...audienceToggles || [] ],
        account,
        {
          updateListForm,
          iScores: iScores.payload.getSocialAccountsIScore.socialAccountsIScore,
          audienceDemographics: audience,
        },
      )

      // Return all the metrics
      setGroups([ { ribbonMetrics: ribbonData } ])
      return ribbonData
    }
    return []
  }, [ updateListForm, audiences ])

  React.useLayoutEffect(() => {
    if (ribbonRef.current) {
      // Get the first ribbon
      const ribGroups: RibbonMetricGroup[] = []
      const { width: MAX_WDITH } = ribbonRef.current.getBoundingClientRect()
      const ribbon = ribbonRef.current.children.item(0)
      let ribGroup: RibbonMetricGroup = { ribbonMetrics: [] }
      let groupWidth = 24
      metrics.forEach((m, i) => {
        if (ribbon) {
          const child = ribbon.children.item(i)
          if (child) {
            const { width: childWidth } = child.getBoundingClientRect()
            if ((groupWidth + childWidth) < MAX_WDITH) {
              ribGroup.ribbonMetrics.push(m)
              groupWidth += (ribGroup.ribbonMetrics.length === 0)
                ? childWidth
                : (24 + childWidth) // 24 is the number of pixels between each metric
            } else {
              ribGroups.push(ribGroup)
              ribGroup = { ribbonMetrics: [ m ] }
              groupWidth = 24 + childWidth
            }
          }
        }
      })
      if (ribGroup.ribbonMetrics.length > 0) ribGroups.push(ribGroup)
      setGroups(ribGroups)
    }
  }, [ metrics, ribbonRef.current ])

  return (
    <div className="cp_lcp_highlights-previews_account-container_ribbon-container" ref={ ribbonRef }>
      { groups.map((group) => (
        <div className="cp_lcp_highlights-previews_account-container_ribbon-container_metrics-ribbon-banner">
          { group.ribbonMetrics.map((metric) => (
            <div key={ `${ metric.label.toLowerCase().replaceAll(" ", "_") }` } className="cp_lcp_hpm_rb_metric">
              <p className="cp_lcp_hpm_rb_metric_value">
                { metric.value }
              </p>
              <p className="label_small-caps-bold cp_lcp_hpm_rb_metric_label">
                { translate(metric.label) }
              </p>
            </div>
          )) }
        </div>
      )) }
    </div>
  )
}
