import { gql } from "@apollo/client"
import { REVIEWER_GROUPS_FRAGMENT } from "../fragments/assignReviewersFragments"

export const REMOVE_USER_FROM_GROUP = gql`
    mutation RemoveUserFromGroup($groupId: ID!, $campaignId: ObfID!, $userId: ObfID!){
     updateReviewerGroup(input: 
        { groupId: $groupId campaignId: $campaignId 
            userAssignmentUpdates: [ { remove: { userId: $userId } } ]
        }) 
  { 
   reviewerGroup{
    ...ReviewerGroupsFragment
  } 
  }
}
${ REVIEWER_GROUPS_FRAGMENT }
`
export const CREATE_REVIEWER_GROUP = gql`
      mutation CreateReviewerGroup($input: CreateReviewerGroupInput!){
        createReviewerGroup(input: $input)
      {
         reviewerGroup{
          ...ReviewerGroupsFragment
        }   
        
      }
      
    }
  ${ REVIEWER_GROUPS_FRAGMENT }
`
export const EDIT_USER_PERMISSIONS = gql`
      mutation EditUserPermission($input: UpdateReviewerGroupInput!){
        updateReviewerGroup(input: $input)
      {
         reviewerGroup{
          ...ReviewerGroupsFragment
        }   
        
      }
      
    }
${ REVIEWER_GROUPS_FRAGMENT }
`

export const EDIT_REVIEWER_GROUP = gql`
      mutation EditReviewerGroup($input: UpdateReviewerGroupInput!){
        updateReviewerGroup(input: $input)
      {
         reviewerGroup{
          ...ReviewerGroupsFragment
        }   
        
      }
      
    }
${ REVIEWER_GROUPS_FRAGMENT }
`

export const REMOVE_GROUP = gql`
    mutation DeleteReviewerGroup($input: DeleteReviewerGroupInput!){
     deleteReviewerGroup(input: $input) 
  { 
   groupId
  }
}
`
