import React, {
  useEffect,
  useState,
  useMemo,
  JSX,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"
import TouchAppIcon from "@mui/icons-material/TouchApp"
import PaidIcon from "@mui/icons-material/Paid"
import GroupIcon from "@mui/icons-material/Group"

import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import { Scope } from "../../../../util/types"
import { useDispatch, useSelector } from "../../../../state/hooks"
import {
  fetchCampaignReportMetricDefinitions,
  fetchCampaignReportSettings,
  fetchCampaignReportV2,
} from "../../../../state/campaignSlice"
import CampaignReportingSettings from "./CampaignReportingSettings"
import CampaignNativePostsReportV2 from "./CampaignNativePostsReportV2"

import Button from "../../../Button"
import LoadingIndicator from "../../../LoadingIndicator"
import { MetricData, platformInsightSections } from "./reportingHelper"
import Trends from "./Trends"

import "./campaign-reporting.sass"
import CampaignNativeInstagramStoriesV2 from "./CampaignNativeInstagramStoriesV2"
import CampaignPaidMediaV2 from "./CampaignPaidMediaV2"

interface PlatformProps {
  platformInsights: GraphQL.CampaignReportInsight[]
  reportMetricDefinitions: GraphQL.CampaignReportMetricDefinition[]
  clientBoosted: boolean
}

interface SectionMetricsProps {
  metrics: MetricData[]
}

function SectionMetrics({ metrics }: SectionMetricsProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReportingMetrics" })
  if (!metrics || !metrics.length) return null
  const [ selectedMetrics, setSelectedMetrics ] = useState<string[]>([])

  function isMetricDefinitionSelected(metricTitle: string): boolean {
    return selectedMetrics.includes(metricTitle)
  }

  function toggleMetricDefinition(metricTitle: string) {
    setSelectedMetrics((prevMetrics) => {
      if (prevMetrics.includes(metricTitle)) {
        return prevMetrics.filter((metric) => metric !== metricTitle)
      }
      return [ ...prevMetrics, metricTitle ]
    })
  }

  function getMetricIcon(icon: string) {
    const icons: { [key: string]: JSX.Element } = {
      ThumbUpIcon: <ThumbUpIcon />,
      VisibilityIcon: <VisibilityIcon />,
      TouchAppIcon: <TouchAppIcon />,
      PhoneIphoneIcon: <PhoneIphoneIcon />,
      PaidIcon: <PaidIcon />,
      GroupIcon: <GroupIcon />,
    }

    return icons[icon] || <ThumbUpIcon />
  }

  return (
    <div className="section-metrics">
      { metrics.map((metricData) => (
        <div key={ metricData.title } className="metric-card">
          <div className="metric-card-body">
            { getMetricIcon(metricData.icon) }
            <p className="metric-value">{ metricData.value }</p>
            <p className="metric-title">{ translate(metricData.title) }</p>
            { metricData.definition && isMetricDefinitionSelected(metricData.title) && (
              <p className="metric-definition">{ metricData.definition }</p>
            ) }
          </div>
          { metricData.definition && (
          <Button
            className="metric-see-more"
            onClick={ () => toggleMetricDefinition(metricData.title) }
            type="button"
            variant="text"
            isPrimary={ false }
            isEnabled={ true }
            label={ isMetricDefinitionSelected(metricData.title) ? "See Less" : "See More" }
            size="small"
          />
          ) }
        </div>
      )) }
    </div>
  )
}

export function PlatformInsights({
  platformInsights, reportMetricDefinitions, clientBoosted,
}: PlatformProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReportingMetrics" })
  const sections = useMemo(
    () => platformInsightSections(platformInsights, reportMetricDefinitions, clientBoosted) || [],
    [ platformInsights, reportMetricDefinitions ],
  )

  return (
    <div className="cp_platform_insight-sections">
      { sections.length !== 0 && (
        sections.map((section) => {
          if (!section.metrics.length) return null
          return (
            <div key={ section.title } className="section">
              <h2 className="section-heading">{ translate(section.title) }</h2>
              <SectionMetrics metrics={ section.metrics } />
            </div>
          )
        })
      ) }
    </div>
  )
}

export default function CampaignReportingV2() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const dispatch = useDispatch()
  const { campaignID } = useParams()
  const { campaignReportV2: campaignReport, campaignReportMetricDefinitions } = useSelector(({ campaignPage }) => campaignPage)

  const scopes = useSelector(({ user }) => user.scopes)
  const reportData = (API.isSuccess(campaignReport) && campaignReport.payload)
    ? campaignReport.payload.campaign.report
    : undefined
  const reportMetricDefinitions = (API.isSuccess(campaignReportMetricDefinitions) && campaignReportMetricDefinitions.payload)
    ? campaignReportMetricDefinitions.payload.getCampaignReportMetricDefinitions
    : undefined
  const [ editIsActive, setEditIsActive ] = useState(false)
  useEffect(() => {
    if (campaignID) {
      dispatch(fetchCampaignReportV2(campaignID))
      dispatch(fetchCampaignReportMetricDefinitions())
    }
  }, [])

  useEffect(() => {
    if (API.isSuccess(campaignReport) && campaignID) {
      dispatch(fetchCampaignReportSettings(campaignID))
    }
  }, [ campaignReport ])

  useEffect(() => {
    if (campaignID && editIsActive) {
      dispatch(fetchCampaignReportSettings(campaignID))
    }
  }, [ editIsActive ])

  const handleEditActive = () => {
    if (campaignID) {
      dispatch(fetchCampaignReportV2(campaignID))
    }
    setEditIsActive(!editIsActive)
  }

  if (campaignReport === "init" || campaignReport === "loading") {
    return (
      <div className="cp_campaign_reporting_tab loading">
        <LoadingIndicator size={ 50 } />
      </div>
    )
  }

  if ((API.isSuccess(campaignReport) && reportData === null) || editIsActive) {
    return (
      <CampaignReportingSettings handleEditActive={ handleEditActive } />
    )
  }

  return (
    <div className="cp_campaign_reporting_tab report">
      <div className="cp_campaign_reporting_tab-actions">
        <h4>{ reportData?.name }</h4>
        { scopes.includes(Scope.CAMPAIGN_REPORTING) && (
        <Button
          isPrimary={ true }
          label={ translate("Edit") }
          onClick={ handleEditActive }
          size="small"
        />
        ) }
      </div>
      <div className="cp_card-metrics_sections">
        { (reportData && reportData.platformInsights.length !== 0) && (
        <PlatformInsights
          platformInsights={ reportData.platformInsights }
          reportMetricDefinitions={ reportMetricDefinitions ?? [] }
          clientBoosted={ reportData.clientBoosted }
        />
        ) }
        { (reportData && reportData.deliverables.length !== 0) && (
          <>
            <CampaignNativePostsReportV2 deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <CampaignNativeInstagramStoriesV2 deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <CampaignPaidMediaV2 deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <Trends
              influencerInsights={ reportData.influencerInsights }
              deliverableInsights={ reportData.deliverableInsights }
              objectiveInsights={ reportData.objectiveInsights }
              audienceInsights={ reportData.audienceInsights }
              creativeThemeInsights={ reportData.creativeThemeInsights }
              template={ reportData.template }
              toggles={ reportData.toggleSettings }
            />
          </>
        ) }
      </div>
    </div>
  )
}
