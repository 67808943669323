import React, {
  JSX,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

// TODO: Replace DataGridPro component with our own custom DataGrid component
// and resolve style conflicts thereunto pertaining...
import {
  DataGridPro,
  GridCellParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridTreeNode,
} from "@mui/x-data-grid-pro"
import {
  LinearProgress,
  Link,
  Typography,
} from "@mui/material"
import { WarningRounded } from "@mui/icons-material"

import * as API from "../../util/apiClient"
import * as ContentTable from "./ContentTable"
import * as SearchGQL from "../../graphql/search"
import * as ListAddAccountState from "../../state/listAddAccount"
import * as SearchState from "../../state/searchAISlice"
import { fetchContentSearchResults } from "../../state/searchAISlice"
import { resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import { Props as SocialAvatarProps } from "../SocialAvatar"
import * as Misc from "../../util/miscHelper"
import ContentEmptySplash from "./ContentEmptySplash"
import ModalPostDetails from "../ModalPostDetails"
import ModalScoreBreakDown from "../ModalScoreBreakDown"

import "./style.sass"
import EmptySplash from "../EmptySplash"
import { HREF_AI_SEARCH_GUIDE } from "../../util/constant"
import { Posts } from "../../state/searchAISlice/helper"

type PostDetailsFromDataGridParams = GridCellParams<
  any, unknown, unknown, GridTreeNode
>

type PostDetailsData = {
  engagementRate: string,
  isReel: boolean,
  isStory: boolean,
  media: SearchGQL.PostMedia[],
  permalink: string,
  postComments: number,
  postContentHighlighted: Array<SearchGQL.PostContentToken>
  postContent: string,
  postDate: number,
  postExits: number,
  postImpressions: number,
  postLikes: number,
  postPlays: number,
  postReach: number,
  postReachEstimated: number,
  postSaves: number,
  postShares: number
  postScreenshots: number,
  postHighlighted: boolean,
  postSaved: boolean,
  postSwipeUps: number,
  postTapsBackward: number,
  postTapsForward: number,
  postViews: number,
  socialAvatarProps: SocialAvatarProps,
}

function ErrorMessage(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResultsTable" })
  return (
    <>
      { translate("Your Search query is still processing, but the request timed out.") }
      <br />
      { translate("Please try again, or refine your search for faster results.") }
    </>
  )
}

export default function SearchResultsTable(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResultsTable" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { vanity } = useParams()

  const [ postDetailsModal, setPostDetailsModal ] = useState<
    PostDetailsData | null
  >(null)
  const [ scoreModalOpen, setScoreModalOpen ] = useState(false)

  const {
    searchAIInput,
    searchResultsContent,
    selectedPosts: searchSelectedPosts,
  } = useSelector(({ searchAI }) => searchAI)

  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(SearchState.setSearchResultsContent("init"))
    dispatch(SearchState.setSelectedAccounts([]))
    dispatch(ListAddAccountState.clearSelectedItems())
  }, [])

  useEffect(() => {
    if (scoreModalOpen === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ scoreModalOpen ])

  // const handleScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore?: boolean) => {
  //   dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType, isAdCouncilScore))
  //   setScoreModalOpen(true)
  // }

  const cellClickAction = (
    { field, row }: PostDetailsFromDataGridParams,
  ): void => {
    const socialAccountId = row.account.id
    // NAVIGATE TO ACCOUNT PROFILE
    // If cell is "account" on either table, navigate to profile page
    if (field === "account" && row.account.hasPersonality) {
      navigate(Misc.generateProfileUrl(socialAccountId, vanity || ""))
      return
    }

    // NAVIGATE TO SEARCH SOCIAL ACCOUNT SUBPAGE
    // If cell is "account" on either table, navigate to social account page
    if (field === "account") {
      navigate(Misc.generateSearchSocialAccountUrl(
        socialAccountId,
        vanity || "",
      ))

      return
    }

    const postDetailsModalColumns = [
      "followers",
      "postDate",
      "media",
      "postDetails",
      "engagementRate",
    ]

    if (!postDetailsModalColumns.includes(field)) return

    setPostDetailsModal({
      engagementRate: row.engagementRate,
      isReel: row.postDetails.isReel,
      isStory: row.postDetails.isStory,
      media: row.media.postMedia,
      permalink: row.media.permalink,
      postComments: row.postDetails.postComments,
      postContent: row.postDetails.postContent,
      postContentHighlighted: row.postDetails.postContentHighlighted,
      postDate: row.postDetails.postDate,
      postExits: row.postDetails.postExits,
      postImpressions: row.postDetails.postImpressions,
      postLikes: row.postDetails.postLikes,
      postPlays: row.postDetails.postPlays,
      postReach: row.postDetails.postReach,
      postReachEstimated: row.postDetails.postReachEstimated,
      postSaves: row.postDetails.postSaves,
      postShares: row.postDetails.postShares,
      postTapsBackward: row.postDetails.postTapsBackward,
      postTapsForward: row.postDetails.postTapsForward,
      postViews: row.postDetails.postViews,
      postScreenshots: row.postDetails.postScreenshots,
      postHighlighted: row.postDetails.postHighlighted,
      postSaved: row.postDetails.postSaved,
      postSwipeUps: row.postDetails.postSwipeUps,
      socialAvatarProps: row.account,
    })
  }

  const tableColumns = useMemo(
    () => ContentTable.generateContentResultsColumns(
      translate,
      {
        showAddPostButton: false,
        showViewProfileButton: true,
        showViewMatchingPostsButton: true,
      },
    ),
    [ searchResultsContent ],
  )

  const tableRows = useMemo(() => {
    if (!API.isSuccess(searchResultsContent)) return []

    return searchResultsContent.payload.searchPosts.posts
      .map((r: SearchGQL.SearchPostsTableRowFragment) => ContentTable
        .generateContentResultsRow(r, translateCommon, searchAIInput.contentParams.keywords))
  }, [ searchResultsContent, scopes ])

  const tableClassName = tableRows.length === 0
    ? "cp_component_search-results-table "
    + "cp_component_search-results-table-empty"
    : "cp_component_search-results-table"

  const handleRowSelectability = (params: GridRowParams<any>): boolean => {
    if (searchSelectedPosts.length === 0) return true
    const selectedNetwork = searchSelectedPosts[0].network
    return params.row.account.network === selectedNetwork
  }

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    if (searchResultsContent === "loading" || !API.isSuccess(searchResultsContent)) return

    const sanitizedRowIds: string[] = rowSelectionModel
      .filter((r: GridRowId): r is string => typeof r === "string")

    const newSelection: Posts = searchResultsContent
      .payload
      .searchPosts
      .posts
      .filter(({ id }) => sanitizedRowIds.includes(id))

    dispatch(SearchState.setSelectedPosts(newSelection))
  }

  const table = useMemo(
    () => (
      <div className={ tableClassName }>
        <DataGridPro
          checkboxSelection={ true }
          initialState={ {
            sorting: {
              sortModel: [ { field: "postDate", sort: "desc" } ],
            },
          } }
          columns={ tableColumns }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          isRowSelectable={ handleRowSelectability }
          loading={ searchResultsContent === "loading" }
          onCellClick={ cellClickAction }
          onRowSelectionModelChange={ handleSelectionModelChange }
          onRowsScrollEnd={ () => {
            if (searchAIInput.page === 1) return
            dispatch(fetchContentSearchResults())
          } }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "ellipsisMenu" ],
          } }
          rowHeight={ 80 }
          rows={ tableRows }
          rowSelectionModel={ searchSelectedPosts.map(({ id }) => id) }
          slots={ {
            loadingOverlay: LinearProgress,
            noResultsOverlay: ContentEmptySplash,
            noRowsOverlay: ContentEmptySplash,
          } }
        />
        { postDetailsModal && (
          <ModalPostDetails
            open={ postDetailsModal != null }
            closeAction={ () => setPostDetailsModal(null) }
            { ...postDetailsModal }
            highlightKeywords={ searchAIInput.contentParams.keywords }
          />
        ) }
        <ModalScoreBreakDown isModalScoreOpen={ scoreModalOpen } closeModal={ () => setScoreModalOpen(false) } />
      </div>
    ),
    [ postDetailsModal,
      searchResultsContent,
      searchSelectedPosts,
      scoreModalOpen,
    ],
  )

  if (searchResultsContent === "init") {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <EmptySplash>
              <>
                <h4>
                  { translate("Click") }
                  <em>
                    { " " }
                    { translate("Search") }
                    { " " }
                  </em>
                  { translate("to see results") }
                </h4>
                <Typography>
                  { translate("New to our AI Search?") }
                  <br />
                  { translate("Learn the basics of our Influential AI search or our filtered search") }
                  { " " }
                  <Link
                    href={ HREF_AI_SEARCH_GUIDE }
                    underline="hover"
                    target="_blank"
                  >
                    { translate("here") }
                  </Link>
                </Typography>
              </>
            </EmptySplash>
          </div>
        </aside>
      </div>
    )
  }

  if (searchResultsContent === "loading") {
    return table
  }

  if (API.isError(searchResultsContent)) {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <WarningRounded />
            <h3>
              { searchResultsContent.message === "TIMEOUT" ? <ErrorMessage />
                : translate("Something went wrong...")
              }
            </h3>
          </div>
        </aside>
      </div>
    )
  }

  return table
}
