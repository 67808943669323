/* eslint-disable max-len */
import React from "react"

import { useTranslation } from "react-i18next"

import { AUDIENCE_DISPLAY_TOGGLES } from "../../../util/constant"
import EmptySplash from "../../EmptySplash"
import { useSelector } from "../../../state/hooks"
import {
  audienceDisplayCodes,
  AudienceGroup,
  getDisplayName,
  getGroupName,
  isMemberOfGroup,
} from "../constants"
import { SuggestionListToggleGroupType, SuggestionListToggleInput } from "../../../graphql"

// Importing images
import { ReactComponent as FollowersOverTimeIcon } from "../../../asset/visualizations/LIST_FOLLOWERS_OVER_TIME_EX.svg"
import { ReactComponent as AudienceAuthenticityIcon } from "../../../asset/visualizations/LIST_AUDIENCE_AUTHENTICITY.svg"
import { ReactComponent as GenderIcon } from "../../../asset/visualizations/LIST_GENDER.svg"
import { ReactComponent as FamilyStatusIcon } from "../../../asset/visualizations/LIST_FAMILY.svg"
// Group Basic images
import { ReactComponent as AgeIncomeEducationRangeIcon } from "../../../asset/visualizations/LIST_BASIC_EX.svg"
import { ReactComponent as AgeEducationIncomeRangeIcon } from "../../../asset/visualizations/BasicGroup/Age-education-income.svg"
import { ReactComponent as EducationAgeIncomeRangeIcon } from "../../../asset/visualizations/BasicGroup/Education-age-income.svg"
import { ReactComponent as EducationIncomeAgeRangeIcon } from "../../../asset/visualizations/BasicGroup/Education-income-age.svg"
import { ReactComponent as IncomeAgeEducationRangeIcon } from "../../../asset/visualizations/BasicGroup/Income-age-education.svg"
import { ReactComponent as IncomeEducationAgeRangeIcon } from "../../../asset/visualizations/BasicGroup/Income-education-age.svg"
import { ReactComponent as AgeEducationRangeIcon } from "../../../asset/visualizations/BasicGroup/Age-education.svg"
import { ReactComponent as AgeIncomeRangeIcon } from "../../../asset/visualizations/BasicGroup/Age-income.svg"
import { ReactComponent as EducationAgeRangeIcon } from "../../../asset/visualizations/BasicGroup/Education-age.svg"
import { ReactComponent as EducationIncomeRangeIcon } from "../../../asset/visualizations/BasicGroup/Education-income.svg"
import { ReactComponent as IncomeAgeRangeIcon } from "../../../asset/visualizations/BasicGroup/Income-age.svg"
import { ReactComponent as IncomeEducationRangeIcon } from "../../../asset/visualizations/BasicGroup/Income-education.svg"
import { ReactComponent as AgeRangeIcon } from "../../../asset/visualizations/BasicGroup/Age.svg"
import { ReactComponent as EducationLevelIcon } from "../../../asset/visualizations/BasicGroup/Education.svg"
import { ReactComponent as IncomeRangeIcon } from "../../../asset/visualizations/BasicGroup/Income.svg"
// Group Location images
import { ReactComponent as CityCountryStateIcon } from "../../../asset/visualizations/LocationGroup/city-country-state.svg"
import { ReactComponent as CityStateCountryIcon } from "../../../asset/visualizations/LocationGroup/city-state-country.svg"
import { ReactComponent as CountryCityStateIcon } from "../../../asset/visualizations/LocationGroup/country-city-state.svg"
import { ReactComponent as CountryStateCityIcon } from "../../../asset/visualizations/LocationGroup/country-state-city.svg"
import { ReactComponent as StateCityCountryIcon } from "../../../asset/visualizations/LocationGroup/state-city-country.svg"
import { ReactComponent as StateCountryCityIcon } from "../../../asset/visualizations/LocationGroup/state-country-city.svg"
import { ReactComponent as StateCountryIcon } from "../../../asset/visualizations/LocationGroup/state-country.svg"
import { ReactComponent as StateCityIcon } from "../../../asset/visualizations/LocationGroup/state-city.svg"
import { ReactComponent as CountryCityIcon } from "../../../asset/visualizations/LocationGroup/country-city.svg"
import { ReactComponent as CountryStateIcon } from "../../../asset/visualizations/LocationGroup/country-state.svg"
import { ReactComponent as CityCountryIcon } from "../../../asset/visualizations/LocationGroup/city-country.svg"
import { ReactComponent as CityStateIcon } from "../../../asset/visualizations/LocationGroup/city-state.svg"
import { ReactComponent as CityIcon } from "../../../asset/visualizations/LocationGroup/city.svg"
import { ReactComponent as StateIcon } from "../../../asset/visualizations/LocationGroup/state.svg"
import { ReactComponent as CountryIcon } from "../../../asset/visualizations/LocationGroup/country.svg"
// Group Vocation images
import { ReactComponent as OccupationIndustryEmployerUniveristyIcon } from "../../../asset/visualizations/VocationGroup/occupation-industry-employer-university.svg"
import { ReactComponent as OccupationIndustryUniveristyEmployerIcon } from "../../../asset/visualizations/VocationGroup/occupation-industry-university-employer.svg"
import { ReactComponent as OccupationEmployerIndustryUniveristyIcon } from "../../../asset/visualizations/VocationGroup/occupation-employer-industry-university.svg"
import { ReactComponent as OccupationEmployerUniveristyIndustryIcon } from "../../../asset/visualizations/VocationGroup/occupation-employer-university-industry.svg"
import { ReactComponent as OccupationUniveristyIndustryEmployerIcon } from "../../../asset/visualizations/VocationGroup/occupation-university-industry-employer.svg"
import { ReactComponent as OccupationUniveristyEmployerIndustryIcon } from "../../../asset/visualizations/VocationGroup/occupation-university-employer-industry.svg"
import { ReactComponent as IndustryOccupationEmployerUniveristyIcon } from "../../../asset/visualizations/VocationGroup/industry-occupation-employer-university.svg"
import { ReactComponent as IndustryOccupationUniveristyEmployerIcon } from "../../../asset/visualizations/VocationGroup/industry-occupation-university-employer.svg"
import { ReactComponent as IndustryEmployerOccupationUniveristyIcon } from "../../../asset/visualizations/VocationGroup/industry-employer-occupation-university.svg"
import { ReactComponent as IndustryEmployerUniveristyOccupationIcon } from "../../../asset/visualizations/VocationGroup/industry-employer-university-occupation.svg"
import { ReactComponent as IndustryUniveristyOccupationEmployerIcon } from "../../../asset/visualizations/VocationGroup/industry-university-occupation-employer.svg"
import { ReactComponent as IndustryUniveristyEmployerOccupationIcon } from "../../../asset/visualizations/VocationGroup/industry-university-employer-occupation.svg"
import { ReactComponent as EmployerOccupationIndustryUniveristyIcon } from "../../../asset/visualizations/VocationGroup/employer-occupation-industry-university.svg"
import { ReactComponent as EmployerOccupationUniveristyIndustryIcon } from "../../../asset/visualizations/VocationGroup/employer-occupation-university-industry.svg"
import { ReactComponent as EmployerIndustryOccupationUniveristyIcon } from "../../../asset/visualizations/VocationGroup/employer-industry-occupation-university.svg"
import { ReactComponent as EmployerIndustryUniveristyOccupationIcon } from "../../../asset/visualizations/VocationGroup/employer-industry-university-occupation.svg"
import { ReactComponent as EmployerUniveristyIndustryOccupationIcon } from "../../../asset/visualizations/VocationGroup/employer-university-industry-occupation.svg"
import { ReactComponent as EmployerUniveristyOccupationIndustryIcon } from "../../../asset/visualizations/VocationGroup/employer-university-occupation-industry.svg"
import { ReactComponent as UniveristyOccupationIndustryEmployerIcon } from "../../../asset/visualizations/VocationGroup/university-occupation-industry-employer.svg"
import { ReactComponent as UniveristyOccupationEmployerIndustryIcon } from "../../../asset/visualizations/VocationGroup/university-occupation-employer-industry.svg"
import { ReactComponent as UniveristyIndustryOccupationEmployerIcon } from "../../../asset/visualizations/VocationGroup/university-industry-occupation-employer.svg"
import { ReactComponent as UniveristyIndustryEmployerOccupationIcon } from "../../../asset/visualizations/VocationGroup/university-industry-employer-occupation.svg"
import { ReactComponent as UniveristyEmployerIndustryOccupationIcon } from "../../../asset/visualizations/VocationGroup/university-employer-industry-occupation.svg"
import { ReactComponent as UniveristyEmployerOccupationIndustryIcon } from "../../../asset/visualizations/VocationGroup/university-employer-occupation-industry.svg"
import { ReactComponent as OccupationIndustryEmployerIcon } from "../../../asset/visualizations/VocationGroup/occupation-industry-employer.svg"
import { ReactComponent as OccupationIndustryUniveristyIcon } from "../../../asset/visualizations/VocationGroup/occupation-industry-university.svg"
import { ReactComponent as OccupationEmployerIndustryIcon } from "../../../asset/visualizations/VocationGroup/occupation-employer-industry.svg"
import { ReactComponent as OccupationEmployerUniveristyIcon } from "../../../asset/visualizations/VocationGroup/occupation-employer-university.svg"
import { ReactComponent as OccupationUniveristyIndustryIcon } from "../../../asset/visualizations/VocationGroup/occupation-university-industry.svg"
import { ReactComponent as OccupationUniveristyEmployerIcon } from "../../../asset/visualizations/VocationGroup/occupation-university-employer.svg"
import { ReactComponent as IndustryOccupationEmployerIcon } from "../../../asset/visualizations/VocationGroup/industry-occupation-employer.svg"
import { ReactComponent as IndustryOccupationUniveristyIcon } from "../../../asset/visualizations/VocationGroup/industry-occupation-university.svg"
import { ReactComponent as IndustryEmployerOccupationIcon } from "../../../asset/visualizations/VocationGroup/industry-employer-occupation.svg"
import { ReactComponent as IndustryEmployerUniveristyIcon } from "../../../asset/visualizations/VocationGroup/industry-employer-university.svg"
import { ReactComponent as IndustryUniveristyOccupationIcon } from "../../../asset/visualizations/VocationGroup/industry-university-occupation.svg"
import { ReactComponent as IndustryUniveristyEmployerIcon } from "../../../asset/visualizations/VocationGroup/industry-university-employer.svg"
import { ReactComponent as EmployerOccupationIndustryIcon } from "../../../asset/visualizations/VocationGroup/employer-occupation-industry.svg"
import { ReactComponent as EmployerOccupationUniveristyIcon } from "../../../asset/visualizations/VocationGroup/employer-occupation-university.svg"
import { ReactComponent as EmployerIndustryOccupationIcon } from "../../../asset/visualizations/VocationGroup/employer-industry-occupation.svg"
import { ReactComponent as EmployerIndustryUniveristyIcon } from "../../../asset/visualizations/VocationGroup/employer-industry-university.svg"
import { ReactComponent as EmployerUniveristyIndustryIcon } from "../../../asset/visualizations/VocationGroup/employer-university-industry.svg"
import { ReactComponent as EmployerUniveristyOccupationIcon } from "../../../asset/visualizations/VocationGroup/employer-university-occupation.svg"
import { ReactComponent as UniveristyOccupationIndustryIcon } from "../../../asset/visualizations/VocationGroup/university-occupation-industry.svg"
import { ReactComponent as UniveristyOccupationEmployerIcon } from "../../../asset/visualizations/VocationGroup/university-occupation-employer.svg"
import { ReactComponent as UniveristyIndustryOccupationIcon } from "../../../asset/visualizations/VocationGroup/university-industry-occupation.svg"
import { ReactComponent as UniveristyIndustryEmployerIcon } from "../../../asset/visualizations/VocationGroup/university-industry-employer.svg"
import { ReactComponent as UniveristyEmployerIndustryIcon } from "../../../asset/visualizations/VocationGroup/university-employer-industry.svg"
import { ReactComponent as UniveristyEmployerOccupationIcon } from "../../../asset/visualizations/VocationGroup/university-employer-occupation.svg"
import { ReactComponent as OccupationIndustryIcon } from "../../../asset/visualizations/VocationGroup/occupation-industry.svg"
import { ReactComponent as OccupationEmployerIcon } from "../../../asset/visualizations/VocationGroup/occupation-employer.svg"
import { ReactComponent as OccupationUniveristyIcon } from "../../../asset/visualizations/VocationGroup/occupation-university.svg"
import { ReactComponent as IndustryOccupationIcon } from "../../../asset/visualizations/VocationGroup/industry-occupation.svg"
import { ReactComponent as IndustryEmployerIcon } from "../../../asset/visualizations/VocationGroup/industry-employer.svg"
import { ReactComponent as IndustryUniveristyIcon } from "../../../asset/visualizations/VocationGroup/industry-university.svg"
import { ReactComponent as EmployerOccupationIcon } from "../../../asset/visualizations/VocationGroup/employer-occupation.svg"
import { ReactComponent as EmployerIndustryIcon } from "../../../asset/visualizations/VocationGroup/employer-industry.svg"
import { ReactComponent as EmployerUniveristyIcon } from "../../../asset/visualizations/VocationGroup/employer-university.svg"
import { ReactComponent as UniveristyOccupationIcon } from "../../../asset/visualizations/VocationGroup/university-occupation.svg"
import { ReactComponent as UniveristyIndustryIcon } from "../../../asset/visualizations/VocationGroup/university-industry.svg"
import { ReactComponent as UniveristyEmployerIcon } from "../../../asset/visualizations/VocationGroup/university-employer.svg"
import { ReactComponent as OccupationIcon } from "../../../asset/visualizations/VocationGroup/occupation.svg"
import { ReactComponent as IndustryIcon } from "../../../asset/visualizations/VocationGroup/industry.svg"
import { ReactComponent as EmployerIcon } from "../../../asset/visualizations/VocationGroup/employer.svg"
import { ReactComponent as UniveristyIcon } from "../../../asset/visualizations/VocationGroup/university.svg"

// eslint-disable-next-line max-len
import { ReactComponent as EthinicitiesOnlyIcon } from "../../../asset/visualizations/LIST_ETHNICITY.svg"
import { ReactComponent as LanguagesOnlyIcon } from "../../../asset/visualizations/LIST_LANGUAGES.svg"
import { ReactComponent as ReligionsOnlyIcon } from "../../../asset/visualizations/LIST_RELIGIONS.svg"
import { ReactComponent as AffinitiesIcon } from "../../../asset/visualizations/LIST_AFFINITIES_EX.svg"

export default function AudienceVisualizations() {
  // Local variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })

  // Global states
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)

  // React hook to build structure to handle groups
  const selectVisualizations = React.useMemo((): (SuggestionListToggleInput | AudienceGroup)[] => {
    if (updateListForm) {
      // Pull out the toggles
      const toggles = (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.filter((t) => t.type === SuggestionListToggleGroupType.AudienceToggles)
          .sort((t1, t2) => t1.order - t2.order)
        : (updateListForm.toggles.type === SuggestionListToggleGroupType.AudienceToggles) ? [ updateListForm.toggles ] : []

      // Loop through the selected toggles and build results
      const visualizations: (SuggestionListToggleInput | AudienceGroup)[] = []
      if (toggles.length > 0) {
        // Build array of switches
        toggles.forEach((toggle, index) => {
          if (isMemberOfGroup(toggle)) {
            // Get the name of the group
            const groupName = getGroupName(toggle)

            // Check to see if the group already exists
            const group = visualizations.find((g) => g.name === groupName) as AudienceGroup | undefined
            if (group) {
              // Add toggle to group
              group.toggles.push(toggle)
            } else {
              // Create new group
              visualizations.push({
                name: groupName,
                order: index,
                toggles: [ toggle ],
              })
            }
          } else {
            // Add the toggle to the list
            visualizations.push(toggle)
          }
        })
      }

      // Return the visualizations
      return visualizations
    }
    return []
  }, [ updateListForm ])

  const getBasicVisualization = (toggles: SuggestionListToggleInput[]): React.JSX.Element | null => {
    // Get visualization name
    const vName: string = toggles.map((t) => getDisplayName(t.name, audienceDisplayCodes)
      .toLowerCase().replaceAll(" ", "_")).join("-")

    // Determine which visualization to show
    switch (vName) {
      case "age_range-income_range-education_level":
        return <AgeIncomeEducationRangeIcon key={ vName } />
      case "age_range-education_level-income_range":
        return <AgeEducationIncomeRangeIcon key={ vName } />
      case "income_range-age_range-education_level":
        return <IncomeAgeEducationRangeIcon key={ vName } />
      case "income_range-education_level-age_range":
        return <IncomeEducationAgeRangeIcon key={ vName } />
      case "education_level-age_range-income_range":
        return <EducationAgeIncomeRangeIcon key={ vName } />
      case "education_level-income_range-age_range":
        return <EducationIncomeAgeRangeIcon key={ vName } />
      case "age_range-income_range":
        return <AgeIncomeRangeIcon key={ vName } />
      case "age_range-education_level":
        return <AgeEducationRangeIcon key={ vName } />
      case "income_range-age_range":
        return <IncomeAgeRangeIcon key={ vName } />
      case "income_range-education_level":
        return <IncomeEducationRangeIcon key={ vName } />
      case "education_level-age_range":
        return <EducationAgeRangeIcon key={ vName } />
      case "education_level-income_range":
        return <EducationIncomeRangeIcon key={ vName } />
      case "age_range":
        return <AgeRangeIcon key={ vName } />
      case "education_level":
        return <EducationLevelIcon key={ vName } />
      case "income_range":
        return <IncomeRangeIcon key={ vName } />
      default:
        return null
    }
  }

  const getLocationVisualization = (toggles: SuggestionListToggleInput[]): React.JSX.Element | null => {
    // Get visualization name
    const vName: string = toggles.map((t) => getDisplayName(t.name, audienceDisplayCodes)
      .toLowerCase().replaceAll(" ", "_")).join("-")

    switch (vName) {
      case "countries-states-cities":
        return <CountryStateCityIcon />
      case "countries-cities-states":
        return <CountryCityStateIcon />
      case "states-countries-cities":
        return <StateCountryCityIcon />
      case "states-cities-countries":
        return <StateCityCountryIcon />
      case "cities-countries-states":
        return <CityCountryStateIcon />
      case "cities-states-countries":
        return <CityStateCountryIcon />
      case "countries-states":
        return <CountryStateIcon />
      case "countries-cities":
        return <CountryCityIcon />
      case "states-countries":
        return <StateCountryIcon />
      case "states-cities":
        return <StateCityIcon />
      case "cities-countries":
        return <CityCountryIcon />
      case "cities-states":
        return <CityStateIcon />
      case "countries":
        return <CountryIcon />
      case "states":
        return <StateIcon />
      case "cities":
        return <CityIcon />
      default:
        return null
    }
  }

  const getVocationVisualization = (toggles: SuggestionListToggleInput[]): React.JSX.Element | null => {
    // Get visualization name
    const vName: string = toggles.map((t) => getDisplayName(t.name, audienceDisplayCodes)
      .toLowerCase().replaceAll(" ", "_")).join("-")

    switch (vName) {
      case "industries-employers-universities-occupations":
        return <IndustryEmployerUniveristyOccupationIcon />
      case "industries-employers-occupations-universities":
        return <IndustryEmployerOccupationUniveristyIcon />
      case "industries-universities-employers-occupations":
        return <IndustryUniveristyEmployerOccupationIcon />
      case "industries-universities-occupations-employers":
        return <IndustryUniveristyOccupationEmployerIcon />
      case "industries-occupations-employers-universities":
        return <IndustryOccupationEmployerUniveristyIcon />
      case "industries-occupations-universities-employers":
        return <IndustryOccupationUniveristyEmployerIcon />
      case "employers-industries-universities-occupations":
        return <EmployerIndustryUniveristyOccupationIcon />
      case "employers-industries-occupations-universities":
        return <EmployerIndustryOccupationUniveristyIcon />
      case "employers-universities-industries-occupations":
        return <EmployerUniveristyIndustryOccupationIcon />
      case "employers-universities-occupations-industries":
        return <EmployerUniveristyOccupationIndustryIcon />
      case "employers-occupations-industries-universities":
        return <EmployerOccupationIndustryUniveristyIcon />
      case "employers-occupations-universities-industries":
        return <EmployerOccupationUniveristyIndustryIcon />
      case "universities-industries-employers-occupations":
        return <UniveristyIndustryEmployerOccupationIcon />
      case "universities-industries-occupations-employers":
        return <UniveristyIndustryOccupationEmployerIcon />
      case "universities-employers-industries-occupations":
        return <UniveristyEmployerIndustryOccupationIcon />
      case "universities-employers-occupations-industries":
        return <UniveristyEmployerOccupationIndustryIcon />
      case "universities-occupations-industries-employers":
        return <UniveristyOccupationIndustryEmployerIcon />
      case "universities-occupations-employers-industries":
        return <UniveristyOccupationEmployerIndustryIcon />
      case "occupations-industries-employers-universities":
        return <OccupationIndustryEmployerUniveristyIcon />
      case "occupations-industries-universities-employers":
        return <OccupationIndustryUniveristyEmployerIcon />
      case "occupations-employers-industries-universities":
        return <OccupationEmployerIndustryUniveristyIcon />
      case "occupations-employers-universities-industries":
        return <OccupationEmployerUniveristyIndustryIcon />
      case "occupations-universities-industries-employers":
        return <OccupationUniveristyIndustryEmployerIcon />
      case "occupations-universities-employers-industries":
        return <OccupationUniveristyEmployerIndustryIcon />
      case "industries-employers-universities":
        return <IndustryEmployerUniveristyIcon />
      case "industires-employers-occupations":
        return <IndustryEmployerOccupationIcon />
      case "industries-universities-employers":
        return <IndustryUniveristyEmployerIcon />
      case "industries-universities-occupations":
        return <IndustryUniveristyOccupationIcon />
      case "industries-occupations-employers":
        return <IndustryOccupationEmployerIcon />
      case "industries-occupations-universities":
        return <IndustryOccupationUniveristyIcon />
      case "employers-industries-universities":
        return <EmployerIndustryUniveristyIcon />
      case "employers-industries-occupations":
        return <EmployerIndustryOccupationIcon />
      case "employers-universities-industries":
        return <EmployerUniveristyIndustryIcon />
      case "employers-universities-occupations":
        return <EmployerUniveristyOccupationIcon />
      case "employers-occupations-industries":
        return <EmployerOccupationIndustryIcon />
      case "employers-occupations-universities":
        return <EmployerOccupationUniveristyIcon />
      case "universities-industries-employers":
        return <UniveristyIndustryEmployerIcon />
      case "universities-industries-occupations":
        return <UniveristyIndustryOccupationIcon />
      case "universities-employers-industries":
        return <UniveristyEmployerIndustryIcon />
      case "universities-employers-occupations":
        return <UniveristyEmployerOccupationIcon />
      case "universities-occupations-employers":
        return <UniveristyOccupationEmployerIcon />
      case "universities-occupations-industries":
        return <UniveristyOccupationIndustryIcon />
      case "occupations-industries-employers":
        return <OccupationIndustryEmployerIcon />
      case "occupations-industries-universities":
        return <OccupationIndustryUniveristyIcon />
      case "occupations-employers-industries":
        return <OccupationEmployerIndustryIcon />
      case "occupations-employers-universities":
        return <OccupationEmployerUniveristyIcon />
      case "occupations-universities-industries":
        return <OccupationUniveristyIndustryIcon />
      case "occupations-universities-employers":
        return <OccupationUniveristyEmployerIcon />
      case "industries-employers":
        return <IndustryEmployerIcon />
      case "industries-universities":
        return <IndustryUniveristyIcon />
      case "industries-occupations":
        return <IndustryOccupationIcon />
      case "employers-industries":
        return <EmployerIndustryIcon />
      case "employers-universities":
        return <EmployerUniveristyIcon />
      case "employers-occupations":
        return <EmployerOccupationIcon />
      case "universities-industries":
        return <UniveristyIndustryIcon />
      case "universities-employers":
        return <UniveristyEmployerIcon />
      case "universities-occupations":
        return <UniveristyOccupationIcon />
      case "occupations-industries":
        return <OccupationIndustryIcon />
      case "occupations-employers":
        return <OccupationEmployerIcon />
      case "occupation-universities":
        return <OccupationUniveristyIcon />
      case "industries":
        return <IndustryIcon />
      case "employers":
        return <EmployerIcon />
      case "universities":
        return <UniveristyIcon />
      case "occupations":
        return <OccupationIcon />
      default:
        return null
    }
  }

  /**
   * getToggleVisualizations: This will fetch the visualization of a toggle or group
   * @param toggle The toggle/group to fetch representing a visualization
   * @returns The visualization to be rendered
   */
  const getToggleVisualizations = (toggle: SuggestionListToggleInput | AudienceGroup): React.JSX.Element | null => {
    // Determine process
    switch (toggle.name) {
      case AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME:
        return <FollowersOverTimeIcon />
      case AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY:
        return <AudienceAuthenticityIcon />
      case AUDIENCE_DISPLAY_TOGGLES.GENDER:
        return <GenderIcon />
      case AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS:
        return <FamilyStatusIcon />
      case AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES:
        return <EthinicitiesOnlyIcon />
      case AUDIENCE_DISPLAY_TOGGLES.LANGUAGES:
        return <LanguagesOnlyIcon />
      case AUDIENCE_DISPLAY_TOGGLES.RELIGIONS:
        return <ReligionsOnlyIcon />
      case AUDIENCE_DISPLAY_TOGGLES.AFFINITIES:
        return <AffinitiesIcon />
      case "Basic":
        return getBasicVisualization((toggle as AudienceGroup).toggles)
      case "Location":
        return getLocationVisualization((toggle as AudienceGroup).toggles)
      case "Vocation":
        return getVocationVisualization((toggle as AudienceGroup).toggles)
      default:
        return null
    }
  }

  // Check to see that we do have some visualizations to show
  if (selectVisualizations.length === 0) {
    return (
      <div className="cp_lcp-preview_example-visualizations audience">
        <EmptySplash headlineText={ translate("You have no filters selected!") } />
      </div>
    )
  }

  // Return the visualizations
  return (
    <div className="cp_lcp-preview_example-visualizations">
      { selectVisualizations.map((toggle) => getToggleVisualizations(toggle)) }
    </div>
  )
}
