import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import { Status, Toast } from "../../util/types"
import * as API from "../../util/apiClient"
import { RootState } from "../store"
import { pushToast } from "../toastSlice"

type SocialAvatarProps = {
  id: string
  followers: number,
  fullName: string,
  isBlacklisted: boolean,
  isNSFW: boolean,
  profilePictureUrl: string,
  username: string,
  isPlaceholder: boolean,
  isUnsubscribed: boolean,
  network: GraphQL.Network,
}

// eslint-disable-next-line no-shadow
export enum MountContext {
  CommsGroup = "comms_group",
  Search2 = "search2",
}

export type ContactModalNetworkEmail = {
  __typename?: "NetworkEmail"
  address: string
}

export type ContactModalEmail = {
  __typename?: "Email"
  id: string
  address: string,
}

export type ContactModalProps = {
  firstName: string
  lastName: string
  websiteUrl: string
  emails: ContactModalNetworkEmail[]
  emailsFromTeam: ContactModalEmail[]
  primaryEmail: string
  emailsToBeSaved?: ContactModalEmail[]
  emailsToBeDeleted?: ContactModalEmail[]
  profile?: GraphQL.GetProfileQuery
}

export interface ContactInfoModalState {
  ContactInfoSocialAvatarInfo: SocialAvatarProps
  SocialAccountId: string
  ContactInfoModalOpen: boolean
  ContactInfo: Status<GraphQL.GetAccountContactInfoQuery>
  ContactInfoUpdated: Status<GraphQL.EditAccountContactInformationMutation | null>
  PrimaryEmailUpdated: Status<GraphQL.EditAccountPrimaryEmailAddressMutation | null>
  EditEmailFromTeamModalOpen: boolean
  ContactInfoModalData: ContactModalProps
  onUpdateSuccessText: string
  onUpdateWarningText: string
  onUpdateErrorText: string
  mountContext: MountContext
}

const initialState: ContactInfoModalState = {
  mountContext: MountContext.CommsGroup,
  ContactInfoSocialAvatarInfo: {
    followers: 0,
    fullName: "Full Name",
    profilePictureUrl: "",
    username: "Username",
    id: "",
    isBlacklisted: false,
    isNSFW: false,
    isPlaceholder: false,
    isUnsubscribed: false,
    network: GraphQL.Network.Facebook,
  },
  onUpdateSuccessText: "",
  onUpdateWarningText: "",
  onUpdateErrorText: "",
  SocialAccountId: "",
  ContactInfoModalOpen: false,
  ContactInfo: "init",
  ContactInfoUpdated: "init",
  PrimaryEmailUpdated: "init",
  EditEmailFromTeamModalOpen: false,
  ContactInfoModalData: {
    firstName: "",
    lastName: "",
    websiteUrl: "",
    emails: [],
    emailsFromTeam: [],
    primaryEmail: "",
    emailsToBeSaved: [],
    emailsToBeDeleted: [],
  },
}

export const ContactInfoModalSlice = createSlice({
  name: "ContactInfoModal",
  initialState,
  reducers: {
    setSocialAvatarInfo: (
      state,
      action: PayloadAction<SocialAvatarProps>,
    ) => ({
      ...state,
      ContactInfoSocialAvatarInfo: action.payload,
    }),
    setSocialAccountId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      SocialAccountId: action.payload,
    }),
    setContactInfoModalOpen: (
      state,
      action: PayloadAction<{
        mountContext: MountContext
        onUpdateSuccessText: string
        onUpdateErrorText: string
        onUpdateWarningText: string
      }>,
    ) => ({
      ...state,
      ContactInfoModalOpen: true,
      mountContext: action.payload.mountContext,
      onUpdateSuccessText: action.payload.onUpdateSuccessText,
      onUpdateErrorText: action.payload.onUpdateErrorText,
      onUpdateWarningText: action.payload.onUpdateWarningText,
    }),
    setContactInfoModalClosed: (
      state,
    ) => ({
      ...state,
      ContactInfoModalOpen: false,
      onUpdateSuccessText: "",
      onUpdateWarningText: "",
      onUpdateErrorText: "",
    }),
    setEmailFromTeamModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      EditEmailFromTeamModalOpen: action.payload,
    }),
    setContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.GetAccountContactInfoQuery>>,
    ) => ({
      ...state,
      ContactInfo: action.payload,
    }),
    setUpdatedContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.EditAccountContactInformationMutation | null>>,
    ) => ({
      ...state,
      ContactInfoUpdated: action.payload,
    }),
    setUpdatedPrimaryEmail: (
      state,
      action: PayloadAction<Status<GraphQL.EditAccountPrimaryEmailAddressMutation | null>>,
    ) => ({
      ...state,
      PrimaryEmailUpdated: action.payload,
    }),
    resetContactInfoModal: () => initialState,
    setContactInfoModalData: (
      state,
      action: PayloadAction<ContactModalProps>,
    ) => ({
      ...state,
      ContactInfoModalData: action.payload,
    }),
  },
})

export const {
  setSocialAvatarInfo,
  setSocialAccountId,
  setContactInfoModalOpen,
  setContactInfoModalClosed,
  setContactInfo,
  setUpdatedContactInfo,
  setUpdatedPrimaryEmail,
  resetContactInfoModal,
  setEmailFromTeamModalOpen,
  setContactInfoModalData,
} = ContactInfoModalSlice.actions

export default ContactInfoModalSlice.reducer

/**
 * Query to get the contact information to fill in the form
 * @param socialAccountId The ID for the social account
 * @returns The required information for the social account contact info edit form
 */
export const getAccountContactInfo = (
  socialAccountId: string,
  emailsToBeSaved: { __typename?: "Email", id: string, address: string }[],
  emailsToBeDeleted: { __typename?: "Email", id: string, address: string }[],
) => async (dispatch: Dispatch) => {
  // Indicate loading status
  dispatch(setContactInfo("loading"))

  // Perform the query
  const results = await API.getAccountContactInfo({ socialAccountId })

  // Check results
  if (API.isSuccess(results)) {
    let profile: GraphQL.GetProfileQuery | undefined
    if (results.payload.socialAccount.personality) {
      const profileResults = await API.query<
        GraphQL.GetProfileQuery,
        GraphQL.GetProfileQueryVariables
      >(GraphQL.GetProfileDocument, { id: results.payload.socialAccount.personality.id })

      if (API.isSuccess(profileResults)) profile = profileResults.payload
    } else profile = undefined

    // Set database value for emails
    let efromt = results.payload.socialAccount.emailsSourcedFromTeam

    // Remove all emails that are being deleted
    if (emailsToBeDeleted && emailsToBeDeleted.length > 0) {
      efromt = efromt.filter((email) => !emailsToBeDeleted.find((demail) => demail.id === email.id))
    }

    // Add all the emails that are not already there
    if (emailsToBeSaved && emailsToBeSaved.length > 0) efromt.push(...emailsToBeSaved)

    // Create the modal data
    const modalData: ContactModalProps = {
      firstName: (results.payload.socialAccount.contact?.firstName) ? results.payload.socialAccount.contact?.firstName : "",
      lastName: (results.payload.socialAccount.contact?.lastName) ? results.payload.socialAccount.contact?.lastName : "",
      websiteUrl: (results.payload.socialAccount.websiteUrl) ? results.payload.socialAccount.websiteUrl : "",
      emails: results.payload.socialAccount.emails,
      emailsFromTeam: efromt,
      primaryEmail: (results.payload.socialAccount.primaryEmail?.address)
        ? results.payload.socialAccount.primaryEmail?.address : "",
      emailsToBeSaved,
      emailsToBeDeleted,
      profile,
    }

    // Save to state
    dispatch(setContactInfoModalData(modalData))
  }

  // Post results
  dispatch(setContactInfo(results))
}

export const updateContactInformationForAccount = (
  params: {
    socialAccountId: string,
    data: ContactModalProps,
    socialAvatar: SocialAvatarProps,
  },
) => async (dispatch: Dispatch, getState: () => RootState) => {
  const { contactInfoModal } = getState()
  // Set the state
  let success = true
  let failed = false
  let warning = false
  let hasName = false
  dispatch(setUpdatedContactInfo("loading"))

  // Determine if updating name
  if (params.data.firstName !== "" && params.data.lastName !== "") {
    // Create data for mutation call
    const ciData: GraphQL.EditAccountContactInformationMutationVariables = {
      socialAccountId: params.socialAccountId,
      firstName: params.data.firstName,
      lastName: params.data.lastName,
    }

    // Make call to GraphQL to update the name
    const results = await API.updateSocialAccountContactInfo(ciData)

    // Set failed and success value
    failed = API.isError(results)
    success = !failed
    hasName = true
  } else if (params.data.firstName !== "" || params.data.lastName !== "") warning = true

  // Save any new email addresses
  if (params.data.emailsToBeSaved && params.data.emailsToBeSaved.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const email of params.data.emailsToBeSaved) {
      // Create required variables for mutation call
      const vars: GraphQL.CreateSocialAccountEmailMutationVariables = {
        networkAccountId: params.socialAccountId,
        emailAddress: email.address,
      }

      // Call the function
      // eslint-disable-next-line no-await-in-loop
      const results = await API.createSocialAccountEmail(vars)

      // Check to see if this failed or was successful
      failed = (failed) ? true : API.isError(results)
      success = !failed
    }
  }

  // Determine if updating primary email address
  if (params.data.primaryEmail !== "") {
    // Create data for mutation call
    const ciPrimaryEmailData: GraphQL.EditAccountPrimaryEmailAddressMutationVariables = {
      socialAccountId: params.socialAccountId,
      email: params.data.primaryEmail,
    }

    // Make call to GraphQL to update email
    const results = await API.updateSocialAccountPrimaryEmail(ciPrimaryEmailData)

    // Set failed and success value
    failed = (failed) ? true : API.isError(results)
    success = !failed
  }

  // Delete any emails to be deleted
  if (params.data.emailsToBeDeleted && params.data.emailsToBeDeleted.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const email of params.data.emailsToBeDeleted) {
      // Create required variables for mutation call
      const vars: GraphQL.DeleteSocialAccountEmailMutationVariables = {
        networkAccountId: params.socialAccountId,
        emailId: email.id,
      }

      // Call the function
      // eslint-disable-next-line no-await-in-loop
      const results = await API.deleteSocialAccountEmail(vars)

      // Check results
      failed = (failed) ? true : API.isError(results)
      success = !failed
    }
  }

  if (params.data.profile) {
    const profile: GraphQL.EditProfileMutationVariables = {
      id: params.data.profile.personality.id,
      name: (hasName) ? `${ params.data.firstName } ${ params.data.lastName }` : params.data.profile.personality.name,
      verticalIds: params.data.profile?.personality.verticals.map((vert) => vert.id),
      socialAccounts: params.data.profile.personality.socialAccounts.map((account) => {
        const pconn: GraphQL.PersonalitySocialAccountInput = {
          foreignUserId: account.id,
          id: account.id,
          network: account.network,
          userName: account.userName,
        }
        return pconn
      }),
      avatarUrl: params.socialAvatar.profilePictureUrl,
      vip: params.data.profile.personality.vip,
      blacklist: params.data.profile.personality.blacklist,
      blacklistReason: params.data.profile.personality.blacklistReason,
      contacts: [ {
        firstName: params.data.firstName || undefined,
        lastName: params.data.lastName || undefined,
        emails: params.data.emailsFromTeam.map((et) => {
          const pcei: GraphQL.PersonalityContactEmailInput = {
            address: et.address,
            primary: (params.data.primaryEmail === et.address),
          }
          return pcei
        }),
      } ],
    }

    // Update the database
    // Update the profile
    const result = await API.mutate<GraphQL.EditProfileMutation, GraphQL.EditProfileMutationVariables>(
      GraphQL.EditProfileDocument,
      profile,
    )

    // Check results
    failed = (failed) ? true : API.isError(result)
    success = !failed
  } else {
    const profile: GraphQL.CreateProfileMutationVariables = {
      name: (hasName) ? `${ params.data.firstName } ${ params.data.lastName }` : params.socialAvatar.username,
      verticalIds: [],
      socialAccounts: [ {
        foreignUserId: params.socialAccountId,
        id: params.socialAccountId,
        network: params.socialAvatar.network,
        userName: params.socialAvatar.username,
      } ],
      avatarUrl: params.socialAvatar.profilePictureUrl,
      vip: false,
      blacklist: false,
      contacts: [ {
        firstName: params.data.firstName || undefined,
        lastName: params.data.lastName || undefined,
        emails: params.data.emailsFromTeam.map((et) => {
          const pcei: GraphQL.PersonalityContactEmailInput = {
            address: et.address,
            primary: (params.data.primaryEmail === et.address),
          }
          return pcei
        }),
      } ],
    }

    // Update the database
    // Update the profile
    const result = await API.mutate<GraphQL.CreateProfileMutation, GraphQL.CreateProfileMutationVariables>(
      GraphQL.CreateProfileDocument,
      profile,
    )

    // Check results
    failed = (failed) ? true : API.isError(result)
    success = !failed
  }

  // Call callbacks
  if (success) {
    const toast: Toast = {
      type: "success",
      message: contactInfoModal.onUpdateSuccessText,
    }
    pushToast(toast)(dispatch)
  }
  if (warning) {
    const toast: Toast = {
      type: "warning",
      message: contactInfoModal.onUpdateWarningText,
    }
    pushToast(toast)(dispatch)
  }

  if (failed) {
    const toast: Toast = {
      type: "error",
      message: contactInfoModal.onUpdateErrorText,
    }
    pushToast(toast)(dispatch)
  }
}

/**
 * This will update the social account contact information in the database
 * @param params The mutation variables required for update along with succes and failure callbacks
 * @returns void
 */
export const updateAccountContactInfo = (
  params: {
    variables: GraphQL.EditAccountContactInformationMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Set the state
  dispatch(setUpdatedContactInfo("loading"))

  // Perform mutation
  const results = await API.updateSocialAccountContactInfo(params.variables)

  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Set the results
  dispatch(setUpdatedContactInfo(results))
}

/**
 * Updates the database primary email address for a social account
 * @param params The primary email address and callbacks for successful or error
 * @returns void
 */
export const updateAccountPrimaryEmail = (
  params: {
    variables: GraphQL.EditAccountPrimaryEmailAddressMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Indicate that the process of update has started
  dispatch(setUpdatedPrimaryEmail("loading"))

  // Call mutation function to update the email
  const results = await API.updateSocialAccountPrimaryEmail(params.variables)

  // Determine if a success update occurred
  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Indicate that the results are complete
  dispatch(setUpdatedPrimaryEmail(results))
}

/**
 * Adds a new social account email address to the database
 * @param params The social account id, email, onSuccess callback and onError callback
 * @returns void
 */
export const updateEmailAddedByTeam = (
  params: {
    socialAccountId: string,
    emails: string[],
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Loop through emails and save them
  let failed = false
  // eslint-disable-next-line no-restricted-syntax
  for (const email of params.emails) {
    // Create required variables for mutation call
    const vars: GraphQL.CreateSocialAccountEmailMutationVariables = {
      networkAccountId: params.socialAccountId,
      emailAddress: email,
    }

    // Call the function
    // eslint-disable-next-line no-await-in-loop
    const results = await API.createSocialAccountEmail(vars)

    // Check to see if this failed or was successful
    failed = API.isError(results)
    if (failed) break
  }

  // Display success or failure
  if (!failed) params.onSuccess()
  else params.onError()
}

/**
 * This will delete 1 or more emails from the database
 * @param params Social Account ID, emails to delete, and success/error callbacks
 * @returns void
 */
export const deleteEmailsAddedByTeam = (
  params: {
    socialAccountId: string,
    emails: GraphQL.Email[],
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Loop though each email to delete
  let failed = false
  // eslint-disable-next-line no-restricted-syntax
  for (const email of params.emails) {
    // Create required variables for mutation call
    const vars: GraphQL.DeleteSocialAccountEmailMutationVariables = {
      networkAccountId: params.socialAccountId,
      emailId: email.id,
    }

    // Call the function
    // eslint-disable-next-line no-await-in-loop
    const results = await API.deleteSocialAccountEmail(vars)

    // Check results
    failed = API.isError(results)
    if (failed) break
  }

  // Check failed indicator and display success or failure message
  if (!failed) params.onSuccess()
  else params.onError()
}
