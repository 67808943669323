import React from "react"
import "./style.sass"

// Libraries
import { useParams } from "react-router-dom"
import { useCookies } from "react-cookie"

// Components
import ReqInfoHeader from "./ReqInfoHeader"
import ReqInfoContainer from "./ReqInfoContainer"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  initializeAccess,
  setAccessCodeCookieKey,
  setCompanyCookieKey,
  setNameCookieKey,
} from "../../state/publicListSlice"
import * as API from "../../util/apiClient"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"

/**
 * RequiredInformation: The root component for the required information
 * page.
 * @returns JSX Element
 */
export default function RequiredInformation() {
  // Local variables
  const dispatch = useDispatch()
  const { listCode } = useParams()

  // Global state
  const {
    userVerification,
    accessCodeCookieKey,
  } = useSelector(({ publicList }) => publicList)

  /**
   * Set up key values for cookies in order to approve or leave
   * a feedback in campaign mode
   */
  React.useEffect(() => {
    if (!listCode) return
    const [ nameKey, companyKey, accessCodeKey ] = [ `${ listCode }-your-name`, `${ listCode }-company`, `${ listCode }-code` ]
    dispatch(setAccessCodeCookieKey(accessCodeKey))
    dispatch(setNameCookieKey(nameKey))
    dispatch(setCompanyCookieKey(companyKey))
  }, [ listCode ])

  // Create cookie state values
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ accessCodeCookieKey ])

  /**
   * Initialize access values to find out the restrictions the current
   * user has for comments or approval
   */
  React.useEffect(() => {
    if (listCode && userVerification === "init") {
      dispatch(initializeAccess(listCode, cookies[`${ listCode }-code`] || ""))
    }
  }, [ listCode ])

  // Display a loading card if the verification has not completed
  if (userVerification === "init" || userVerification === "loading") {
    return <LoadingIndicatorCard />
  }

  // Display an error if the verification failed
  if (API.isError(userVerification)) {
    return <p>There was an error! Please try again.</p>
  }

  // Extract the restrictions and valid to use values
  const {
    suggestionAccessCodeRestriction,
    isValid,
  } = userVerification.payload.publicVerifySuggestionListAccessCodeByListCode

  // If the user is not value show error
  if (!isValid && suggestionAccessCodeRestriction === null) {
    return <p>Redirect to 404</p>
  }

  // Return the required information to be filled out
  return (
    <div className="required-information-page">
      <div className="required-information-page-content">
        <ReqInfoHeader />
        <ReqInfoContainer />
      </div>
    </div>
  )
}
