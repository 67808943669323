import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useQuery } from "@apollo/client"
import ToastController from "../../controller/Toast"

import "./style.sass"

import CampaignTabs from "../../component/CampaignTabs/Tabs"
import CampaignTabsV2 from "../../component/CampaignTabs/Tabs/campaignTabsV2"
import PageShell from "../../component/PageShell"
import { fetchCampaign, setCampaignDeliverablesStartsWithFilter } from "../../state/campaignSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import { isError } from "../../util/apiClient"
import LoadingIndicator from "../../component/LoadingIndicator"
import { GET_IS_MIGRATED_CAMPAIGN } from "../../graphqlV2/queries/campaignQueries"
import { CampaignVersionInput, CampaignVersionOutput } from "../../graphqlV2/types/campaignTypes"
import LoadingIndicatorCard from "../../component/LoadingIndicatorCard"
import ErrorHandler from "../../component/ErrorHandler"
import { setIsCampaignMigrated } from "../../state/campaignV2Slice"
import { Scope } from "../../util/types"

export default function Campaign() {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Campaign" })
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const dispatch = useDispatch()
  const { campaignID } = useParams()
  const { scopes } = useSelector(({ user }) => user)
  const enableEnhancedContent = scopes.includes(Scope.DEVELOPMENT)

  const {
    data, loading, error,
  } = useQuery<CampaignVersionOutput, CampaignVersionInput>(GET_IS_MIGRATED_CAMPAIGN, {
    variables: {
      campaignId: campaignID || "0",
    },
    context: {
      apiVersion: "v2",
    },
    fetchPolicy: "network-only",
    skip: !enableEnhancedContent,
  })

  useEffect(() => {
    if (!campaignID) return
    dispatch(fetchCampaign({ campaignId: campaignID }))
    // Included here to prevent previous searches being carried to other campaign pages
    dispatch(setCampaignDeliverablesStartsWithFilter(""))
  }, [ campaignID ])

  useEffect(() => {
    if (data?.isCampaignMigrated) {
      dispatch(setIsCampaignMigrated(data.isCampaignMigrated))
    }
  }, [ campaignID, data ])

  const campaignName: React.ReactNode = useMemo(() => {
    if (campaign === "init" || campaign === "loading" || isError(campaign)) return <LoadingIndicator size={ 20 } />
    if (isError(campaign)) return translate("Unable to load campaign name")
    return campaign.payload?.campaign?.name || ""
  }, [ campaign ])

  const renderContent = () => {
    if (loading || campaign === "init" || campaign === "loading") {
      return <LoadingIndicatorCard size={ 50 } />
    }

    if (error) {
      return <ErrorHandler />
    }
    return (
      <div className="cp_campaign_page-container">
        <section className="cp_campaign_page-tabs">
          { data?.isCampaignMigrated.isMigrated && scopes.includes(Scope.DEVELOPMENT) ? <CampaignTabsV2 /> : <CampaignTabs /> }
        </section>
      </div>
    )
  }

  return (
    <div className="cp_campaign_page">
      <ToastController />
      <PageShell title={ campaignName } />
      { renderContent() }
    </div>
  )
}
