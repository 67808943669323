import React from "react"
import "./style.sass"

import { Campaign, Check } from "@mui/icons-material"
import { Badge } from "@mui/material"
import { useTranslation } from "react-i18next"

import IconButton from "../../component/IconButton"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import { PublicListApprovalStatus } from "../../util/types"
import {
  AccountApprovalStatus,
  PublicListSocialAccount,
  setLeaveFeedback,
  setLeftPanelApprovedStatus,
} from "../../state/publicListSlice"

/**
 * CampaignCTA: Create the approve and feedback button
 * @returns The JSX elements needed to generate results
 */
export default function CampaignCTA() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })
  const dispatch = useDispatch()

  // Global state
  const {
    leftPanelApprovedStatus,
    loadedListSocialAccount,
  } = useSelector((state) => state.publicList)

  // Local state
  const [ account, setAccount ] = React.useState<PublicListSocialAccount>()

  // React hook to set approval status
  const approval = React.useMemo((): PublicListApprovalStatus => {
    if (API.isSuccess(loadedListSocialAccount)) {
      const { id: accountId } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
      return leftPanelApprovedStatus
        .find((status) => status.accountId === accountId)?.status || PublicListApprovalStatus.NotApproved
    }
    return PublicListApprovalStatus.NotApproved
  }, [ leftPanelApprovedStatus, loadedListSocialAccount ])

  /**
   * React hook: Set the account value
   */
  React.useEffect(() => {
    if (API.isSuccess(loadedListSocialAccount)) {
      setAccount(loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId)
    }
  }, [ loadedListSocialAccount ])

  /**
   * updateApprovalStatus: Update the approval status for given account
   * @param status The status to update
   * @returns A copy of the left panel statuses with updated status for given account
   */
  const updateApprovalStatus = (status: PublicListApprovalStatus): AccountApprovalStatus[] => {
    // Rebuild status changing the status for given account
    const statuses = leftPanelApprovedStatus.map((accountStatus) => {
      if ((account) && (accountStatus.accountId === account.id)) {
        return {
          ...accountStatus,
          status,
        }
      }
      return {
        ...accountStatus,
      }
    })

    // Return the updated statuses
    return statuses
  }

  /**
   * handleApproveSocialAccount: Updates the global state statuses with current account set
   * as pending approval
   */
  const handleApproveSocialAccount = () => {
    // Make sure to only update the status if it isn't already approved
    if (approval !== PublicListApprovalStatus.Approved) {
      // Set pending
      dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.PendingApproval)))
    }
  }

  /**
   * handleAddFeedbackToSocialAccount: Update the global state to reflect that a feedback
   * is to be made.  This will open the feedback overlay
   */
  const handleAddFeedbackToSocialAccount = () => {
    // Mark that we are leaving a feedback
    dispatch(setLeaveFeedback(true))
  }

  // Return the JSX elements for render
  return (
    <div className="cp_component_pl-account-page-content-header-cta-content">
      <div className="cp_component_pl-account-page-content-header-cta-content-approve-button">
        <IconButton
          className={ `approved-button${ (approval === PublicListApprovalStatus.Approved)
            ? " approved-state"
            : (approval === PublicListApprovalStatus.PendingApproval)
              ? " pending-approval-state"
              : "" }` }
          variant="outlined"
          onClick={ handleApproveSocialAccount }
        >
          <Check />
        </IconButton>
        <p className="approved-label">{ translate("Approved") }</p>
      </div>
      <div className="cp_component_pl-account-page-content-header-cta-content-feedback-button">
        <Badge
          color="primary"
          className="feedback-badge"
          badgeContent={ leftPanelApprovedStatus.find((stats) => stats.accountId === account?.id)?.comments.length || 0 }
          showZero={ true }
        >
          <IconButton
            className="feedback-button"
            variant="outlined"
            onClick={ handleAddFeedbackToSocialAccount }
          >
            <Campaign />
          </IconButton>
        </Badge>
        <p className="feedback-label">{ translate("Feedback") }</p>
      </div>
    </div>
  )
}
