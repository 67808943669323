import React from "react"
import "./style.sass"

import { useParams } from "react-router-dom"
import { useCookies } from "react-cookie"

import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import {
  initializeAccess,
  setAccessCodeCookieKey,
  setCompanyCookieKey,
  setNameCookieKey,
} from "../../state/publicListSlice"
import LoadingIndicator from "../../component/LoadingIndicator"
import PublicListV2AuthShell from "./PublicListV2AuthShell"

export default function PublicListV2() {
  const dispatch = useDispatch()
  const { listCode } = useParams()
  const {
    userVerification, accessCodeCookieKey,
  } = useSelector(({ publicList }) => publicList)

  React.useEffect(() => {
    if (!listCode) return
    const [ nameKey, companyKey, accessCodeKey ] = [ `${ listCode }-your-name`, `${ listCode }-company`, `${ listCode }-code` ]
    dispatch(setAccessCodeCookieKey(accessCodeKey))
    dispatch(setNameCookieKey(nameKey))
    dispatch(setCompanyCookieKey(companyKey))
  }, [ listCode ])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ accessCodeCookieKey ])

  React.useEffect(() => {
    if (listCode) {
      dispatch(initializeAccess(listCode, cookies[`${ listCode }-code`] || ""))
    }
  }, [ listCode ])

  if (userVerification === "init" || userVerification === "loading") {
    return <LoadingIndicator />
  }
  if (API.isError(userVerification)) {
    return <p>There was an error! Please try again.</p>
  }

  const {
    suggestionAccessCodeRestriction,
    isValid,
  } = userVerification.payload.publicVerifySuggestionListAccessCodeByListCode

  if (!isValid && suggestionAccessCodeRestriction === null) {
    return <p>Redirect to 404</p>
  }

  return (
    <PublicListV2AuthShell
      userIsValid={ isValid }
      accessCodeRestriction={ suggestionAccessCodeRestriction }
    />
  )
}
