import React from "react"
import "./style.sass"
import OverviewMetrics from "./OverviewMetrics"
import SocialAvatar from "../../component/SocialAvatar"
import { PublicListSocialAccount } from "../../state/publicListSlice"
import { useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"

/**
 * Overview: Component to remder the overview of the account page
 * @returns The JSX elements that represent the overview
 */
export default function Overview() {
  // Global state
  const { loadedListSocialAccount, list } = useSelector((state) => state.publicList)

  // React hook to extract the account
  const account = React.useMemo((): PublicListSocialAccount => {
    if (API.isSuccess(loadedListSocialAccount)) {
      return loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
    }
    return {} as PublicListSocialAccount
  }, [ loadedListSocialAccount ])

  // Extract epsilonVerfied
  const isEpsilonVerified: boolean = React.useMemo(() => {
    if (API.isSuccess(list)) {
      return list.payload.publicSuggestionListByCode.epsilonVerified
    }
    return false
  }, [ list ])

  // The JSX elements for render
  return (
    <div className="cp_component_pl-account-page-content-overview">
      <SocialAvatar
        followers={ account.socialAccount.socialAccountStatistics.followers }
        fullName=""
        isBlacklisted={ false }
        isNSFW={ false }
        isPlaceholder={ false }
        isUnsubscribed={ false }
        network={ account.socialAccount.network }
        profilePictureUrl={ account.socialAccount.profilePictureUrl }
        username={ account.socialAccount.userName }
        eVerified={ isEpsilonVerified }
      />
      <OverviewMetrics />
    </div>
  )
}
