import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import SearchIcon from "@mui/icons-material/Search"
import CheckIcon from "@mui/icons-material/Check"

import "./affinities.sass"

import {
  LinearProgress,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import Tabs from "../../Tabs"
import Input from "../../Input"
import { AudienceDemographicAffinityFragment } from "../../../graphql"
import { prettyPrintDecimal, shorthandNumber } from "../../../util/miscHelper"
import Tooltip from "../../Tooltip"
import Select from "../../Select"
import MenuItem from "../../MenuItem"
import * as GraphQL from "../../../graphql"

type Props = {
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
  socialProfile: GraphQL.SocialProfileFragment
  showSearchInput?: boolean
}

export default function Affinities({
  audienceDemographics, socialProfile, showSearchInput = true,
}:Props) {
  const [ query, setQuery ] = React.useState("")
  const [ selectedFilter, selectFilter ] = React.useState("")
  const [ brandAffinities, setBrandAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ hashtagAffinities, setHashtagAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ influenceAffinities, setInfluenceAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ interestAffinities, setInterestAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ mediaAffinities, setMediaAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ musicAffinities, setMusicAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ sportAffinities, setSportAffinities ] = React.useState<AudienceDemographicAffinityFragment[]>([])
  const [ tabIndex, setTabIndex ] = React.useState<undefined | number>()
  const { t: translate } = useTranslation([], { keyPrefix: "component.Affinities" })

  const tabs = useMemo(() => {
    const allTabs = []
    if (audienceDemographics?.brandAffinities?.length) allTabs.push({ label: translate("Brands") })
    if (audienceDemographics?.interestAffinities?.length) allTabs.push({ label: translate("Likes & Interests") })
    if (audienceDemographics?.hashtagAffinities?.length) allTabs.push({ label: translate("Hashtags") })
    if (audienceDemographics?.influenceAffinities?.length) allTabs.push({ label: translate("Influences") })
    if (audienceDemographics?.mediaAffinities?.length) allTabs.push({ label: translate("Media") })
    if (audienceDemographics?.musicAffinities?.length) allTabs.push({ label: translate("Music") })
    if (audienceDemographics?.sportAffinities?.length) allTabs.push({ label: translate("Sports") })
    return allTabs
  }, [])

  useEffect(() => {
    setTabIndex(0)
  }, [])

  useEffect(() => {
    const newBrandAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.brandAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newHashtagAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.hashtagAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newInfluenceAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.influenceAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newInterestAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.interestAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newMediaAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.mediaAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newMusicAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.musicAffinities
      || []
    ).filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    const newSportAffinities: AudienceDemographicAffinityFragment[] = (
      audienceDemographics?.sportAffinities
      || []
    )
      .filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
      .filter(({ subcategory }) => (subcategory === selectedFilter) || !selectedFilter)

    setBrandAffinities(newBrandAffinities)
    setInterestAffinities(newInterestAffinities)
    setHashtagAffinities(newHashtagAffinities)
    setInfluenceAffinities(newInfluenceAffinities)
    setMediaAffinities(newMediaAffinities)
    setMusicAffinities(newMusicAffinities)
    setSportAffinities(newSportAffinities)
  }, [ query, tabIndex, selectedFilter, audienceDemographics ])

  const affinityGroup = useMemo(() => {
    switch (tabs[tabIndex || 0]?.label) {
      case translate("Brands"):
        return brandAffinities
      case translate("Likes & Interests"):
        return interestAffinities
      case translate("Hashtags"):
        return hashtagAffinities
      case translate("Influences"):
        return influenceAffinities
      case translate("Media"):
        return mediaAffinities
      case translate("Music"):
        return musicAffinities
      case translate("Sports"):
        return sportAffinities
      default:
        return []
    }
  }, [
    tabIndex,
    tabs,
    brandAffinities,
    interestAffinities,
    hashtagAffinities,
    mediaAffinities,
    musicAffinities,
    sportAffinities,
  ])

  const filterOptions = useMemo(() => {
    const getGroup = (): AudienceDemographicAffinityFragment[] => {
      switch (tabs[tabIndex || 0]?.label) {
        case translate("Brands"):
          return audienceDemographics?.brandAffinities || []
        case translate("Likes & Interests"):
          return audienceDemographics?.interestAffinities || []
        case translate("Hashtags"):
          return audienceDemographics?.hashtagAffinities || []
        case translate("Influences"):
          return audienceDemographics?.influenceAffinities || []
        case translate("Media"):
          return audienceDemographics?.mediaAffinities || []
        case translate("Music"):
          return audienceDemographics?.musicAffinities || []
        case translate("Sports"):
          return audienceDemographics?.sportAffinities || []
        default:
          return []
      }
    }
    const group = getGroup()

    const options = [
      "",
      ...Array.from(
        new Set(group.map(({ subcategory }) => subcategory)),
      ),
    ]
      .filter((subCategory) => (subCategory !== undefined) && (subCategory !== null))
      .sort()
      .map((subcategory) => (
        <MenuItem
          value={ subcategory || "" }
          key={ `${ subcategory || "" }-${ tabIndex }` }
          onClick={ () => selectFilter(subcategory || "") }
          className="cp_affinities_component-filter-option"
        >
          { selectedFilter === subcategory ? (
            <ListItemIcon className="">
              <CheckIcon fontSize="small" />
            </ListItemIcon>
          ) : <ListItemIcon className="" /> }
          <ListItemText className="">
            { subcategory === "" ? translate("All") : subcategory?.replaceAll("_", " ") }
          </ListItemText>
        </MenuItem>
      ))
    return options
  }, [ tabIndex, tabs, selectedFilter, audienceDemographics ])

  return (
    <div className="cp_affinities_component">
      <div className="cp_affinities_component-header">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Affinities") }
        </h6>
        <div className="cp_affinities_component-header-controls">
          <Select
            displayEmpty={ true }
            id="affinity-filter"
            label=""
            labelId="affinity-filter"
            menuItems={ filterOptions }
            mode="thin"
            name="affinity-filter"
            value={ selectedFilter }
          />
          { showSearchInput
            ? (
              <Input
                InputProps={ {
                  startAdornment: <SearchIcon />,
                } }
                mode="thin"
                onChange={ (e) => setQuery(e.currentTarget.value) }
                placeholder={ translate("Search") }
                value={ query }
              />
            )
            : null
          }
        </div>
      </div>
      <Tabs
        handleChange={ (index) => {
          setTabIndex(index)
          setQuery("")
          selectFilter("")
        } }
        tabs={ tabs }
        controls={ <div /> }
      />
      <div className="cp_affinities_component-content">
        {
          affinityGroup.map(({
            name, value, subcategory,
          }, i) => {
            const followerCount = shorthandNumber(
              Math.floor(value * socialProfile.socialAccountStatistics.followers),
            )
            return (
              <div key={ `${ subcategory }-${ name }` } className="cp_affinities_component-content-demo">
                <div className="cp_affinities_component-content-demo-header">
                  <p className="cp_affinities_component-content-demo-header-name">
                    { `${ i + 1 }. ${ name }` }
                  </p>
                  <p className="cp_affinities_component-content-demo-header-value">
                    { `${ prettyPrintDecimal(value, 1) }%` }
                  </p>
                </div>
                <Tooltip
                  title={ `${ followerCount } ${ translate("FOLLOWERS") }` }
                  arrow={ true }
                  placement="top"
                >
                  <LinearProgress value={ value * 100 } variant="determinate" />
                </Tooltip>
              </div>
            )
          })
        }
        {
          affinityGroup.length === 0 && (
            <p className="cp_affinities_component-content-demo-no-data">
              { translate("No Data") }
            </p>
          )
        }
      </div>
    </div>
  )
}
