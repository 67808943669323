import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { List, ListItem } from "@mui/material"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { setAssignReviewerSearchInput, setSelectedReviewerGroupId } from "../../state/campaignDeliverableAssignReviewerModalSlice"
import {
  GET_REVIEWER_GROUPS_FOR_CAMPAIGN,
  UserGroupsForCampaignOutput,
  UserGroupsForCampaignVariables,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import SearchBar from "../SearchBar"

function SearchReviewerGroup(): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const dispatch = useDispatch()

  const campaignId = useParams().campaignID || ""

  const { searchInput, selectedReviewerGroupId } = useSelector(
    ({ campaignDeliverableAssignReviewerModal }) => campaignDeliverableAssignReviewerModal,
  )

  const handleToggle = (value: string) => {
    dispatch(setSelectedReviewerGroupId(value))
  }

  const { data: reviewerGroups } = useQuery<UserGroupsForCampaignOutput, UserGroupsForCampaignVariables>(
    GET_REVIEWER_GROUPS_FOR_CAMPAIGN,
    {
      variables: {
        campaignId,
      },
      context: {
        apiVersion: "v2",
      },
      skip: !campaignId,
    },
  )

  const reviewerGroupOptions = useMemo(() => {
    if (!reviewerGroups) return []
    const regex = new RegExp(searchInput, "i")
    return reviewerGroups.reviewerGroupsForCampaign.filter(({ displayName }) => displayName.match(regex))
  }, [ reviewerGroups, searchInput ])

  return (
    <div className="cp_deliverable-search-reviewergroup-container" id="cp_component_search-reviewergroup-modal">
      <div className="cp_deliverable-search-reviewergroup-body">
        <p className="body_large">
          { translate("Search For Groups") }
        </p>
        <SearchBar
          className="search-bar"
          searchPlaceholderText={ translate("Search For Groups") }
          lastSubmittedSearch={ searchInput || "" }
          onChange={ (e) => {
            dispatch(setAssignReviewerSearchInput(e.target.value))
            dispatch(setSelectedReviewerGroupId(""))
          } }
        />
        <div>
          <List className="reviewer-list">
            { reviewerGroupOptions.map(({ displayName, id }) => (
              <ListItem
                className={ `reviewer-list${ selectedReviewerGroupId === id ? "-selected" : "" }` }
                id={ id }
                key={ id }
                onClick={ () => handleToggle(id) }
                value={ id }
              >
                { displayName }
              </ListItem>
            )) }
          </List>
        </div>
      </div>
    </div>
  )
}

export default SearchReviewerGroup
