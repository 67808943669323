import { DeliverableSummary } from "./deliverableTypes"

export interface Campaign {
    id: String
    numberOfCampaignNetworkAccounts: number
    numberOfAllDeliverables: number
    numberOfDeliverablesWithoutContent: number
    numberOfPendingFeedBackDeliverables: number
    numberOfPendingRevisionDeliverables: number
    numberOfFinalizedDeliverables: number
    numberOfLiveDeliverables: number
    deliverableSummaryList: DeliverableSummary[]
  }

export interface CampaignVersion{
    id: String
    isMigrated: boolean
}

export interface CampaignVersionInput {
  campaignId: string
}

export interface CampaignVersionOutput{
  isCampaignMigrated: CampaignVersion
}

// eslint-disable-next-line no-shadow
export enum DeliverableNotificationType {
  DELIVERABLE_PENDING_REVIEW = "DELIVERABLE_PENDING_REVIEW",
  DELIVERABLE_FEEDBACK = "DELIVERABLE_FEEDBACK",
  DELIVERABLE_APPROVAL_VOTE = "DELIVERABLE_APPROVAL_VOTE",
  DELIVERABLE_FEEDBACK_COMPLETE = "DELIVERABLE_FEEDBACK_COMPLETE",
  DELIVERABLE_PENDING_REVISION = "DELIVERABLE_PENDING_REVISION",
  DELIVERABLE_FINALIZED = "DELIVERABLE_FINALIZED",
  DELIVERABLE_LIVE = "DELIVERABLE_LIVE",
}

export interface UserNotificationPreference {
  campaignId: string,
  notificationPreferences: DeliverableNotificationType[] | null
}
