import React from "react"

import { useTranslation } from "react-i18next"

import { PUBLIC_DETAILS_DISPLAY_TOGGLES } from "../../../util/constant"
import Pill from "../../../component/Pill"
import { SuggestionListAccount } from "../CategoryAccounts"

/**
 * AccountDetailsProps: Properties for component
 */
interface AccountDetailsProps {
  // eslint-disable-next-line max-len
  account: SuggestionListAccount
  toggles: string[]
}

/**
 * PublicAccountDetails:  Display the rationale, bio and tag details
 * @param param0 The account information
 * @returns The display element for this component
 */
export default function PublicAccountDetails({ account, toggles }: AccountDetailsProps) {
  // Local variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ConfigurationHighlightsTab" })
  const displayedTags = (account.verticals.length > 0) ? account.verticals : account.suggestionList.verticals

  return (
    <div className="cp_lcp_highlights-previews_account-container_public-account-details">
      { (toggles.includes(PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE))
      && (account.rationale)
      && (account.rationale.trim() !== "") && (
        <div className="public-account-details_rationale">
          <h5 className="section-title">{ translate("Influencer Rationale") }</h5>
          <p className="body_medium">{ account.rationale }</p>
        </div>
      ) }
      { (toggles.includes(PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO)) && (account.bio) && (account.bio.trim() !== "") && (
        <div className="public-account-details_bio">
          <h5 className="section-title">{ translate("Bio") }</h5>
          <p className="body_medium">{ account.bio }</p>
        </div>
      ) }
      { (displayedTags.length > 0) && (toggles.includes(PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS)) && (
        <div className="public-account-details_tags">
          <h5 className="section-title">{ translate("Tags") }</h5>
          <div className="section-pills">
            { displayedTags.map((tag) => (
              <Pill key={ `${ tag.id }-${ tag.name }` } label={ tag.name } />
            )) }
          </div>
        </div>
      ) }
    </div>
  )
}
