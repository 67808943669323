import React from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import ReturnToTag from "../ReturnToTag"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  setFeedbackInput,
  setFeedbackPageOpen,
  setFeedbackPagePendingVote,
} from "../../state/campaignDeliverableContentModalV2Slice"
import Button from "../Button"

import "./feedback-page.sass"
import {
  CAST_DELIVERABLE_VOTE,
  CastDeliverableVoteInput,
  CastDeliverableVoteOutput,
  GET_DELIVERABLE_CAPTION_ITEMS,
  GET_DELIVERABLE_MEDIA_ITEMS,
  GET_DELIVERABLE_PERMISSIONS,
  GET_DELIVERABLE_USER_GROUP,
  GET_DELIVERABLE_VOTE,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import RichTextEditor from "../RichTextEditor"

export default function FeedbackPage() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.FeedbackPage" })
  const dispatch = useDispatch()
  const {
    feedbackInput, feedbackPagePendingVote, deliverable,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const [ castDeliverableVote ] = useMutation<
        CastDeliverableVoteOutput,
        CastDeliverableVoteInput
      >(CAST_DELIVERABLE_VOTE)

  const handleClose = () => {
    dispatch(setFeedbackPageOpen(false))
    dispatch(setFeedbackInput(""))
    dispatch(setFeedbackPagePendingVote(undefined))
  }

  const handleSubmit = async () => {
    if (!deliverable || !feedbackPagePendingVote || feedbackInput.length === 0) return
    await castDeliverableVote({
      variables: {
        deliverableId: deliverable.id,
        voteType: feedbackPagePendingVote,
        comment: feedbackInput,
      },
      context: {
        apiVersion: "v2",
      },
      refetchQueries: [
        {
          query: GET_DELIVERABLE_CAPTION_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_MEDIA_ITEMS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_VOTE,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_USER_GROUP,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
        {
          query: GET_DELIVERABLE_PERMISSIONS,
          variables: { deliverableId: deliverable.id },
          context: { apiVersion: "v2" },
        },
      ],
    })
    handleClose()
  }

  return (
    <div className="cp_component_deliverable-v2_feedback-page">
      <ReturnToTag
        className="back-to-content-page-tag"
        label={ translate("< Back To Content Page") }
        onClick={ handleClose }
      />
      <h3>{ translate("Feedback") }</h3>

      <RichTextEditor
        editorContent={ feedbackInput }
        setEditorContent={ (content) => dispatch(setFeedbackInput(content)) }
        menuBarOptions={ {
          mergeTagsDisabled: true,
          textAlignDisabled: true,
          codeBlockDisabled: true,
          textSizeDisabled: true,
        } }
      />
      <Button
        className="submit-button"
        label={ translate("Submit") }
        isPrimary={ true }
        onClick={ handleSubmit }
      />
    </div>
  )
}
