/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {
  LIST_DISPLAY_TOGGLES,
  PUBLIC_DETAILS_DISPLAY_TOGGLES,
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES,
  ACCOUNT_INSIGHTS_DISPLAY_TOGGLES,
  HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES,
  HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
  DefaultSuggestionListToggles,
} from "../../util/constant"
import { Metric, SocialAccountAudienceDemographics } from "../../state/listConfigurationSlice"
import * as MiscHelper from "../../util/miscHelper"
import * as GraphQL from "../../graphql"

// ############## ENUMS ####################
export enum RibbonBanner {
  Default,
  Audience,
}

// #############  TYPES #####################
// eslint-disable-next-line max-len
export type SuggestionListSocialAccount = GraphQL.SuggestionListSocialAccountFragmentFragment | GraphQL.SuggestionListSocialAccountForPublicListFragment

export type DemoScoreFormValueCompare =
  GraphQL.Sex[]
  | GraphQL.Ethnicity[]
  | GraphQL.Family[]
  | GraphQL.Religion[]
  | GraphQL.IncomeBrackets[]
  | GraphQL.AudienceLocation[]
  | Occupation[]
  | Affinity[]
  | { from: number, to: number }

export type Occupation = { __typename?: "Tag", id: string, name: string, code: string, category?: string | null }

export type Affinity = { __typename?: "Tag", id: string, name: string, code: string, category?: string | null }

export type GroupMetrics = {
  groupName: string
  metrics: Metric[]
}

export type DemographicScoreForm = {
  sexes: GraphQL.Sex[]
  age: {
    from: number,
    to: number,
  },
  ethnicity: GraphQL.Ethnicity[]
  family: GraphQL.Family[]
  religion: GraphQL.Religion[]
  income: GraphQL.IncomeBrackets[]
  locations: GraphQL.AudienceLocation[]
  occupations: Occupation[]
  affinities: Affinity[]
}

export type RibbonMetric = {
  label: string
  value: string | number
}

export type RibbonMetricToggle = {
  code: string
  label: string
}

export type RibbonMetricGroup = {
  ribbonMetrics: RibbonMetric[]
}

export type AudienceData = {
  iScores: GraphQL.GetSocialAccountsIScoreQuery["getSocialAccountsIScore"]["socialAccountsIScore"]
  audienceDemographics?: SocialAccountAudienceDemographics
  updateListForm?: GraphQL.UpdateSuggestionListMutationVariables
  audienceToggleData?: GraphQL.DetailedHighlightsAudienceDataFragment
}

export type AudienceGroup = {
  name: string
  order: number
  toggles: GraphQL.SuggestionListToggleInput[]
}

export type DragNDropSwitchInformation = {
  currentIndex: number
  option: AudienceGroup | GraphQL.SuggestionListToggleInput
}

// ################ CONSTANTS ###############
export const highlightMetrics: GroupMetrics[] = [ {
  groupName: "Metrics",
  metrics: [],
}, {
  groupName: "Public List Account Details",
  metrics: [
    {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE,
      name: "Age",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER,
      name: "Gender",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
      name: "Location",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS,
      name: "Tags",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO,
      name: "Bio",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE,
      name: "Rationale",
    },
  ],
}, {
  groupName: "Scores & Engagement Rate",
  metrics: [ {
    code: LIST_DISPLAY_TOGGLES.ENG_SCORE,
    name: "Engagement Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ENG_RATE,
    name: "Engagement Rate (90 days)",
  }, {
    code: LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
    name: "TTCM Engagement Rate",
  }, {
    code: LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
    name: "Age/Gender In Demo",
  }, {
    code: LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
    name: "Demographic Score*",
  }, {
    code: LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
    name: "Audience Authenticity Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
    name: "Ad Council Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ISCORE,
    name: "I-Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.NO_SCORE,
    name: "None",
  } ],
}, {
  groupName: "Account Insights",
  metrics: [ {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    name: "Engagement by Post Type Organic vs Sponsored",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    name: "Engagement by Post Type Reel vs Non-Reel",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    name: "Brands Mentions & Brands Worked With",
  }, {
    code: HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    name: "Personality Traits",
  } ],
}, {
  groupName: "Audience Metrics (up to 5)",
  metrics: [ {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER,
    name: "Gender",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
    name: "Family Status",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
    name: "Age Range",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
    name: "Income Range",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
    name: "Ethnicity",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
    name: "Religion",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
    name: "Countries",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES,
    name: "States",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES,
    name: "Cities",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
    name: "Occupations",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
    name: "Affinities",
  }, {
    code: HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
    name: "Followers",
  } ],
}, {
  groupName: "Content",
  metrics: [ {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
    name: "Top Posts",
  }, {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
    name: "Relevant Posts",
  }, {
    code: HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
    name: "Post Impressions",
  } ],
} ]

export const initialDemographicScoreForm: DemographicScoreForm = {
  sexes: [],
  age: {
    from: 0,
    to: 0,
  },
  ethnicity: [],
  family: [],
  religion: [],
  income: [],
  locations: [],
  occupations: [],
  affinities: [],
}

export const ReadableAgeRange = {
  [GraphQL.AgeRange.Under_18]: "17 and under",
  [GraphQL.AgeRange["18_20"]]: "18 to 20",
  [GraphQL.AgeRange["21_24"]]: "21 to 24",
  [GraphQL.AgeRange["25_29"]]: "25 to 29",
  [GraphQL.AgeRange["30_34"]]: "30 to 34",
  [GraphQL.AgeRange["35_44"]]: "35 to 44",
  [GraphQL.AgeRange["45_54"]]: "45 to 54",
  [GraphQL.AgeRange["55_64"]]: "55 to 64",
  [GraphQL.AgeRange.Above_64]: "65 and over",
}

export const ReadableEthnicity = {
  [GraphQL.Ethnicity.AfricanAmerican]: "African American",
  [GraphQL.Ethnicity.AsianPacificIslander]: "Asian / Pacific Islander",
  [GraphQL.Ethnicity.HispanicLatino]: "Hispanic / Latino",
  [GraphQL.Ethnicity.WhiteCaucasian]: "White / Caucasian",
}

export const ReadableFamilyStatus = {
  [GraphQL.FamilyStatus.Married]: "Married",
  [GraphQL.FamilyStatus.Parent]: "Parent",
  [GraphQL.FamilyStatus.Single]: "Single",
  [GraphQL.FamilyStatus.NonParent]: "Non-parent",
}

export const ReadableFamily = {
  [GraphQL.Family.Married]: "Married",
  [GraphQL.Family.Parent]: "Parent",
  [GraphQL.Family.Single]: "Single",
}

export const ReadableReligion = {
  [GraphQL.Religion.Christian]: "Christian",
  [GraphQL.Religion.Jewish]: "Jewish",
  [GraphQL.Religion.Muslim]: "Muslim",
}

export const ReadableIncome = {
  [GraphQL.IncomeBrackets["100000Above"]]: "$100,000+",
  [GraphQL.IncomeBrackets["10000_19999"]]: "$10,000 - $19,999",
  [GraphQL.IncomeBrackets["20000_29999"]]: "$20,000 - $29,999",
  [GraphQL.IncomeBrackets["30000_39999"]]: "$30,000 - $39,999",
  [GraphQL.IncomeBrackets["40000_49999"]]: "$40,000 - $49,999",
  [GraphQL.IncomeBrackets["50000_74999"]]: "$50,000 - $74,999",
  [GraphQL.IncomeBrackets["75000_100000"]]: "$75,000 - $99,999",
  [GraphQL.IncomeBrackets.Under_10000]: "$0 - $9,999",
}

export const engagementToggles = [ {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.ENG_SCORE,
  order: 0,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.ENG_RATE,
  order: 1,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
  order: 2,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
  order: 6,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
  order: 3,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
  order: 4,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.ISCORE,
  order: 5,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.NO_SCORE,
  order: 7,
}, {
  type: GraphQL.SuggestionListToggleGroupType.AudienceToggles,
  name: AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
  order: 1,
} ]

export const basicSelectedMetrics = [ {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsPublicListDetailsToggles,
  name: PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
  order: 2,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsPublicListDetailsToggles,
  name: PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS,
  order: 3,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsPublicListDetailsToggles,
  name: PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO,
  order: 4,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsPublicListDetailsToggles,
  name: PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE,
  order: 5,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.ENG_SCORE,
  order: 0,
}, {
  type: GraphQL.SuggestionListToggleGroupType.HighlightsScoresEngRatesToggles,
  name: LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
  order: 3,
} ]

export const accountInsightDisplayCodes = [ {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
  displayName: "Engagement Rate over Time",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
  displayName: "Engagement by Post Type Organic vs Sponsored",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
  displayName: "Engagement by Post Type Reel vs Non-Reel",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
  displayName: "Engagement Heat Map",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
  displayName: "Recent Media & AI Image Analysis",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
  displayName: "Brands Mentions & Brands Worked With",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
  displayName: "Recent Media Stories",
}, {
  code: ACCOUNT_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
  displayName: "Personality Traits",
} ]

export const audienceDisplayCodes = [ {
  code: AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
  displayName: "Followers Over Time",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
  displayName: "Audience Authenticity",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.GENDER,
  displayName: "Gender",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
  displayName: "Family Status",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
  displayName: "Age Range",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
  displayName: "Income Range",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
  displayName: "Education Level",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
  displayName: "Ethnicities",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
  displayName: "Languages",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
  displayName: "Religions",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
  displayName: "Countries",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.STATES,
  displayName: "States",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.CITIES,
  displayName: "Cities",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
  displayName: "Occupations",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
  displayName: "Industries",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
  displayName: "Employers",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
  displayName: "Universities",
}, {
  code: AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
  displayName: "Affinities",
} ]

export const contentDisplayCodes = [ {
  code: CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
  displayName: "Top Posts",
}, {
  code: CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
  displayName: "Relevant Posts",
}, {
  code: CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
  displayName: "Post Impressions",
} ]

export const defaultSelectedMetrics = [
  ...basicSelectedMetrics,
  ...DefaultSuggestionListToggles.filter((t) => accountInsightDisplayCodes.map((code) => code.code).includes(t.name)),
  ...DefaultSuggestionListToggles.filter((t) => audienceDisplayCodes.map((code) => code.code).includes(t.name)),
  ...DefaultSuggestionListToggles.filter((t) => contentDisplayCodes.map((code) => code.code).includes(t.name)),
]

export const appendixMetrics: GroupMetrics[] = [ {
  groupName: "Metrics",
  metrics: [],
}, {
  groupName: "Account Insights",
  metrics: [ {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
    name: "Engagement Rate over Time",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    name: "Engagement by Post Type Organic vs Sponsored",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    name: "Engagement by Post Type Reel vs Non-Reel",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
    name: "Engagement Heat Map",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
    name: "Recent Media & AI Image Analysis",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
    name: "Recent Media Stories",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    name: "Brands Mentions & Brands Worked With",
  }, {
    code: APPENDIX_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    name: "Personality Traits",
  } ],
}, {
  groupName: "Audience Metrics",
  metrics: [ {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME,
    name: "Followers Over Time",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
    name: "Audience Authenticity",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.GENDER,
    name: "Gender",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS,
    name: "Family Status",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE,
    name: "Age Range",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE,
    name: "Income Range",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL,
    name: "Education Level",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES,
    name: "Ethnicities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.LANGUAGES,
    name: "Languages",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS,
    name: "Religions",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES,
    name: "Countries",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.STATES,
    name: "States",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.CITIES,
    name: "Cities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS,
    name: "Occupations",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES,
    name: "Industries",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS,
    name: "Employers",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES,
    name: "Universities",
  }, {
    code: APPENDIX_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES,
    name: "Affinities",
  } ],
}, {
  groupName: "Content",
  metrics: [ {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.TOP_POSTS,
    name: "Top Posts",
  }, {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS,
    name: "Relevant Posts",
  }, {
    code: APPENDIX_CONTENT_DISPLAY_TOGGLES.POST_IMPRESSIONS,
    name: "Post Impressions",
  } ],
} ]

export const contentTitleMetrics = {
  TOP_POSTS: "Top Posts",
  RELEVANT_POSTS: "Relevant Posts",
  POST_IMPRESSIONS: "Post Impressions",
}

export const populateRibbonBannerMetrics = (
  toggles: string[],
  metricToggles: RibbonMetricToggle[],
  account: SuggestionListSocialAccount,
  data: AudienceData,
): RibbonMetric[] => {
  const audienceData = (data.updateListForm?.highlightsAudienceDemographics)
    ? data.updateListForm?.highlightsAudienceDemographics
    : data.audienceToggleData
  const ribbonMetrics: RibbonMetric[] = []
  metricToggles.forEach((metric) => {
    if (toggles.includes(metric.code) || metric.code === HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME) {
      switch (metric.code) {
        case PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE:
          if (account.age) {
            ribbonMetrics.push({
              label: "Age",
              value: account.age,
            })
          }
          break
        case PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER:
          if (account.gender) {
            ribbonMetrics.push({
              label: "Gender",
              value: account.gender,
            })
          }
          break
        case PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION:
          if (account.location) {
            ribbonMetrics.push({
              label: "Location",
              value: account.location,
            })
          }
          break
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME:
          if (account.socialAccount.socialAccountStatistics.followers) {
            ribbonMetrics.push({
              label: "Followers",
              value: MiscHelper.shorthandNumber(account.socialAccount.socialAccountStatistics.followers),
            })
          }
          break
        case LIST_DISPLAY_TOGGLES.ENG_SCORE:
          if (account.socialAccount.engagementScore?.value) {
            ribbonMetrics.push({
              label: "Eng Score",
              value: Math.round(account.socialAccount.engagementScore.value),
            })
          }
          break
        case LIST_DISPLAY_TOGGLES.ENG_RATE:
          if (account.socialAccount.socialAccountStatistics.engagementRate) {
            ribbonMetrics.push({
              label: "Eng Rate (90 Day)",
              value: MiscHelper.formatPercent(2, account.socialAccount.socialAccountStatistics.engagementRate),
            })
          }
          break
        case LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE:
          if (account.socialAccount.network === GraphQL.Network.Tiktok) {
            if (account.socialAccount.ttcmEngagementRate) {
              ribbonMetrics.push({
                label: "Eng Rate (TTCM)",
                value: MiscHelper.formatPercent(2, account.socialAccount.ttcmEngagementRate),
              })
            }
          }
          break
        case LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO:
          if (account.inDemo) {
            ribbonMetrics.push({
              label: "In-Demo",
              value: MiscHelper.formatPercent(2, account.inDemo),
            })
          }
          break
        case LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE:
          if (account.score && account.score.type === GraphQL.ScoreType.Demographic) {
            ribbonMetrics.push({
              label: "Demographic Score",
              value: `${ Math.round(account.score.value) }%`,
            })
          }
          break
        case LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE: {
          const iScore = data.iScores.find((accountIscore) => (
            accountIscore.socialAccountId === account.socialAccount.id
          ))
          if (iScore?.iScore) {
            ribbonMetrics.push({
              label: "Ad Council Score",
              value: `${ Math.round(iScore.iScore.value) }%`,
            })
          }
          break
        }
        case LIST_DISPLAY_TOGGLES.ISCORE: {
          const iScore = data.iScores.find((accountIscore) => (
            accountIscore.socialAccountId === account.socialAccount.id
          ))
          if (iScore?.iScore) {
            ribbonMetrics.push({
              label: "I-Score",
              value: `${ Math.round(iScore.iScore.value) }%`,
            })
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.GENDER: {
          if (data.audienceDemographics?.demographics && audienceData) {
            // Pull out the statistics
            const { genderStatistics } = data.audienceDemographics.demographics
            const { audienceGender } = audienceData

            // Make sure there is data
            if (genderStatistics && audienceGender.length > 0) {
              // Push metric
              ribbonMetrics.push({
                label: metric.label,
                value: (Array.isArray(audienceGender))
                  ? audienceGender.map((gender) => {
                    if (gender === GraphQL.Sex.Female) {
                      return `${ Math.floor(((genderStatistics.female || 0) * 100)) }% ${ gender }`
                    }
                    return `${ Math.floor(((genderStatistics?.male || 0) * 100)) }% ${ gender }`
                  }).join(" | ")
                  : (audienceGender === GraphQL.Sex.Female)
                    ? `${ Math.floor(((genderStatistics.female || 0) * 100)) }% ${ audienceGender }`
                    : `${ Math.floor(((genderStatistics.male || 0) * 100)) }% ${ audienceGender }`,
              })
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { familyStatistics } = data.audienceDemographics.demographics
            const { audienceFamilyStatus } = audienceData

            // Ensure family statistics exist
            if (familyStatistics && audienceFamilyStatus.length > 0) {
              // Create Married / Single ribbon value
              const marriedSingle = (Array.isArray(audienceFamilyStatus))
                ? audienceFamilyStatus
                  .filter((status) => status === GraphQL.FamilyStatus.Married || status === GraphQL.FamilyStatus.Single)
                  .map((status) => {
                    if (status === GraphQL.FamilyStatus.Single) {
                      return `${ ((familyStatistics.single) * 100).toFixed(1) }% SINGLE`
                    }
                    return `${ ((familyStatistics.married) * 100).toFixed(1) }% MARRIED`
                  }).join(" | ")
                : (audienceFamilyStatus === GraphQL.FamilyStatus.Married
                  || audienceFamilyStatus === GraphQL.FamilyStatus.Single)
                  ? (audienceFamilyStatus === GraphQL.FamilyStatus.Married)
                    ? `${ ((familyStatistics.married || 0) * 100).toFixed(1) }% MARRIED`
                    : `${ ((familyStatistics.single || 0) * 100).toFixed(1) }% SINGLE`
                  : undefined

              // Create Parent / Non-Parent ribbon value
              const parentNonparent = (Array.isArray(audienceFamilyStatus))
                ? audienceFamilyStatus
                  .filter((status) => status === GraphQL.FamilyStatus.Parent || status === GraphQL.FamilyStatus.NonParent)
                  .map((status) => {
                    if (status === GraphQL.FamilyStatus.Parent) {
                      return `${ ((familyStatistics.parents) * 100).toFixed(1) }% PARENTS`
                    }
                    return `${ ((100 - (familyStatistics.parents) * 100)).toFixed(1) }% NON-PARENTS`
                  }).join(" | ")
                : (audienceFamilyStatus === GraphQL.FamilyStatus.Parent
                  || audienceFamilyStatus === GraphQL.FamilyStatus.NonParent)
                  ? (audienceFamilyStatus === GraphQL.FamilyStatus.Parent)
                    ? `${ ((familyStatistics.parents) * 100).toFixed(1) }% PARENTS`
                    : `${ (100 - ((familyStatistics.parents) * 100)).toFixed(1) }% NON-PARENTS`
                  : undefined

              // Push married single, if exists
              if (marriedSingle) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: marriedSingle,
                })
              }

              // Push Parent non-parent, if exists
              if (parentNonparent) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: parentNonparent,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { ageGroups } = data.audienceDemographics.demographics
            const { audienceAgeRange } = audienceData

            if (audienceAgeRange) {
              // Push value
              let ageGroup
              switch (audienceAgeRange) {
                case GraphQL.AgeRange["18_20"]:
                  ageGroup = ageGroups.find((group) => group.minAge >= 18 && group.minAge <= 20)
                  break
                case GraphQL.AgeRange["21_24"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 21 && group.minAge <= 24)
                  break
                case GraphQL.AgeRange["25_29"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 25 && group.minAge <= 29)
                  break
                case GraphQL.AgeRange["30_34"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 30 && group.minAge <= 34)
                  break
                case GraphQL.AgeRange["35_44"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 35 && group.minAge <= 44)
                  break
                case GraphQL.AgeRange["45_54"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 45 && group.minAge <= 54)
                  break
                case GraphQL.AgeRange["55_64"]:
                  ageGroup = ageGroups.find((group) => group.minAge === 55 && group.minAge <= 64)
                  break
                case GraphQL.AgeRange.Above_64:
                  ageGroup = ageGroups.find((group) => group.minAge > 64)
                  break
                case GraphQL.AgeRange.Under_18:
                default:
                  ageGroup = ageGroups.find((group) => group.minAge < 18)
                  break
              }

              // Make sure it's set
              if (ageGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (ageGroup.value * 100).toFixed(1) }% ${ ReadableAgeRange[audienceAgeRange] }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { incomeGroups } = data.audienceDemographics.demographics
            const { audienceIncomeRange } = audienceData

            // Check to see if data is set
            if (audienceIncomeRange) {
              let incomeGroup
              switch (audienceIncomeRange) {
                case GraphQL.IncomeBrackets["100000Above"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 100000)
                  break
                case GraphQL.IncomeBrackets["10000_19999"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 10000 && group.minIncome <= 19999)
                  break
                case GraphQL.IncomeBrackets["20000_29999"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 20000 && group.minIncome <= 29999)
                  break
                case GraphQL.IncomeBrackets["30000_39999"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 30000 && group.minIncome <= 39999)
                  break
                case GraphQL.IncomeBrackets["40000_49999"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 40000 && group.minIncome <= 49999)
                  break
                case GraphQL.IncomeBrackets["50000_74999"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 50000 && group.minIncome <= 74999)
                  break
                case GraphQL.IncomeBrackets["75000_100000"]:
                  incomeGroup = incomeGroups.find((group) => group.minIncome >= 75000 && group.minIncome <= 100000)
                  break
                case GraphQL.IncomeBrackets.Under_10000:
                default:
                  incomeGroup = incomeGroups.find((group) => group.minIncome < 10000)
                  break
              }

              // Make sure we have a group
              if (incomeGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (incomeGroup.value * 100).toFixed(1) }% ${ ReadableIncome[audienceIncomeRange] }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { ethnicityGroups } = data.audienceDemographics.demographics
            const { audienceEthnicity } = audienceData

            // Check to see if there is a value
            if (audienceEthnicity) {
              // Find the ethnicity group
              let ethnicityGroup
              switch (audienceEthnicity) {
                case GraphQL.Ethnicity.AfricanAmerican:
                  ethnicityGroup = ethnicityGroups.find((group) => group.name.toLowerCase().includes("african"))
                  break
                case GraphQL.Ethnicity.AsianPacificIslander:
                  ethnicityGroup = ethnicityGroups.find((group) => group.name.toLowerCase().includes("asian"))
                  break
                case GraphQL.Ethnicity.HispanicLatino:
                  ethnicityGroup = ethnicityGroups.find((group) => group.name.toLowerCase().includes("hispanic"))
                  break
                case GraphQL.Ethnicity.WhiteCaucasian:
                default:
                  ethnicityGroup = ethnicityGroups.find((group) => group.name.toLowerCase().includes("white"))
                  break
              }

              // Check to make sure group was found
              if (ethnicityGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (ethnicityGroup.value * 100).toFixed(1) }% ${ ReadableEthnicity[audienceEthnicity] }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.RELIGIONS: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { religionGroups } = data.audienceDemographics.demographics
            const { audienceReligion } = audienceData

            // Make sure we have a religion
            if (audienceReligion) {
              // Set group
              let religionGroup
              switch (audienceReligion) {
                case GraphQL.Religion.Christian:
                  religionGroup = religionGroups.find((group) => group.name.toLowerCase().includes("christian"))
                  break
                case GraphQL.Religion.Jewish:
                  religionGroup = religionGroups.find((group) => group.name.toLowerCase().includes("jew"))
                  break
                case GraphQL.Religion.Muslim:
                default:
                  religionGroup = religionGroups.find((group) => group.name.toLowerCase().includes("muslim"))
                  break
              }

              // Make sure group was found
              if (religionGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (religionGroup.value * 100).toFixed(1) }% ${ ReadableReligion[audienceReligion] }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.COUNTRIES: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { countryGroups } = data.audienceDemographics.demographics
            const { audienceCountry } = audienceData

            // Check to make sure there is a value
            if (audienceCountry.length > 0) {
              const countryLocation = audienceCountry[0]
              const countryGroup = countryGroups
                .find((group) => group.locationCode === countryLocation.code.slice(1))

              // Make sure group was found
              if (countryGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (countryGroup.value * 100).toFixed(1) }% ${ countryGroup.name }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.STATES: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { stateGroups } = data.audienceDemographics.demographics
            const { audienceState } = audienceData

            // Check to make sure there is a value
            if (audienceState.length > 0) {
              const stateLocation = audienceState[0]
              const stateGroup = stateGroups
                .find((group) => group.locationCode === stateLocation.code.slice(1))

              // Make sure group was found
              if (stateGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (stateGroup.value * 100).toFixed(1) }% ${ stateGroup.name }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.CITIES: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { cityGroups } = data.audienceDemographics.demographics
            const { audienceCity } = audienceData

            // Check to make sure there is a value
            if (audienceCity.length > 0) {
              const cityLocation = audienceCity[0]
              const cityGroup = cityGroups
                .find((group) => group.name.toLowerCase().includes(cityLocation.name.toLowerCase()))

              // Make sure group was found
              if (cityGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (cityGroup.value * 100).toFixed(1) }% ${ cityGroup.name }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { occupationGroups } = data.audienceDemographics.demographics
            const { audienceOccupation } = audienceData

            // Make sure we have toggle data
            if (audienceOccupation.length > 0) {
              const occupation = audienceOccupation[0]
              const occupationGroup = occupationGroups
                .find((group) => group.name.toLowerCase() === occupation.name.toLowerCase())

              // Make sure group was found
              if (occupationGroup) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (occupationGroup.value * 100).toFixed(1) }% ${ occupationGroup.name }`,
                })
              }
            }
          }
          break
        }
        case HIGHLIGHTS_AUDIENCE_DISPLAY_TOGGLES.AFFINITIES: {
          if (data.audienceDemographics?.demographics && audienceData) {
            const { audienceAffinity } = audienceData

            // Check to see if affinity
            if (audienceAffinity.length > 0) {
              const affinity = audienceAffinity[0]
              let affinityGroup
              let type: GraphQL.AffinityGroupType | undefined
              // eslint-disable-next-line no-restricted-syntax, guard-for-in
              for (const affinityType in GraphQL.AffinityGroupType) {
                let affinityGroups
                switch (affinityType) {
                  case GraphQL.AffinityGroupType.Brands:
                    type = GraphQL.AffinityGroupType.Brands
                    affinityGroups = data.audienceDemographics?.demographics.brandAffinities
                    break
                  case GraphQL.AffinityGroupType.Hashtags:
                    type = GraphQL.AffinityGroupType.Hashtags
                    affinityGroups = data.audienceDemographics?.demographics.hashtagAffinities
                    break
                  case GraphQL.AffinityGroupType.Influences:
                    type = GraphQL.AffinityGroupType.Influences
                    affinityGroups = data.audienceDemographics?.demographics.influenceAffinities
                    break
                  case GraphQL.AffinityGroupType.Interests:
                    type = GraphQL.AffinityGroupType.Interests
                    affinityGroups = data.audienceDemographics?.demographics.interestAffinities
                    break
                  case GraphQL.AffinityGroupType.Media:
                    type = GraphQL.AffinityGroupType.Media
                    affinityGroups = data.audienceDemographics?.demographics.mediaAffinities
                    break
                  case GraphQL.AffinityGroupType.Music:
                    type = GraphQL.AffinityGroupType.Music
                    affinityGroups = data.audienceDemographics?.demographics.musicAffinities
                    break
                  case GraphQL.AffinityGroupType.Sports:
                  default:
                    type = GraphQL.AffinityGroupType.Sports
                    affinityGroups = data.audienceDemographics?.demographics.sportAffinities
                    break
                }

                // Find the group
                affinityGroup = (affinityGroups)
                  ? affinityGroups.find((group) => group.name.toLowerCase() === affinity.name.toLowerCase())
                  : undefined
                if (affinityGroup) break
              }

              // Make sure the group was found
              if (affinityGroup && type) {
                ribbonMetrics.push({
                  label: metric.label,
                  value: `${ (affinityGroup.value * 100).toFixed(1) }% ${ affinity.name } ${ type }`,
                })
              }
            }
          }
          break
        }
        default:
          break
      }
    }
  })
  return ribbonMetrics
}

/**
 * getDisplayName: Retrieves the name of the switch to display on the UI
 * @param code The toggle code or name
 * @param displayNames The list of names for the group of toggles
 * @returns The display name string
 */
export const getDisplayName = (code: string, displayNames: { code: string, displayName: string }[]) => {
  const name = displayNames.find((dname) => dname.code === code)
  if (name) return name.displayName
  return "Not found!"
}

/**
 * isMemberOfGroup: Checks to see if the option or toggle is member of a widget that represents a group
 * of toggles
 * @param option The option you are checking
 * @returns True if the option is a member of a group
 */
export const isMemberOfGroup = (option: GraphQL.SuggestionListToggleInput): boolean => {
  // Check the code
  switch (option.name) {
    case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
    case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
    case AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL:
    case AUDIENCE_DISPLAY_TOGGLES.COUNTRIES:
    case AUDIENCE_DISPLAY_TOGGLES.STATES:
    case AUDIENCE_DISPLAY_TOGGLES.CITIES:
    case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
    case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
    case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
    case AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES:
      return true
    default:
      return false
  }
}

/**
 * getGroupName: Retrieves the name of the group
 * @param option The option you are checking
 * @returns The name of the group
 */
export const getGroupName = (option: GraphQL.SuggestionListToggleInput): string => {
  // Check the code
  switch (option.name) {
    case AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE:
    case AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE:
    case AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL:
      return "Basic"
    case AUDIENCE_DISPLAY_TOGGLES.COUNTRIES:
    case AUDIENCE_DISPLAY_TOGGLES.STATES:
    case AUDIENCE_DISPLAY_TOGGLES.CITIES:
      return "Location"
    case AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS:
    case AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES:
    case AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS:
    case AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES:
      return "Vocation"
    default:
      return ""
  }
}
