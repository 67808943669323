import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import DeliverableFeedbackCard from "./FeedbackCard"
import Input from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import { DeliverableMediaFragment } from "../../graphql"
import { isSuccess } from "../../util/apiClient"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  addFeedback,
  setDeliverable,
  setEdited,
  setMediaFeedback,
} from "../../state/campaignDeliverableContentModalSlice"

import "./media-feedback.sass"

interface MediaFeedbackProps {
  deliverableMedia: DeliverableMediaFragment
}

function MediaFeedback({ deliverableMedia }: MediaFeedbackProps): React.JSX.Element {
  const [ loading, setLoading ] = useState(false)
  const [ newMessage, setNewMessage ] = useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const { deliverable } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const dispatch = useDispatch()

  const closeCard = useCallback(() => {
    dispatch(setEdited(true))
    dispatch(setMediaFeedback({ media: undefined }))
  }, [])

  const createFeedback = useCallback(async () => {
    if (!deliverable) return
    setLoading(true)
    const updatedDeliverable = await dispatch(addFeedback({
      deliverableId: deliverable.id,
      deliverableItemId: deliverableMedia.id,
      text: newMessage,
    }))
    setLoading(false)
    if (!isSuccess(updatedDeliverable)) return

    if (updatedDeliverable.payload && updatedDeliverable.payload.createDeliverableItemFeedback) {
      dispatch(setDeliverable({ deliverable: updatedDeliverable.payload.createDeliverableItemFeedback }))
      const mediaFound = updatedDeliverable
        .payload
        .createDeliverableItemFeedback
        .deliverableMedia
        .find((media) => media.id === deliverableMedia.id)
      if (mediaFound) {
        dispatch(setEdited(true))
        dispatch(setMediaFeedback({ media: mediaFound }))
      }
    }

    setNewMessage("")
  }, [ newMessage, deliverable ])

  return (
    <div className="cp_media-feedback_component">
      { deliverableMedia.feedback.map((feedback) => <DeliverableFeedbackCard feedback={ feedback } />) }
      <div className="cp_media-feedback_component-new-message">
        <Input
          fullWidth={ true }
          placeholder={ translate("Add your feedback below to message our team...") }
          label={ translate("Feedback") }
          value={ newMessage }
          onChange={ (e) => setNewMessage(e.target.value) }
          multiline={ true }
        />
        <div className="cp_media-feedback_component-new-message-controls">
          <Button
            label={ loading ? <LoadingIndicator /> : translate("Send") }
            disabled={ loading }
            onClick={ createFeedback }
          />
          <Button
            isPrimary={ false }
            disabled={ loading }
            onClick={ closeCard }
            label={ translate("CANCEL") }
          />
        </div>
      </div>
    </div>
  )
}

export default MediaFeedback
