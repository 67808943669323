import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Deliverable Content Modal Slice Interface and Initial State
export interface CampaignDeliverableMetricModal {
  deliverable?: GraphQL.DeliverableFragment
  modalOpen: boolean
  source:string
  updateCallback?: (edited: boolean) => Promise<void>
}

const initialState: CampaignDeliverableMetricModal = {
  deliverable: undefined,
  modalOpen: false,
  source: "",
}

// Deliverable Content Modal Slice
export const CampaignDeliverableMetricModalSlice = createSlice({
  name: "CampaignDeliverableMetricModalSlice",
  initialState,
  reducers: {
    openDeliverableMetricModal: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
        source:string,
        updateCallback?: (edited: boolean) => Promise<void>
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
      updateCallback: action.payload.updateCallback,
      source: action.payload.source,
      modalOpen: true,
    }),
    setDeliverable: (
      state,
      action: PayloadAction<{
        deliverable: GraphQL.DeliverableFragment,
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
    }),
    setModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      modalOpen: action.payload,
    }),
    closeDeliverableMetricModal: (
      state,
    ) => ({
      ...state,
      deliverable: undefined,
      updateCallback: undefined,
      modalOpen: false,
      source: "",
    }),
  },
})

export const {
  openDeliverableMetricModal,
  setModalOpen,
  setDeliverable,
  closeDeliverableMetricModal,
} = CampaignDeliverableMetricModalSlice.actions
export default CampaignDeliverableMetricModalSlice.reducer

export const updateDeliverableMetrics = (
  params: {
    variables: GraphQL.UpdateDeliverableMetricsMutationVariables,
    onSuccess: () => any,
    onError: () => any
  },
) => async () => {
  const result = await API.updateDeliverableMetrics(params.variables)
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}
