import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import {
  GridColDef,
  getGridSingleSelectOperators,
  getGridBooleanOperators,
  getGridStringOperators,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid"
import { useSearchParams } from "react-router-dom"
import DataGrid from "../../../DataGrid"
import EmptySplash from "../../../EmptySplash"
import EntityInfoRow from "../../../EntityInfoRow"
import { shorthandNumber } from "../../../../util/miscHelper"
import Pill from "../../../Pill"
import Avatar from "../../../Avatar"
import * as GraphQL from "../../../../graphql"
import ContextMenu from "../CampaignDeliverableRowContextMenuV2"
import "./recent-deliverables-table.sass"
import { Scope, Success } from "../../../../util/types"
import { useSelector, useDispatch } from "../../../../state/hooks"
import { openDeliverableContentModal } from "../../../../state/campaignDeliverableContentModalV2Slice"
import StatusDeliverableV2 from "../../../StatusDeliverable/StatusDeliverableV2"
import HoverableCopyableText from "./HoverableCopyableText"
import Tooltip from "../../../Tooltip"
import { Network } from "../../../../graphql"

type Props = {
  campaign: Success<GraphQL.CampaignQuery>
}
const statusOrder = {
  [GraphQL.EnhancedDeliverableStatus.Draft]: 0,
  [GraphQL.EnhancedDeliverableStatus.PendingFeedback]: 1,
  [GraphQL.EnhancedDeliverableStatus.PendingRevision]: 2,
  [GraphQL.EnhancedDeliverableStatus.Finalized]: 3,
  [GraphQL.EnhancedDeliverableStatus.Uploaded]: 4,
  [GraphQL.EnhancedDeliverableStatus.Live]: 5,
}

export default function RecentDeliverablesTable({ campaign }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.RecentDeliverablesTable" })
  const { scopes } = useSelector(({ user }) => user)
  const { modalOpen } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const { deliverableSummaryList } = useSelector(({ campaignV2 }) => campaignV2.campaignDetails)

  const NoDeliverablesOverlay = useCallback(() => (
    <EmptySplash bodyText={ translate("No Recent Deliverables") } />
  ), [ translate ])

  // Note: Handles when the user direct links to an opened deliverable
  React.useEffect(() => {
    if (!searchParams.has("deliverableId") || modalOpen) return
    dispatch(openDeliverableContentModal())
  }, [ searchParams, modalOpen ])

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "account",
      headerName: translate("Account"),
      sortable: true,
      filterable: true,
      align: "left",
      alignHeader: "center",
      headerClassName: "account-header",
      filterOperators: getGridStringOperators(),
      valueGetter(params) {
        return params.row.account
      },
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          avatarSrc={ params.row.profilePictureUrl }
          network={ params.row.network }
          avatarSize="md"
          name={ `@${ params.row.account }` }
          subInfo={ `${ shorthandNumber(params.row.followers) } ${ translate("FOLLOWERS") }` }
        />
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "network",
      headerName: translate("Network"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        {
          label: translate("Facebook"),
          value: GraphQL.Network.Facebook,
        },
        {
          label: translate("Instagram"),
          value: GraphQL.Network.Instagram,
        },
        {
          label: translate("Snapchat"),
          value: GraphQL.Network.Snapchat,
        },
        {
          label: translate("TikTok"),
          value: GraphQL.Network.Tiktok,
        },
        {
          label: translate("Youtube"),
          value: GraphQL.Network.Youtube,
        },
      ],
      filterOperators: getGridSingleSelectOperators(),
      valueGetter(params) {
        return params.row.network
      },
      renderCell: (params) => (
        params.row.network
      ),
      resizable: false,
      flex: 1,
      maxWidth: 350,
    },
    {
      field: "title",
      headerName: translate("Title"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      type: "string",
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.name
      },
      renderCell: (params) => (
        <div className="table-title">
          <p className="table-title-content">
            { params.row.name }
          </p>
        </div>
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "caption",
      headerName: translate("Post Caption"),
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        const { caption } = params.row
        if (!caption || typeof caption !== "string") return null

        return (
          <HoverableCopyableText
            text={ caption }
            containerClass="table-caption"
            contentClass="table-caption-content"
          />
        )
      },
      flex: 0.7,
      maxWidth: 250,
      align: "left",
    },
    {
      field: "media",
      headerName: translate("Media"),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const media = params.row.media || undefined
        if (!media) return null
        return (
          <Avatar
            className="table-media"
            variant="rounded"
            src={ media }
          />
        )
      },
      flex: 0.7,
      maxWidth: 120,
      align: "left",
    },
    {
      field: "reviewerGroupName",
      headerName: translate("Review Group"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.reviewerGroupName
      },
      renderCell: (params) => (
        <Tooltip
          classes={ { tooltip: "cp-tooltip cp_campaign-recent-deliverables-table-tooltip" } }
          title={ params.row.reviewerGroupName || "" }
          placement="top"
          arrow={ true }
          disableHoverListener={ !params.row.reviewerGroupName }
        >
          <p className="table-review-group-content">
            { params.row.reviewerGroupName
          }
          </p>
        </Tooltip>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "reviewerGroupStatus",
      headerName: translate("Review Group Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.reviewerGroupStatus
      },
      renderCell: (params) => (
        <p className="table-review-group-status-content">
          { params.row.reviewerGroupName && params.row.reviewerGroupStatus ? translate(params.row.reviewerGroupStatus) : ""
      }
        </p>
      ),
      flex: 1,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "feedback",
      headerName: translate("Feedback"),
      sortable: true,
      filterable: true,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.feedback || 0
      },
      disableColumnMenu: false,
      renderCell: (params) => (
        <Pill label={ Math.round(params.row.feedback) } />
      ),
      flex: 0.7,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "status",
      headerName: translate("Status"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      type: "singleSelect",
      valueOptions: [
        GraphQL.EnhancedDeliverableStatus.Draft,
        GraphQL.EnhancedDeliverableStatus.PendingFeedback,
        GraphQL.EnhancedDeliverableStatus.PendingRevision,
        GraphQL.EnhancedDeliverableStatus.Finalized,
        GraphQL.EnhancedDeliverableStatus.Live,
        GraphQL.EnhancedDeliverableStatus.Uploaded,
      ],
      sortComparator: (
        v1: GraphQL.EnhancedDeliverableStatus,
        v2: GraphQL.EnhancedDeliverableStatus,
      ) => statusOrder[v1] - statusOrder[v2],
      filterOperators: getGridSingleSelectOperators(),
      renderCell: (params) => (
        <StatusDeliverableV2
          deliverableStatus={ params.row.status }
        />
      ),
      flex: 1,
      maxWidth: 150,
      align: "left",
    },
    {
      field: "tiktok",
      headerName: translate("TCM Campaign"),
      sortable: true,
      filterable: true,
      disableColumnMenu: false,
      filterOperators: getGridBooleanOperators(),
      sortComparator: gridStringOrNumberComparator,
      valueGetter(params) {
        return params.row.ttcmCampaignName || translate("NOT LINKED")
      },
      renderCell: (params) => (
        <Tooltip
          classes={ { tooltip: "cp-tooltip cp_campaign-recent-deliverables-table-tooltip" } }
          title={ params.row.tiktok || "" }
          placement="top"
          arrow={ true }
          disableHoverListener={ params.row.network !== Network.Tiktok }
        >
          <p className="table-ttcm-content">
            { params.row.network === Network.Tiktok
              ? (params.row.tiktok || translate("NOT LINKED"))
              : ""
          }
          </p>
        </Tooltip>
      ),
      flex: 0.7,
      maxWidth: 170,
      align: "left",
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="column-context">
          <ContextMenu deliverableSummary={ params.row.deliverable } />
        </div>
      ),
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      maxWidth: 60,
    },
  ], [ translate ])

  const topSixDeliverables = deliverableSummaryList.slice(0, 5)

  const filteredColumns = React.useMemo(() => {
    let filteredCols = COLUMNS

    if (
      !campaign.payload.campaign.enableTiktok
      || !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) filteredCols = filteredCols.filter((column) => column.field !== "tiktok")

    if (
      !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) filteredCols = filteredCols.filter((column) => column.field !== "feedback")

    return filteredCols
  }, [ campaign, scopes ])

  return (
    <DataGrid
      className="cp_campaign-recent-deliverables-table"
      columnVisibilityModel={ {
        network: false,
      } }
      getRowId={ (row) => row.id }
      disableRowSelectionOnClick={ true }
      rowHeight={ 90 }
      columnHeaderHeight={ 40 }
      columns={ filteredColumns }
      disableColumnReorder={ true }
      hideFooter={ true }
      rows={ topSixDeliverables.map(({
        id,
        caption,
        name,
        mediaUrl,
        socialAccount,
        ttcmCampaignName,
        deliverableStatus,
        feedbackCount,
        reviewerGroupName,
        reviewerGroupStatus,
      }, i) => ({
        account: socialAccount.username,
        profilePictureUrl: socialAccount.profilePictureUrl,
        followers: socialAccount.followerCount,
        network: socialAccount.networkType,
        id,
        name,
        caption: caption || "",
        media: mediaUrl,
        status: deliverableStatus,
        deliverable: topSixDeliverables[i],
        feedback: feedbackCount,
        tiktok: ttcmCampaignName,
        reviewerGroupName,
        reviewerGroupStatus,
      })) }
      slots={ {
        noResultsOverlay: NoDeliverablesOverlay,
        noRowsOverlay: NoDeliverablesOverlay,
      } }
      pinnedColumns={
          {
            left: [ "account" ],
            right: [ "ellipsisMenu" ],
          }
        }
      onRowClick={ (params) => {
        setSearchParams({ deliverableId: params.row.deliverable.id })
        dispatch(openDeliverableContentModal())
      } }
    />
  )
}
