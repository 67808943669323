import React from "react"
import { TFunction } from "i18next"
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"
import moment from "moment"

import * as Constant from "../../util/constant"
import * as MiscHelper from "../../util/miscHelper"
import * as SearchHelper from "../../util/searchHelper"
import ContentEllipsisMenuCell, { SearchContentEllipsisMenuOptions } from "./ContentEllipsisMenuCell"
import MediaCell from "./MediaCell"
import PostDetailsCell from "./PostDetailsCell"
import PostTypeComponent from "../PostType"
import SocialAvatar from "../SocialAvatar"
import {
  Network,
  SearchPostsTableRowFragment,
  PostType,
} from "../../graphql/search"

export function generateContentResultsColumns(
  translate: TFunction,
  contextMenu: SearchContentEllipsisMenuOptions,
): GridColDef[] {
  return [
    {
      field: SearchHelper.SearchColumn.Account,
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 300,
    },
    // {
    //   field: SearchHelper.SearchColumn.Score,
    //   headerName: translate("Rel. Score"),
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Pill content={ params.row.score } color="primary" />
    //   ),
    //   disableColumnMenu: true,
    //   resizable: false,
    //   width: 100,
    // },
    {
      field: SearchHelper.SearchColumn.Followers,
      description: translate("Tooltip Followers"),
      headerName: translate("Followers"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.followers }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.PostDate,
      headerName: translate("Post Date"),
      sortable: true,
      valueGetter: (params) => params.row.unixPostDate,
      renderCell: (params) => (
        <p>{ params.row.postDate }</p>
      ),
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: SearchHelper.SearchColumn.PostType,
      description: translate("Tooltip Post Type"),
      headerName: translate("Post Type"),
      sortable: false,
      renderCell: (params) => (
        <div className="cp_component_search-results-post-details-cell">
          <PostTypeComponent
            isReel={ params.row.postType.isReel }
            isStory={ params.row.postType.isStory }
            network={ params.row.account.network }
            isShort={ params.row.postType.isShort }
            isHighlighted={ params.row.postType.isHighlighted }
            showInFeedPill={ true }
          />
        </div>
      ),
      disableColumnMenu: true,
      width: 135,
    },
    {
      field: SearchHelper.SearchColumn.Media,
      headerName: translate("Media"),
      sortable: false,
      renderCell: (params) => (
        // NOTE: Media cell uses data from other columns for Post Details modal
        <MediaCell
          media={ params.row.media.postMedia }
          socialAvatarProps={ params.row.account }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
    },
    {
      field: SearchHelper.SearchColumn.PostDetails,
      headerName: translate("Post Details"),
      sortable: false,
      renderCell: (params) => (
        <PostDetailsCell { ...params.row.postDetails } />
      ),
      disableColumnMenu: true,
      width: 360,
    },
    {
      field: SearchHelper.SearchColumn.EngagementRate,
      description: translate("Tooltip Engagement Rate"),
      headerName: translate("Engagement Rate"),
      sortable: true,
      valueGetter: (params) => params.row.rawEngagementRate,
      renderCell: (params) => {
        const { isStory } = params.row.postType
        const { network } = params.row.account
        if (isStory && network === Network.Instagram) {
          return (
            <p className="inactive">--</p>
          )
        }
        return (
          <p>{ params.row.engagementRate }</p>
        )
      },
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: SearchHelper.SearchColumn.EllipsisMenu,
      headerName: "", // This column should be pinned and doesn't need a header
      sortable: false,
      renderCell: (params) => (
        <ContentEllipsisMenuCell
          hasPersonality={ params.row.account.hasPersonality }
          network={ params.row.account.network }
          postId={ params.row.id }
          socialAcctId={ params.row.account.id }
          sponsored={ params.row.ellipsisMenu }
          showOptions={ contextMenu }
          score={ params.row.score }
          account={ params.row.account }
          postDetailsProps={ {
            engagementRate: params.row.engagementRate,
            isReel: params.row.postDetails.isReel,
            isStory: params.row.postDetails.isStory,
            media: params.row.media.postMedia,
            permalink: params.row.media.permalink,
            postComments: params.row.postDetails.postComments,
            postContent: params.row.postDetails.postContent,
            postContentHighlighted: params.row.postDetails.postContentHighlighted,
            postDate: params.row.postDetails.postDate,
            postExits: params.row.postDetails.postExits,
            postImpressions: params.row.postDetails.postImpressions,
            postLikes: params.row.postDetails.postLikes,
            postPlays: params.row.postDetails.postPlays,
            postReach: params.row.postDetails.postReach,
            postReachEstimated: params.row.postDetails.postReachEstimated,
            postSaves: params.row.postDetails.postSaves,
            postShares: params.row.postDetails.postShares,
            postScreenshots: params.row.postDetails.postScreenshots,
            postHighlighted: params.row.postDetails.postHighlighted,
            postSaved: params.row.postDetails.postSaved,
            postSwipeUps: params.row.postDetails.postSwipeUps,
            postTapsBackward: params.row.postDetails.postTapsBackward,
            postTapsForward: params.row.postDetails.postTapsForward,
            postViews: params.row.postDetails.postViews,
            socialAvatarProps: params.row.account,
          } }
        />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]
}

export function generateContentResultsRow(
  row: SearchPostsTableRowFragment,
  translateCommon: TFunction,
  boldedKeywords: string[] = [],
): GridValidRowModel | null {
  return {
    id: row.id,
    score: row.score,
    account: {
      id: row.networkAccountId,
      followers: row.followers,
      fullName: "",
      isBlacklisted: false,
      isNSFW: row.isNsfw,
      isPlaceholder: false,
      isUnsubscribed: false,
      lastPostedDate: "",
      network: row.network,
      profilePictureUrl: row.avatarUrl,
      username: row.username,
      hasPersonality: false,
      oauthed: false,
    },
    postDate: moment.unix(row.postedTime)
      .format(Constant.LONGFORM_DATE_TIME),
    unixPostDate: moment.unix(row.postedTime),
    followers: translateCommon("intlNumber", { val: row.followers }),
    postType: {
      isReel: row.postType === PostType.Reel,
      isStory: row.postType === PostType.Story,
      isShort: row.postType === PostType.Short,
      isHighlighted: false,
    },
    media: {
      permalink: row.permalink,
      postMedia: row.postMedia,
    },
    postDetails: {
      isReel: row.postType === PostType.Reel,
      isStory: row.postType === PostType.Story,
      network: row.network,
      postComments: row.postComments,
      postContent: row.postContent,
      postContentHighlighted: false,
      postDate: row.postedTime,
      postExits: 0,
      postImpressions: 0,
      postLikes: row.postLikes,
      postPlays: 0,
      postReach: 0,
      postReachEstimated: 0,
      postSaves: 0,
      postShares: row.postShares,
      postTapsBackward: 0,
      postTapsForward: 0,
      postScreenshots: 0,
      postHighlighted: false,
      postSaved: false,
      postSwipeUps: 0,
      postViews: 0,
      boldedKeywords,
    },
    rawEngagementRate: row.engagementRate,
    // eslint-disable-next-line max-len
    engagementRate: `${ row.engagementRate >= 1 ? 100 : MiscHelper.prettyPrintDecimal(row.engagementRate) }%${ row.engagementRate >= 1 ? "+" : "" }`,
    ellipsisMenu: row.sponsored,
  }
}
