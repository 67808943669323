import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Campaign, Check } from "@mui/icons-material"
import { Badge, Tooltip } from "@mui/material"

import * as API from "../../../util/apiClient"
import * as GraphQL from "../../../graphql"
import IconButton from "../../IconButton"
import LoadingIndicator from "../../LoadingIndicator"
import PublicModalScoreBreakDown from "../../PublicModalScoreBreakDown"
import NetworkAvatar from "../../NetworkAvatar"
import Pill from "../../Pill"
import Tags from "../../ProfileOverview/Tags"
import { ScoreBreakDown } from "../../../util/types"
import { SuggestionListMode } from "../../../graphql"
import { rawDataToScoreBreakDownForPublicList, resetScoreBreakDown } from "../../../state/scoreBreakDownSlice"
import { scoreModalTypes } from "../../../util/constant"
import { setLeftPanelStatus, setRightPanelStatus } from "../../../state/publicListSlice"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  formatPercent,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../../util/miscHelper"

import "./public-list-account-header.sass"

export default function PublicListAccountHeader() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListAccountHeader" })
  const dispatch = useDispatch()
  const {
    list, loadedListSocialAccount, rightPanelStatus,
  } = useSelector(({ publicList }) => publicList)

  const { listCode } = useParams()

  const [ engagementScoreModal, setEngagementScoreModal ] = useState(false)

  useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null) || !listCode) return
    dispatch(rawDataToScoreBreakDownForPublicList(scoreBreakDown, modalType, listCode))
    setEngagementScoreModal(true)
  }

  if (loadedListSocialAccount === "init" || loadedListSocialAccount === "loading" || list === "loading" || list === "init") {
    return <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
  }

  if (API.isError(list) || API.isError(loadedListSocialAccount)) {
    return <p>There was an error. Please try again!</p>
  }

  const {
    age: listSocialAccountAge,
    avatar: listSocialAccountAvatar,
    location: listSocialAccountLocation,
    bio: listSocialAccountBio,
    gender: listSocialAccountGender,
    name: listSocialAccountName,
    verticals: listSocialAccountVerticals,
    inDemo,
    socialAccount: {
      networkUrl,
      userName,
      network,
      socialAccountStatistics: {
        followers,
      },
      profilePictureUrl,
      ttcmEngagementRate,
    },
    score,
    suggestionList: {
      verticals,
    },
    approvalStatus,
    comments,
  } = loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId

  const {
    suggestionListMode, toggles, epsilonVerified, audienceTypeNotes,
  } = list.payload.publicSuggestionListByCode
  const fullSocialAccount = loadedListSocialAccount
    .payload
    .publicSuggestionListSocialAccountByListCodeSocialAccountId
    .socialAccount

  const subInfo = []
  if (listSocialAccountLocation) subInfo.push(listSocialAccountLocation)
  if (listSocialAccountAge) subInfo.push(listSocialAccountAge)
  if (listSocialAccountGender) subInfo.push(listSocialAccountGender)

  const approveButtonClass = [
    "approve-button",
    approvalStatus?.value ? "approved" : "",
  ].join(" ")

  const hasEngagmentRate = toggles.some((toggle) => toggle.name === "ToggleEngagementRate")
  const hasTTCMToggleOn = toggles.some((toggle) => toggle.name === "ToggleTTCMEngagementRate")
  const displayVerticals = listSocialAccountVerticals.length > 0 || verticals.length > 0
  const displayedVerticals = listSocialAccountVerticals.length > 0 ? listSocialAccountVerticals : verticals

  const getEngagementRate = (components: GraphQL.ScoreComponent[]) => {
    const engagementComponent = components.find((component) => component.display === "Engagement Rate")
    return engagementComponent ? formatPercent(2, engagementComponent.raw) : 0
  }
  let activeScore: null | string | number = null
  let scoreLabel = translate("SCORE")
  const nativeAudience = translate("NATIVE AUDIENCE")

  if (score) {
    if (hasEngagmentRate) {
      activeScore = getEngagementRate(score?.components)
      scoreLabel = translate("ENG. RATE")
    } else if (hasTTCMToggleOn) {
      activeScore = formatPercent(2, ttcmEngagementRate || 0)
      scoreLabel = translate("TTCM ENG. RATE")
    } else {
      activeScore = Math.round(score?.value || 0)
    }
  }

  return (
    <div className="cp_profile_component-list-tabs-account-header">
      <div className="cp_profile_component-list-tabs-account-header-main">
        <div className="cp_profile_component-list-tabs-account-header-main-container">
          <NetworkAvatar size="xl" network={ network } src={ listSocialAccountAvatar?.url.address || profilePictureUrl } />
          <div className="cp_profile_component-list-tabs-account-header-main-container-info">
            <a href={ networkUrl || "" } target="_blank" rel="noreferrer">
              <h2>{ userName }</h2>
            </a>
            { listSocialAccountName && <h4>{ listSocialAccountName }</h4> }
            { subInfo.length > 0 && <h5>{ subInfo.join(" | ") }</h5> }
            <p className="cp_profile_component-list-tabs-account-header-main-container-info-audience-type-notes">
              { audienceTypeNotes }
            </p>
            <div className="cp_profile_component-list-tabs-account-header-main-container-info-scores">
              <div>
                <p className="label_small-caps-semibold">
                  { translate("FOLLOWERS") }
                </p>
                <Pill label={ shorthandNumber(followers) } color="warning" />
              </div>
              { inDemo !== null && (
              <div>
                <p className="label_small-caps-semibold">
                  { translate("In-demo") }
                </p>
                <Pill label={ `${ prettyPrintDecimal(inDemo) }%` } color="info" />
              </div>
              ) }
              { score && (
              <div>
                <p className="label_small-caps-semibold">
                  { scoreLabel }
                </p>
                <Pill
                  label={ activeScore }
                  handleClick={
                  (fullSocialAccount && !hasTTCMToggleOn) ? () => {
                    const scoreBreakDown = {
                    // NOTE: this component uses only a fragment of socialAccount and score
                      socialAccount: fullSocialAccount as GraphQL.SocialAccount,
                      scores: [ score as GraphQL.Score ],
                    }
                    handleEngagmentScoreModal(scoreBreakDown, scoreModalTypes.ENGAGEMENT)
                  }
                    : () => {}
               }
                />
              </div>
              ) }
              { epsilonVerified && (
              <div>
                <p className="label_small-caps-semibold">
                  { nativeAudience }
                </p>
                <Tooltip
                  title={ translate("Stat. Sig. Native Audience") }
                  placement="bottom-start"
                  arrow={ true }
                  classes={ { tooltip: "cp_component_public-list-overview-table-tooltip" } }
                >
                  <div
                    className="cp_component_public-list-overview-table-e-verfied-badge"
                  />
                </Tooltip>
              </div>
              ) }
            </div>
          </div>
        </div>
        { suggestionListMode === SuggestionListMode.Campaign
      && (
        <div className="cp_profile_component-list-tabs-account-header-main-buttons">
          <Tooltip title={ approvalStatus?.value ? translate("Approved") : translate("Approve") }>
            <div>
              <IconButton
                className={ approveButtonClass }
                variant={ approvalStatus?.value || rightPanelStatus === "approve" ? "filled" : "outlined" }
                onClick={ () => {
                  if (!approvalStatus?.value) {
                    dispatch(setLeftPanelStatus("closed"))
                    dispatch(setRightPanelStatus("approve"))
                  }
                } }
              >
                <Check />
              </IconButton>
            </div>
          </Tooltip>
          <Badge color="primary" className="feedback-badge" badgeContent={ comments.length } showZero={ true }>
            <Tooltip title={ translate("Leave Feedback") }>
              <div>
                <IconButton
                  className="feedback-button"
                  variant={ rightPanelStatus === "feedback" ? "filled" : "outlined" }
                  onClick={ () => {
                    dispatch(setLeftPanelStatus("closed"))
                    dispatch(setRightPanelStatus("feedback"))
                  } }
                >
                  <Campaign />
                </IconButton>
              </div>
            </Tooltip>
          </Badge>
        </div>
      ) }
      </div>
      { listSocialAccountBio && (
      <div className="cp_profile_component-list-tabs-account-header-sub">
        <p className="cp_profile_component-list-tabs-account-header-sub-bio-title">
          { translate("BIOGRAPHY") }
        </p>
        <p className="cp_profile_component-list-tabs-account-header-sub-bio">
          { listSocialAccountBio }
        </p>
        { displayVerticals && (
        <div className="cp_profile_component-list-tabs-account-header-sub-tags">
          <Tags verticals={ displayedVerticals } color="success" />
        </div>
        ) }
      </div>
      ) }
      <PublicModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </div>
  )
}
