import { gql } from "@apollo/client"
import { USER_DELIVERABLE_NOTIFICATION_FRAGMENT } from "../fragments/campaignFragments"

const UPDATE_USER_NOTIFICATIONS = gql`
mutation updateNotifications($input: UserNotificationPreference!){
  updateUserNotifications(input: $input){
    ...UserDeliverableNotifcationFragment
  }
}
  ${ USER_DELIVERABLE_NOTIFICATION_FRAGMENT }
`

export default UPDATE_USER_NOTIFICATIONS
