import React from "react"

import * as GraphQL from "../../../graphql"

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}

export default function ImageAnalysis({ socialProfile }: Props) {
  // Pull traits
  const traits = React.useMemo(() => socialProfile.piTraitScores, [ socialProfile ])

  return (
    <div className="cp_pi-traits_component">
      { (traits.map((trait) => {
        // Get the score percentage
        const percentage = (trait.score.value) ? trait.score.value * 100 : 0

        return (
          <div className="cp_pi-traits_component_container">
            <p className="cp_pi-traits_component_container_description">{ trait.trait.description }</p>
            <div className="cp_pi-traits_component_container_pill">
              <div className="cp_pi-traits_component_container_pill_high">
                <p className="value">{ `${ ((percentage >= 50) ? percentage.toFixed(0) : (100 - percentage).toFixed(0)) }%` }</p>
                <p className="descriptor">{ (percentage >= 50) ? trait.trait.descriptorHigh : trait.trait.descriptorLow }</p>
              </div>
              <div className="cp_pi-traits_component_container_pill_low">
                <p className="value">{ `${ ((percentage < 50) ? percentage.toFixed(0) : (100 - percentage).toFixed(0)) }%` }</p>
                <p className="descriptor">{ (percentage < 50) ? trait.trait.descriptorHigh : trait.trait.descriptorLow }</p>
              </div>
            </div>
          </div>
        )
      })) }
    </div>
  )
}
