import React, { useEffect, useState } from "react"
import {
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material"
import { Search, Close } from "@mui/icons-material"
import Input, { Props as InputProps } from "../../../Input"
import Autocomplete from "../../../Autocomplete"
import "./user-filter-list.sass"
import EntityInfoRow from "../../../EntityInfoRow"

interface Props {
 onUserSelect: (user: User) => void
 users: User[]
 onRemoveUser: (user: User) => void
 isEditUser?: boolean
 selectedUsers: User[]
 addFilteredUsers: (user: User) => void
 selectedUser: User | undefined
}

type User = {
 userId: string
 permission: "FINALIZE" | "APPROVE" | "COMMENT"
 user: UserDetails
}

type UserDetails = {
 fullName: string
 avatarUrl: string
}

function UserFilterList({
  users, onUserSelect, onRemoveUser, isEditUser, selectedUsers, addFilteredUsers, selectedUser,
}: Props) {
  const [ filteredUsers, setFilteredUsers ] = useState(users || [])
  const [ userSearchInput, setUserSearchInput ] = useState("")

  useEffect(() => {
    setFilteredUsers(filterOptions(users, userSearchInput))
  }, [ userSearchInput, users ])

  const handleUserSelect = (user: User) => {
    addFilteredUsers(user)
  }

  const handleUserRemove = (event: React.MouseEvent, user: User) => {
    onRemoveUser(user)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserSearchInput(event.target.value)
  }

  const filterOptions = (options: User[], inputValue: string) => options.filter((option) => option.user.fullName.toLowerCase()
    .includes(inputValue.toLowerCase()))

  const renderAutoCompleteList = () => (
    <Autocomplete
      multiple={ true }
      className="cp_assign-reviewer-modal-auto-complete"
      filterSelectedOptions={ false }
      getOptionLabel={ (o) => typeof o === "string" ? o : `${ o.user.fullName } ${ o.userId }` }
      options={ filteredUsers } // ￼ Dynamically updates based on input
      disableCloseOnSelect={ true }
      forcePopupIcon={ true }
      renderOption={ (props, option, { index }) => (
        <li { ...props }>
          <EntityInfoRow
            onClick={ () => {
              if (!selectedUsers.some((usr) => usr.userId === option.userId)) handleUserSelect(option)
            } }
            key={ `${ option.userId } ${ option.user.fullName }` }
            checkboxEnabled={ true }
            checkboxDisabled={ selectedUsers.some((usr) => usr.userId === option.userId) }
            checkboxValue={ selectedUsers.some((usr) => usr.userId === option.userId) }
            id={ `auto-complete-row-user-${ index }` }
            className="auto-complete-row"
            name={ option.user.fullName }
            avatarSrc={ option.user.avatarUrl }
          />
        </li>
      ) }
      renderTags={ (tagValue) => tagValue.length > 0 ? (
        <Typography>
          { tagValue.length }
          { " " }
          { tagValue.length > 1 ? "users" : "user" }
        </Typography>
      ) : null
    }
      renderInput={ (params) => (
        <Input
          { ...(params as InputProps) }
          className="auto-complete-input"
          value={ userSearchInput }
          onChange={ handleInputChange }
          InputProps={ {
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search className="cp_component_search-bar-search-icon" />
              </InputAdornment>
            ),
          } }
        />
      ) }
      clearOnBlur={ false }
      selectOnFocus={ false }
      id="cp_assign-reviewer-modal-auto-complete-users-input"
      openOnFocus={ true }
      handleHomeEndKeys={ true }
      isOptionEqualToValue={ (option, value) => option.userId === value.userId }
    />
  )

  return (
    <div className="cp_component-user-filter-list">
      <div className="search-container">
        { !isEditUser && renderAutoCompleteList() }
        <div className="selected-users-container">
          { selectedUsers.map((row) => {
            const classNames = [
              "user-row",
              selectedUser && selectedUser.userId === row.userId ? "user-row-selected" : "",
            ].join(" ")
            return (
              <div className={ classNames }>
                <EntityInfoRow
                  onClick={ () => onUserSelect(row) }
                  name={ row.user.fullName }
                  avatarSrc={ row.user.avatarUrl }
                />
                { !isEditUser && (
                <IconButton onClick={ (event) => handleUserRemove(event, row) }>
                  <Close />
                </IconButton>
                ) }
              </div>
            )
          }) }
        </div>
      </div>
    </div>
  )
}

export default UserFilterList
