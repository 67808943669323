import React, { JSX, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Container, Typography } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"
import SlidingPanel from "../../SlidingPanel"
import Avatar from "../../Avatar"
import "./campaignsSlidingPanel.sass"
import SearchBar from "../../SearchBar"
import * as GraphQL from "../../../graphql"
import LoadingIndicator from "../../LoadingIndicator"
import * as API from "../../../util/apiClient"
import { FilterMenuOption } from "../../FilterMenu"
import { RootState, store } from "../../../state/store"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  archiveCampaign,
  deleteCampaign,
  getCampaigns,
  getMoreCampaigns,
} from "../../../state/slidingPanelSlice/campaigns"
import {
  PanelState,
  setCampaignSearchFilter,
  setCampaignSearchInput,
  togglePanel,
} from "../../../state/slidingPanelSlice"
import InfiniteScroll from "../../InfiniteScroll"
import EmptySplash from "../../EmptySplash"
import Tooltip from "../../Tooltip"
import ContextMenuButton from "../../DataGrid/RenderCellComponents/ContextMenuButton"
import MenuItem from "../../MenuItem"
import { openConfirmationModal } from "../../../state/confirmationModalSlice"
import { Scope } from "../../../util/types"

type Props = {
  open: boolean
  onClose: () => void
}

function CampaignsSlidingPanel({
  open,
  onClose,
}: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.NavigationBar" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, scopes } = useSelector(({ user: currentUser }) => currentUser)

  const {
    campaignsContent, campaignsStatus, campaignSearchFilter, campaignSearchInput,
  } = useSelector((root: RootState) => root.slidingPanels)
  const [ page, setPage ] = React.useState<number>(1)

  useEffect(() => {
    setPage(1)
    store.dispatch(getCampaigns(campaignSearchInput, campaignSearchFilter, 1))
  }, [ campaignSearchInput, campaignSearchFilter ])

  const filterOptions: FilterMenuOption[] = [ {
    label: "Filter",
    values: [
      {
        label: translate("Archived"), value: GraphQL.SearchCampaignFilter.Archived, keyId: uuidv4(),
      },
      {
        label: translate("My Campaigns"), value: GraphQL.SearchCampaignFilter.MyCampaigns, keyId: uuidv4(),
      },
      {
        label: translate("TikTok-All"), value: GraphQL.SearchCampaignFilter.TiktokAll, keyId: uuidv4(),
      },
      {
        label: translate("TikTok-Published"), value: GraphQL.SearchCampaignFilter.TiktokPublished, keyId: uuidv4(),
      },
      {
        label: translate("TikTok-Not Published"), value: GraphQL.SearchCampaignFilter.TiktokUnpublished, keyId: uuidv4(),
      },
    ],
  } ]

  const renderContent = () => {
    if (campaignsStatus === "init" || campaignsStatus === "loading") {
      return (
        <div className="cp_component_sliding-panels_loading-indicator-container">
          <LoadingIndicator />
        </div>
      )
    }

    if (API.isSuccess(campaignsStatus) && campaignsContent.length === 0) {
      return (
        <EmptySplash bodyText={ translate("No campaigns found!") } />
      )
    }

    if (API.isSuccess(campaignsStatus) && API.isSuccess(user)) {
      const vanity = user.payload.currentUser?.customer.vanity

      return (
        <InfiniteScroll
          dataLength={ campaignsContent.length }
          next={ () => {
            store.dispatch(getMoreCampaigns(campaignSearchInput, campaignSearchFilter, page + 1))
            setPage((prev) => prev + 1)
          } }
          hasMore={ campaignsStatus.payload.searchCampaign.totalCount > campaignsContent.length }
        >
          { campaignsContent.map((row) => (
            <Container
              key={ `campaign-search-${ row.id }` }
              className="cp_component_navigation-bar-campaign-avatar-container"
              onClick={ () => {
                navigate(`/${ vanity }/campaigns/${ row.id }/overview`)
                dispatch(togglePanel(PanelState.CLOSED))
              } }
            >
              <Avatar
                src={ row.thumbnail?.url.address }
                className="cp_component_navigation-bar-campaigns-avatar"
              >
                { row.name.charAt(0).toUpperCase() }
              </Avatar>
              <Tooltip title={ row.name } placement="top">
                <Typography className="cp_component_navigation-bar-campaign-name">{ row.name }</Typography>
              </Tooltip>
              { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
              <ContextMenuButton
                transformOrigin={ { horizontal: "right", vertical: "top" } }
                anchorOrigin={ { horizontal: "center", vertical: "center" } }
              >
                <MenuItem
                  onClick={ (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    dispatch(openConfirmationModal({
                      title: translate("Delete Campaign"),
                      subtext: translate("Are you sure you want to delete this campaign?"),
                      onConfirm: () => dispatch(deleteCampaign(row.id)),
                    }))
                  } }
                >
                  <Typography>{ translate("Delete") }</Typography>
                </MenuItem>
                <MenuItem
                  onClick={ (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    dispatch(openConfirmationModal({
                      title: translate("Archive Campaign"),
                      subtext: translate("Are you sure you want to archive this campaign?"),
                      onConfirm: () => dispatch(archiveCampaign(row.id)),
                    }))
                  } }
                >
                  <Typography>{ translate("Archive") }</Typography>
                </MenuItem>
              </ContextMenuButton>
              ) }
            </Container>
          )) }
        </InfiniteScroll>
      )
    }

    return (
      <EmptySplash
        bodyText={ translateCommon("An unexpected error occurred!") }
      />
    )
  }

  return (
    <SlidingPanel
      title={ translate("Campaigns") }
      open={ open }
      onClose={ onClose }
      disablePortal={ true }
    >
      <SearchBar
        onChange={ (e) => dispatch(setCampaignSearchInput(e.target.value)) }
        onChangeDelay={ 600 }
        lastSubmittedSearch={ campaignSearchInput }
        filterOptions={ filterOptions }
        filterValue={ campaignSearchFilter }
        setFilterValue={ (value) => dispatch(setCampaignSearchFilter(value)) }
      />
      { renderContent() }
    </SlidingPanel>
  )
}

export default CampaignsSlidingPanel
