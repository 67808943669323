import React from "react"
import "./style.sass"

import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import { FeedbackComment } from "../../state/publicListSlice"

interface CommentCardProps {
  comment: FeedbackComment
}

export default function CommentCard(props: CommentCardProps) {
  return (
    <div className="cp_component_pl-comment-card">
      <div className="cp_component_pl-comment-card-header">
        <div className="cp_component_pl-comment-card-header-name">
          { props.comment.author }
        </div>
        <div className="cp_component_pl-comment-card-header-company">
          { props.comment.company }
        </div>
      </div>
      <div className="cp_component_pl-comment-card-content">
        { props.comment.comment }
      </div>
      <div className="cp_component_pl-comment-card-footer">
        { dayjs(props.comment.created, "X").format(Constant.LONGFORM_DATE_TIME) }
      </div>
    </div>
  )
}
