import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import EmptySplash from "../../EmptySplash"
import { ReactComponent as TopRelevantPostsIcon } from "../../../asset/visualizations/ContentGroup/top-relevant-posts.svg"
import { ReactComponent as RelevantTopPostsIcon } from "../../../asset/visualizations/ContentGroup/relevant-top-posts.svg"
import { ReactComponent as TopPostsIcon } from "../../../asset/visualizations/ContentGroup/top-posts.svg"
import { ReactComponent as RelevantPostsIcon } from "../../../asset/visualizations/ContentGroup/relevant-posts.svg"
import { useSelector } from "../../../state/hooks"
import { SuggestionListToggleGroupType, SuggestionListToggleInput } from "../../../graphql"
import { contentDisplayCodes, getDisplayName } from "../constants"

export default function ContentVisualizations() {
  // Local variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })

  // Global state
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)

  // React hook to pull in all the toggles for content
  const toggles = useMemo((): SuggestionListToggleInput[] => {
    if (updateListForm?.toggles) {
      if (Array.isArray(updateListForm.toggles)) {
        return updateListForm.toggles
          .filter((toggle) => toggle.type === SuggestionListToggleGroupType.ContentToggles)
          .sort((t1, t2) => t1.order - t2.order)
      }

      // Check for single toggle
      if (updateListForm.toggles.type === SuggestionListToggleGroupType.ContentToggles) {
        return [ updateListForm.toggles ]
      }

      // Return nothing
      return []
    }
    return []
  }, [ updateListForm ])

  const renderWidget = (): React.JSX.Element | null => {
    const vName = toggles.map((option) => getDisplayName(option.name, contentDisplayCodes)
      .toLowerCase().replaceAll(" ", "_")).join("-")

    switch (vName) {
      case "top_posts-relevant_posts-post_impressions":
        return <TopRelevantPostsIcon />
      case "top_posts-post_impressions-relevant_posts":
        return <TopRelevantPostsIcon />
      case "relevant_posts-top_posts-post_impressions":
        return <RelevantTopPostsIcon />
      case "relevant_posts-post_impressions-top_posts":
        return <RelevantTopPostsIcon />
      case "post_impressions-top_posts-relevant_posts":
        return <TopRelevantPostsIcon />
      case "post_impressions-relevant_posts-top_posts":
        return <RelevantTopPostsIcon />
      case "top_posts-relevant_posts":
        return <TopRelevantPostsIcon />
      case "top_posts-post_impressions":
        return <TopPostsIcon />
      case "relevant_posts-top_posts":
        return <RelevantTopPostsIcon />
      case "relevant_posts-post_impressions":
        return <RelevantPostsIcon />
      case "post_impressions-top_posts":
        return <TopPostsIcon />
      case "post_impressions-relevant_posts":
        return <RelevantPostsIcon />
      case "top_posts":
        return <TopPostsIcon />
      case "relevant_posts":
        return <RelevantPostsIcon />
      case "post_impressions":
        return null
      default:
        return null
    }
  }

  if (toggles.length === 0) {
    return (
      <div className="cp_lcp-preview_example-visualizations content">
        <EmptySplash headlineText={ translate("You have no filters selected!") } />
      </div>
    )
  }

  return (
    <div className="cp_lcp-preview_example-visualizations content">
      { renderWidget() }
    </div>
  )
}
