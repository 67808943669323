import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as SearchGQL from "../../graphql/search"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "./helper"
import { setSelectedAccounts as setListSelectedAccounts } from "../listAddAccount"
import { RootState } from "../store"
import {
  Err,
  Status,
  Success,
} from "../../util/types"
import { search2Accounts, search2Posts } from "../../util/apiClient/queries.search"
import * as API from "../../util/apiClient"

const TIMEOUT_MS_LIMIT = 55000

// Search Slice Interface and Initial State
export interface SearchAIState {
  searchAIInput: SearchHelper.SearchInput
  searchResultsAccounts: Status<SearchGQL.Search2AccountsQuery>
  searchResultsContent: Status<SearchGQL.Search2PostsQuery>
  selectedAccounts: SearchHelper.Accounts,
  selectedPosts: SearchHelper.Posts,
  audienceLocations: Status<GraphQL.SearchAudienceLocationsQuery>,
  selectedLocations: SearchGQL.MinimumLocationMatch[],
  brands: Status<GraphQL.SearchBrandsQuery>,
  imageTags: Status<GraphQL.SearchImageTagsQuery>,
  searchLatency: string,
}

const initialState: SearchAIState = {
  searchAIInput: SearchHelper.initialSearchState(),
  searchResultsAccounts: "init",
  searchResultsContent: "init",
  selectedAccounts: [],
  selectedPosts: [],
  audienceLocations: "init",
  brands: "init",
  imageTags: "init",
  selectedLocations: [],
  searchLatency: "0",
}

// Search Slice
export const searchAISlice = createSlice({
  name: "SearchAI",
  initialState,
  reducers: {
    setSearchInput: (
      state,
      action: PayloadAction<SearchHelper.SearchInput>,
    ) => ({
      ...state,
      searchAIInput: action.payload,
    }),
    setSearchResultsAccounts: (
      state,
      action: PayloadAction<Status<SearchGQL.Search2AccountsQuery>>,
    ) => ({
      ...state,
      searchResultsAccounts: action.payload,
    }),

    setSearchResultsContent: (
      state,
      action: PayloadAction<Status<SearchGQL.Search2PostsQuery>>,
    ) => ({
      ...state,
      searchResultsContent: action.payload,
    }),
    setSelectedAccounts: (
      state,
      action: PayloadAction<SearchHelper.Accounts>,
    ) => ({
      ...state,
      selectedAccounts: action.payload,
    }),
    setSelectedPosts: (
      state,
      action: PayloadAction<SearchHelper.Posts>,
    ) => ({
      ...state,
      selectedPosts: action.payload,
    }),
    setAudienceLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchAudienceLocationsQuery>>,
    ) => ({
      ...state,
      audienceLocations: action.payload,
    }),

    setBrands: (
      state,
      action: PayloadAction<Status<GraphQL.SearchBrandsQuery>>,
    ) => ({
      ...state,
      brands: action.payload,
    }),

    setImageTags: (
      state,
      action: PayloadAction<Status<GraphQL.SearchImageTagsQuery>>,
    ) => ({
      ...state,
      imageTags: action.payload,
    }),

    setLocations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchLocationsQuery>>,
    ) => ({
      ...state,
      locations: action.payload,
    }),
    setSelectedLocations: (
      state,
      action: PayloadAction<SearchGQL.MinimumLocationMatch[]>,
    ) => ({
      ...state,
      selectedLocations: action.payload,
    }),
    setSearchLatency: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      searchLatency: action.payload,
    }),
  },
})

export const {
  setSearchInput,
  setSearchResultsAccounts,
  setSearchResultsContent,
  setSelectedAccounts,
  setSelectedPosts,
  setAudienceLocations,
  setLocations,
  setImageTags,
  setSelectedLocations,
  setBrands,
  setSearchLatency,
} = searchAISlice.actions

export default searchAISlice.reducer

// Search Slice Thunks
export const clearSearchState = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setSearchInput(SearchHelper.initialSearchState()))
  dispatch(setSearchResultsAccounts("init"))
  dispatch(setSearchResultsContent("init"))
  dispatch(setSelectedAccounts([]))
  dispatch(setSelectedPosts([]))
  dispatch(setListSelectedAccounts([]))
}

export const fetchAccountSearchResults = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { searchAI } = await getState()

  dispatch(setSearchResultsAccounts("loading"))
  dispatch(setSearchLatency("0"))
  const startTime = Date.now() / 1000
  const newInput = SearchHelper.cloneSearchInput(searchAI.searchAIInput)
  if (newInput.audienceParams.locationMinimum) {
    newInput.audienceParams.locationMinimum = newInput.audienceParams.locationMinimum.map((locationMin) => {
      const newlocationMin = {
        match: locationMin.match,
        location: {
          code: locationMin.location.code,
          name: locationMin.location.name,
          type: locationMin.location.type,
        },
      }
      return newlocationMin
    })
  }
  const searchPromise = search2Accounts({
    input: {
      prompts: searchAI.searchAIInput.prompts.map((prompt) => prompt.value),
      username: searchAI.searchAIInput.username,
      networks: searchAI.searchAIInput.socialNetworks,
      profileParams: searchAI.searchAIInput.profileParams,
      contentParams: searchAI.searchAIInput.contentParams,
      audienceParams: newInput.audienceParams,
    },
  })

  const timeoutPromise = new Promise((_, reject) => {
    const timeoutError: Err = {
      status: "error",
      message: "TIMEOUT",
    }
    setTimeout(() => {
      reject(timeoutError)
    }, TIMEOUT_MS_LIMIT)
  })

  Promise.race([ timeoutPromise, searchPromise ])
    .then((results) => {
      dispatch(setSearchResultsAccounts(results as Err | Success<SearchGQL.Search2AccountsQuery>))
    }, (err) => dispatch(setSearchResultsAccounts(err as Err | Success<SearchGQL.Search2AccountsQuery>)))
    .finally(() => {
      const endTime = Date.now() / 1000
      dispatch(setSearchLatency((endTime - startTime).toFixed(1)))
    })
}

export const fetchContentSearchResults = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { searchAI } = await getState()

  dispatch(setSearchResultsContent("loading"))
  dispatch(setSearchLatency("0"))
  const startTime = Date.now() / 1000

  const searchPromise = search2Posts({
    input: {
      prompts: searchAI.searchAIInput.prompts.map((prompt) => prompt.value),
      username: searchAI.searchAIInput.username,
      networks: searchAI.searchAIInput.socialNetworks,
      profileParams: searchAI.searchAIInput.profileParams,
      contentParams: searchAI.searchAIInput.contentParams,
      audienceParams: searchAI.searchAIInput.audienceParams,
    },
  })

  const timeoutPromise = new Promise((_, reject) => {
    const timeoutError: Err = {
      status: "error",
      message: "TIMEOUT",
    }
    setTimeout(() => {
      reject(timeoutError)
    }, TIMEOUT_MS_LIMIT)
  })

  Promise.race([ timeoutPromise, searchPromise ])
    .then((results) => {
      dispatch(setSearchResultsContent(results as Err | Success<SearchGQL.Search2PostsQuery>))
    }, (err) => dispatch(setSearchResultsContent(err as Err | Success<SearchGQL.Search2PostsQuery>)))
    .finally(() => {
      const endTime = Date.now() / 1000
      dispatch(setSearchLatency((endTime - startTime).toFixed(1)))
    })
}

export const fetchAudienceLocations = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setAudienceLocations("loading"))
  const audienceLocationsResult = await API.fetchAudienceLocations(startsWith)
  dispatch(setAudienceLocations(audienceLocationsResult))
}

export const fetchBrands = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setBrands("loading"))
  const brandsResults = await API.fetchBrands(startsWith)
  dispatch(setBrands(brandsResults))
}

export const fetchImageTags = (
  startsWith: string | null,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setImageTags("loading"))
  const imageTagResults = await API.fetchImageTags(startsWith)
  dispatch(setImageTags(imageTagResults))
}
