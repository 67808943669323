import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import {
  Deliverable,
  DeliverableMediaItem,
  DeliverableCaptionContainer,
  DeliverablePermissionsContainer,
  UserVote,
} from "./types"

// Deliverable Content Modal V2 Slice Interface and Initial State
export interface CampaignDeliverableContentModalV2State {
  modalOpen: boolean
  confirmFinalize: boolean
  goLiveConfirmationModalOpen: boolean
  finalizeDeliverableConfirmationModalOpen: boolean
  deliverable?: Deliverable
  deliverableMedia?: DeliverableMediaItem[]
  deliverableCaption?: DeliverableCaptionContainer
  deliverablePermissions?: DeliverablePermissionsContainer
  captionFeedback?: DeliverableCaptionContainer
  captionToApprove?: DeliverableCaptionContainer
  history: Status<GraphQL.DeliverableHistoryQuery> // TODO: replace this with new schema type
  selectedMediaForFeedback?: DeliverableMediaItem
  mediaToApprove?: DeliverableMediaItem
  edited: boolean
  edittingUrl: boolean
  feedbackPageOpen: boolean
  feedbackPagePendingVote?: UserVote.APPROVED_WITH_SUGGESTIONS | UserVote.REQUEST_REVISION
  feedbackInput: string
  finalizeConfirmationModalOpen: boolean
  updateStatusModalOpen: boolean
  /**
   * This property is used to hide the modal when the user navigates away from the modal to a nested modal.
   * This will preserve the state of the modal when the user navigates back to the parent modal.
   */
  hidden: boolean
}

const initialState: CampaignDeliverableContentModalV2State = {
  modalOpen: false,
  deliverable: undefined,
  deliverableCaption: undefined,
  deliverablePermissions: undefined,
  deliverableMedia: [],
  confirmFinalize: false,
  history: "init",
  edittingUrl: false,
  edited: false,
  hidden: false,
  finalizeConfirmationModalOpen: false,
  feedbackPageOpen: false,
  feedbackInput: "",
  updateStatusModalOpen: false,
  goLiveConfirmationModalOpen: false,
  finalizeDeliverableConfirmationModalOpen: false,
}

// Deliverable Content Modal Slice
export const CampaignDeliverableContentModalV2Slice = createSlice({
  name: "CampaignDeliverableContentModalV2Slice",
  initialState,
  reducers: {
    openFinalizeDeliverableConfirmModal: (
      state,
    ) => ({
      ...state,
      finalizeDeliverableConfirmationModalOpen: true,
    }),
    closeFinalizeDeliverableConfirmModal: (
      state,
    ) => ({
      ...state,
      finalizeDeliverableConfirmationModalOpen: false,
    }),
    openFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: true,
    }),
    closeFinalizeConfirmModal: (
      state,
    ) => ({
      ...state,
      confirmFinalize: false,
    }),
    openGoLiveConfirmationModal: (
      state,
    ) => ({
      ...state,
      goLiveConfirmationModalOpen: true,
    }),
    closeGoLiveConfirmationModal: (
      state,
    ) => ({
      ...state,
      goLiveConfirmationModalOpen: false,
    }),
    openDeliverableContentModal: (
      state,
    ) => ({
      ...state,
      modalOpen: true,
      hidden: false,
    }),
    setDeliverable: (
      state,
      action: PayloadAction<{
        deliverable?: Deliverable,
      }>,
    ) => ({
      ...state,
      deliverable: action.payload.deliverable,
    }),
    setDeliverableCaption: (
      state,
      action: PayloadAction<{
        deliverableCaption?: DeliverableCaptionContainer,
      }>,
    ) => ({
      ...state,
      deliverableCaption: action.payload.deliverableCaption,
    }),
    setDeliverableMedia: (
      state,
      action: PayloadAction<{
        deliverableMedia: DeliverableMediaItem[],
      }>,
    ) => ({
      ...state,
      deliverableMedia: action.payload.deliverableMedia,
    }),
    setDeliverablePermissions: (
      state,
      action: PayloadAction<DeliverablePermissionsContainer>,
    ) => ({
      ...state,
      deliverablePermissions: action.payload,
    }),
    closeDeliverableContentModal: (
      state,
    ) => ({
      ...state,
      modalOpen: false,
      hidden: false,
    }),
    hideDeliverableContentModal: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      hidden: action.payload,
    }),
    setEdittingUrl: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edittingUrl: action.payload,
    }),
    setEdited: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      edited: action.payload,
    }),
    setMediaToApprove: (
      state,
      action: PayloadAction<{
        media?: DeliverableMediaItem,
      }>,
    ) => ({
      ...state,
      mediaToApprove: action.payload.media,
    }),
    setCaptionToApprove: (
      state,
      action: PayloadAction<{
        caption?: DeliverableCaptionContainer
      }>,
    ) => ({
      ...state,
      captionToApprove: action.payload.caption,
    }),
    setSelectedMediaForFeedback: (
      state,
      action: PayloadAction<{
        media?: DeliverableMediaItem,
      }>,
    ) => ({
      ...state,
      selectedMediaForFeedback: action.payload.media,
    }),
    setCaptionFeedback: (
      state,
      action: PayloadAction<{
        caption?: DeliverableCaptionContainer
      }>,
    ) => ({
      ...state,
      captionFeedback: action.payload.caption,
    }),
    setFeedbackPageOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      feedbackPageOpen: action.payload,
    }),
    setFeedbackInput: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      feedbackInput: action.payload,
    }),
    setFeedbackPagePendingVote: (
      state,
      action: PayloadAction<UserVote.APPROVED_WITH_SUGGESTIONS | UserVote.REQUEST_REVISION | undefined>,
    ) => ({
      ...state,
      feedbackPagePendingVote: action.payload,
    }),
    setFinalizeConfirmationModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      finalizeConfirmationModalOpen: action.payload,
    }),
    setUpdateStatusModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      updateStatusModalOpen: action.payload,
    }),
  },
})

export const {
  closeFinalizeDeliverableConfirmModal,
  openFinalizeDeliverableConfirmModal,
  closeGoLiveConfirmationModal,
  openGoLiveConfirmationModal,
  openFinalizeConfirmModal,
  closeFinalizeConfirmModal,
  closeDeliverableContentModal,
  openDeliverableContentModal,
  hideDeliverableContentModal,
  setDeliverable,
  setDeliverableCaption,
  setDeliverableMedia,
  setDeliverablePermissions,
  setEdittingUrl,
  setEdited,
  setSelectedMediaForFeedback,
  setMediaToApprove,
  setCaptionToApprove,
  setCaptionFeedback,
  setFeedbackInput,
  setFeedbackPageOpen,
  setFeedbackPagePendingVote,
  setFinalizeConfirmationModalOpen,
  setUpdateStatusModalOpen,
} = CampaignDeliverableContentModalV2Slice.actions
export default CampaignDeliverableContentModalV2Slice.reducer
