import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  Share,
  Archive,
  Edit,
  Download,
} from "@mui/icons-material"
import { IconButton } from "@mui/material"

import LoadingIndicator from "../LoadingIndicator"
import Tooltip from "../Tooltip"
import { openConfirmationModal } from "../../state/confirmationModalSlice"
import { openCopyLinkModal } from "../../state/copyLinkModalSlice"
import { pushToast } from "../../state/toastSlice"
import { setListModalMode, setListModalOpen } from "../../state/listModalFormSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchList,
  getListCsv,
  setIsArchiving,
  setIsDownloading,
  submitArchiveSuggestionList,
  submitUnarchiveSuggestionList,
} from "../../state/listSlice"

import "./metrics.sass"
import "./modals.sass"
import "./overview.sass"
import { Scope } from "../../util/types"

export default function ListManagementControls() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListOverview" })

  const {
    list: fetchedList, isDownloading, isArchiving,
  } = useSelector(({ list }) => list)
  const { scopes } = useSelector((state) => state.user)

  const dispatch = useDispatch()

  if (fetchedList === "init" || fetchedList === "loading") {
    return null
  }
  if (fetchedList.status === "error") {
    return null
  }

  const {
    id,
    name,
    archived,
    publicUrl,
    public: isPubliclyAvailable,
  } = fetchedList.payload.suggestionListById

  const handleDownloadClick = async () => {
    dispatch(setIsDownloading(true))
    dispatch(getListCsv({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Success!"),
          additionalInfo: translate("Your download is complete"),
        }))
        dispatch(setIsDownloading(false))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: translate("There was an error. Please try again!"),
        }))
        dispatch(setIsDownloading(false))
      },
    }))
  }

  const handleArchiveSubmit = async () => {
    dispatch(setIsArchiving(true))
    await dispatch(submitArchiveSuggestionList({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Archive List"),
          additionalInfo: translate("The selected list is successfully archived!"),
        }))
        dispatch(fetchList(id))
      },
      onError: () => dispatch(pushToast({
        type: "error",
        message: translate("There was an error. Please try again!"),
      })),
    }))
    dispatch(setIsArchiving(false))
  }

  const handleUnarchiveSubmit = async () => {
    dispatch(setIsArchiving(true))
    await dispatch(submitUnarchiveSuggestionList({
      variables: {
        id,
      },
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Unarchive List"),
          additionalInfo: translate("The selected list is successfully unarchived!"),
        }))
        dispatch(fetchList(id))
      },
      onError: () => dispatch(pushToast({
        type: "error",
        message: translate("There was an error. Please try again!"),
      })),
    }))
    dispatch(setIsArchiving(false))
  }

  const handleArchiveModalOpen = () => {
    dispatch(openConfirmationModal({
      title: translate("Archive List"),
      subtitle: translate("Archived lists can be found using the filter in the Lists panel."),
      subtext: `${ translate("Are you sure you want to archive") } ${ name }?`,
      primaryLabel: translate("Archive"),
      onConfirm: handleArchiveSubmit,
    }))
  }

  const handleUnarchiveModalOpen = () => {
    dispatch(openConfirmationModal({
      title: translate("Unarchive List"),
      subtext: `${ translate("Are you sure you want to unarchive") } ${ name }?`,
      primaryLabel: translate("Unarchive"),
      onConfirm: handleUnarchiveSubmit,
    }))
  }

  const handleSharePublicUrlModalOpen = () => {
    dispatch(openCopyLinkModal({
      link: (scopes.includes(Scope.DEVELOPMENT)) ? publicUrl.replaceAll(/\/list\//g, "/listV2/") : publicUrl,
      title: translate("Share"),
      subtitle: translate("Copy the public URL for this suggestion list below."),
    }))
  }

  return (
    <div className="cp_list-overview_component-controls">
      <Tooltip title={ archived ? translate("Unarchive") : translate("Archive") }>
        <IconButton onClick={ () => archived ? handleUnarchiveModalOpen() : handleArchiveModalOpen() } size="small">
          { isArchiving ? <LoadingIndicator /> : <Archive /> }
        </IconButton>
      </Tooltip>
      <Tooltip title={ translate("Share") }>
        <IconButton
          disabled={ !isPubliclyAvailable }
          onClick={ handleSharePublicUrlModalOpen }
          size="small"
        >
          <Share />
        </IconButton>
      </Tooltip>
      <Tooltip
        onClick={ () => {
          dispatch(setListModalMode({ isEditMode: true, listId: id }))
          dispatch(setListModalOpen(true))
        } }
        title={ translate("Edit")
        }
      >
        <IconButton size="small">
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title={ translate("Download") }>
        <IconButton onClick={ handleDownloadClick } size="small">
          { isDownloading ? <LoadingIndicator /> : <Download /> }
        </IconButton>
      </Tooltip>
    </div>
  )
}
