import React from "react"

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import Checkbox from "../../../../Checkbox"
import { AudienceMetricFormDataProps } from "../AudienceMetricsForm"
import * as GraphQL from "../../../../../graphql"

export default function AudienceGenderForm({ label, setSelected }: AudienceMetricFormDataProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })

  const [ selectedGenders, setSelectedGenders ] = React.useState<GraphQL.Sex[]>([])

  const updateCheckedStatus = (type: GraphQL.Sex) => {
    if (selectedGenders.includes(type)) {
      setSelected(selectedGenders.splice(selectedGenders.indexOf(type), 1))
      setSelectedGenders(selectedGenders.splice(selectedGenders.indexOf(type), 1))
    } else {
      setSelected([ ...selectedGenders, type ])
      setSelectedGenders([ ...selectedGenders, type ])
    }
  }

  return (
    <FormControl
      className="audience-metrics-form-metric-form"
      component="fieldset"
      fullWidth={ true }
    >
      <FormLabel component="legend" className="section-label">
        { translate(label) }
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          className="audience-gender-checkbox-label"
          control={ (
            <Checkbox
              id="gender-female-checkbox"
              className="gender-checkbox"
              name={ translate("Female") }
              size="small"
              checked={ selectedGenders.includes(GraphQL.Sex.Female) }
              onClick={ () => updateCheckedStatus(GraphQL.Sex.Female) }
            />
          ) }
          label={ translate("Female") }
        />
        <FormControlLabel
          className="audience-gender-checkbox-label"
          control={ (
            <Checkbox
              id="gender-male-checkbox"
              className="gender-checkbox"
              name={ translate("Male") }
              size="small"
              checked={ selectedGenders.includes(GraphQL.Sex.Male) }
              onClick={ () => updateCheckedStatus(GraphQL.Sex.Male) }
            />
          ) }
          label={ translate("Male") }
        />
      </FormGroup>
    </FormControl>
  )
}
