import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"
import {
  ListCategory,
  ListCategorySocialAccount,
  Status,
} from "../../util/types"
import { fetchListCsv } from "../../util/apiClient/non-graphql"
import { CreateSuggestionListSocialAccountFeedbackMutationVariables } from "../../graphql"

// List Slice Interface and Initial State
export interface ListsState {
  list: Status<GraphQL.SearchSuggestionListByIdQuery>
  iScores: Status<GraphQL.GetSocialAccountsIScoreQuery>
  selectedSocialAccounts: ListCategorySocialAccount[]
  openCreateCategoryModal: boolean
  renameCategory: ListCategory | undefined
  removeCategory: ListCategory | undefined
  removeAccount: ListCategorySocialAccount | undefined
  createFeedback: ListCategorySocialAccount | undefined
  archiveModalOpen: boolean
  copyLinkModalOpen: boolean
  isDownloading: boolean
  isArchiving: boolean
}

const initialState: ListsState = {
  list: "init",
  iScores: "init",
  selectedSocialAccounts: [],
  openCreateCategoryModal: false,
  renameCategory: undefined,
  removeCategory: undefined,
  removeAccount: undefined,
  createFeedback: undefined,
  archiveModalOpen: false,
  copyLinkModalOpen: false,
  isDownloading: false,
  isArchiving: false,
}

// Profile Slice
export const ListSlice = createSlice({
  name: "List",
  initialState,
  reducers: {
    setList: (
      state,
      action: PayloadAction<Status<GraphQL.SearchSuggestionListByIdQuery>>,
    ) => ({
      ...state,
      list: action.payload,
    }),
    setIScores: (state, action: PayloadAction<Status<GraphQL.GetSocialAccountsIScoreQuery>>) => ({
      ...state,
      iScores: action.payload,
    }),
    setArchiveModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      archiveModalOpen: action.payload,
    }),
    setCopyLinkModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      copyLinkModalOpen: action.payload,
    }),
    setIsDownloading: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isDownloading: action.payload,
    }),
    selectSocialAccount: (
      state,
      action: PayloadAction<ListCategorySocialAccount>,
    ) => ({
      ...state,
      selectedSocialAccounts: [ ...state.selectedSocialAccounts, action.payload ],
    }),
    deselectSocialAccount: (
      state,
      action: PayloadAction<ListCategorySocialAccount>,
    ) => ({
      ...state,
      selectedSocialAccounts: state
        .selectedSocialAccounts
        .filter(({ id }) => id !== action.payload.id),
    }),
    setSelectedSocialAccounts: (
      state,
      action: PayloadAction<ListCategorySocialAccount[]>,
    ) => ({
      ...state,
      selectedSocialAccounts: action.payload,
    }),
    toggleCreateCategoryModal: (
      state,
    ) => ({
      ...state,
      openCreateCategoryModal: !state.openCreateCategoryModal,
    }),
    openRenameCategoryModal: (
      state,
      action: PayloadAction<ListCategory>,
    ) => ({
      ...state,
      renameCategory: action.payload,
    }),
    closeRenameCategoryModal: (
      state,
    ) => ({
      ...state,
      renameCategory: undefined,
    }),
    openRemoveAccountModal: (
      state,
      action: PayloadAction<ListCategorySocialAccount>,
    ) => ({
      ...state,
      removeAccount: action.payload,
    }),
    closeRemoveAccountModal: (
      state,
    ) => ({
      ...state,
      removeAccount: undefined,
    }),
    openRemoveCategoryModal: (
      state,
      action: PayloadAction<ListCategory>,
    ) => ({
      ...state,
      removeCategory: action.payload,
    }),
    closeRemoveCategoryModal: (
      state,
    ) => ({
      ...state,
      removeCategory: undefined,
    }),
    openCreateFeedbackModal: (
      state,
      action: PayloadAction<ListCategorySocialAccount>,
    ) => ({
      ...state,
      createFeedback: action.payload,
    }),
    closeCreateFeedbackModal: (
      state,
    ) => ({
      ...state,
      createFeedback: undefined,
    }),
    setIsArchiving: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isArchiving: action.payload,
    }),
  },
})

export const {
  setList,
  setIsDownloading,
  selectSocialAccount,
  deselectSocialAccount,
  setSelectedSocialAccounts,
  toggleCreateCategoryModal,
  setArchiveModalOpen,
  setIsArchiving,
  setCopyLinkModalOpen,
  openRenameCategoryModal,
  closeRenameCategoryModal,
  openRemoveAccountModal,
  closeRemoveAccountModal,
  openRemoveCategoryModal,
  closeRemoveCategoryModal,
  openCreateFeedbackModal,
  closeCreateFeedbackModal,
  setIScores,
} = ListSlice.actions
export default ListSlice.reducer

// Profile Slice Thunks
export const fetchList = (
  id: string,
  loadingStatus: boolean = true,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  if (loadingStatus) dispatch(setList("loading"))

  const listResult = await API.getDetailedSuggestionList({
    suggestionListByIdId: id,
    types: [
      GraphQL.SuggestionListSocialAccountCommentType.Feedback,
      GraphQL.SuggestionListSocialAccountCommentType.Internal,
    ],
  })

  // Check to see if successful
  if (API.isSuccess(listResult)) {
    // Create input for gathering IScore values
    const input: GraphQL.GetSocialAccountsIScoreQueryVariables = {
      input: {
        socialAccountIds: listResult.payload.suggestionListById.suggestionListCategories
          .map((category) => category.suggestionListSocialAccounts
            .map((account) => account.socialAccount.id)).flat(),
        sexes: listResult.payload.suggestionListById.sexes,
        minAge: listResult.payload.suggestionListById.minAge,
        maxAge: listResult.payload.suggestionListById.maxAge,
        contextualRelevancy: listResult.payload.suggestionListById.contextualRelevancy,
      },
    }

    // Query to get the iscores
    const iscores = await API.fetchAccountsIScore(input)

    // Update state
    dispatch(setIScores(iscores))
  }

  // Update state for list results
  dispatch(setList(listResult))
}
export const addCategory = (
  params: GraphQL.SuggestionListCategoryAddMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<string | undefined> => {
  const { list } = getState()
  const newList: Status<GraphQL.SearchSuggestionListByIdQuery> = JSON.parse(JSON.stringify(list.list))
  if (newList === "loading" || newList === "init" || API.isError(newList)) return

  const newCategoryResult = await API.addSuggestionListCategory(params)

  if (newCategoryResult === "init" || newCategoryResult === "loading" || newCategoryResult.status === "error") return

  const newCategory = newCategoryResult.payload?.suggestionListCategoryAdd

  newList.payload.suggestionListById.suggestionListCategories.push({
    id: newCategory?.id || "",
    name: newCategory?.name || "",
    suggestionListSocialAccounts: [],
  })

  dispatch(setList(newList))

  // eslint-disable-next-line consistent-return
  return newCategory?.id
}

export const renameCategory = (
  params: GraphQL.SuggestionListCategoryRenameMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<string | undefined> => {
  const { list } = getState()
  const newList: Status<GraphQL.SearchSuggestionListByIdQuery> = JSON.parse(JSON.stringify(list.list))
  if (newList === "loading" || newList === "init" || API.isError(newList)) return

  const newCategoryResult = await API.renameSuggestionListCategory(params)

  if (newCategoryResult === "init" || newCategoryResult === "loading" || newCategoryResult.status === "error") return

  const categoryToRename = newList
    .payload
    .suggestionListById
    .suggestionListCategories
    .find(({ id }) => id === params.suggestionListCategoryRenameId)

  if (categoryToRename) categoryToRename.name = params.name

  dispatch(setList(newList))
}

export const deleteCategory = (
  params: GraphQL.SuggestionListCategoryDeleteMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { list } = getState()
  const result = await API.deleteSuggestionListCategory(params)

  if (result === "loading" || result === "init" || API.isError(result)) return

  const newList: ListsState = JSON.parse(JSON.stringify(list))
  if (newList.list === "loading" || newList.list === "init" || API.isError(newList.list)) return
  const categories = newList.list.payload.suggestionListById.suggestionListCategories
  const uncategorizedCategory = categories.find(({ name }) => name === "Uncategorized")

  const deletedCategory = categories.find(({ id }) => id === params.suggestionListCategoryDeleteId)

  if (uncategorizedCategory && deletedCategory) {
    uncategorizedCategory.suggestionListSocialAccounts.push(
      ...deletedCategory.suggestionListSocialAccounts,
    )
    newList.list.payload.suggestionListById.suggestionListCategories = categories
      .filter(({ id }) => id !== params.suggestionListCategoryDeleteId)
    dispatch(setList(newList.list))
  }
}

export const reorderCategory = (
  params: GraphQL.SuggestionListCategoryReorderOrdinalMutationVariables,
  oldIndex: number,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { list } = getState()
  const result = await API.reorderSuggestionListCategory(params)

  if (result === "loading" || result === "init" || API.isError(result)) return

  const newList: ListsState = JSON.parse(JSON.stringify(list))
  if (newList.list === "loading" || newList.list === "init" || API.isError(newList.list)) return
  const categories = newList.list.payload.suggestionListById.suggestionListCategories

  const temp = categories[oldIndex]
  categories[oldIndex] = categories[params.index]
  categories[params.index] = temp

  dispatch(setList(newList.list))
}

export const reorderSocialAccount = (
  params: GraphQL.SuggestionListSocialAccountReorderOrdinalMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { list } = getState()
  await API.reorderSuggestionListSocialAccount(params)

  const newList: ListsState = JSON.parse(JSON.stringify(list))
  if (newList.list === "loading" || newList.list === "init" || API.isError(newList.list)) return
  const categories = newList.list.payload.suggestionListById.suggestionListCategories
  const newCategory = categories.find(({ id }) => id === params.categoryId)

  let socialAccountIndex = -1
  let socialAccount
  const oldCategory = categories.find(({ suggestionListSocialAccounts }) => {
    socialAccountIndex = suggestionListSocialAccounts.findIndex(({ id }) => id === params.suggestionListSocialAccountId)
    socialAccount = suggestionListSocialAccounts.find(({ id }) => id === params.suggestionListSocialAccountId)
    return socialAccountIndex > -1
  })

  if (oldCategory && newCategory) {
    const isSameCat = (newCategory.suggestionListSocialAccounts.find(({ id }) => id === params.suggestionListSocialAccountId))
    if (isSameCat && socialAccount) {
      newCategory.suggestionListSocialAccounts.splice(socialAccountIndex, 1)
      newCategory.suggestionListSocialAccounts.splice(params.index || 0, 0, socialAccount)
    } else if (!isSameCat) {
      newCategory.suggestionListSocialAccounts.push(
        ...oldCategory.suggestionListSocialAccounts.splice(socialAccountIndex, 1),
      )
    }
  }
  newList.list.payload.suggestionListById.suggestionListCategories = categories
  dispatch(setList(newList.list))
}

export const removeSocialAccount = (
  params: GraphQL.SuggestionListSocialAccountRemoveMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  const { list } = getState()
  await API.removeSuggestionListAccount(params)

  const newList: ListsState = JSON.parse(JSON.stringify(list))
  if (newList.list === "loading" || newList.list === "init" || API.isError(newList.list)) return
  const categories = newList.list.payload.suggestionListById.suggestionListCategories

  let socialAccountIndex = -1
  const oldCategory = categories.find(({ suggestionListSocialAccounts }) => {
    socialAccountIndex = suggestionListSocialAccounts.findIndex(({ id }) => id === params.suggestionListSocialAccountRemoveId)
    return socialAccountIndex > -1
  })

  if (oldCategory) oldCategory.suggestionListSocialAccounts.splice(socialAccountIndex, 1)

  newList.list.payload.suggestionListById.suggestionListCategories = categories
  dispatch(setList(newList.list))
}

export const getListCsv = (params: {
  variables: {
    id: string,
  },
  onSuccess: () => void,
  onError: () => void
}) => async () => {
  const result = await fetchListCsv(params.variables.id)
  if (result.status === "success" && result.payload) {
    const blob = await result.payload.blob()
    const file = window.URL.createObjectURL(blob)
    window.location.assign(file)

    params.onSuccess()
  } else {
    params.onError()
  }
}

export const submitArchiveSuggestionList = (params: {
  variables: {
    id: string,
  }
  onSuccess: () => void,
  onError: () => void
}) => async () => {
  const result = await API.archiveSuggestionList({ ids: params.variables.id })

  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
}

export const submitUnarchiveSuggestionList = (params: {
  variables: {
    id: string
  },
  onSuccess: () => void,
  onError: () => void
}) => async (dispatch: Dispatch) => {
  const result = await API.unarchiveSuggestionList({ ids: params.variables.id })
  if (API.isSuccess(result)) {
    params.onSuccess()
  } else {
    params.onError()
  }
  dispatch(setIsArchiving(false))
}

export const createComment = (
  params: CreateSuggestionListSocialAccountFeedbackMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { list } = getState()
  const result = await API.createSuggestionListSocialAccountComment(params)
  if (!API.isSuccess(result)) return
  if (list.list === "init" || list.list === "loading" || API.isError(list.list)) return

  const listResult = await API.getDetailedSuggestionList({
    suggestionListByIdId: list.list.payload.suggestionListById.id,
    types: [
      GraphQL.SuggestionListSocialAccountCommentType.Feedback,
      GraphQL.SuggestionListSocialAccountCommentType.Internal,
    ],
  })

  await dispatch(setList(listResult))
  if (API.isError(listResult)) return

  let socialAccountIndex = -1
  const category = listResult.payload.suggestionListById.suggestionListCategories
    .find(({ suggestionListSocialAccounts }) => {
      socialAccountIndex = suggestionListSocialAccounts.findIndex(({ id }) => id === params.suggestionListSocialAccountId)
      return socialAccountIndex > -1
    })

  if (category) dispatch(openCreateFeedbackModal(category.suggestionListSocialAccounts[socialAccountIndex]))
}
export const setApprovalStatus = (
  params: GraphQL.UpdateSuggestionListSocialAccountApprovalStatusMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { list } = getState()
  const result = await API.updateSuggestionListSocialAccountApprovalStatus(params)
  if (!API.isSuccess(result)) return
  if (list.list === "init" || list.list === "loading" || API.isError(list.list)) return

  const listResult = await API.getDetailedSuggestionList({
    suggestionListByIdId: list.list.payload.suggestionListById.id,
    types: [
      GraphQL.SuggestionListSocialAccountCommentType.Feedback,
      GraphQL.SuggestionListSocialAccountCommentType.Internal,
    ],
  })

  await dispatch(setList(listResult))
  if (API.isError(listResult)) return

  let socialAccountIndex = -1
  const category = listResult.payload.suggestionListById.suggestionListCategories
    .find(({ suggestionListSocialAccounts }) => {
      socialAccountIndex = suggestionListSocialAccounts.findIndex(({ id }) => id === params.suggestionListSocialAccountId)
      return socialAccountIndex > -1
    })

  if (category) dispatch(openCreateFeedbackModal(category.suggestionListSocialAccounts[socialAccountIndex]))
}

export const createInternalNote = (
  params: GraphQL.CreateSuggestionListSocialAccountInternalNoteMutationVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { list } = getState()
  const result = await API.createSuggestionListSocialAccountInternalNote(params)
  if (!API.isSuccess(result)) return
  if (list.list === "init" || list.list === "loading" || API.isError(list.list)) return

  const listResult = await API.getDetailedSuggestionList({
    suggestionListByIdId: list.list.payload.suggestionListById.id,
    types: [
      GraphQL.SuggestionListSocialAccountCommentType.Feedback,
      GraphQL.SuggestionListSocialAccountCommentType.Internal,
    ],
  })

  await dispatch(setList(listResult))
  if (API.isError(listResult)) return

  let socialAccountIndex = -1
  const category = listResult.payload.suggestionListById.suggestionListCategories
    .find(({ suggestionListSocialAccounts }) => {
      socialAccountIndex = suggestionListSocialAccounts.findIndex(({ id }) => id === params.suggestionListSocialAccountId)
      return socialAccountIndex > -1
    })

  if (category) dispatch(openCreateFeedbackModal(category.suggestionListSocialAccounts[socialAccountIndex]))
}
