import React from "react"
import "./style.sass"
import { Alert as AlertMui, Link } from "@mui/material"
import {
  CheckCircleOutline,
  Close,
  ErrorOutline,
  Info,
  WarningAmber,
} from "@mui/icons-material"
import IconButton from "../IconButton"

 interface AlertProps{
  severity: "success" | "warning" | "error" | "info"
  className?: string
  message: string
  linkText?: string
  linkAction?: () => void
  linkPath?: string
  onClose?: () => void
  secondaryLinkText?: string
  secondatyLinkPath?: string
  secondaryLinkAction?:()=>void
}

const classes = [ "cp-alert" ]

const iconMapping = {
  success: <CheckCircleOutline fontSize="inherit" />,
  warning: <WarningAmber fontSize="inherit" />,
  error: <ErrorOutline fontSize="inherit" />,
  info: <Info fontSize="inherit" />,
}

function Alert({
  severity,
  message,
  linkAction,
  linkText,
  linkPath,
  onClose,
  className,
  secondaryLinkText,
  secondatyLinkPath,
  secondaryLinkAction,

}:AlertProps):React.JSX.Element {
  const inputClassName = className?.split(" ") || []
  const classNames = classes.concat(inputClassName).join(" ")
  return (
    <AlertMui
      severity={ severity }
      className={ `${ classNames } ${ severity }` }
      icon={ (
        <div className="icon-mapper">
          { onClose && (
          <IconButton className="alert-close-icon">
            <Close onClick={ onClose } fontSize="inherit" />
          </IconButton>
          )
      }
          { iconMapping[severity] }
        </div>
      ) }

    >
      <span className="alert-message">{ message }</span>
      <div className="alert-links">
        { linkText && (
        <Link
          href={ linkPath || "#" }
          target={ linkPath ? "_blank" : undefined }
          rel={ linkPath ? "noopener noreferrer" : undefined }
          className="alert-link-primary"
          onClick={ linkAction }
        >
          { linkText }
        </Link>

        ) }
        { secondaryLinkText && (
        <Link
          href={ secondatyLinkPath || "#" }
          target={ secondatyLinkPath ? "_blank" : undefined }
          rel={ secondatyLinkPath ? "noopener noreferrer" : undefined }
          className="alert-link-secondary"
          onClick={ secondaryLinkAction }
        >
          { secondaryLinkText }
        </Link>

        ) }
      </div>
    </AlertMui>
  )
}

export default Alert
