import React, { JSX } from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import SelectInput, { SelectProps } from "@mui/material/Select"

import "./style.sass"

// NOTE: Per conversation with design team, omitting placeholder prop,
// will be leveraging label prop for input labels instead

// NOTE: `id`, `label`, and `labelId` props must be present to ensure
// labels function
interface Props<T> extends Omit<
  SelectProps<T>, "id" | "label" | "labelId" | "placeholder" | "variant"
> {
  id: string,
  label: string,
  labelId: string,
  menuItems: JSX.Element | JSX.Element[],
  mode?: "default" | "thin",
}

export default function Select<T>(props: Props<T>): JSX.Element {
  const {
    id,
    label,
    labelId,
    menuItems,
    mode,
    ...restSelectComponentProps
  } = props

  const modeClass = mode === "thin" ? "cp_component_select-thin" : ""

  return (
    <FormControl
      className={ `component_select ${ modeClass }` }
      disabled={ props.disabled }
      error={ props.error }
      fullWidth={ props.fullWidth }
    >
      <InputLabel id={ props.labelId }>{ label }</InputLabel>
      <SelectInput
        className="component_select"
        id={ id }
        label={ label }
        labelId={ labelId }
        MenuProps={ {
          classes: { paper: "component_select-dropdown-menu" },
        } }
        variant="outlined"
        { ...restSelectComponentProps }
      >
        { menuItems }
      </SelectInput>
    </FormControl>
  )
}
