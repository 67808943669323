import React, {
  useEffect,
  useMemo,
  useState,
} from "react"

import { useTranslation } from "react-i18next"

import "./post-preview.sass"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "../../state/hooks"
import {
  DeliverableStatus,
  GET_DELIVERABLE,
  GET_DELIVERABLE_HISTORY,
  UPDATE_DELIVERABLE_STATUS,
  UpdateDeliverableStatusInput,
  UpdateDeliverableStatusOutput,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import Modal from "../Modal"
import Select from "../Select"
import MenuItem from "../MenuItem"
import LoadingIndicator from "../LoadingIndicator"

import "./update-status-modal.sass"
import { closeDeliverableUpdateStatusModal } from "../../state/campaignDeliverableV2UpdateStatusModal"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"
// eslint-disable-next-line max-len
import { openFinalizeDeliverableConfirmModal, openGoLiveConfirmationModal } from "../../state/campaignDeliverableContentModalV2Slice"

function UpdateStatusModal(): React.JSX.Element {
  const {
    open,
    deliverableId,
    deliverableStatus,
  } = useSelector(({ campaignDeliverableV2UpdateStatusModal }) => campaignDeliverableV2UpdateStatusModal)

  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.UpdateStatusModal" })
  const [ statusInput, setStatusInput ] = useState<DeliverableStatus>()
  const { campaignID } = useParams()
  const [ updateStatus, { loading } ] = useMutation<
  UpdateDeliverableStatusOutput,
    UpdateDeliverableStatusInput
  >(UPDATE_DELIVERABLE_STATUS)

  useEffect(() => {
    setStatusInput(deliverableStatus || DeliverableStatus.DRAFT)
  }, [ deliverableStatus ])

  const statuses = useMemo(() => [
    {
      label: translate("Draft"),
      value: DeliverableStatus.DRAFT,
    },
    {
      label: translate("Pending Feedback"),
      value: DeliverableStatus.PENDING_FEEDBACK,
    },
    {
      label: translate("Pending Revision"),
      value: DeliverableStatus.PENDING_REVISION,
    },
    {
      label: translate("Finalized"),
      value: DeliverableStatus.FINALIZED,
    },
    {
      label: translate("Live"),
      value: DeliverableStatus.LIVE,
    },
  ], [ translate ])

  const handleClose = () => {
    dispatch(closeDeliverableUpdateStatusModal())
  }

  const handleUpdate = async () => {
    if (!deliverableId || !statusInput || !campaignID) return
    if (statusInput === DeliverableStatus.LIVE) {
      if (!searchParams.has("deliverableId")) setSearchParams({ deliverableId })
      dispatch(openGoLiveConfirmationModal())
    } else if (statusInput === DeliverableStatus.FINALIZED) {
      if (!searchParams.has("deliverableId")) setSearchParams({ deliverableId })
      dispatch(openFinalizeDeliverableConfirmModal())
    } else {
      await updateStatus({
        variables: {
          deliverableId,
          status: statusInput,
        },
        awaitRefetchQueries: true,
        context: {
          apiVersion: "v2",
        },
        refetchQueries: [ {
          query: GET_DELIVERABLE,
          variables: { deliverableId },
          context: {
            apiVersion: "v2",
          },
          fetchPolicy: "network-only",
        }, {
          query: GET_CAMPAIGN_DETAILS,
          variables: { campaignId: campaignID },
          context: {
            apiVersion: "v2",
          },
          fetchPolicy: "network-only",
        },
        {
          query: GET_DELIVERABLE_HISTORY,
          variables: { deliverableId },
          context: { apiVersion: "v2" },
        },
        ],
      })
    }
    handleClose()
  }

  return (
    <Modal
      id="cp_component_deliverable-v2_update-status-modal"
      className="cp_component_deliverable-v2_update-status-modal"
      title={ translate("Update Status") }
      closeAction={ handleClose }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("UPDATE") }
      secondaryLabel={ translate("CANCEL") }
      primaryAction={ handleUpdate }
      secondaryAction={ handleClose }
      open={ open }
    >
      <p className="label_small-caps-bold select-label">{ translate("Status") }</p>
      <Select
        label=""
        className="status-select"
        id="modal-update-deliverable-status-select"
        labelId=""
        fullWidth={ true }
        value={ statusInput }
        defaultValue={ statusInput }
        onChange={ (e) => setStatusInput(e.target.value as DeliverableStatus) }
        menuItems={ statuses.map(({ label, value }) => <MenuItem key={ value } value={ value }>{ label }</MenuItem>) }
      />
    </Modal>
  )
}

export default UpdateStatusModal
