import React, { JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import AddIcon from "@mui/icons-material/Add"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { useSearchParams } from "react-router-dom"
import { ReactComponent as SearchAIIcon } from "../../asset/img/ai_search_light_icon.svg"
import Tooltip from "../Tooltip"
import FilterPanel from "./FilterPanel"

import "./prompt-ai.sass"
import Input from "../Input"
import Pill from "../Pill"
import { useSelector, useDispatch } from "../../state/hooks"
import { setSearchInput } from "../../state/searchAISlice"
import { cloneSearchInput, setSearchInputQueryParams } from "../../state/searchAISlice/helper"

export default function PromptAI(): JSX.Element {
  const [ aiSearch, setAISearch ] = React.useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })
  const { searchAIInput } = useSelector(({ searchAI }) => searchAI)
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()

  const removePrompt = (id: number) => {
    const { prompts } = searchAIInput
    const newInput = cloneSearchInput(searchAIInput)
    newInput.prompts = prompts.filter((prompt) => prompt.id !== id)
    dispatch(setSearchInput(newInput))
    setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  const addPrompt = () => {
    if (aiSearch.trim().length === 0) return
    const { prompts } = searchAIInput
    const newInput = cloneSearchInput(searchAIInput)
    newInput.prompts = [
      ...prompts,
      {
        id: prompts.length + 1,
        value: aiSearch,
      },
    ]
    dispatch(setSearchInput(newInput))
    setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
    setAISearch("")
  }

  const filterCount = useMemo(() => {
    const count = searchAIInput.prompts.length
    return count
  }, [ searchAIInput.prompts.length ])

  return (
    <FilterPanel
      panelID="search-ai-prompt-panel"
      title={ translate("AI Search") }
      customPanelIcon={ SearchAIIcon }
      filterCount={ filterCount }
    >
      <Stack padding={ 2 } gap={ 1 } className="cp_component-prompt-ai">
        <Stack direction="row" alignItems="center" gap={ 0.5 }>
          <Typography fontSize="0.75rem" fontWeight={ 700 }>
            { translate("Influential AI Search") }
          </Typography>
          <Tooltip title={ (
            <Typography fontSize="0.75rem" color="inherit">
              { translate("Enter a word or phrase to find related Accounts and Content.") }
            </Typography>
            ) }
          >
            <InfoOutlinedIcon fontSize="inherit" className="cp_component-prompt-ai-icon-muted" color="inherit" />
          </Tooltip>
        </Stack>
        <form onSubmit={ (e) => {
          e.preventDefault()
          addPrompt()
        } }
        >
          <Input
            id="cp_component-prompt-ai-input"
            value={ aiSearch }
            onChange={ (e) => setAISearch(e.target.value) }
            onBlur={ addPrompt }
            placeholder={ translate("Ask Influential AI") }
            InputProps={ {
              startAdornment: (
                <AutoAwesomeIcon className="cp_component-prompt-ai-input-adornment" htmlColor="#C5CACE" fontSize="small" />
              ),
              endAdornment: (
                <IconButton id="cp_component-prompt-ai-add" size="small" onClick={ addPrompt }>
                  <AddIcon htmlColor="#C5CACE" fontSize="small" />
                </IconButton>
              ),
            } }
          />
        </form>
        <Stack flexWrap="wrap" direction="row" alignItems="center" gap={ 0.5 }>
          { searchAIInput.prompts.map((prompt, i) => (
            <Pill
              id={ `cp_component-prompt-ai-prompt-${ i }` }
              className="cp_component-prompt-ai-pill"
              key={ prompt.id }
              label={ prompt.value }
              onDelete={ () => removePrompt(prompt.id) }
            />
          )) }
        </Stack>
      </Stack>
    </FilterPanel>
  )
}
