import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import "./style.sass"
import { useDispatch } from "../../state/hooks"
import { fetchCampaignReportMetricDefinitions, fetchPublicCampaignReportV2 } from "../../state/publicCampaignReport"
import PublicCampaignReportV2 from "../../component/PublicCampaignReport/PublicCampaignReportV2"

export default function PublicCampaignReportPageV2() {
  const dispatch = useDispatch()
  const { reportCode } = useParams()

  useEffect(() => {
    if (!reportCode) return
    dispatch(fetchPublicCampaignReportV2({ code: reportCode }))
    dispatch(fetchCampaignReportMetricDefinitions())
  }, [ reportCode ])

  return (
    <div className="cp_public-campaign-report">
      <div className="cp_public-campaign-report-container">
        <PublicCampaignReportV2 />
      </div>
    </div>
  )
}
