import React from "react"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useTranslation } from "react-i18next"

import "./ethnicity-chart.sass"

import { prettyPrintDecimal } from "../../../util/miscHelper"
import { CHART_BASE_COLORS, CHART_BORDER_COLORS } from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import { useSelector } from "../../../state/hooks"

const baseOptions: Options = {
  chart: {
    type: "pie",
    plotShadow: false,
    height: 300,
    width: 300,
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  plotOptions: {
    pie: {
      cursor: "pointer",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "white",
      dataLabels: {
        color: "white",
        enabled: true,
        format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
        distance: -80,
        className: "cp_ethnicity-chart_component-chart-label",
        filter: {
          property: "percentage",
          operator: ">",
          value: 10,
        },
        useHTML: true,
        style: {
          width: 80,
          textOverflow: "ellipsis",
          fontSize: "0.9rem",
        },
      },
    },
  },
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
    },
    allowDecimals: true,
    labels: {
      format: "{value:.1f}",
    },
  },
  tooltip: {
    pointFormatter(this: Point) {
      // eslint-disable-next-line max-len
      return `<span style="color:${ this.color }">\u25CF</span> <b>${ prettyPrintDecimal((this.y || 0)) }%</b><br/>`
    },
  },
  credits: {
    enabled: false,
  },
}

type Props = {
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
}

export default function EthnicityChart({ audienceDemographics }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.EthnicityChart" })
  const { theme } = useSelector(({ themeMode }) => themeMode)

  const ethnicityGroups = audienceDemographics?.ethnicityGroups || []

  const getOptions = () => {
    if (!baseOptions.plotOptions?.pie) return baseOptions
    baseOptions.plotOptions.pie.colors = Highcharts
      .getOptions()
      .colors?.map((c, i, _colors) => Highcharts.color(CHART_BASE_COLORS[theme][i % _colors.length]).get())

    baseOptions.plotOptions.pie.borderColor = CHART_BORDER_COLORS[theme]

    baseOptions.legend = {
      borderWidth: 1,
      borderRadius: 2,
      padding: 16,
    }
    baseOptions.series = []
    baseOptions.series.push({
      name: translate("Ethnicity"),
      type: "pie",
      data: ethnicityGroups.map(({ name, value: y }) => ({ name, y })),
    })
    return baseOptions
  }

  return (
    <div className="cp_ethnicity-chart_component">
      <div className="cp_ethnicity-chart_component-chart">
        <HighchartsReact highcharts={ Highcharts } options={ getOptions() } />
      </div>
    </div>
  )
}
