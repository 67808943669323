import React from "react"
import "./style.sass"

/**
 * PageHeader: Renders the header of the page with the radius logo
 * @returns The JSX Element to render the header
 */
export default function PageHeader() {
  return (
    <div className="cp_component_pl-account-page-header">
      <div className="cp_component_pl-account-page-header-logo" />
    </div>
  )
}
