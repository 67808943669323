import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import "./campaign-widget.sass"

import { useMemo, useState } from "react"
import { CampaignPageFragment } from "../../../graphql"
import { useSelector } from "../../../state/hooks"
import Avatar from "../../Avatar"
import IconButton from "../../IconButton"
import Tabs from "../../Tabs"
import CampaignWidgetDeliverables from "./CampaignWidgetDeliverables"
import CampaignWidgetAccounts from "./CampaignWidgetAccounts"
import { isSuccess } from "../../../util/apiClient"
import CampaignWidgetDeliverablesV2 from "./CampaignWidgetDeliverablesV2"
import { Scope } from "../../../util/types"

interface CampaignWidgetProps {
  campaign: CampaignPageFragment
}

export default function CampaignWidget({ campaign }: CampaignWidgetProps) {
  const [ tabIndex, setTabIndex ] = useState(0)
  const { t: translate } = useTranslation([], { keyPrefix: "component.DashboardCampaignWidget" })
  const userDetails = useSelector(({ user: userSlice }) => userSlice.user)
  const navigate = useNavigate()
  const { scopes } = useSelector(({ user }) => user)

  const vanity = useMemo(() => {
    if (!isSuccess(userDetails)) return ""
    return userDetails.payload.currentUser?.customer.vanity
  }, [ userDetails ])

  const tabOptions = useMemo(() => [
    {
      label: translate("Deliverables"),
      className: "cp_dashboard-campaign_component-tab-deliverables",
      id: "cp_dashboard-campaign_component-tab-deliverables",
      badge: campaign.deliverables.length,
    },
    {
      label: translate("Accounts"),
      className: "cp_dashboard-campaign_component-tab-accounts",
      id: "cp_dashboard-campaign_component-tab-accounts",
      badge: campaign.campaignNetworkAccounts.length,
    },
  ], [ translate, campaign ])
  const handleTabChange = (i: number) => setTabIndex(i)

  return (
    <div className="cp_dashboard-campaign_component">
      <div className="cp_dashboard-campaign_component-header">
        <div className="cp_dashboard-campaign_component-header-info">
          <Avatar src={ campaign.thumbnail?.url.address } size="lg">
            { campaign.name[0] }
          </Avatar>
          <h6 className="cp_dashboard-campaign_component-header-info-title">
            { campaign.name }
          </h6>
        </div>
        <IconButton
          disabled={ !vanity }
          onClick={ () => {
            navigate(`/${ vanity }/campaigns/${ campaign.id }/overview`)
          } }
        >
          <OpenInFullIcon />
        </IconButton>
      </div>
      <div className="cp_dashboard-campaign_component-body">
        <Tabs
          tabs={ tabOptions }
          controls={ <> </> }
          handleChange={ handleTabChange }
        />
        { tabIndex === 0 && (scopes.includes(Scope.DEVELOPMENT)
          ? <CampaignWidgetDeliverablesV2 campaign={ campaign } />
          : <CampaignWidgetDeliverables campaign={ campaign } />) }
        { tabIndex === 1 && <CampaignWidgetAccounts campaign={ campaign } /> }
      </div>
    </div>
  )
}
