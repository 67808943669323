import {
  CheckBoxOutlined,
  CheckCircleOutline,
  RemoveRedEyeOutlined,
} from "@mui/icons-material"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import Switch from "../../../Switch"

interface PermissionToggle {
  id: permissionValues
  titleKey: string
  descriptionKey: string
  icon: React.ReactNode
}

type permissionValues = "FINALIZE"| "APPROVE" | "COMMENT"

interface Props {
  permission: permissionValues
  onToggle?: (id: permissionValues)=>void
}

const permissionToggles: PermissionToggle[] = [
  {
    id: "FINALIZE",
    titleKey: "Finalize Feedback",
    descriptionKey: "Enabling this permission also enables Approve and View & Leave Feedback permissions.",
    icon: <CheckBoxOutlined />,
  },
  {
    id: "APPROVE",
    titleKey: "Approve",
    descriptionKey: "Enabling this permission also enables View & Leave Feedback permissions.",
    icon: <CheckCircleOutline />,
  },
  {
    id: "COMMENT",
    titleKey: "View & Leave Feedback",
    descriptionKey: "User can view Deliverable content and leave feedback on Media or Caption.",
    icon: <RemoveRedEyeOutlined />,
  },
]

export default function PermissionsToggle({ permission, onToggle }:Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PermissionsToggle" })
  const handleToggle = (id: permissionValues) => {
    if (onToggle) {
      onToggle(id)
    }
  }

  const isChecked = (id: permissionValues):boolean => {
    if (permission === id) {
      return true
    }

    switch (id) {
      case "APPROVE":
        if (permission === "FINALIZE") return true
        break
      case "COMMENT":
        if (permission === "FINALIZE" || permission === "APPROVE") return true
        break
      default:
        return false
    }

    return false
  }
  return (
    <Box className="permissions-toggle-container">
      <List>
        { permissionToggles.map((toggle) => (
          <ListItem
            key={ toggle.id }
            alignItems="flex-start"
            className="permissions-list"
          >
            <ListItemIcon className="permissions-icon">
              { toggle.icon }
            </ListItemIcon>
            <ListItemText
              primary={ (
                <Typography component="div" className="permissions-title">
                  { translate(toggle.titleKey) }
                </Typography>
              ) }
              secondary={ (
                <Typography color="text.secondary">
                  { translate(toggle.descriptionKey) }
                </Typography>
              ) }
            />
            <ListItemSecondaryAction>
              <Switch
                id={ toggle.id }
                className="switch-component"
                handleChange={ () => handleToggle(toggle.id) }
                isChecked={ isChecked(toggle.id) }
              />
            </ListItemSecondaryAction>
          </ListItem>
        )) }
      </List>
    </Box>
  )
}
