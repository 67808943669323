import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import { CloseOutlined } from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import { isSuccess } from "../../util/apiClient"
import {
  createFeedbackV2,
  PublicListSocialAccount,
  setFeedbackComment,
  setInfoRequiredSubmitCallback,
  setLeaveFeedback,
  userCanEdit,
} from "../../state/publicListSlice"
import EntityInfoRow from "../../component/EntityInfoRow"
import IconButton from "../../component/IconButton"
import Input from "../../component/Input"
import Button from "../../component/Button"
import LoadingIndicator from "../../component/LoadingIndicator"
import CommentCard from "../PublicListV2/CommentCard"

/**
 * FeedbackOverlay: The component for rendering the feedback overlay
 * @returns The JSX Elements to render feedback overlay
 */
export default function FeedbackOverlay() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listCode, accountCode } = useParams()

  // Global state
  const {
    leftPanelApprovedStatus,
    nameCookieKey,
    companyCookieKey,
    userVerification,
    feedbackComment,
    loadedListSocialAccount,
  } = useSelector(({ publicList }) => publicList)

  // Cookies for handling required information
  const [ cookies ] = useCookies([ nameCookieKey, companyCookieKey ])

  // Local state
  const [ feedbackInput, setFeedbackInput ] = React.useState<string>(feedbackComment || "")
  const [ accessCodeRestriction, setAccessCodeRestriction ] = React
    .useState<GraphQL.SuggestionAccessCodeRestriction | null | undefined>()
  const [ userIsValid, setUserIsValid ] = React.useState<boolean>(false)
  const [ submitted, setSubmitted ] = React.useState<boolean>(false)

  // React hook to retrieve the suggestion list social account
  const account = React.useMemo((): PublicListSocialAccount => {
    if (isSuccess(loadedListSocialAccount)) {
      return loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
    }
    return {} as PublicListSocialAccount
  }, [ loadedListSocialAccount ])

  /**
   * React hook: Set the access restriction and verify that the user is valid
   */
  React.useEffect(() => {
    if (isSuccess(userVerification)) {
      const { publicVerifySuggestionListAccessCodeByListCode: verification } = userVerification.payload
      setAccessCodeRestriction(verification.suggestionAccessCodeRestriction)
      setUserIsValid(verification.isValid)
    }
  }, [ userVerification ])

  /**
   * submitFeedback: Checks to see if the required information exists, if not redirects to the
   * required information page and then finally creates and saves the comment
   */
  const submitFeedback = async () => {
    // Check to see that code is present
    setSubmitted(true)
    if (listCode && accountCode) {
      if (cookies[nameCookieKey] && cookies[companyCookieKey] && userCanEdit(accessCodeRestriction, userIsValid)) {
        dispatch(setFeedbackComment())
        dispatch(createFeedbackV2(
          listCode,
          account.id,
          feedbackInput,
          () => {
            // Close the feedback
            dispatch(setLeaveFeedback(false))
          },
        ))
      } else {
        dispatch(setInfoRequiredSubmitCallback(() => {
          dispatch(setFeedbackComment())
          dispatch(createFeedbackV2(
            listCode,
            account.id,
            feedbackInput,
            () => {
              // Close the feedback
              dispatch(setLeaveFeedback(false))
              navigate(`../../account/${ account.socialAccount.id }`, { relative: "path" })
            },
          ))
        }))
        navigate(`../../requiredInformation/${ account.id }`, { relative: "path" })
      }
    }
  }

  // The JSX Elements for rendering
  return (
    <div className="cp_component_pl-account-page-content-feedback">
      <div className="cp_component_pl-account-page-content-feedback-header">
        <div className="cp_component_pl-account-page-content-feedback-header-avatar">
          <EntityInfoRow
            avatarSize="lg"
            avatarSrc={ account.socialAccount.profilePictureUrl }
            network={ account.socialAccount.network }
            name={ account.socialAccount.userName }
          />
          <div className="cp_component_pl-account-page-content-feedback-header-avatar-title">
            <div className="static-feedback-title">{ translate("Feedback") }</div>
            <div className="feedback-counter">
              { leftPanelApprovedStatus.find((accountStatus) => accountStatus.accountId === account.id)?.comments.length || 0 }
            </div>
          </div>
        </div>
        <div className="cp_component_pl-account-page-content-feedback-header-closer">
          <IconButton
            className="close-feedback-overlay-button"
            onClick={ () => dispatch(setLeaveFeedback(false)) }
          >
            <CloseOutlined className="cp_component_pl-account-page-content-feedback-header-closer-icon" />
          </IconButton>
        </div>
      </div>
      <div className="cp_component_pl-account-page-content-feedback-content">
        { (leftPanelApprovedStatus.find((accountStatus) => accountStatus.accountId === account.id)?.comments.length === 0) && (
          <div className="cp_component_pl-account-page-content-feedback-content-no-feedback">
            <p className="no-feedback-text">{ translate("No feedback yet.") }</p>
            <p className="no-feedback-text">{ translate("Leave a feedback comment below") }</p>
          </div>
        ) }
        { leftPanelApprovedStatus.find((accountStatus) => accountStatus.accountId === account.id)?.comments.map((comment) => (
          <CommentCard comment={ comment } />
        )) }
      </div>
      <div className="cp_component_pl-account-page-content-feedback-footer">
        <Input
          className="cp_component_pl-account-page-content-feedback-footer-feedback-message"
          value={ feedbackInput }
          onChange={ (e) => setFeedbackInput(e.currentTarget.value) }
          type="text"
          multiline={ true }
          maxRows={ 5 }
          fullWidth={ true }
          required={ true }
          placeholder={ translate("Leave Feedback...") }
        />
        <Button
          className="cp_component_pl-account-page-content-feedback-footer-submit-button"
          label={ (submitted) ? <LoadingIndicator /> : translate("Submit") }
          isPrimary={ true }
          onClick={ submitFeedback }
        />
      </div>
    </div>
  )
}
