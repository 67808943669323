import React from "react"
import { useTranslation } from "react-i18next"

import "./media-list.sass"
import DeliverableMediaCard from "./MediaCard"
import { DeliverableMediaItem } from "../../state/campaignDeliverableContentModalV2Slice/types"

interface DeliverableMediaListProps {
  deliverableMedia: DeliverableMediaItem[]
}

function DeliverableMediaList({ deliverableMedia }: DeliverableMediaListProps): React.JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })
  if (deliverableMedia.length === 0) return <> </>
  return (
    <div id="cp_campaign-deliverable-media-list-v2_component" className="cp_campaign-deliverable-media-list-v2_component">
      <p className="body_large">
        { translate("Media*") }
      </p>
      <div className="cp_campaign-deliverable-media-list-v2_component-body">
        {
          deliverableMedia.map((media) => (
            <DeliverableMediaCard
              key={ media.id }
              deliverableMedia={ media }
            />
          ))
        }
      </div>
    </div>
  )
}

export default DeliverableMediaList
