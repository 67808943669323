import React from "react"
import Highcharts, { Options, Point } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useTranslation } from "react-i18next"

import "./marital-chart.sass"

import { useSelector } from "../../../state/hooks"
import { prettyPrintDecimal } from "../../../util/miscHelper"
import { CHART_BASE_COLORS, CHART_BORDER_COLORS } from "../../../util/constant"
import * as GraphQL from "../../../graphql"

const baseOptions: Options = {
  chart: {
    type: "pie",
    plotShadow: false,
    height: 300,
    width: 300,
    style: {
      fontFamily: "Open Sans, sans-serif",
    },
  },
  plotOptions: {
    pie: {
      cursor: "pointer",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "white",
      dataLabels: {
        color: "white",
        enabled: true,
        format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
        distance: -50,
        className: "cp_family-chart_component-chart-label",
        filter: {
          property: "percentage",
          operator: ">",
          value: 10,
        },
      },
    },
  },
  title: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
    },
    allowDecimals: true,
    labels: {
      format: "{value:.1f}",
    },
  },
  tooltip: {
    pointFormatter(this: Point) {
      // eslint-disable-next-line max-len
      return `<span style="color:${ this.color }">\u25CF</span> <b>${ prettyPrintDecimal((this.y || 0) / 100) }%</b><br/>`
    },
  },
  credits: {
    enabled: false,
  },
}

type Props = {
  audienceDemographics: GraphQL.AudienceDemographicsFragment | undefined | null
}

export default function MaritalChart({ audienceDemographics }: Props) {
  const theme = useSelector(({ themeMode }) => themeMode.theme)
  const { t: translate } = useTranslation([], { keyPrefix: "component.MaritalChart" })

  const singleScore = audienceDemographics?.familyStatistics?.single || 0
  const marriedScore = audienceDemographics?.familyStatistics?.married || 0

  const getOptions = () => {
    if (!baseOptions.plotOptions?.pie) return baseOptions
    baseOptions.plotOptions.pie.colors = Highcharts
      .getOptions()
      .colors?.map((c, i, _colors) => Highcharts.color(CHART_BASE_COLORS[theme][i % _colors.length]).get())

    baseOptions.plotOptions.pie.borderColor = CHART_BORDER_COLORS[theme]

    baseOptions.legend = {
      borderWidth: 1,
      borderRadius: 2,
      padding: 16,
    }
    baseOptions.series = []
    baseOptions.series.push({
      name: "Gender",
      type: "pie",
      data: [
        {
          name: translate("Single"),
          y: singleScore * 100,
        },
        {
          name: translate("Married"),
          y: marriedScore * 100,
        },
      ].sort((a, b) => b.y - a.y),
    })
    return baseOptions
  }

  return (
    <div className="cp_marital-chart_component">
      <div className="cp_marital-chart_component-chart">
        <HighchartsReact highcharts={ Highcharts } options={ getOptions() } immutable={ false } />
      </div>
    </div>
  )
}
