import React from "react"
import "./style.sass"

import { useNavigate } from "react-router-dom"

import { SuggestionListCategory } from "./CategoryAccounts"
import AccountCard from "./AccountCard"
import { PublicListApprovalStatus } from "../../util/types"
import { useDispatch } from "../../state/hooks"
import {
  AccountApprovalStatus,
  FeedbackComment,
  setLeftPanelAccountSelected,
  setLeftPanelApprovedStatus,
} from "../../state/publicListSlice"

interface CategoryLeftPanelProps {
  category: SuggestionListCategory
  isCampaign: boolean
  isEpsilonVerified: boolean
}

export default function CategoryLeftPanel({
  category,
  isCampaign,
  isEpsilonVerified,
}: CategoryLeftPanelProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    const campaignApprovalStatuses: AccountApprovalStatus[] = category.suggestionListSocialAccounts.map((account) => {
      if (isCampaign) {
        return {
          accountId: account.id,
          status: (account.approvalStatus?.value) ? PublicListApprovalStatus.Approved : PublicListApprovalStatus.NotApproved,
          comments: account.comments.map((feedback) => ({
            author: (feedback.author.__typename === "AnonymousUser")
              ? feedback.author.name
              : (feedback.author.__typename === "User")
                ? feedback.author.contact.name
                : "",
            company: (feedback.author.__typename === "AnonymousUser")
              ? feedback.author.company
              : (feedback.author.__typename === "User")
                ? feedback.author.customer.company?.name || ""
                : "",
            comment: feedback.content,
            created: feedback.created,
          } as FeedbackComment)),
        } as AccountApprovalStatus
      }
      return {
        accountId: account.id,
        status: PublicListApprovalStatus.NotApproved,
        comments: [],
      }
    })
    dispatch(setLeftPanelApprovedStatus(campaignApprovalStatuses))
  }, [ category ])

  const handleSelectedAccount = (index: number, openAccount: boolean) => {
    // Set index for selected account
    dispatch(setLeftPanelAccountSelected(index))

    // Navigate to the account page
    if (openAccount) {
      navigate(`./account/${ category.suggestionListSocialAccounts[index].socialAccount.id }`, { relative: "path" })
    }
  }

  return (
    <div className="cp_component_pl-category-left-panel">
      <h5 className="title_medium">{ category.name }</h5>
      { (category.suggestionListSocialAccounts.map((account, index) => (
        <AccountCard
          account={ account }
          isCampaign={ isCampaign }
          onClick={ (openAccount) => { handleSelectedAccount(index, openAccount) } }
          isEpsilonVerified={ isEpsilonVerified }
        />
      ))) }
    </div>
  )
}
