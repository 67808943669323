import { gql } from "@apollo/client"

export const DELIVERABLE_SUMMARY_FRAGMENT = gql` fragment DeliverableSummaryFragment on DeliverableSummary {
    id
    name
    caption
    deliverableStatus
    feedbackCount
    networkId
    ttcmCampaignName
    mediaUrl
    reviewerGroupName
    reviewerGroupStatus
    postTracker{
      id
      enabled
      status
    }
    socialAccount{
      id
      name
      username
      profilePictureUrl
      followerCount
      networkType
    }

}

`

export const CAMPAIGN_DETAILS_FRAGMENT = gql` fragment CampaignDetailsFragment on Campaign{
    id
    numberOfAllDeliverables
    numberOfDeliverablesWithoutContent
    numberOfPendingFeedBackDeliverables
    numberOfPendingRevisionDeliverables
    numberOfFinalizedDeliverables
    numberOfCampaignNetworkAccounts
    numberOfLiveDeliverables
}

`

export const CAMPAIGN_VERSION_FRAGMENT = gql` fragment CampaignVersionFragment on CampaignVersion{
    id
    isMigrated
}
`

export const USER_DELIVERABLE_NOTIFICATION_FRAGMENT = gql` 
fragment UserDeliverableNotifcationFragment on UserDeliverableNotification {
id
notificationPreferences
}

`
