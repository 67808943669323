/* eslint-disable max-len */
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import "./modal-confirm.sass"
import {
  Button,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import Modal from "../Modal"
import LoadingIndicator from "../LoadingIndicator"
import { useSelector, useDispatch } from "../../state/hooks"
import {
  closeDeliverableContentModal,
  closeFinalizeConfirmModal,
  finalizeDeliverable,
  updateDeliverableLiveStatus,
} from "../../state/campaignDeliverableContentModalSlice"
import { isSuccess } from "../../util/apiClient"
import { refreshCampaign } from "../../state/campaignSlice"
import { DeliverableStatus, Network } from "../../graphql"
import Input from "../Input"
import { pushToast } from "../../state/toastSlice"
import StatusDeliverable from "../StatusDeliverable"

const steps: DeliverableStatus[] = [ DeliverableStatus.Pending, DeliverableStatus.Finalized, DeliverableStatus.Live ]

function ModalConfirm(): React.JSX.Element {
  const [ loadingPrimaryAction, setLoadingPrimaryAction ] = React.useState(false)
  const [ liveUrl, setLiveUrl ] = React.useState("")

  const {
    deliverable,
    confirmFinalize,
    onClose,
    edited,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const dispatch = useDispatch()

  const closeModal = (isEdited: boolean = false) => {
    dispatch(closeFinalizeConfirmModal())
    dispatch(closeDeliverableContentModal())
    if (onClose) onClose(edited || isEdited)
  }

  const currentStepIndex = useMemo(() => {
    if (!deliverable) return -1
    if (deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok && steps.length === 3) {
      steps.splice(2, 0, DeliverableStatus.Uploaded)
    }
    return steps.findIndex((step) => step === deliverable.contentStatus)
  }, [ deliverable ])

  const confirmHandler = async () => {
    if (!deliverable) return
    setLoadingPrimaryAction(true)
    const result = await dispatch(finalizeDeliverable({ deliverableId: deliverable.id }))
    setLoadingPrimaryAction(false)
    if (isSuccess(result)) {
      dispatch(refreshCampaign())
      closeModal(true)
    } else {
      dispatch(pushToast({
        message: translate("Failed to update finalized status"),
        type: "error",
      }))
    }
  }

  const goLiveHandler = async () => {
    if (!deliverable) return
    setLoadingPrimaryAction(true)
    const result = await dispatch(updateDeliverableLiveStatus({ deliverableId: deliverable.id, livePostUrl: liveUrl }))
    setLoadingPrimaryAction(false)
    if (isSuccess(result)) {
      dispatch(refreshCampaign())
      closeModal()
    } else {
      dispatch(pushToast({
        message: translate("Failed to update live status"),
        type: "error",
      }))
    }
  }

  const modalIsOpen = Boolean(deliverable) && confirmFinalize

  useEffect(() => {
    if (!modalIsOpen) setLiveUrl("")
    if (deliverable?.contentStatus === DeliverableStatus.Uploaded) setLiveUrl(deliverable?.liveStatusUrl?.address || "")
  }, [ modalIsOpen, deliverable ])

  const primaryButtonLabel = useMemo(() => {
    if (loadingPrimaryAction) return <LoadingIndicator size={ 20 } />
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      || deliverable?.contentStatus === DeliverableStatus.Uploaded
    ) return translate("Go Live")
    return translate("Confirm")
  }, [ loadingPrimaryAction, deliverable ])

  if (!deliverable) return <> </>

  return (
    <Modal
      title={ deliverable.name }
      primaryLabel={ primaryButtonLabel }
      secondaryLabel={ translate("Cancel") }
      open={ modalIsOpen }
      closeAction={ () => closeModal() }
      secondaryAction={ () => closeModal() }
      primaryAction={ deliverable.contentStatus === DeliverableStatus.Finalized || deliverable.contentStatus === DeliverableStatus.Uploaded ? goLiveHandler : confirmHandler }
      maxWidth="xl"
      className="cp_component_modal-finalize-deliverable"
      disabled={ loadingPrimaryAction || (!liveUrl && deliverable.contentStatus === DeliverableStatus.Finalized) }
    >
      <div
        className="cp_component_modal-finalize-deliverable-container"
      >
        <div
          className="cp_component_modal-finalize-deliverable-container-head"
        >
          <Button
            startIcon={ <ChevronLeftIcon /> }
            onClick={ () => dispatch(closeFinalizeConfirmModal()) }
          >
            { translate("Back to deliverable") }
          </Button>
        </div>
        <div
          className="cp_component_modal-finalize-deliverable-container-body"
        >
          <h6>
            { translate("Please Confirm") }
          </h6>
          <Stepper nonLinear={ true }>
            { steps.map((label, index) => (
              <Step key={ label } completed={ index <= currentStepIndex }>
                <StepLabel color="inherit" icon={ <StatusDeliverable hideLabel={ true } deliverableStatus={ label } /> }>
                  { label }
                </StepLabel>
              </Step>
            )) }
          </Stepper>
          {
            deliverable.contentStatus !== DeliverableStatus.Finalized && deliverable?.contentStatus !== DeliverableStatus.Uploaded ? (
              <>
                <p>
                  { translate("Please confirm that all deliverable content") }
                  <strong>
                    { translate("is approved and ready to go live.") }
                  </strong>
                  { translate("Once finalized, the post will be scheduled and status can only be reverted if the deliverable is updated.") }
                </p>
                <p>
                  { translate("Finalizing deliverable will notify all assigned parties.") }
                </p>
              </>
            ) : (
              <>
                <p>
                  { translate("Once live, Brand Managers will be notified and the status cannot be reverted. Please verify that the live post matches the approved content and enter the post URL below.") }
                </p>
                <div>
                  <Input
                    placeholder={ translate("Enter Post URL") }
                    label={ translate("Live Post URL") }
                    value={ liveUrl }
                    onChange={ (e) => setLiveUrl(e.currentTarget.value) }
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirm
