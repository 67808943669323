import React from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"

import "./content.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import EmptySplash from "../EmptySplash"
import Button from "../Button"
import DeliverableContentNote from "./ContentNote"
import DeliverableMediaList from "./MediaList"
import Caption from "./Caption"
import MediaApprovalConfirmation from "./MediaApprovalConfirmation"
import MediaFeedback from "./MediaFeedback"
import CaptionApprovalConfirmation from "./CaptionApprovalConfirmation"
import CaptionFeedback from "./CaptionFeedback"
import { Scope } from "../../util/types"
import { openEditDeliverableV2Modal } from "../../state/updateCampaignDeliverableV2Modal"

// eslint-disable-next-line max-len
const emptyText = "There is no content available to review for this deliverable yet. Once media and/or captions are added, you can approve or leave feedback for them here."

function ModalCampaignDeliverableContentTabV2(): React.JSX.Element {
  const {
    deliverable,
    deliverableMedia,
    deliverableCaption,
    mediaToApprove,
    selectedMediaForFeedback,
    captionToApprove,
    captionFeedback,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const {
    scopes,
  } = useSelector(({ user }) => user)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const dispatch = useDispatch()

  const handleEditDeliverable = () => {
    if (!deliverable) return
    dispatch(openEditDeliverableV2Modal({ deliverableId: deliverable.id }))
  }

  if (!deliverable) return <> </>
  if (!deliverableCaption && !deliverableMedia?.length) {
    return (
      <div className="cp_campaign-deliverable-v2-content-tab_component empty">
        <EmptySplash
          headlineText={ translate("CONTENT FOR THIS DELIVERABLE IS AWAITING SUBMISSION") }
          bodyText={ translate(emptyText) }
        />
        {
          scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Button
            className="upload-content-button"
            isPrimary={ false }
            startIcon={ <AddIcon /> }
            label={ translate("Upload Content") }
            onClick={ handleEditDeliverable }
          />
          ) }
      </div>
    )
  }
  return (
    <div className="cp_campaign-deliverable-v2-content-tab_component">
      <div className="cp_campaign-deliverable-v2-content-tab_component-title">{ translate("Content") }</div>
      <div className="cp_campaign-deliverable-v2-content-tab_component-container">
        <DeliverableContentNote note={ deliverable.note } />
        {
          mediaToApprove
            ? <MediaApprovalConfirmation deliverableMedia={ mediaToApprove } />
            : <DeliverableMediaList deliverableMedia={ deliverableMedia || [] } />
        }
        { selectedMediaForFeedback
          ? <MediaFeedback deliverableMedia={ selectedMediaForFeedback } />
          : null
        }
        {
          captionToApprove
            ? <CaptionApprovalConfirmation caption={ captionToApprove } />
            : <Caption caption={ deliverableCaption } />
        }
        {
          captionFeedback
            ? <CaptionFeedback caption={ captionFeedback } />
            : null
        }
      </div>
    </div>
  )
}

export default ModalCampaignDeliverableContentTabV2
