import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

import * as GraphQL from "../../../graphql"
import { useSelector } from "../../../state/hooks"
import { HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES, SUGGESTED_POSTS_QTY } from "../../../util/constant"
import Pill from "../../../component/Pill"
import { SuggestionListAccount } from "../CategoryAccounts"
import { SocialAccountFetchedTopics } from "../../../state/listConfigurationSlice"

/**
 * ContentSectionProps: Properties for component
 */
interface ContentSectionProps {
  account: SuggestionListAccount
  toggles: string[]
}

/**
 * ContentSection:  Display the top posts, posted keywords, posted image tags
 * @param param0 The account information
 * @returns The display element for this component
 */
export default function ContentSection({
  account,
  toggles,
}: ContentSectionProps) {
  // Local variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ConfigurationHighlightsTab" })

  // Pull the list form to check toggles
  const {
    contentTopPosts,
    contentKeywordPosts,
    contentImageTagPosts,
    contentPostKeywords,
    contentPostImageTags,
  } = useSelector((state) => state.publicList)

  // Local state
  const [ keywordTagIndex, setKeywordTagIndex ] = React.useState<number>(0)
  const [ imageTagIndex, setImageTagIndex ] = React.useState<number>(0)

  const accountTopPosts: SocialAccountFetchedTopics = React.useMemo(
    (): SocialAccountFetchedTopics => contentTopPosts.find((topPost) => topPost.socialAccountId === account.socialAccount.id)
      || { socialAccountId: account.socialAccount.id, posts: [] },
    [ contentTopPosts ],
  )

  const accountKeywordPosts: GraphQL.PostFragment[] = React.useMemo((): GraphQL.PostFragment[] => {
    const posts = contentKeywordPosts.find((post) => post.socialAccountId === account.socialAccount.id)
    if (posts && posts.tagPosts.length > 0) {
      return posts.tagPosts[keywordTagIndex].posts
    }
    return []
  }, [ contentKeywordPosts, keywordTagIndex ])

  const accountImageTagPosts: GraphQL.PostFragment[] = React.useMemo((): GraphQL.PostFragment[] => {
    const posts = contentImageTagPosts.find((post) => post.socialAccountId === account.socialAccount.id)
    if (posts && posts.tagPosts.length > 0) {
      return posts.tagPosts[imageTagIndex].posts
    }
    return []
  }, [ contentImageTagPosts, imageTagIndex ])

  const strippedTopPosts = React.useMemo((): GraphQL.SuggestionsPostFragment[] => (
    accountTopPosts.posts.slice(0, SUGGESTED_POSTS_QTY)
  ), [ accountTopPosts ])

  const strippedKeywordPosts = React.useMemo(() => accountKeywordPosts.slice(0, SUGGESTED_POSTS_QTY), [ accountKeywordPosts ])

  const strippedImageTagPosts = React.useMemo(() => accountImageTagPosts.slice(0, SUGGESTED_POSTS_QTY), [ accountImageTagPosts ])

  return (
    <div className="cp_lcp_highlights-previews_account-container_content-container">
      { (toggles.includes(HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.TOP_POSTS) && strippedTopPosts.length > 0) && (
        <div className="content-container_top-posts">
          <div className="content-header-container">
            <p className="content-header-container_main">{ translate("Top Posts") }</p>
          </div>
          <div className="content-container">
            <div className="content-container_posts">
              { strippedTopPosts.map((post) => (
                <img className="" src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
              )) }
            </div>
          </div>
        </div>
      ) }
      { (toggles.includes(HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS) && strippedKeywordPosts.length > 0) && (
        <div className="content-container_keyword-posts">
          <div className="content-header-container">
            <p className="content-header-container_main">{ translate("Relevant Posts: Keywords") }</p>
            <p className="content-header-container_sub">{ translate("Posts relevant to the following keywords") }</p>
          </div>
          <div className="content-container">
            <div className="content-container_pills">
              { (contentPostKeywords.length > 0) && contentPostKeywords.map((tag, index) => (
                <Pill
                  className={ `tag-pill${ (keywordTagIndex === index) ? " tag-selected" : "" }` }
                  label={ tag.name }
                  onClick={ () => setKeywordTagIndex(index) }
                />
              )) }
            </div>
            <div className="content-container_posts">
              { strippedKeywordPosts.map((post) => {
                if (post.postMedia.length > 0) {
                  if (post.postMedia[0].mediaUrl) {
                    return (
                      <img src={ `${ post.postMedia[0].mediaUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
                    )
                  }
                  if (post.postMedia[0].thumbnailUrl) {
                    return (
                      <img src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
                    )
                  }
                  return null
                }
                return null
              }) }
            </div>
          </div>
        </div>
      ) }
      { (toggles.includes(HIGHLIGHTS_CONTENT_DISPLAY_TOGGLES.RELEVANT_POSTS) && strippedImageTagPosts.length > 0) && (
      <div className="content-container_image-tag-posts">
        <div className="content-header-container">
          <p className="content-header-container_main">{ translate("Relevant Posts: Image Tags") }</p>
          <p className="content-header-container_sub">{ translate("Posts relevant to the following image tags") }</p>
        </div>
        <div className="content-container">
          <div className="content-container_pills">
            { (contentPostImageTags.length > 0) && contentPostImageTags.map((tag, index) => (
              <Pill
                className={ `tag-pill${ (imageTagIndex === index) ? " tag-selected" : "" }` }
                label={ tag.name }
                onClick={ () => setImageTagIndex(index) }
              />
            )) }
          </div>
          <div className="content-container_posts">
            { strippedImageTagPosts.map((post) => (
              <img src={ `${ post.postMedia[0].thumbnailUrl }?auto=format` } alt="Post thumbnail" loading="lazy" />
            )) }
          </div>
        </div>
      </div>
      ) }
    </div>
  )
}
