import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Tabs, { TabLabelProps } from "../../component/Tabs"
import LoadingIndicator from "../../component/LoadingIndicator"
import CategoryAccounts from "./CategoryAccounts"
import { setSelectedCategoryTab } from "../../state/publicListSlice"

type SLCategory = GraphQL.GetPublicListByCodeQuery["publicSuggestionListByCode"]["suggestionListCategories"][number]
export type ToggleScoreData = {
  demographicScoreData: {
    demographicScoreGender: GraphQL.Sex[]
    demographicScoreEthnicity: GraphQL.Ethnicity[]
    demographicScoreFamily: GraphQL.Family[]
    demographicScoreReligion: GraphQL.Religion[]
    demographicScoreIncome: GraphQL.IncomeBrackets[]
    demographicScoreLocations: GraphQL.AudienceLocation[]
    demographicScoreOccupations: GraphQL.DetailedTagFragment[]
    demographicScoreAffinities: GraphQL.DetailedTagFragment[]
  }
  audienceScoreData?: GraphQL.DetailedHighlightsAudienceDataFragment
  contextualRelevancy?: string
}

export default function ListV2PageContent() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListV2Content" })
  const dispatch = useDispatch()

  // Global state
  const { list: publicList, selectedCategoryTab } = useSelector((state) => state.publicList)

  const [ categories, setCategories ] = React.useState<SLCategory[]>([])
  const [ selectedTab, setSelectedTab ] = React.useState<number>(selectedCategoryTab)
  const [ toggles, setToggles ] = React.useState<string[]>([])
  const [ isCampaign, setIsCampaign ] = React.useState<boolean>(false)

  const data: ToggleScoreData = React.useMemo((): ToggleScoreData => {
    if (API.isSuccess(publicList)) {
      const { publicSuggestionListByCode: list } = publicList.payload
      return {
        demographicScoreData: {
          demographicScoreGender: list.demographicScoreGender,
          demographicScoreEthnicity: list.demographicScoreEthnicity,
          demographicScoreFamily: list.demographicScoreFamily,
          demographicScoreReligion: list.demographicScoreReligion,
          demographicScoreIncome: list.demographicScoreIncome,
          demographicScoreLocations: list.demographicScoreLocations,
          demographicScoreOccupations: list.demographicScoreOccupations,
          demographicScoreAffinities: list.demographicScoreAffinities,
        },
        audienceScoreData: list.highlightsAudienceDemographics || undefined,
        contextualRelevancy: list.contextualRelevancy || undefined,
      }
    }
    return {
      demographicScoreData: {
        demographicScoreGender: [],
        demographicScoreEthnicity: [],
        demographicScoreFamily: [],
        demographicScoreReligion: [],
        demographicScoreIncome: [],
        demographicScoreLocations: [],
        demographicScoreOccupations: [],
        demographicScoreAffinities: [],
      },
    }
  }, [ publicList ])

  const tabs: TabLabelProps[] = React.useMemo(() => {
    if (API.isSuccess(publicList)) {
      // extract the categories
      const { suggestionListCategories } = publicList.payload.publicSuggestionListByCode

      // Check to see if multiple categories
      return suggestionListCategories.map((category) => ({
        label: category.name,
      }))
    }
    return []
  }, [ publicList ])

  React.useEffect(() => {
    if (API.isSuccess(publicList)) {
      // extract the categories
      const { suggestionListCategories } = publicList.payload.publicSuggestionListByCode

      // Check to see if multiple categories
      setCategories(suggestionListCategories)
      setToggles(publicList.payload.publicSuggestionListByCode.toggles.map((toggle) => toggle.name))
      setIsCampaign(publicList.payload.publicSuggestionListByCode.suggestionListMode === GraphQL.SuggestionListMode.Campaign)
    }
  }, [ publicList ])

  const handleTabSelection = (index: number) => {
    dispatch(setSelectedCategoryTab(index))
    setSelectedTab(index)
  }

  const isEpsilonVerified: boolean = React.useMemo(() => {
    if (API.isSuccess(publicList)) {
      return publicList.payload.publicSuggestionListByCode.epsilonVerified
    }
    return false
  }, [ publicList ])
  return (
    <div className="cp_page_public-list-page-flex_content">
      { (publicList === "init" || publicList === "loading") && (
        <div className="cp_page_public-list-page-flex_content-loading">
          <LoadingIndicator size={ 50 } />
        </div>
      ) }
      { (API.isError(publicList)) && (
        <div className="cp_page_public-list-page-flex_content-error">
          <p>
            { translate("Unable to fetch list.") }
          </p>
        </div>
      ) }
      { (categories.length > 1) ? (
        <div className="">
          <Tabs
            className="pl-overview-tabs"
            handleChange={ handleTabSelection }
            tabs={ tabs }
            defaultValue={ selectedTab }
            controls={ <div /> }
            controlledValue={ selectedTab }
          />
          { categories.map((category, index) => {
            if (index === selectedTab) {
              return (
                <CategoryAccounts
                  category={ category }
                  toggles={ toggles }
                  data={ data }
                  isCampaign={ isCampaign }
                  isEpsilonVerified={ isEpsilonVerified }
                />
              )
            }
            return undefined
          }) }
        </div>
      ) : (categories.length === 1) ? (
        <CategoryAccounts
          category={ categories[0] }
          toggles={ toggles }
          data={ data }
          isCampaign={ isCampaign }
          isEpsilonVerified={ isEpsilonVerified }
        />
      ) : undefined }
    </div>
  )
}
