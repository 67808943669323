import React from "react"
import "./style.sass"

import * as GraphQL from "../../graphql"
import CategoryLeftPanel from "./CategoryLeftPanel"
import CategoryRightPanel from "./CategoryRightPanel"
import { ToggleScoreData } from "./ListV2PageContent"

/**
 * Create type to reduce code length
 */
export type SuggestionList = GraphQL.GetPublicListByCodeQuery["publicSuggestionListByCode"]
export type SuggestionListCategory = SuggestionList["suggestionListCategories"][number]
export type SuggestionListAccount = SuggestionListCategory["suggestionListSocialAccounts"][number]

/**
 * CategoryAccountsProps: Properties for component
 */
interface CategoryAccountsProps {
  category: SuggestionListCategory
  isCampaign: boolean
  toggles: string[]
  data: ToggleScoreData
  isEpsilonVerified: boolean
}

/**
 * CategoryAccounts: Component to display category account information
 * @param param0 The properties that are able to be passed
 * @returns The React JSX Element representing this component
 */
export default function CategoryAccounts({
  category,
  isCampaign,
  toggles,
  data,
  isEpsilonVerified,
}: CategoryAccountsProps) {
  // Return the JSX Element
  return (
    <div className="cp_component_pl-category-accounts">
      <CategoryLeftPanel
        category={ category }
        isCampaign={ isCampaign }
        isEpsilonVerified={ isEpsilonVerified }
      />
      <CategoryRightPanel
        category={ category }
        toggles={ toggles }
        data={ data }
        isEpsilonVerified={ isEpsilonVerified }
      />
    </div>
  )
}
