import React from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import { useParams, useSearchParams } from "react-router-dom"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  DeliverableQueryVariables,
  FINALIZE_REVIEWER_GROUP_FEEDBACK,
  FinalizeReviewerGroupFeedbackOutput,
  GET_DELIVERABLE,
  GET_DELIVERABLE_HISTORY,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import LoadingIndicator from "../LoadingIndicator"
import { setFinalizeConfirmationModalOpen } from "../../state/campaignDeliverableContentModalV2Slice"

import "./finalize-group-feedback-confirmation-modal.sass"
import { GET_CAMPAIGN_DETAILS } from "../../graphqlV2/queries/campaignQueries"

export default function FinalizeGroupFeedbackConfirmationModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.FinalizeGroupFeedbackConfirmationModal" })

  const [ searchParams ] = useSearchParams()
  const deliverableId = searchParams.get("deliverableId") || ""
  const { campaignID } = useParams()
  const dispatch = useDispatch()

  const { finalizeConfirmationModalOpen } = useSelector(
    ({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2,
  )

  const [ finalizeReviewerGroupFeedback, { loading } ] = useMutation<
    FinalizeReviewerGroupFeedbackOutput, DeliverableQueryVariables
  >(FINALIZE_REVIEWER_GROUP_FEEDBACK, {
    refetchQueries: [ {
      query: GET_DELIVERABLE,
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
    },
    {
      query: GET_CAMPAIGN_DETAILS,
      variables: { campaignId: campaignID || "0" },
      context: {
        apiVersion: "v2",
      },
      fetchPolicy: "network-only",
    },
    {
      query: GET_DELIVERABLE_HISTORY,
      variables: { deliverableId },
      context: { apiVersion: "v2" },
    },
    ],
    awaitRefetchQueries: true,
  })

  const handleSubmit = async () => {
    await finalizeReviewerGroupFeedback({
      variables: {
        deliverableId,
      },
      context: {
        apiVersion: "v2",
      },
    })
    handleClose()
  }

  const handleClose = () => {
    dispatch(setFinalizeConfirmationModalOpen(false))
  }

  return (
    <Modal
      className="cp_component_finalize-group-feedback-confirmation-modal"
      title={ translate("Finalize Group Feedback") }
      subtitle={ translate("You cannot undo this action") }
      primaryLabel={ loading ? <LoadingIndicator /> : translate("CONFIRM") }
      primaryAction={ handleSubmit }
      disabled={ loading }
      secondaryLabel={ translate("GO BACK") }
      secondaryAction={ handleClose }
      secondaryDisabled={ loading }
      open={ finalizeConfirmationModalOpen }
      closeAction={ handleClose }
    >
      <p className="confirm-finalize-warning">
        { translate("CONFIRM FINALIZE WARNING") }
      </p>
    </Modal>
  )
}
