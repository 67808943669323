import React, {
  JSX,
  useEffect,
  useState,
} from "react"
import { useSearchParams, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import AddIcon from "@mui/icons-material/Add"
import {
  AutocompleteRenderInputParams,
  Container,
  ButtonGroup,
  FormControl,
  FormLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  FormControlLabel,
  debounce,
} from "@mui/material"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import Autocomplete from "../Autocomplete"
import Button from "../Button"
import Checkbox from "../Checkbox"
import DatePicker from "../DatePicker"
import Input from "../Input"
import Pill from "../Pill"
import Select from "../Select"
import Tooltip from "../Tooltip"
import { KeywordsSearch, ImageTagsSearch } from "../ModalAdvancedOptions"
import { Scope } from "../../util/types"
import { isNumeric } from "../../util/miscHelper"
import { relevancyItems, selectionOptions } from "./searchContentHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  fetchBrands,
  fetchImageTags,
  loadLogoBrands,
  setBrands,
  setImageTags,
  setSearchInput,
  setSelectedLocations,
} from "../../state/searchSlice"

import "./style.sass"

interface Props {
  showContextualRelevancy?: boolean
}

function SearchContentForm({ showContextualRelevancy = true }: Props): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchFilterTabs" })
  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const location = useLocation()
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  const {
    brands: brandLogoOptions,
    imageTags: imageTagOptions,
    searchInput,
  } = useSelector(({ search }) => search)

  const [ activeBrandLogo, setActiveBrandLogo ] = useState<GraphQL.BrandLogo | null>(null)
  const [ activeImageTag, setActiveImageTag ] = useState<string | null>(null)
  const [ dateRangeToggle, setDateRangeToggle ] = useState<boolean>(true)
  const [ excludeInputValue, setExcludeInputValue ] = useState("")
  const [ imageTagsModal, setImageTagsModal ] = useState(false)
  const [ isDecimal, setIsDecimal ] = useState<boolean>(false)
  const [ keyWordsModal, setKeyWordsModal ] = useState(false)
  const [ keywordInputValue, setKeywordInputValue ] = useState<string>("")
  const [ postedOnDate, setPostedOnDate ] = useState<Dayjs | null>(null)
  const [ rangeEndDate, setRangeEndDate ] = useState<Dayjs | null>(null)
  const [ rangeStartDate, setRangeStartDate ] = useState<Dayjs | null>(null)
  const [ selectedBrands, setSelectedBrands ] = useState<GraphQL.BrandLogo[]>([])

  const hasContextualRelevancyScopes = scopes
    .includes(Scope.FEATURE_SEARCH_ISCORE)
    || scopes.includes(Scope.SCORE_AD_COUNCIL)

  React.useEffect(() => {
    dispatch(loadLogoBrands(
      searchInput.contentParams.brandLogoTags.map((logoTag) => logoTag.tags).flat(),
      (logoBrands: GraphQL.BrandLogo[]) => {
        setSelectedBrands(logoBrands)
      },
    ))
  }, [ searchInput ])

  // List Helpers
  function handleEnterKey(
    event: React.KeyboardEvent,
    callback: () => void,
  ): void {
    if (event.key === "Enter") {
      event.preventDefault()
      callback()
    }
  }

  // Keywords Helpers
  function keywordLabel(keyword: string, inclusive: boolean): string {
    if (!inclusive) return keyword
    return `${ translate("All") } | ${ keyword }`
  }

  function addKeywordsBasic(): void {
    if (keywordInputValue == null || keywordInputValue === "") return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const currentKeywords = [ ...searchInput.contentParams.keywords ]
    const currentKeywordTags = currentKeywords.flatMap(({ tags }) => tags)

    const newKeywords = keywordInputValue.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword && !currentKeywordTags.includes(keyword))
      .map((keyword) => ({ tags: [ keyword ], inclusive: false }))

    const combinedKeywords: GraphQL.KeywordInput[] = currentKeywords.concat(newKeywords)
    newInput.contentParams.keywords = combinedKeywords
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setKeywordInputValue("")
  }

  function removeKeyword(keyword: GraphQL.KeywordInput): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredKeywords = [ ...searchInput.contentParams.keywords ]
      .filter(({ tags }) => !tags.some((tag) => tag === keyword.tags[0]))

    newInput.contentParams.keywords = filteredKeywords
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  const addKeywordsAdvanced = (
    exclusive: string[],
    inclusive: string[],
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    const exclusiveKeywords: GraphQL.KeywordInput[] = [ ...exclusive ]
      .map((k) => ({ tags: [ k ], inclusive: false }))

    const inclusiveKeywords: GraphQL.KeywordInput[] = [ ...inclusive ]
      .map((k) => ({ tags: [ k ], inclusive: true }))

    newInput.contentParams.keywords = [
      ...exclusiveKeywords,
      ...inclusiveKeywords,
    ]

    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setKeyWordsModal(false)
  }

  // Excludes Helpers
  function addExcludesBasic(): void {
    if (excludeInputValue == null || excludeInputValue === "") return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const currentExcludes = [ ...searchInput.contentParams.excludeKeywords ]

    const newExcludes = excludeInputValue.split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword && !currentExcludes.includes(keyword))

    const combinedExcludes: string[] = currentExcludes.concat(newExcludes)
    newInput.contentParams.excludeKeywords = combinedExcludes
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setExcludeInputValue("")
  }

  function removeExclude(keyword: string): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredExcludes = [ ...searchInput.contentParams.excludeKeywords ]
      .filter((k) => keyword !== k)

    newInput.contentParams.excludeKeywords = filteredExcludes
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  // Image Tags Helpers
  function addImageTagBasic(imageTag: string | string[] | null): void {
    if (imageTag == null || Array.isArray(imageTag)) return
    const currentImageTags = [ ...searchInput.contentParams.optionalImageTags ]
    const currentFlattenedTags = currentImageTags.flatMap(({ tags }) => tags)

    // Tag already exists. Reset form state and do nothing.
    if (currentFlattenedTags.some((tag) => tag === imageTag)) {
      setActiveImageTag(null)
      return
    }

    // Add unique tag to state
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const combinedTags = currentImageTags
      .concat([ { tags: [ imageTag ], inclusive: false } ])

    // Save image tag and reset image tag form state
    newInput.contentParams.optionalImageTags = combinedTags
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setActiveImageTag(null)
    dispatch(setImageTags("init"))
  }

  function fetchImageTagsByStartsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveImageTag(null)
      dispatch(setImageTags("init"))
      return
    }

    dispatch(fetchImageTags(startsWith))
  }

  function getImageTagOptions(): string[] {
    if (
      imageTagOptions === "init"
      || imageTagOptions === "loading"
      || API.isError(imageTagOptions)
    ) {
      return []
    }

    return imageTagOptions.payload.searchImageTags.rows
  }

  function removeImageTag(imageTag: GraphQL.ImageTagInput): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredImageTags = [ ...searchInput.contentParams.optionalImageTags ]
      .filter(({ tags }) => !tags.some((tag) => tag === imageTag.tags[0]))

    newInput.contentParams.optionalImageTags = filteredImageTags
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  function renderImageTagInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        InputLabelProps={ {} }
        label=""
        placeholder={ translate("Image tags placeholder") }
      />
    )
  }

  const addImageTagsAdvanced = (
    exclusive: string[],
    inclusive: string[],
  ): void => {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    const exclusiveImageTags: GraphQL.ImageTagInput[] = [ ...exclusive ]
      .map((k) => ({ tags: [ k ], inclusive: false }))

    const inclusiveImageTags: GraphQL.ImageTagInput[] = [ ...inclusive ]
      .map((k) => ({ tags: [ k ], inclusive: true }))

    newInput.contentParams.optionalImageTags = [
      ...exclusiveImageTags,
      ...inclusiveImageTags,
    ]

    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setImageTagsModal(false)
  }

  // Brand logos Helpers
  function addBrandLogoBasic(
    brandLogo: string
      | GraphQL.BrandLogo
      | (string | GraphQL.BrandLogo)[]
      | null,
  ): void {
    if (
      brandLogo == null
      || typeof brandLogo === "string"
      || Array.isArray(brandLogo)
    ) {
      return
    }

    const currentBrandLogos = [ ...searchInput.contentParams.brandLogoTags ]
    const currentFlattenedTags = currentBrandLogos.flatMap(({ tags }) => tags)

    // Tag already exists. Reset form state and do nothing.
    if (currentFlattenedTags.some((tag) => tag === brandLogo.code)) {
      setActiveBrandLogo(null)
      return
    }

    // Set selected brand logos so pills can render names properly...
    const newSelectedBrands = [ ...selectedBrands ]
      .filter(({ code }) => code !== brandLogo.code)
      .concat([ brandLogo ])

    // Add unique tag to state
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const combinedTags = currentBrandLogos
      .concat([ { tags: [ brandLogo.code ], inclusive: false } ])

    // Save brand logos and reset brand logo form state
    newInput.contentParams.brandLogoTags = combinedTags
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    setActiveBrandLogo(null)
    setSelectedBrands(newSelectedBrands)
    dispatch(setBrands("init"))
  }

  function fetchBrandLogosByStartsWithValue(startsWith: string): void {
    if (startsWith === "") {
      setActiveBrandLogo(null)
      dispatch(setBrands("init"))
      return
    }

    dispatch(fetchBrands(startsWith))
  }

  function getBrandLogoOptionLabel(
    option: string | GraphQL.BrandLogo,
  ): string {
    if (typeof option === "string") return option
    return option.name
  }

  function getBrandLogosOptions(): GraphQL.BrandLogo[] {
    if (
      brandLogoOptions === "init"
      || brandLogoOptions === "loading"
      || API.isError(brandLogoOptions)
    ) {
      return []
    }

    return brandLogoOptions.payload.searchBrandLogos.rows
  }

  function removeBrandLogo(brandLogo: GraphQL.BrandLogo): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const filteredBrandLogos = [ ...searchInput.contentParams.brandLogoTags ]
      .filter(({ tags }) => !tags.some((tag) => tag === brandLogo.code))

    const newSelectedBrands = [ ...selectedBrands ]
      .filter(({ code }) => code !== brandLogo.code)

    newInput.contentParams.brandLogoTags = filteredBrandLogos
    setSelectedBrands(newSelectedBrands)
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  function renderBrandLogosInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <Input
        { ...params }
        InputLabelProps={ {} }
        label=""
        placeholder={ translate("Select Brand Logo") }
      />
    )
  }

  // Post Date/Post Range Helpers
  function clearDateForms(): void {
    setRangeStartDate(null)
    setRangeEndDate(null)
    setPostedOnDate(null)
  }

  function setDateState(
    d: Dayjs | string | null,
    callback: (dateToSet: Dayjs) => void,
  ): void {
    if (d == null) return

    const date = typeof d === "string" ? dayjs(d) : d
    if (date.isValid()) {
      callback(date)
    }
  }

  function addDatePill(): void {
    if (postedOnDate == null) return
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const newDate = (postedOnDate.unix() * Constant.THOUSAND).toString()
    const newPostedDates = [ ...searchInput.contentParams.postedDates ]
      .filter((d) => d !== newDate)
      .concat([ newDate ])

    newInput.contentParams.postedDates = newPostedDates
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
    clearDateForms()
  }

  function addRangePill(): void {
    if (rangeStartDate == null || rangeEndDate == null) return
    if (rangeEndDate.isAfter(rangeStartDate)) {
      const newInput = SearchHelper.cloneSearchInput(searchInput)
      const newStart: string = (rangeStartDate.unix() * Constant.THOUSAND).toString()
      const newEnd: string = (rangeEndDate.unix() * Constant.THOUSAND).toString()
      const newPostedRanges = searchInput.contentParams.postedRanges
        .filter(({ start, end }) => !(start === newStart && end === newEnd))
        .concat([ { start: newStart, end: newEnd } ])

      newInput.contentParams.postedRanges = newPostedRanges
      dispatch(setSearchInput(newInput))
      SearchHelper.setContentInputQueryParams(
        newInput.contentParams,
        searchParams,
        setSearchParams,
      )
      clearDateForms()
    }
  }

  function handleRemoveDate(
    date: string | { start: string, end: string },
  ): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (typeof date === "string") {
      const newPostedDates = [ ...searchInput.contentParams.postedDates ]
        .filter((p) => p !== date)

      newInput.contentParams.postedDates = newPostedDates
      dispatch(setSearchInput(newInput))
      SearchHelper.setContentInputQueryParams(
        newInput.contentParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    const newPostedRanges = [ ...searchInput.contentParams.postedRanges ]
      .filter(({ start, end }) => !(date.start === start && date.end === end))

    newInput.contentParams.postedRanges = newPostedRanges
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  function renderDatePill(
    date: string | { start: string, end: string },
  ): JSX.Element {
    let dateString

    if (typeof date === "string") {
      dateString = dayjs.unix(
        parseInt(date, 10) / Constant.THOUSAND,
      ).format(Constant.LONGFORM_DATE)
    } else {
      const dateStringStart = dayjs.unix(
        parseInt(date.start, 10) / Constant.THOUSAND,
      ).format(Constant.LONGFORM_DATE)

      const dateStringEnd = dayjs.unix(
        parseInt(date.end, 10) / Constant.THOUSAND,
      ).format(Constant.LONGFORM_DATE)

      dateString = `${ dateStringStart } - ${ dateStringEnd }`
    }

    return (
      <Pill
        key={ dateString }
        label={ dateString }
        onDelete={ () => handleRemoveDate(date) }
      />
    )
  }

  useEffect(() => {
    addDatePill()
  }, [ postedOnDate ])

  useEffect(() => {
    addRangePill()
  }, [ rangeStartDate, rangeEndDate ])

  // Check for full range dates
  useEffect(() => {
    if (rangeStartDate && rangeEndDate) {
      addRangePill()
    } else if (postedOnDate) {
      addDatePill()
    }
  }, [ rangeStartDate, rangeEndDate, postedOnDate ])

  // Engagement Rate Helpers
  function getMinEngagementRateValue(
    minEngagement: number | null | undefined,
  ): string {
    if (minEngagement == null) return ""
    const percentValue = (minEngagement * 100).toLocaleString()
    if (isDecimal) return `${ percentValue }.`.slice(0, 5)
    return percentValue
  }

  const handleEngmntFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const newInput = SearchHelper.cloneSearchInput(searchInput)

    if (newValue === "") {
      newInput.contentParams.minPostEngagement = null
      dispatch(setSearchInput(newInput))
      SearchHelper.setContentInputQueryParams(
        newInput.contentParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    if (isNumeric(newValue) && parseFloat(newValue) <= 100) {
      newInput.contentParams.minPostEngagement = parseFloat(newValue) * 0.01
      dispatch(setSearchInput(newInput))
      SearchHelper.setContentInputQueryParams(
        newInput.contentParams,
        searchParams,
        setSearchParams,
      )
    }

    if (newValue.slice(-1) === ".") {
      setIsDecimal(true)
      return
    }

    setIsDecimal(false)
  }

  // Publish Location Helpers
  function togglePublishLocation(pl: GraphQL.PublishLocation): void {
    const newInput = SearchHelper.cloneSearchInput(searchInput)
    const currentPLs = [ ...searchInput.contentParams.publishLocations ]
    const checked = currentPLs.some((l) => l === pl)

    if (checked) {
      const newPLs = currentPLs.filter((l) => l !== pl)
      newInput.contentParams.publishLocations = newPLs
      dispatch(setSearchInput(newInput))
      SearchHelper.setContentInputQueryParams(
        newInput.contentParams,
        searchParams,
        setSearchParams,
      )
      return
    }

    const newPLs = currentPLs.concat([ pl ])
    newInput.contentParams.publishLocations = newPLs
    dispatch(setSearchInput(newInput))
    SearchHelper.setContentInputQueryParams(
      newInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }

  return (
    <Container className="cp_component_search-content cp_component_search-form-container">
      <form>
        { /* Keywords section */ }
        <FormControl
          className="cp_component_search-content-keywords"
          component="fieldset"
          fullWidth={ true }
        >
          <div className="cp_component_search-content-keywords_header has-advanced-link">
            <FormLabel component="legend">
              { translate("KEYWORDS") }
            </FormLabel>
            <Button
              className="cp_advanced_link"
              variant="text"
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("ADVANCED") }
              onClick={ () => { setKeyWordsModal(true) } }
            />
          </div>
          <Input
            label=""
            value={ keywordInputValue }
            placeholder={ translate("Keywords placehoder") }
            onChange={ (e) => setKeywordInputValue(e.target.value) }
            onKeyDown={ (e) => handleEnterKey(e, addKeywordsBasic) }
            InputProps={ {
              endAdornment: (
                <IconButton onClick={ () => addKeywordsBasic() }>
                  <AddIcon />
                </IconButton>
              ),
            } }
          />
          <div className="cp_component_search-content-keywords-pills">
            { searchInput.contentParams.keywords.map((keyword) => (
              <Pill
                key={ keyword.tags[0] || "" }
                label={ keywordLabel(keyword.tags[0] || "", keyword.inclusive) }
                onDelete={ () => removeKeyword(keyword) }
              />
            )) }
          </div>
          <KeywordsSearch
            onSave={ addKeywordsAdvanced }
            open={ keyWordsModal }
            closeAction={ () => { setKeyWordsModal(false) } }
          />
        </FormControl>
        { /* Excludes words section */ }
        <FormControl
          className="cp_component_search-content-excludes"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("EXCLUDES") }
          </FormLabel>
          <Input
            label=""
            value={ excludeInputValue }
            placeholder={ translate("Keywords placehoder") }
            onChange={ (e) => setExcludeInputValue(e.target.value) }
            onKeyDown={ (e) => handleEnterKey(e, addExcludesBasic) }
            InputProps={ {
              endAdornment: (
                <IconButton onClick={ () => addExcludesBasic() }>
                  <AddIcon />
                </IconButton>
              ),
            } }
          />
          <div className="cp_component_search-content-excludes-pills">
            { searchInput.contentParams.excludeKeywords.map((keyword) => (
              <Pill
                key={ keyword }
                label={ keyword }
                onDelete={ () => removeExclude(keyword) }
              />
            )) }
          </div>
        </FormControl>
        { /* Image tag section */ }
        <FormControl
          className="cp_component_search-content-imagetags"
          component="fieldset"
          fullWidth={ true }
        >
          <div className="cp_component_search-content-imagetags_header has-advanced-link">
            <FormLabel component="legend">
              { translate("IMAGE TAGS") }
            </FormLabel>
            <Button
              className="cp_advanced_link"
              variant="text"
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("ADVANCED") }
              onClick={ () => { setImageTagsModal(true) } }
            />
          </div>
          <Autocomplete
            disablePortal={ true }
            filterOptions={ (x) => x }
            filterSelectedOptions={ true }
            getOptionLabel={ (o) => o || "" }
            isOptionEqualToValue={ (tag) => tag === activeImageTag }
            loading={ imageTagOptions === "loading" }
            loadingText={ translate("Loading image tags...") }
            noOptionsText={ translate("Search for an image tag") }
            onInputChange={ debounce((_, newValue) => {
              fetchImageTagsByStartsWithValue(newValue)
            }, Constant.DEFAULT_DEBOUNCE_WAIT) }
            onChange={ (_, newValue) => addImageTagBasic(newValue) }
            options={ getImageTagOptions() }
            renderInput={ (params) => renderImageTagInput(params) }
            value={ activeImageTag }
          />
          <div className="cp_component_search-content-imagetags-pills">
            { searchInput.contentParams.optionalImageTags.map((k) => (
              <Pill
                key={ k.tags[0] || "" }
                label={ keywordLabel(k.tags[0] || "", k.inclusive) }
                onDelete={ () => removeImageTag(k) }
              />
            )) }
          </div>
          <ImageTagsSearch
            onSave={ addImageTagsAdvanced }
            open={ imageTagsModal }
            closeAction={ () => setImageTagsModal(false) }
          />
        </FormControl>

        { /* Brand logos section */ }
        { scopes.includes(Scope.FEATURE_SEARCH_BRAND_LOGO) && (
          <FormControl
            className="cp_component_search-content-brandlogos"
            component="fieldset"
            fullWidth={ true }
          >
            <div className="cp_component_search-content-brandlogos_header has-advanced-link">
              <FormLabel component="legend">
                { translate("BRAND LOGOS") }
              </FormLabel>
            </div>
            <Autocomplete
              key={ `brand-logo-key-${ selectedBrands.length }` }
              disablePortal={ true }
              filterOptions={ (x) => x }
              filterSelectedOptions={ true }
              getOptionLabel={ (o) => getBrandLogoOptionLabel(o) }
              isOptionEqualToValue={ ({ code }) => code === activeBrandLogo?.code }
              loading={ brandLogoOptions === "loading" }
              loadingText={ translate("Loading brand logos...") }
              noOptionsText={ translate("Search for a brand logo") }
              onInputChange={ debounce((_, newValue) => {
                fetchBrandLogosByStartsWithValue(newValue)
              }, Constant.DEFAULT_DEBOUNCE_WAIT) }
              onChange={ (_, newValue) => addBrandLogoBasic(newValue) }
              options={ getBrandLogosOptions() }
              renderInput={ (params) => renderBrandLogosInput(params) }
              value={ activeBrandLogo }
            />
            <div className="cp_component_search-content-brandlogos-pills">
              { selectedBrands.map((b) => (
                <Pill
                  key={ b.code }
                  label={ b.name }
                  onDelete={ () => removeBrandLogo(b) }
                />
              )) }
            </div>
          </FormControl>
        ) }

        { /* Dates section */ }
        <FormControl
          className="cp_component_search-content-postedon"
          component="fieldset"
          fullWidth={ true }
        >
          <div className="cp_component_search-content-postedon-header">
            <ButtonGroup size="small">
              <Button
                className={ `postedon-button ${ dateRangeToggle ? "posted-button-active" : "" }` }
                label={ translate("DATE") }
                isEnabled={ true }
                isPrimary={ true }
                size="small"
                onClick={ () => { setDateRangeToggle(true) } }
              />
              <Button
                className={ `postedon-button ${ !dateRangeToggle ? "posted-button-active" : "" }` }
                label={ translate("RANGE") }
                isEnabled={ true }
                isPrimary={ true }
                size="small"
                onClick={ () => { setDateRangeToggle(false) } }
              />
            </ButtonGroup>
          </div>
          { /* Post on date */ }
          { dateRangeToggle && (
            <div className="cp_component_search-content-postedon-date">
              <div className="date">
                <FormLabel component="legend" id="postedonMuiLegend">
                  { translate("POSTED ON") }
                </FormLabel>
                <DatePicker
                  value={ postedOnDate }
                  onDateChange={ (d) => setDateState(d, setPostedOnDate) }
                  onAccept={ (d) => setDateState(d, setPostedOnDate) }
                />
              </div>
            </div>
          ) }
          { /* Post on date */ }
          { !dateRangeToggle && (
            <div className="cp_component_search-content-postedon-range">
              <div className="range-posted-start">
                <FormLabel component="legend" id="postedonMuiLegend">
                  { translate("POSTED START") }
                </FormLabel>
                <DatePicker
                  value={ rangeStartDate }
                  onDateChange={ (d) => setDateState(d, setRangeStartDate) }
                  onAccept={ (d) => setDateState(d, setRangeStartDate) }
                />
              </div>
              <div className="range-posted-end">
                <FormLabel component="legend" id="postedonMuiLegend">
                  { translate("END") }
                </FormLabel>
                <DatePicker
                  value={ rangeEndDate }
                  onDateChange={ (d) => setDateState(d, setRangeEndDate) }
                  onAccept={ (d) => setDateState(d, setRangeEndDate) }
                />
              </div>
            </div>
          ) }
          <div className="cp_component_search-content-postedon-pills">
            { [ ...searchInput.contentParams.postedDates, ...searchInput.contentParams.postedRanges ].map(renderDatePill) }
          </div>
        </FormControl>
        { /* Minimum Post Engagement Rate section */ }
        <FormControl
          className="cp_component_search-content-engagement"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("MINIMUM POST ENGAGEMENT RATE") }
          </FormLabel>
          <Input
            label=""
            placeholder={ translate("Enter engagement rate") }
            value={ getMinEngagementRateValue(
              searchInput.contentParams.minPostEngagement,
            ) }
            onChange={ handleEngmntFieldChange }
            type="text"
            InputProps={ {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                min: 0,
                max: 100,
                step: "0.01",
                maxLength: 5,
              },
            } }
          />
        </FormControl>
        { /* Post Types section */ }
        <FormControl
          className="cp_component_search-content-post_type"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("POST TYPE") }
            <Tooltip arrow={ true } title={ translate("PostType Tooltip") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ searchInput.contentParams.sponsored === true }
                  name={ translate("SPONSORED") }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const checked = searchInput.contentParams.sponsored

                    if (checked == null) {
                      newInput.contentParams.sponsored = true
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setContentInputQueryParams(
                        newInput.contentParams,
                        searchParams,
                        setSearchParams,
                      )
                      return
                    }

                    if (checked) {
                      newInput.contentParams.sponsored = null
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setContentInputQueryParams(
                        newInput.contentParams,
                        searchParams,
                        setSearchParams,
                      )
                      return
                    }

                    newInput.contentParams.sponsored = true
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setContentInputQueryParams(
                      newInput.contentParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                />
              ) }
              label={ translate("SPONSORED") }
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ searchInput.contentParams.sponsored === false }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const checked = searchInput.contentParams.sponsored

                    if (checked == null) {
                      newInput.contentParams.sponsored = false
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setContentInputQueryParams(
                        newInput.contentParams,
                        searchParams,
                        setSearchParams,
                      )
                      return
                    }

                    if (checked) {
                      newInput.contentParams.sponsored = false
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setContentInputQueryParams(
                        newInput.contentParams,
                        searchParams,
                        setSearchParams,
                      )
                      return
                    }

                    newInput.contentParams.sponsored = null
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setContentInputQueryParams(
                      newInput.contentParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("ORGANIC") }
                />
              ) }
              label={ translate("ORGANIC") }
            />
          </FormGroup>
        </FormControl>
        { /* Content Types section */ }
        <FormControl
          className="cp_component_search-content-content_type"
          component="fieldset"
          fullWidth={ true }
        >
          <FormLabel component="legend">
            { translate("CONTENT TYPE") }
            <Tooltip arrow={ true } title={ translate("ContentType Tooltip") }>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Feed)
                  }
                  onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Feed) }
                  name={ translate("IN-FEED") }
                />
              ) }
              label={ translate("IN-FEED") }
            />
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Short)
                  }
                  onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Short) }
                  name={ translate("SHORT-FORM") }
                />
              ) }
              label={ translate("SHORT-FORM") }
            />
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={
                    searchInput.contentParams.publishLocations
                      .some((l) => l === GraphQL.PublishLocation.Story)
                  }
                  onChange={ () => togglePublishLocation(GraphQL.PublishLocation.Story) }
                  name={ translate("STORY") }
                />
              ) }
              label={ translate("STORY") }
            />
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={ searchInput.contentParams.containsMedia || false }
                  onChange={ () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    const checked = searchInput.contentParams.containsMedia || false
                    newInput.contentParams.containsMedia = !checked
                    dispatch(setSearchInput(newInput))
                    SearchHelper.setContentInputQueryParams(
                      newInput.contentParams,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                  name={ translate("CONTENT MUST CONTAIN MEDIA") }
                />
              ) }
              label={ translate("CONTENT MUST CONTAIN MEDIA") }
            />
            { scopes.includes(Scope.NSFW_FLAG) && (
              <FormControlLabel
                control={ (
                  <Checkbox
                    checked={ searchInput.contentParams.excludeNsfwContent || false }
                    onChange={ () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      const checked = searchInput.contentParams.excludeNsfwContent || false
                      newInput.contentParams.excludeNsfwContent = !checked
                      dispatch(setSearchInput(newInput))
                      SearchHelper.setContentInputQueryParams(
                        newInput.contentParams,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                    name={ translate("HIDE NOT SAFE FOR WORK CONTENT") }
                  />
                ) }
                label={ translate("HIDE NOT SAFE FOR WORK CONTENT") }
              />
            ) }
          </FormGroup>
        </FormControl>
        { /* Contextual Relevancy section */ }
        { hasContextualRelevancyScopes && showContextualRelevancy && (
          <FormControl
            className="cp_component_search-profile-relevancy"
            component="fieldset"
            fullWidth={ true }
          >
            <FormLabel component="legend">
              { translate("CONTEXTUAL RELEVANCY") }
              <Tooltip arrow={ true } title={ translate("Relevancy tooltip") }>
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </FormLabel>
            <Select
              value={ searchInput.contentParams.contextualRelevancy || "" }
              onChange={ (e) => {
                const newInput = SearchHelper.cloneSearchInput(searchInput)
                newInput.contentParams.contextualRelevancy = e.target.value
                dispatch(setSearchInput(newInput))
                SearchHelper.setContentInputQueryParams(
                  newInput.contentParams,
                  searchParams,
                  setSearchParams,
                )
              } }
              id="cp_component_search-profile-age_range-select"
              label=""
              labelId="cp-search-ageRange-label"
              menuItems={ selectionOptions(relevancyItems, true) }
            />
          </FormControl>
        ) }
        { /* Reset filters button */ }
        <div className="cp_component_search-content-footer">
          <Button
            className="cp_component_search-content-reset_button"
            isEnabled={ true }
            isPrimary={ false }
            label={ translate("RESET FILTERS") }
            onClick={ () => {
              // the behavior depends on the mounting context!
              // for example in the profile page, we want to reset the search input
              // but keep the social account ids, and Post Results are set as the default state.
              if (location.pathname.includes("profile") && location.pathname.includes("content")) {
                // profile page, content tab
                const socialAccountContentSearch = SearchHelper.initialSearchState()
                socialAccountContentSearch.socialAccountIds = searchInput.socialAccountIds
                socialAccountContentSearch.resultType = GraphQL.GodSearchType.Post
                socialAccountContentSearch.influencerParams.minFollowers = null
                dispatch(setSearchInput(socialAccountContentSearch))
                SearchHelper.setSearchInputQueryParams(
                  socialAccountContentSearch,
                  searchParams,
                  setSearchParams,
                )
                SearchHelper.setContentInputQueryParams(
                  socialAccountContentSearch.contentParams,
                  searchParams,
                  setSearchParams,
                )
              } else {
                dispatch(setSearchInput(SearchHelper.initialSearchState()))
                dispatch(setSelectedLocations([]))
                searchParams.delete(SearchHelper.QUERY_PARAM_Q)
                searchParams.delete(SearchHelper.QUERY_PARAM_INFLUENCER)
                searchParams.delete(SearchHelper.QUERY_PARAM_AUDIENCE)
                searchParams.delete(SearchHelper.QUERY_PARAM_CONTENT)
                searchParams.delete(SearchHelper.QUERY_PARAM_LOCATION)
                setSearchParams(searchParams)
              }
            }
            }
          />
        </div>
      </form>
    </Container>
  )
}

export default SearchContentForm
