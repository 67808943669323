import React from "react"
import "../drag-n-drop-display.sass"

import { useTranslation } from "react-i18next"
import { DragIndicator } from "@mui/icons-material"

import * as GraphQL from "../../../../graphql"
import {
  audienceDisplayCodes,
  AudienceGroup,
  DragNDropSwitchInformation,
  getDisplayName,
} from "../../constants"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { setUpdateListForm } from "../../../../state/listConfigurationSlice"
import Switch from "../../../Switch"

export interface ToggleGroupProps {
  startIndex: number,
  toggles: GraphQL.SuggestionListToggleInput[]
  updateToggles: (toggles: GraphQL.SuggestionListToggleInput[]) => void
  setDraggingInfo: (info: AudienceGroup | GraphQL.SuggestionListToggleInput | undefined) => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
}

export default function ToggleGroup({
  startIndex,
  toggles,
  updateToggles,
  setDraggingInfo,
  onDrop,
}: ToggleGroupProps) {
  // Local field variables
  const {
    t: translateCVH,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const dispatch = useDispatch()

  // Global state
  const updateListForm = useSelector((state) => state.listConfiguration.updateListForm)

  const [ switches, setSwitches ] = React.useState<GraphQL.SuggestionListToggleInput[]>(toggles)
  const [ dragInfo, setDragInfo ] = React.useState<DragNDropSwitchInformation>()

  /**
   * React hook to get all the toggles and ensure they are in array format.
   */
  const allToggles = React.useMemo((): GraphQL.SuggestionListToggleInput[] => {
    if (updateListForm) {
      const { toggles: formToggles } = updateListForm
      if (Array.isArray(formToggles)) return [ ...formToggles ]
      return [ formToggles ]
    }
    return []
  }, [ updateListForm ])

  /**
   * isChecked: Checks to see if the toggle has been checked by checking
   * the update list form for the toggle name
   * @param option The toggle to check for
   * @returns True if the toggle exists in the update list form, otherwise false
   */
  const isChecked = (option: GraphQL.SuggestionListToggleInput) => (
    allToggles.some((toggle) => toggle.name === option.name)
  )

  /**
   * toggleSwitch: Checks or unchecks a switch based on the option being in the list
   * form toggles.
   * @param option The option being toggled
   */
  const toggleSwitch = (option: GraphQL.SuggestionListToggleInput) => {
    if (isChecked(option)) {
      // Get the toggle from all toggles
      const toggle = allToggles.find((t) => t.name === option.name)
      if (toggle) {
        // Remove the toggle
        allToggles.splice(allToggles.indexOf(toggle), 1)
      }
    } else {
      // Add the toggle
      allToggles.push(option)
    }

    // Make sure form exists
    if (updateListForm) {
      // Copy of form
      const form = { ...updateListForm }

      // Set the toggles
      form.toggles = allToggles

      // Save to update form
      dispatch(setUpdateListForm(form))
    }
  }

  /**
   * startDrag: This function runs when dragging an item begins
   * @param event The event for the drag
   * @param option The option being dragged
   */
  const startDrag = (event: React.DragEvent<HTMLSpanElement>, option: GraphQL.SuggestionListToggleInput) => {
    // Set local state to indicate dragging
    setDragInfo({
      currentIndex: switches.indexOf(option),
      option,
    })

    // Pass to parent for drag end event processing
    setDraggingInfo(option)
  }

  /**
   * dragOverSwitch: This function runs when the drag item moves over the top of a droppable item
   * @param event The event of moving over drop zone
   * @param option The option we are over top of
   */
  const dragOverSwitch = (event: React.DragEvent<HTMLSpanElement>, option: GraphQL.SuggestionListToggleInput) => {
    // Prevent default actions
    event.preventDefault()

    // Make sure this component is dragging something
    if (dragInfo && switches.some((t) => t.name === option.name)) {
      // Get the index of the option
      const optionIndex = switches.indexOf(option)

      // Check to see if current index is different from option index
      if (dragInfo.currentIndex !== optionIndex) {
        // Set new drag information
        setDragInfo({
          ...dragInfo,
          currentIndex: optionIndex,
        })

        // Move option to new location
        const updatedSwitches = [ ...switches ]
        updatedSwitches.splice(optionIndex, 0, ...updatedSwitches.splice(dragInfo.currentIndex, 1))
        setSwitches(updatedSwitches)
      }
    }
  }

  /**
   * dropSwitch: This event is fired when the drag is relaeased and it
   * reorders and places the order in the update list for as well as in
   * the view.
   * @param event The event for dropping
   */
  const dropSwitch = (event: React.DragEvent<HTMLDivElement | HTMLParagraphElement>) => {
    // Prevent default action
    event.preventDefault()

    // Make sure we are dragging something
    const formToggles = [ ...allToggles ]
    if (dragInfo && updateListForm) {
      // Reset drag information
      setDragInfo(undefined)
      setDraggingInfo(undefined)

      // Loop through switches and reset counters
      let counter = startIndex
      const updatedSwitches = switches.map((option) => {
        // New option
        const newOption: GraphQL.SuggestionListToggleInput = {
          ...option,
          // eslint-disable-next-line no-plusplus
          order: counter++,
        }

        // Get the checked toggle (if it's there)
        const checkedToggle = formToggles.find((toggle) => toggle.name === option.name)
        if (checkedToggle) {
          // Replace checked toggle with the one in switch
          formToggles.splice(formToggles.indexOf(checkedToggle), 1, newOption)
        }

        // Return the new option
        return newOption
      })

      // Update the form and switches
      setSwitches(updatedSwitches)
      updateToggles(updatedSwitches)

      // Copy global state form
      const form = { ...updateListForm }

      // set the form toggles
      form.toggles = formToggles
      dispatch(setUpdateListForm(form))
    } else onDrop(event)
  }

  return (
    <div className="cp_component_group-toggles" onDrop={ dropSwitch }>
      { switches && switches.map((option) => (
        <p
          className={
            `config-option${ (dragInfo && dragInfo.option.name === option.name)
              ? " dragging"
              : "" }`
          }
          draggable={ true }
          onDragStart={ (e) => { startDrag(e, option) } }
          onDragOver={ (e) => { dragOverSwitch(e, option) } }
          onDrop={ dropSwitch }
        >
          <Switch
            className="config-option-switch"
            isChecked={ isChecked(option) }
            handleChange={ () => toggleSwitch(option) }
          />
          <span className="config-option-label">
            { translateCVH(getDisplayName(option.name, audienceDisplayCodes)) }
          </span>
          <DragIndicator className="config-option-dragicon" />
        </p>

      )) }
    </div>
  )
}
