import React, { JSX } from "react"
import Container from "@mui/material/Container"
import { Link, useParams } from "react-router-dom"

import * as GraphQL from "../../graphql"
import NetworkIcon from "../NetworkIcon"
import { Scope } from "../../util/types"
import { generateProfileUrl } from "../../util/miscHelper"
import { useSelector } from "../../state/hooks"

export type MinimalSocialAccount = Pick<
  GraphQL.SocialAccount,
  "id"
  | "userName"
  | "network"
  | "name"
  | "profilePictureUrl"
>

export interface NetworksProps {
  headingLabel: string,
  activeNetworks: MinimalSocialAccount[],
}
export default function Networks(
  { headingLabel, activeNetworks }: NetworksProps,
): JSX.Element {
  const { socialAccountId: currentSocialAccountId, vanity } = useParams()
  const { scopes } = useSelector(({ user }) => user)

  // TODO: Currently, the backend still returns Twitter accounts. Filtering
  // Twitter profiles from view here for now, but this filter can be removed
  // once the backend no longer returns Twitter accounts.
  const profileNetworks = activeNetworks
    .filter(({ network }) => network !== GraphQL.Network.Twitter)
    // Filter TikTok accounts from available network list if user is not scoped
    .filter(({ network }) => {
      if (scopes.includes(Scope.FEATURE_TIKTOK)) return true

      return network !== GraphQL.Network.Tiktok
    })

  return (
    <div className="cp_profile-overview_component-networks-wrapper">
      { profileNetworks.length > 1 && (
        <p className="label_small-caps-semibold cp_profile-overview_component-networks-heading">
          { headingLabel }
        </p>
      ) }
      <Container className="cp_profile-overview_component-networks-list">
        { profileNetworks.map((network) => {
          if (network.id !== currentSocialAccountId) {
            const profileURL = generateProfileUrl(network.id, vanity || "")
            return (
              <Link
                key={ network.id }
                replace={ true }
                to={ profileURL }
              >
                <NetworkIcon
                  additionalClasses="cp_profile-overview_component-network"
                  isActive={ false }
                  network={ network.network }
                />
              </Link>
            )
          }
          return (
            <NetworkIcon
              additionalClasses="cp_profile-overview_component-network"
              isActive={ true }
              key={ network.id }
              network={ network.network }
            />
          )
        }) }
      </Container>
    </div>
  )
}
