import { gql } from "@apollo/client"
import {
  CAMPAIGN_DETAILS_FRAGMENT,
  CAMPAIGN_VERSION_FRAGMENT,
  DELIVERABLE_SUMMARY_FRAGMENT,
  USER_DELIVERABLE_NOTIFICATION_FRAGMENT,
} from "../fragments/campaignFragments"

export const GET_CAMPAIGN_DETAILS = gql`
query GetCampaignDetails($campaignId: ObfID!){

    campaignDetails(campaignId: $campaignId){
        ...CampaignDetailsFragment
        deliverableSummaryList{
        ...DeliverableSummaryFragment
        }
    }
}
${ CAMPAIGN_DETAILS_FRAGMENT } ${ DELIVERABLE_SUMMARY_FRAGMENT }
`

export const GET_IS_MIGRATED_CAMPAIGN = gql`
query isMigratedCampaign($campaignId: ObfID!){
  isCampaignMigrated(campaignId: $campaignId){
  ...CampaignVersionFragment
  }
}
${ CAMPAIGN_VERSION_FRAGMENT }
`

export const MIGRATE_CAMPAIGN = gql`
mutation migrateCampaign($campaignId: ObfID!){
  migrateCampaignToEnhancedFlow(campaignId: $campaignId){
  ...CampaignVersionFragment
  }
}
${ CAMPAIGN_VERSION_FRAGMENT }
`

export const REVERT_CAMPAIGN_UPGRADE = gql`
mutation revertToV1($campaignId: ObfID!){
  revertCampaignUpgrade(campaignId: $campaignId){
  ...CampaignVersionFragment
  }

}
  ${ CAMPAIGN_VERSION_FRAGMENT }
`

export const GET_USER_NOTIFICATIONS = gql`
query getUserNotifications($campaignId:ObfID!){
  getUserNotifications(campaignId:$campaignId){
    ...UserDeliverableNotifcationFragment
  }
}
${ USER_DELIVERABLE_NOTIFICATION_FRAGMENT }
`
