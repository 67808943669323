import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/client"
import "./notification-toggles.sass"
import Switch from "../../Switch"
import { DeliverableNotificationType, UserNotificationPreference } from "../../../graphqlV2/types/campaignTypes"
import UPDATE_USER_NOTIFICATIONS from "../../../graphqlV2/mutations/campaignMutations"

type Props = {
  notificationPreferences: DeliverableNotificationType[]
}

type NotificationPreference = {

  title: string,
  subTitle: string,
  notificationType: DeliverableNotificationType

}

export default function NotificationTogglesV2({
  notificationPreferences,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignNotificationsV2" })
  const [ updateNotificationMutation ] = useMutation(UPDATE_USER_NOTIFICATIONS)
  const { campaignID } = useParams()
  const options:NotificationPreference[] = [

    {
      title: translate("Deliverable Pending Review"),
      subTitle: translate("Notification sent when Deliverable is pending review from your group."),
      notificationType: DeliverableNotificationType.DELIVERABLE_PENDING_REVIEW,
    },
    {
      title: translate("Deliverable Feedback"),
      subTitle: translate("Notification sent when feedback has been left on media or caption in a Deliverable."),
      notificationType: DeliverableNotificationType.DELIVERABLE_FEEDBACK,
    },
    {
      title: translate("Deliverable Approval Vote"),
      subTitle: translate("Notification sent when a reviewer has voted to approve a Deliverable."),
      notificationType: DeliverableNotificationType.DELIVERABLE_APPROVAL_VOTE,
    },
    {
      title: translate("Deliverable Feedback Complete"),
      subTitle: translate("Notification sent when feedback has been finalized for your group."),
      notificationType: DeliverableNotificationType.DELIVERABLE_FEEDBACK_COMPLETE,
    },
    {
      title: translate("Deliverable Pending Revision"),
      subTitle: translate("Notification sent when Deliverable is pending revision."),
      notificationType: DeliverableNotificationType.DELIVERABLE_PENDING_REVISION,
    },
    {
      title: translate("Deliverable Finalized"),
      subTitle: translate("Notification sent when a Deliverable has been finalized."),
      notificationType: DeliverableNotificationType.DELIVERABLE_FINALIZED,
    },
    {
      title: translate("Deliverable Live"),
      subTitle: translate("Notification sent when a Deliverable status changed to live."),
      notificationType: DeliverableNotificationType.DELIVERABLE_LIVE,
    },

  ]

  const [ preferences, setPreferences ] = useState<DeliverableNotificationType[]>(notificationPreferences)

  const toggleNotification = async (selectedPreference: DeliverableNotificationType) => {
    const updatedNotofications = preferences.includes(selectedPreference)
      ? preferences.filter((preference) => preference !== selectedPreference)
      : [ ...preferences, selectedPreference ]

    const input:UserNotificationPreference = {
      campaignId: campaignID || "0",
      notificationPreferences: updatedNotofications,
    }
    setPreferences(updatedNotofications)
    await updateNotificationMutation({
      variables: { input },
      context: {
        apiVersion: "v2",
      },
    })
  }
  return (
    <div className="cp_component_campaign-notification-toggles">
      {
        options.map((option) => (
          <div className="toggle-row">
            <div className="row-header">
              <h4>{ option.title }</h4>
              <p>{ option.subTitle }</p>
            </div>
            <Switch
              isChecked={ preferences.includes(option.notificationType) }
              key={ option.notificationType }
              handleChange={ () => toggleNotification(option.notificationType) }
            />
          </div>
        ))
      }
    </div>
  )
}
