import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// Deliverable Content Modal Slice Interface and Initial State
export interface CampaignDeliverableAssignReviewerModal {
  modalOpen: boolean,
  selectedReviewerGroupId: string
  searchInput: string
}

const initialState: CampaignDeliverableAssignReviewerModal = {
  modalOpen: false,
  selectedReviewerGroupId: "",
  searchInput: "",
}

// Deliverable Content Modal Slice
export const CampaignDeliverableAssignReviewerModalSlice = createSlice({
  name: "CampaignDeliverableAssignReviewerModalSlice",
  initialState,
  reducers: {
    setAssignReviewerModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      modalOpen: action.payload,
    }),
    closeAssignReviewerModal: (
      state,
    ) => ({
      ...state,
      modalOpen: false,
    }),
    setSelectedReviewerGroupId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedReviewerGroupId: action.payload,
    }),
    setAssignReviewerSearchInput: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      searchInput: action.payload,
    }),
  },
})

export const {
  setAssignReviewerModalOpen,
  closeAssignReviewerModal,
  setSelectedReviewerGroupId,
  setAssignReviewerSearchInput,
} = CampaignDeliverableAssignReviewerModalSlice.actions
export default CampaignDeliverableAssignReviewerModalSlice.reducer
