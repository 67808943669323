import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"

import Carousel, { CardData } from "../../component/Carousel"
import { useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as MiscHelper from "../../util/miscHelper"

/**
 * OverviewMetrics: Component to render the carousel of metrics used to display on the overview
 * @returns The JSX Element to render the metrics
 */
export default function OverviewMetrics() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })

  // Global state
  const { loadedListSocialAccount, iScores } = useSelector((state) => state.publicList)

  /**
   * cardData: Creates thee data cards and JSX elements for the carousel to display
   * @returns The card data for carousel
   */
  const cardData = (): CardData[] => {
    if (API.isSuccess(loadedListSocialAccount) && API.isSuccess(iScores)) {
      // Extract needed data
      const { publicSuggestionListSocialAccountByListCodeSocialAccountId: listSocialAccount } = loadedListSocialAccount.payload
      const { socialAccountsIScore: scores } = iScores.payload.getSocialAccountsIScore

      // Create array
      const cards: CardData[] = []

      // Set the age
      if (listSocialAccount.age && listSocialAccount.age !== null) {
        cards.push({
          card: createCardElement("Age", `${ listSocialAccount.age }`),
          name: "Age",
          width: 80,
        })
      }

      // Set the gender
      if (listSocialAccount.gender && listSocialAccount.gender !== null) {
        cards.push({
          card: createCardElement("Gender", listSocialAccount.gender),
          name: "Gender",
          width: 120,
        })
      }

      // Set the location
      if (listSocialAccount.location && listSocialAccount.location !== null) {
        cards.push({
          card: createCardElement("Location", listSocialAccount.location),
          name: "Location",
          width: 145,
        })
      }

      // Set the engagement score
      if (listSocialAccount.socialAccount.engagementScore) {
        cards.push({
          card: createCardElement("Eng Score", `${ Math.round(listSocialAccount.socialAccount.engagementScore.value) }`),
          name: "Engagement Score",
          width: 225,
        })
      }

      // Set the engagement rate
      if (listSocialAccount.socialAccount.socialAccountStatistics.engagementRate) {
        cards.push({
          card: createCardElement("Eng. Rate", `${
            MiscHelper.formatPercent(2, listSocialAccount.socialAccount.socialAccountStatistics.engagementRate)
          }`),
          name: "Engagement Rate",
          width: 215,
        })
      }

      // Set the TTCM Engagement rate
      if (listSocialAccount.socialAccount.ttcmEngagementRate) {
        cards.push({
          card: createCardElement("Eng. Rate (90 Day)", `${
            MiscHelper.formatPercent(2, listSocialAccount.socialAccount.ttcmEngagementRate)
          }`),
          name: "TTCM Engagement Rate",
          width: 275,
        })
      }

      // Set the in-demo value
      if (listSocialAccount.inDemo && listSocialAccount.inDemo !== null) {
        cards.push({
          card: createCardElement("In-Demo", `${
            MiscHelper.formatPercent(2, listSocialAccount.inDemo)
          }`),
          name: "Age/Gender In-Demo Percent",
          width: 300,
        })
      }

      // Set the demographic score
      if (listSocialAccount.score && listSocialAccount.score.type === GraphQL.ScoreType.Demographic) {
        cards.push({
          card: createCardElement("Demographic Score", `${ `${ Math.floor(listSocialAccount.score.value) }` }`),
          name: "Demographic Score",
          width: 215,
        })
      }

      // Get remaining scores
      const iScore = scores.find((score) => score.socialAccountId === listSocialAccount.socialAccount.id)

      // Check to see if score exists
      if (iScore?.iScore) {
        // Create the ad council score
        cards.push({
          card: createCardElement("Ad Council Score", `${ Math.floor(iScore.iScore.value) }`),
          name: "Ad Council Score",
          width: 215,
        })

        // Create the i-score value
        cards.push({
          card: createCardElement("I-Score", `${ Math.floor(iScore.iScore.value) }`),
          name: "I-Score",
          width: 120,
        })
      }

      // Return the cards, filter out the TTCM engagement rate if the network is not TIKTOK
      return (listSocialAccount.socialAccount.network === GraphQL.Network.Tiktok)
        ? cards
        : cards.filter((card) => !card.name.startsWith("TTCM"))
    }

    // Return empty state if the social account or iscores are not set
    return []
  }

  /**
   * createCardElement: Creates the JSX elements card data for a single card
   * @param name The name of the metric
   * @param value The value of the metric
   * @returns The metric card data JSX elements
   */
  const createCardElement = (name: string, value: string): React.JSX.Element => (
    <Stack
      spacing={ 1 }
      className="carousel-card"
      direction="row"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      { (name === "In-Demo")
        ? (
          <>
            <p className="body_small">{ value }</p>
            <p className="label_small-caps-bold">{ `${ translate("M") } | ` }</p>
            <p className="body_small">{ value }</p>
            <p className="label_small-caps-bold">{ translate("F") }</p>
          </>
        ) : (
          <>
            <p className="body_small">{ value }</p>
            <p className="label_small-caps-bold">{ translate(name) }</p>
          </>
        )
      }
    </Stack>
  )

  // Return the carousel JSX Elements
  return (
    <Carousel
      id="account-page-carousel"
      className="cp_component_pl-account-page-content-overview-carousel"
      displayCards={ cardData() }
    />
  )
}
