import React from "react"
import "./style.sass"

import { SuggestionListCategory } from "./CategoryAccounts"
import { ToggleScoreData } from "./ListV2PageContent"
import SocialAvatar from "../../component/SocialAvatar"
import MetricsRibbonBanner from "./highlights/MetricsRibbonBanner"
import { highlightMetrics } from "../../component/ListConfiguration/constants"
import AccountInsights from "./highlights/AccountInsights"
import PublicAccountDetails from "./highlights/PublicAccountDetails"
import ContentSection from "./highlights/ContentSection"
import { useSelector } from "../../state/hooks"
import { PublicListApprovalStatus } from "../../util/types"
import ApprovalPanel from "./ApprovalPanel"
import FeedbackPanel from "./FeedbackPanel"

interface CategoryRightPanelProps {
  category: SuggestionListCategory
  toggles: string[]
  data: ToggleScoreData
  isEpsilonVerified: boolean
}

export default function CategoryRightPanel({
  category,
  toggles,
  data,
  isEpsilonVerified,
}: CategoryRightPanelProps) {
  const {
    leftPanelApprovedStatus,
    leftPanelAccountSelected,
    leaveFeedback,
  } = useSelector((state) => state.publicList)

  // Make sure there are insights
  const hasAccountInsights: boolean = React.useMemo(() => (
    toggles.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Account Insights")?.metrics
      .find((hmMetric) => hmMetric.code === metric))
  ), [ toggles ])

  // Make sure there are some content metrics checked
  const hasContentMetrics: boolean = React.useMemo(() => (
    toggles.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Content")?.metrics
      .find((hmMetric) => hmMetric.code === metric))
  ), [ toggles ])

  // Make sure there are public account details
  const hasPublicAccountDetails: boolean = React.useMemo(() => (
    toggles.some((metric) => highlightMetrics
      .find((group) => group.groupName === "Public List Account Details")?.metrics
      .find((hmMetric) => (hmMetric.code === metric) && ([ "Tags", "Bio", "Rationale" ].includes(hmMetric.name))))
  ), [ toggles ])

  const isPendingApproval = React.useMemo(() => {
    const accountApprovalStatus = leftPanelApprovedStatus
      .find((status) => (category.suggestionListSocialAccounts[leftPanelAccountSelected])
        && (status.accountId === category.suggestionListSocialAccounts[leftPanelAccountSelected].id))
    return accountApprovalStatus?.status === PublicListApprovalStatus.PendingApproval
  }, [ leftPanelApprovedStatus ])

  // Show approval overlay
  if (isPendingApproval) {
    return <ApprovalPanel account={ category.suggestionListSocialAccounts[leftPanelAccountSelected] } />
  }

  if (leaveFeedback) {
    return <FeedbackPanel account={ category.suggestionListSocialAccounts[leftPanelAccountSelected] } />
  }

  return (
    <div className="cp_component_pl-right-panel">
      { category.suggestionListSocialAccounts.map((account) => (
        <div className="cp_lcp_highlights-previews_account-container" key={ account.id }>
          <SocialAvatar
            followers={ account.socialAccount.socialAccountStatistics.followers }
            fullName=""
            isBlacklisted={ false }
            isNSFW={ false }
            isPlaceholder={ false }
            isUnsubscribed={ false }
            network={ account.socialAccount.network }
            profilePictureUrl={ account.socialAccount.profilePictureUrl }
            username={ account.socialAccount.userName }
            eVerified={ isEpsilonVerified }
          />
          <MetricsRibbonBanner key={ account.id } account={ account } toggles={ toggles } data={ data } />
          { hasPublicAccountDetails && (
            <div>
              <PublicAccountDetails key={ account.id } account={ account } toggles={ toggles } />
            </div>
          ) }
          { hasAccountInsights && (
            <div>
              <AccountInsights key={ account.id } account={ account } toggles={ toggles } />
            </div>
          ) }
          { hasContentMetrics && (
            <div>
              <ContentSection key={ account.id } account={ account } toggles={ toggles } />
            </div>
          ) }
        </div>
      )) }
    </div>
  )
}
