import React from "react"
import { useSelector } from "../../state/hooks"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import PublicCampaignReportingV2 from "../CampaignTabs/Tabs/reporting/PublicCampaignReportingV2"

export default function PublicCampaignReportV2() {
  const { reportV2: report } = useSelector(({ publicCampaignReport }) => publicCampaignReport)

  const renderContent = () => {
    if (report === "init" || report === "loading") {
      return <LoadingIndicatorCard />
    }

    if (report.status === "error") {
      return <ErrorHandler err={ report } />
    }

    return (
      <div className="cp_component_public-campaign-report_container">
        <PublicCampaignReportingV2 />
      </div>
    )
  }

  return (
    <div className="cp_component_public-campaign-report">
      { renderContent() }
    </div>
  )
}
