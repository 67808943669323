import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { useMutation, useQuery } from "@apollo/client"
import Button from "../Button"
import DeliverableFeedbackCard from "./FeedbackCard"
import LoadingIndicator from "../LoadingIndicator"

import { useDispatch, useSelector } from "../../state/hooks"
import { setEdited, setSelectedMediaForFeedback } from "../../state/campaignDeliverableContentModalV2Slice"

import "./media-feedback.sass"
import {
  ADD_DELIVERABLE_ITEM_COMMENT,
  AddDeliverableItemCommentInput,
  AddDeliverableItemCommentOutput,
  DeliverableItemFeedbackItemsOutput,
  DeliverableItemFeedbackItemsVariables,
  DeliverableMediaItem,
  DeliverableMediaItemsOutput,
  GET_DELIVERABLE_HISTORY,
  GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
  GET_DELIVERABLE_MEDIA_ITEMS,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import RichTextEditor from "../RichTextEditor"

interface MediaFeedbackProps {
  deliverableMedia: DeliverableMediaItem
}

function MediaFeedback({ deliverableMedia }: MediaFeedbackProps): React.JSX.Element {
  const [ newMessage, setNewMessage ] = useState("")
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const {
    data, loading: queryLoading,
  } = useQuery<DeliverableItemFeedbackItemsOutput, DeliverableItemFeedbackItemsVariables>(
    GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
    {
      variables: { deliverableItemId: deliverableMedia.id },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableMedia.id,
    },
  )

  const { deliverable } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const dispatch = useDispatch()

  const closeCard = useCallback(() => {
    dispatch(setEdited(true))
    dispatch(setSelectedMediaForFeedback({ media: undefined }))
  }, [])

  const [ feedbackHidden, setFeedbackHidden ] = useState<boolean>(true)

  // NOTE: Temporariy removing feature until BE updates for campaign managers come into place
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleFeedbackVisibility = () => {
    setFeedbackHidden((prev) => !prev)
  }

  const [ addDeliverableItemComment, { loading: mutationLoading } ] = useMutation<
    AddDeliverableItemCommentOutput,
    AddDeliverableItemCommentInput
  >(ADD_DELIVERABLE_ITEM_COMMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [ {
      query: GET_DELIVERABLE_HISTORY,
      variables: { deliverableId: deliverable?.id || "" },
      context: { apiVersion: "v2" },
    }, {
      query: GET_DELIVERABLE_ITEM_FEEDBACK_ITEMS,
      variables: { deliverableItemId: deliverableMedia.id },
      context: { apiVersion: "v2" },
    } ],
  })

  const createFeedback = useCallback(async () => {
    if (!deliverable) return
    await addDeliverableItemComment({
      variables: {
        content: newMessage,
        deliverableItemId: deliverableMedia.id,
        isHidden: feedbackHidden,
      },
      context: {
        apiVersion: "v2",
      },
      update: (cache) => {
        if (!feedbackHidden) {
          const deliverableItemsData = cache.readQuery<DeliverableMediaItemsOutput>({
            query: GET_DELIVERABLE_MEDIA_ITEMS,
            variables: {
              deliverableId: deliverable.id,
            },
          })
          if (deliverableItemsData) {
            cache.writeQuery<DeliverableMediaItemsOutput>({
              query: GET_DELIVERABLE_MEDIA_ITEMS,
              variables: deliverable.id,
              data: {
                deliverableMediaItems: deliverableItemsData.deliverableMediaItems.map((mediaItem) => ({
                  ...mediaItem,
                  feedbackCount: mediaItem.id === deliverableMedia.id
                    ? mediaItem.feedbackCount + 1
                    : mediaItem.feedbackCount,
                })),
              },
            })
          }
        }
      },
    })
    setNewMessage("")
    setFeedbackHidden(false)
  }, [ newMessage, deliverable, feedbackHidden ])

  if (queryLoading || !data?.deliverableItemFeedbackItems) return <LoadingIndicator />

  return (
    <div className="cp_media-feedback_component">
      { data.deliverableItemFeedbackItems.map(
        (feedback) => <DeliverableFeedbackCard feedback={ feedback } />,
      ) }
      <div className="cp_media-feedback_component-new-message">
        <RichTextEditor
          menuBarOptions={ {
            mergeTagsDisabled: true,
            codeBlockDisabled: true,
            textAlignDisabled: true,
            textSizeDisabled: true,
          } }
          editorContent={ newMessage }
          setEditorContent={ setNewMessage }
        />
        <div className="cp_media-feedback_component-new-message-controls">
          <div className="button-container">
            <Button
              label={ mutationLoading ? <LoadingIndicator /> : translate("Send") }
              disabled={ newMessage.length === 0 || mutationLoading }
              onClick={ createFeedback }
            />
            <Button
              isPrimary={ false }
              disabled={ mutationLoading }
              onClick={ closeCard }
              label={ translate("CANCEL") }
            />
          </div>
          { /* NOTE: Temporariy removing feature until BE updates for campaign managers come into place */ }
          { /* <div className="visibility-container">
            { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
            <div className="visibility-container">
              { !feedbackHidden
                ? (
                  <>
                    <p>{ translate("FEEDBACK VISIBLE") }</p>
                    <IconButton onClick={ toggleFeedbackVisibility }>
                      <VisibilityOutlined />
                    </IconButton>
                  </>
                )
                : (
                  <>
                    <p>{ translate("FEEDBACK HIDDEN") }</p>
                    <IconButton onClick={ toggleFeedbackVisibility }>
                      <VisibilityOffOutlined />
                    </IconButton>
                  </>
                )
              }
            </div>
            ) }
          </div> */ }
        </div>
      </div>
    </div>
  )
}

export default MediaFeedback
