import React from "react"
import "./style.sass"

import {
  Campaign,
  Check,
  ChevronRight,
} from "@mui/icons-material"
import { Badge } from "@mui/material"
import { useTranslation } from "react-i18next"

import { SuggestionListAccount } from "./CategoryAccounts"
import SocialAvatar from "../../component/SocialAvatar"
import IconButton from "../../component/IconButton"
import { PublicListApprovalStatus } from "../../util/types"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  setLeftPanelApprovedStatus,
  setLeaveFeedback,
  AccountApprovalStatus,
} from "../../state/publicListSlice"

interface AccountCardProps {
  account: SuggestionListAccount
  isCampaign: boolean
  onClick: (openAccount: boolean) => void
  isEpsilonVerified: boolean
}

export default function AccountCard({
  account,
  isCampaign,
  onClick,
  isEpsilonVerified,
}: AccountCardProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListV2AccountCard" })
  const dispatch = useDispatch()

  const { leftPanelApprovedStatus } = useSelector((state) => state.publicList)

  const [ approval, setApproval ] = React.useState<PublicListApprovalStatus>(PublicListApprovalStatus.NotApproved)

  React.useEffect(() => {
    setApproval(leftPanelApprovedStatus
      .find((status) => status.accountId === account.id)?.status || PublicListApprovalStatus.NotApproved)
  }, [ account, leftPanelApprovedStatus ])

  const updateApprovalStatus = (status: PublicListApprovalStatus): AccountApprovalStatus[] => {
    // Rebuild status changing the status for given account
    const statuses = leftPanelApprovedStatus.map((accountStatus) => {
      if (accountStatus.accountId === account.id) {
        return {
          ...accountStatus,
          status,
        }
      }
      return {
        ...accountStatus,
      }
    })

    // Return the updated statuses
    return statuses
  }

  return (
    <div
      className={ `cp_component_pl-account-card ${ (approval === PublicListApprovalStatus.PendingApproval)
        ? "pending-approval" : "" }` }
    >
      <div
        className="cp_component_pl-account-card-header"
        role="button"
        tabIndex={ 0 }
        onClick={ () => onClick(true) }
        onKeyDown={ (e) => { if (e.key === "Enter" || e.key === " ") onClick(true) } }
        aria-label="header-click-action"
      >
        <div className="cp_component_pl-account-card-header-avatar">
          <SocialAvatar
            followers={ account.socialAccount.socialAccountStatistics.followers }
            fullName=""
            isBlacklisted={ false }
            isNSFW={ false }
            isPlaceholder={ false }
            isUnsubscribed={ false }
            network={ account.socialAccount.network }
            profilePictureUrl={ account.socialAccount.profilePictureUrl }
            username={ account.socialAccount.userName }
            eVerified={ isEpsilonVerified }
          />
        </div>
        <div className="cp_component_pl-account-card-header-selector">
          <IconButton
            className="select-account-button"
            variant="outlined"
            onClick={ () => onClick(true) }
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
      { isCampaign && (
        <div className="cp_component_pl-account-card-cta">
          <div className="cp_component_pl-account-card-cta-approve-button">
            <IconButton
              className={ `approved-button ${ (approval === PublicListApprovalStatus.Approved)
                ? "approved-state"
                : (approval === PublicListApprovalStatus.PendingApproval)
                  ? "pending-approval-state"
                  : "" }` }
              variant="outlined"
              onClick={ () => {
                onClick(false)
                dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.PendingApproval)))
              } }
            >
              <Check />
            </IconButton>
            <p className="approved-label">{ translate("Approved") }</p>
          </div>
          <div className="cp_component_pl-account-card-cta-feedback-button">
            <Badge
              color="primary"
              className="feedback-badge"
              badgeContent={
                leftPanelApprovedStatus.find((accountStatus) => accountStatus.accountId === account.id)?.comments.length || 0
              }
              showZero={ true }
            >
              <IconButton
                className="feedback-button"
                variant="outlined"
                onClick={ () => {
                  onClick(false)
                  dispatch(setLeaveFeedback(true))
                } }
              >
                <Campaign />
              </IconButton>
            </Badge>
            <p className="feedback-label">{ translate("Feedback") }</p>
          </div>
        </div>
      ) }
    </div>
  )
}
