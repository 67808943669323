import React from "react"
import "./style.sass"

import { useParams } from "react-router-dom"

import PageShell from "./PageShell"
import { useDispatch } from "../../state/hooks"
import { fetchPublicListSocialProfile } from "../../state/publicListSlice"

/**
 * PublicListAccountPage: Root component of the account page
 * @returns Render's the page
 */
export default function PublicListAccountPage() {
  // Local field variables
  const { listCode, accountCode } = useParams()
  const dispatch = useDispatch()

  /**
   * React hook to fetch the public list social account
   */
  React.useEffect(() => {
    if (listCode && accountCode) {
      // Fetch the social profile
      dispatch(fetchPublicListSocialProfile({ listId: listCode, socialAccountId: accountCode }))
    }
  }, [ ])

  // JSX to render component
  return (
    <div className="cp_component_pl-account-page">
      <div className="cp_component_pl-account-page-background">
        <PageShell />
      </div>
    </div>
  )
}
