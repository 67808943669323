import React from "react"
import "../drag-n-drop-display.sass"

import { useTranslation } from "react-i18next"
import { DragIndicator } from "@mui/icons-material"

import * as GraphQL from "../../../../graphql"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { DefaultSuggestionListToggles } from "../../../../util/constant"
import {
  accountInsightDisplayCodes,
  AudienceGroup,
  DragNDropSwitchInformation,
  getDisplayName,
} from "../../constants"
import { setUpdateListForm } from "../../../../state/listConfigurationSlice"
import Switch from "../../../Switch"

/**
 * AccountInsightTogglesProps: Properties for the component
 */
export interface AccountInsightTogglesProps {
  isDragging: boolean
  setDraggingInfo: (info: AudienceGroup | GraphQL.SuggestionListToggleInput | undefined) => void
}

/**
 * AccountInsightToggles: The component to display all the configuration toggles for account insight tabs
 * for both internal and public pages
 * @param props The properties for the component
 * @returns The React JSX Elements to create view
 */
export default function AccountInsightToggles({ isDragging, setDraggingInfo }: AccountInsightTogglesProps) {
  // Local field variables
  const {
    t: translateConfigOptions,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualizationsOptions" })
  const dispatch = useDispatch()

  // Global state
  const updateListForm = useSelector((state) => state.listConfiguration.updateListForm)

  // Local state
  const [ dragInfo, setDragInfo ] = React.useState<DragNDropSwitchInformation>()
  const [ switches, setSwitches ] = React.useState<GraphQL.SuggestionListToggleInput[]>([])

  /**
   * React hook to get all the toggles and ensure they are in array format.
   */
  const allToggles = React.useMemo((): GraphQL.SuggestionListToggleInput[] => {
    if (updateListForm) {
      const { toggles } = updateListForm
      if (Array.isArray(toggles)) return [ ...toggles ]
      return [ toggles ]
    }
    return []
  }, [ updateListForm ])

  /**
   * React hook to pull in all the account insight toggles and order them properly based on
   * what has been checked.
   */
  React.useEffect(() => {
    const toggles = DefaultSuggestionListToggles
      .filter((toggle) => toggle.type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles)
      .sort((t1, t2) => t1.order - t2.order)

    // Check to see if there list form is available
    if (updateListForm) {
      // Extract checked toggles
      const { toggles: formToggles } = updateListForm
      const checkedToggles = (Array.isArray(formToggles))
        ? formToggles.filter((toggle) => toggle.type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles)
        : (formToggles.type === GraphQL.SuggestionListToggleGroupType.AccountInsightsToggles) ? [ formToggles ] : []

      // Insert checked toggles at appropriate place
      checkedToggles.forEach((toggle) => {
        // Remove toggle and replace toggle with checked at index of checked
        const defaultToggle = toggles.find((option) => option.name === toggle.name)
        if (defaultToggle) {
          toggles.splice(toggles.indexOf(defaultToggle), 1)
          toggles.splice(toggle.order, 0, toggle)
        }
      })

      // Re-order toggles
      setSwitches(toggles.map((toggle, index) => ({
        ...toggle,
        order: index,
      })))
    } else {
      setSwitches(toggles)
    }
  }, [ updateListForm ])

  /**
   * isChecked: Checks to see if the toggle has been checked by checking
   * the update list form for the toggle name
   * @param option The toggle to check for
   * @returns True if the toggle exists in the update list form, otherwise false
   */
  const isChecked = (option: GraphQL.SuggestionListToggleInput) => (
    allToggles.some((toggle) => toggle.name === option.name)
  )

  /**
   * toggleSwitch: Checks or unchecks a switch based on the option being in the list
   * form toggles.
   * @param option The option being toggled
   */
  const toggleSwitch = (option: GraphQL.SuggestionListToggleInput) => {
    if (isChecked(option)) {
      // Get the toggle from all toggles
      const toggle = allToggles.find((t) => t.name === option.name)
      if (toggle) {
        // Remove the toggle
        allToggles.splice(allToggles.indexOf(toggle), 1)
      }
    } else {
      // Add the toggle
      allToggles.push(option)
    }

    // Make sure form exists
    if (updateListForm) {
      // Copy of form
      const form = { ...updateListForm }

      // Set the toggles
      form.toggles = allToggles

      // Save to update form
      dispatch(setUpdateListForm(form))
    }
  }

  /**
   * startDrag: This function runs when dragging an item begins
   * @param event The event for the drag
   * @param option The option being dragged
   */
  const startDrag = (event: React.DragEvent<HTMLSpanElement>, option: GraphQL.SuggestionListToggleInput) => {
    // Set local state to indicate dragging
    setDragInfo({
      currentIndex: switches.indexOf(option),
      option,
    })

    // Pass to parent for drag end event processing
    setDraggingInfo(option)
  }

  /**
   * dragOverSwitch: This function runs when the drag item moves over the top of a droppable item
   * @param event The event of moving over drop zone
   * @param option The option we are over top of
   */
  const dragOverSwitch = (event: React.DragEvent<HTMLSpanElement>, option: GraphQL.SuggestionListToggleInput) => {
    // Prevent default actions
    event.preventDefault()

    // Make sure this component is dragging something
    if (dragInfo) {
      // Get the index of the option
      const optionIndex = switches.indexOf(option)

      // Check to see if current index is different from option index
      if (dragInfo.currentIndex !== optionIndex) {
        // Set new drag information
        setDragInfo({
          ...dragInfo,
          currentIndex: optionIndex,
        })

        // Move option to new location
        const updatedSwitches = [ ...switches ]
        updatedSwitches.splice(optionIndex, 0, ...updatedSwitches.splice(dragInfo.currentIndex, 1))
        setSwitches(updatedSwitches)
      }
    }
  }

  /**
   * dropSwitch: This event is fired when the drag is relaeased and it
   * reorders and places the order in the update list for as well as in
   * the view.
   * @param event The event for dropping
   */
  const dropSwitch = (event: React.DragEvent<HTMLSpanElement>) => {
    // Prevent default action
    event.preventDefault()

    // Make sure we are dragging something
    if (dragInfo && updateListForm) {
      const form = { ...updateListForm }
      const updatedSwitches = switches.map((option, index) => {
        // New option
        const newOption: GraphQL.SuggestionListToggleInput = {
          ...option,
          order: index,
        }

        // Get the checked toggle (if it's there)
        const checkedToggle = allToggles.find((toggle) => toggle.name === option.name)
        if (checkedToggle) {
          // Replace checked toggle with the one in switch
          allToggles.splice(allToggles.indexOf(checkedToggle), 1, newOption)
        }

        // Return the new option
        return newOption
      })

      // set the form toggles
      form.toggles = allToggles

      // Update the form and switches
      setSwitches(updatedSwitches)
      dispatch(setUpdateListForm(form))
      setDragInfo(undefined)
    }
  }

  // Turn off dragging, if dragEnd event fired upstream
  if (!isDragging && dragInfo) {
    // Clear drag information
    setDragInfo(undefined)

    // Set the correct order
    const updateSwitches = switches.sort((t1, t2) => t1.order - t2.order)
    setSwitches(updateSwitches)
  }

  return (
    <div className="cp_component_account-insight-toggles">
      { switches.map((option) => (
        <p
          className={
            `config-option${ (dragInfo && dragInfo.option.name === option.name)
              ? " dragging"
              : "" }`
          }
          draggable={ true }
          onDragStart={ (e) => { startDrag(e, option) } }
          onDragOver={ (e) => { dragOverSwitch(e, option) } }
          onDrop={ dropSwitch }
        >
          <Switch
            className="config-option-switch"
            isChecked={ isChecked(option) }
            handleChange={ () => toggleSwitch(option) }
          />
          <span className="config-option-label">
            { translateConfigOptions(getDisplayName(option.name, accountInsightDisplayCodes)) }
          </span>
          <DragIndicator className="config-option-dragicon" />
        </p>

      )) }
    </div>
  )
}
