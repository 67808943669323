import React from "react"

import { useTranslation } from "react-i18next"
import * as GraphQL from "../../../../graphql"
import { CAMPAIGN_REPORTING_VISUALS, NETWORK_REPORTING_ORDER } from "../../../../util/constant"
import { groupBy } from "./reportingHelper"

import "./native-posts.sass"
import PostCardV2 from "./PostCardV2"

interface Props {
  deliverables: GraphQL.DeliverableOverviewV2Fragment[]
  toggles: GraphQL.CampaignReportToggleSetting[]
}

interface CardsProps {
  deliverables: GraphQL.DeliverableOverviewV2Fragment[]
  network: string
  toggles: GraphQL.CampaignReportToggleSetting[]
}

function NativePostsCards({
  deliverables, network, toggles,
}: CardsProps) {
  return (
    <div className="cards-sections">
      { (deliverables && deliverables.length !== 0) && (
        deliverables.map(
          (deliverable) => (
            <PostCardV2
              key={ deliverable.id }
              deliverable={ deliverable }
              network={ network }
              toggles={ toggles }
            />
          ),
        )
      ) }
    </div>
  )
}

export default function CampaignNativePostsReportV2({ deliverables, toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const filteredDeliverables = deliverables.filter((deliverable) => (
    deliverable.enhancedContentStatus === GraphQL.EnhancedDeliverableStatus.Live
      && deliverable.postType !== GraphQL.DeliverablePostType.Story
      && deliverable.nativeInsights
  ))

  const groupedByNetwork = groupBy<GraphQL.DeliverableOverviewV2Fragment, string>(
    filteredDeliverables,
    (deliverable) => deliverable.campaignNetworkAccount.socialAccount.network,
  )
  const togglesByNetwork = groupBy<GraphQL.CampaignReportToggleSetting, string>(
    toggles,
    (setting) => setting.platform,
  )
  const networksAvailable = Object.keys(groupedByNetwork) || []

  // Order the networks for proper display
  const networksAvailableOrdered = NETWORK_REPORTING_ORDER
    .map((network) => networksAvailable.find((item) => item === network.toUpperCase()))
    .filter((nw): nw is string => nw != null)

  const filteredByShowPostEnabled = networksAvailableOrdered.filter((network) => {
    // find show_posts toggle
    // return the enable value per network
    const currNetwork = network.toLowerCase()
    const currentNetworkToggles = togglesByNetwork[currNetwork] || []
    if (!currentNetworkToggles.length) return false
    const showPostToggle = currentNetworkToggles.filter((setting) => (
      setting.section === CAMPAIGN_REPORTING_VISUALS.MEDIA_POSTS_NATIVE
        && setting.toggle === CAMPAIGN_REPORTING_VISUALS.SHOW_POSTS
    ))
    return showPostToggle.length ? showPostToggle[0].enabled : false
  })

  return (
    <div className="cp_report-native-posts">
      { filteredByShowPostEnabled.length !== 0 && (
        filteredByShowPostEnabled.map((network) => {
          const capitalizedNetwork = network.charAt(0).toUpperCase() + network.slice(1).toLowerCase()
          const uniqueId: string = `${ network }-${ Date.now() }`
          return (
            <div key={ `${ uniqueId } ` } className="section">
              <h2 className="section-heading">{ `${ translate("Native Posts") } ${ capitalizedNetwork }` }</h2>
              <NativePostsCards deliverables={ groupedByNetwork[network] } toggles={ toggles } network={ network } />
            </div>
          )
        })
      ) }
    </div>
  )
}
