import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useCookies } from "react-cookie"
import { CheckCircleOutline } from "@mui/icons-material"

import * as GraphQL from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import { isSuccess } from "../../util/apiClient"
import { PublicListApprovalStatus } from "../../util/types"
import {
  AccountApprovalStatus,
  approvePublicList,
  PublicListSocialAccount,
  setInfoRequiredSubmitCallback,
  setLeftPanelApprovedStatus,
  userCanEdit,
} from "../../state/publicListSlice"
import Button from "../../component/Button"
import LoadingIndicator from "../../component/LoadingIndicator"

/**
 * ApprovalOverlay: The component to render the overlay for the approval of the account
 * @returns The JSX Elements to render approval overlay
 */
export default function ApprovalOverlay() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "page.PublicListAccountPage" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listCode, accountCode } = useParams()

  // Global state
  const {
    loadedListSocialAccount,
    leftPanelApprovedStatus,
    nameCookieKey,
    companyCookieKey,
    userVerification,
  } = useSelector((state) => state.publicList)

  // Cookies for verifying required information
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ cookies, setCookie ] = useCookies([ nameCookieKey, companyCookieKey ])

  // Local state
  const [ accessCodeRestriction, setAccessCodeRestriction ] = React
    .useState<GraphQL.SuggestionAccessCodeRestriction | null | undefined>()
  const [ userIsValid, setUserIsValid ] = React.useState<boolean>(false)
  const [ submitted, setSubmitted ] = React.useState<boolean>(false)

  // React hook to set access code restrictions and if the user is valid
  React.useEffect(() => {
    if (isSuccess(userVerification)) {
      const { publicVerifySuggestionListAccessCodeByListCode: verification } = userVerification.payload
      setAccessCodeRestriction(verification.suggestionAccessCodeRestriction)
      setUserIsValid(verification.isValid)
    }
  }, [ userVerification ])

  // React hook to set the account value
  const account = React.useMemo((): PublicListSocialAccount => {
    if (isSuccess(loadedListSocialAccount)) {
      return loadedListSocialAccount.payload.publicSuggestionListSocialAccountByListCodeSocialAccountId
    }
    return {} as PublicListSocialAccount
  }, [ loadedListSocialAccount ])

  /**
   * updateApprovalStatus: Updates the approval status for the given account
   * @param status The updated status value for account
   * @returns The list of statuses with current account updated
   */
  const updateApprovalStatus = (status: PublicListApprovalStatus): AccountApprovalStatus[] => {
    // Rebuild status changing the status for given account
    const statuses = leftPanelApprovedStatus.map((accountStatus) => {
      if (accountStatus.accountId === account.id) {
        return {
          ...accountStatus,
          status,
        }
      }
      return {
        ...accountStatus,
      }
    })

    // Return the updated statuses
    return statuses
  }

  /**
   * submitApproval: Submit the account for approval.  This function will check to see if the
   * user is valid or redirect the user to the required information before updating the account
   * status to approved and closing the overlay
   */
  const submitApproval = async () => {
    setSubmitted(true)
    if (listCode && accountCode) {
      // Check the cookies
      if (cookies[nameCookieKey] && cookies[companyCookieKey] && userCanEdit(accessCodeRestriction, userIsValid)) {
        // Save to database
        dispatch(approvePublicList({
          suggestionListCode: listCode,
          suggestionListSocialAccountId: account.id,
          onSuccess: () => {
            // Update global branch
            dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.Approved)))
          },
        }))
      } else {
        dispatch(setInfoRequiredSubmitCallback(() => {
          dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.Approved)))
          navigate(`../../account/${ account.socialAccount.id }`, { relative: "path" })
        }))
        navigate(`../../requiredInformation/${ account.id }`, { relative: "path" })
      }
    }
  }

  /**
   * cancelApproval: Updates the global state to cancel this process and close the overlay
   */
  const cancelApproval = () => {
    // Update global branch
    dispatch(setLeftPanelApprovedStatus(updateApprovalStatus(PublicListApprovalStatus.NotApproved)))
  }

  // Return the JSX Elements for render
  return (
    <div className="cp_component_pl-account-page-content-approval-overlay">
      <div className="cp_component_pl-account-page-content-approval-overlay-content">
        <div className="cp_component_pl-account-page-content-approval-overlay-check-icon">
          <CheckCircleOutline className="checkmark-icon" />
        </div>
        <p>{ translate("Are you sure you want to approve this account for your campaign?") }</p>
        <div className="cp_component_pl-account-page-content-approval-overlay-actions">
          <Button
            className="btn-approval"
            label={ (submitted) ? <LoadingIndicator /> : translate("Yes, I Approve") }
            isPrimary={ true }
            onClick={ submitApproval }
          />
          <Button
            className="btn-cancel"
            label={ translate("Cancel") }
            isPrimary={ false }
            onClick={ cancelApproval }
          />
        </div>
      </div>
    </div>
  )
}
