import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"

import { useQuery } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import Content from "./Content"
import History from "./History"
import Modal from "../Modal"
import Overview from "./Overview"
import PostPreview from "./PostPreview"
import StatusDeliverable from "../StatusDeliverable"
import {
  DeliverableQueryVariables,
  GET_DELIVERABLE,
  DeliverableQueryOutput,
  DeliverableCaptionItemsOutput,
  GET_DELIVERABLE_CAPTION_ITEMS,
  DeliverableMediaItemsOutput,
  GET_DELIVERABLE_MEDIA_ITEMS,
} from "../../state/campaignDeliverableContentModalV2Slice/types"
import { useSelector, useDispatch } from "../../state/hooks"
import { DeliverableStatus, Network } from "../../graphql"
import {
  closeDeliverableContentModal,
  setDeliverable,
  setDeliverableCaption,
  setDeliverableMedia,
  setCaptionToApprove,
  setMediaToApprove,
  setCaptionFeedback,
  setSelectedMediaForFeedback,
} from "../../state/campaignDeliverableContentModalV2Slice"
import IconButton from "../IconButton"

import "./style.sass"
import FeedbackPage from "./FeedbackPage"

function ModalCampaignDeliverableContentV2(): React.JSX.Element {
  const {
    modalOpen: deliverableModalOpen,
    deliverable,
    hidden,
    feedbackPageOpen,
    goLiveConfirmationModalOpen,
    finalizeDeliverableConfirmationModalOpen,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const {
    modalOpen: updateModalOpen,
  } = useSelector(({ campaignDeliverableV2UpdateContentModal }) => campaignDeliverableV2UpdateContentModal)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const dispatch = useDispatch()

  const [ searchParams, setSearchParams ] = useSearchParams()

  const deliverableId = searchParams.get("deliverableId") || ""

  const { data: deliverableData, refetch: refetchDeliverable } = useQuery<DeliverableQueryOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      fetchPolicy: "network-only",
      skip: !deliverableId || !deliverableModalOpen,
    },
  )
  const {
    data: deliverableCaptionData,
    refetch: refetchCaption,
  } = useQuery<DeliverableCaptionItemsOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_CAPTION_ITEMS,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId || !deliverableModalOpen,
    },
  )
  const { data: deliverableMediaData, refetch: refetchMedia } = useQuery<DeliverableMediaItemsOutput, DeliverableQueryVariables>(
    GET_DELIVERABLE_MEDIA_ITEMS,
    {
      variables: { deliverableId },
      context: {
        apiVersion: "v2",
      },
      skip: !deliverableId || !deliverableModalOpen,
    },
  )

  // Refetch on subsequest openings of modal
  useEffect(() => {
    if (deliverableId && modalOpen) {
      if (deliverableCaptionData) refetchCaption()
      if (deliverableMediaData)refetchMedia()
      if (deliverableData) refetchDeliverable()
    }
  }, [ deliverableModalOpen ])

  useEffect(() => {
    if (deliverableData) {
      const {
        deliverable: fetchedDeliverable,
      } = deliverableData

      if (fetchedDeliverable) {
        dispatch(setDeliverable({ deliverable: fetchedDeliverable }))
      }
    }

    return () => {
      dispatch(setDeliverable({ deliverable: undefined }))
    }
  }, [ deliverableData ])

  useEffect(() => {
    if (deliverableCaptionData) {
      dispatch(setDeliverableCaption({ deliverableCaption: deliverableCaptionData.deliverableCaptionItems }))
    }

    if (deliverableMediaData) {
      dispatch(setDeliverableMedia({ deliverableMedia: deliverableMediaData.deliverableMediaItems }))
    }
    return () => {
      dispatch(setDeliverableCaption({ deliverableCaption: undefined }))
      dispatch(setDeliverableMedia({ deliverableMedia: [] }))
    }
  }, [ deliverableCaptionData, deliverableMediaData ])

  const closeModal = async () => {
    searchParams.delete("deliverableId")
    setSearchParams(searchParams)
    dispatch(setCaptionToApprove({ caption: undefined }))
    dispatch(setMediaToApprove({ media: undefined }))
    dispatch(setCaptionFeedback({ caption: undefined }))
    dispatch(setSelectedMediaForFeedback({ media: undefined }))
    dispatch(closeDeliverableContentModal())
  }

  function customActions(): JSX.Element[] {
    if (!deliverable) return [ <> </> ]

    const componentRight = (
      <div className="cp_deliverable-v2-content-overview_component-right">
        <StatusDeliverable deliverableStatus={ deliverable.deliverableStatus as DeliverableStatus } />
        { (deliverable.deliverableStatus === DeliverableStatus.Live) && (
        <IconButton
          disabled={ !deliverable.livePostUrl }
          onClick={ () => {
            window.open(deliverable.livePostUrl || "", "_blank")
          } }
        >
          <OpenInNewIcon />
        </IconButton>
        ) }
      </div>
    )
    return [ componentRight ]
  }

  const primaryButtonLabel = useMemo(() => {
    if (deliverable?.deliverableStatus === DeliverableStatus.Finalized
      && deliverable.socialAccount.networkType === Network.Tiktok
      && !deliverable.ttcmOrderId
    ) return translate("Generate Invite Link")
    if (deliverable?.deliverableStatus === DeliverableStatus.Finalized
      || deliverable?.deliverableStatus === DeliverableStatus.Uploaded
    ) return translate("Go Live")
    return translate("Finalize Deliverable")
  }, [ deliverable ])

  // Hides deliverable modal while sub modals are open
  const modalOpen = deliverableModalOpen
    && !updateModalOpen
    && !goLiveConfirmationModalOpen
    && !finalizeDeliverableConfirmationModalOpen

  const getModalSubTitle = useMemo(
    () => {
      if (deliverable?.socialAccount.networkType === Network.Tiktok && deliverable.ttcmOrderId && deliverable.ttcmCampaignName) {
        return `ORDER ID #${ deliverable.ttcmOrderId } ${ "   " } ${ deliverable.ttcmCampaignName }`
      }

      return ""
    },
    [ deliverable ],
  )

  if (!deliverable) return <> </>

  return (
    <Modal
      hidden={ hidden }
      open={ modalOpen }
      title={ deliverable?.name }
      subtitle={ getModalSubTitle }
      clipboardTitle={ deliverable?.name }
      primaryLabel={ primaryButtonLabel } // TODO: Add rules
      closeAction={ closeModal }
      customTopRightActions={ customActions() }
      maxWidth="xl"
      className="cp_component_modal-campaign-deliverable-v2-content"
      hasPrimaryButton={ false }
      hasSecondaryButton={ false }
      hasFooter={ false }
    >
      <Overview />
      { !feedbackPageOpen ? (
        <div className="cp_component_modal-campaign-deliverable-v2-content-columns">
          <div className="content-history">
            <Content />
            <History />
          </div>
          <PostPreview />
        </div>
      ) : (
        <FeedbackPage />
      ) }
    </Modal>
  )
}

export default ModalCampaignDeliverableContentV2
