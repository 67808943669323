import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  debounce,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material"
import { Search } from "@mui/icons-material"
import FlagCircleIcon from "@mui/icons-material/FlagCircle"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import Input, { Props as InputProps } from "../Input"
import LoadingIndicator from "../LoadingIndicator"
import NetworkIcon from "../NetworkIcon"
import { DEFAULT_DEBOUNCE_WAIT } from "../../util/constant"

import "./list-autocomplete.sass"

type List = GraphQL.SuggestionListRowFragment

interface Props {
  setSelectedLists: (list: List[]) => void
  selectedLists: List[],
}

const networkFilters = [
  GraphQL.Network.Twitter,
  GraphQL.Network.Instagram,
  GraphQL.Network.Tiktok,
  GraphQL.Network.Youtube,
  GraphQL.Network.Facebook,
]

function ListsAutocomplete({
  setSelectedLists,
  selectedLists,
}: Props) {
  const [ lists, setLists ] = useState<List[]>([])
  const [ listsLoading, setListsLoading ] = useState(false)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaign" })

  const searchLists = async (startsWith: string) => {
    const result = await API.fetchLists({
      startsWith: startsWith || "",
      networkFilter: networkFilters,
      page: 1,
      limit: 50,
    })

    if (API.isSuccess(result)) {
      const campaignLists = result.payload.searchSuggestionList.rows.filter((
        list,
      ) => list.suggestionListMode === GraphQL.SuggestionListMode.Campaign)
      setLists(campaignLists)
    } else {
      setLists([])
    }
  }

  const debouncedSearchLists = debounce(async (e, inputValue) => {
    setListsLoading(true)
    await searchLists(inputValue)
    setListsLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    changeLists: Array<string | List>,
  ) => {
    const cleanedLists = changeLists.filter((list) => typeof list !== "string") as typeof lists

    setSelectedLists(cleanedLists)
  }

  const filter = createFilterOptions<List>()

  const autoCompleteFilter = (
    autoCompleteLists: List[],
    params: FilterOptionsState<List>,
  ) => {
    const filtered = filter(autoCompleteLists, params)
      .filter((list) => !selectedLists.some((selectedList) => list.id === selectedList.id))

    return filtered
  }

  useEffect(() => {
    searchLists("")
  }, [])

  return (
    <Autocomplete
      filterSelectedOptions={ true }
      value={ selectedLists }
      multiple={ true }
      disableClearable={ true }
      forcePopupIcon={ true }
      getOptionLabel={ (option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option
        return option.name
      } }
      className="cp_component_autocomplete_lists"
      ListboxProps={ {
        className: "cp_component_autocomplete_lists",
      } }
      onInputChange={ debouncedSearchLists }
      renderTags={ () => <></> }
      onChange={ onAutoCompleteChange }
      filterOptions={ autoCompleteFilter }
      selectOnFocus={ true }
      clearOnBlur={ false }
      handleHomeEndKeys={ true }
      id="autocomplete-lists"
      options={ lists.sort() }
      openOnFocus={ true }
      loading={ listsLoading }
      loadingText={ <LoadingIndicator size={ 20 } /> }
      renderOption={ (props, list, state, ownerState) => {
        if (ownerState.loading && state.index > 0) return (<></>)
        if (ownerState.loading) {
          return (
            <li
              { ...props }
              // eslint-disable-next-line react/prop-types
              className={ `${ props.className || "" } cp_component_autocomplete_lists_loading` }
            >
              <LoadingIndicator size={ 20 } />
            </li>
          )
        }
        return (
          <li
            { ...props }
            key={ list.id }
          >
            <p>
              { list.name }
              { " " }
              { list.suggestionListMode === GraphQL.SuggestionListMode.Campaign ? <FlagCircleIcon /> : null }
              <NetworkIcon
                network={ list.network }
                isActive={ true }
              />
            </p>
            <strong>
              {
              `${ translate("Created by") } ${ list.creator.username } 
              | ${ dayjs(list.created * 1000).format(Constant.LONGFORM_DATE) }`
              }
            </strong>
          </li>
        )
      }
}
      freeSolo={ true }
      renderInput={ (params) => (
        <Input
          { ...params as InputProps }
          placeholder={ translate("Enter List Name") }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <Search />,
          } }
        />
      )
}
    />
  )
}

export default ListsAutocomplete
