import React from "react"

import { useTranslation } from "react-i18next"

import * as GraphQL from "../../../graphql"
import { useDispatch, useSelector } from "../../../state/hooks"
import { HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES } from "../../../util/constant"
import EngagementTable from "../../ProfileTabs/AccountInsights/EngagementTable"
import { getLocalTZOffset } from "../../../util/miscHelper"
import PublicBrands from "../../ProfileTabs/PublicListTabs/AccountInsightsDetails/PublicBrands"
import {
  fetchDetectedBrands,
  fetchMentionedBrands,
  fetchWorkedWithBrands,
  getDailyHourlyEngagementRate,
  getMentionedPosts,
  getMoreMentionedPosts,
} from "../../../state/publicListSlice"
import PersonalityTraits from "./PersonalityTraits"

type FetchPublicBrandParams = Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">

/**
 * AccountInsightsProps: Properties required for component
 */
interface AccountInsightsProps {
  // eslint-disable-next-line max-len
  account: GraphQL.SearchSuggestionListByIdQuery["suggestionListById"]["suggestionListCategories"][number]["suggestionListSocialAccounts"][number]
}

/**
 * AccountInsights: Displays all the charts for the account insights page
 * @param param0 The suggestion list social account
 * @returns The display for all the fields
 */
export default function AccountInsights({ account }: AccountInsightsProps) {
  // Local variables
  const dispatch = useDispatch()
  const { t: translate } = useTranslation([], { keyPrefix: "component.ConfigurationHighlightsTab" })

  // Global state
  const updateListForm = useSelector(({ listConfiguration }) => listConfiguration.updateListForm)
  const {
    detectedBrands,
    mentionedBrands,
    workedWithBrands,
    mentionedPostsContent,
    mentionedPostsStatus,
  } = useSelector((state) => state.publicList)

  // Local state
  const [ timeZone ] = React.useState(getLocalTZOffset() || -8)

  React.useEffect(() => {
    // Fetch the daily hourly engagement rate
    dispatch(getDailyHourlyEngagementRate({
      suggestionListCode: account.suggestionList.code,
      socialAccountId: account.socialAccount.id,
      timeZoneOffset: timeZone,
      limit: 20,
    }))
  }, [ ])

  const toggles: string[] = React.useMemo((): string[] => (
    (updateListForm)
      ? (Array.isArray(updateListForm.toggles))
        ? updateListForm.toggles.map((t) => t.name) : [ updateListForm.toggles.name ]
      : []
  ), [ updateListForm ])

  return (
    <div className="cp_lcp_highlights-previews_account-container_account-insights">
      { ((toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE))
        || (toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL))) && (
        <div className="account-insights_rate-by-post-type">
          <p className="account-insights_rate-by-post-type_header">{ translate("Engagement by Post Type") }</p>
          <div className="account-insights_rate-by-post-type_widget">
            <EngagementTable
              socialProfile={ account.socialAccount }
              displayPostType={ toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE) }
              displayPostTypeReel={ toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL) }
            />
          </div>
        </div>
      ) }
      { (toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH)) && (
        <div className="account-insights_brands-mentioned-worked">
          <p className="account-insights_brands-mentioned-worked_header">{ translate("Brands") }</p>
          <div className="account-insights_brands-mentioned-worked_widget">
            <PublicBrands
              socialProfile={ account.socialAccount }
              detectedBrands={ detectedBrands }
              mentionedBrands={ mentionedBrands }
              workedWithBrands={ workedWithBrands }
              fetchDetectedBrands={ (params: FetchPublicBrandParams) => dispatch(fetchDetectedBrands(params)) }
              fetchMentionedBrands={ (params: FetchPublicBrandParams) => dispatch(fetchMentionedBrands(params)) }
              fetchWorkedWithBrands={ (params: FetchPublicBrandParams) => dispatch(fetchWorkedWithBrands(params)) }
              mentionedPostsStatus={ mentionedPostsStatus }
              mentionedPostsContent={ mentionedPostsContent }
              displayBrandsMentionedWorked={ true }
              getMentionedPosts={ (params: GraphQL.GetPublicMentionedPostsQueryVariables) => dispatch(getMentionedPosts(params)) }
              getMoreMentionedPosts={
                (params: GraphQL.GetPublicMentionedPostsQueryVariables) => dispatch(getMoreMentionedPosts(params))
              }
            />
          </div>
        </div>
      ) }
      { (toggles.includes(HIGHLIGHTS_INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS)) && (
        <div className="account-insights_pi-traits">
          <p className="account-insights_pi-traits_header">{ translate("Personality Traits") }</p>
          <PersonalityTraits socialProfile={ account.socialAccount } />
        </div>
      ) }
    </div>
  )
}
