/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import {
  AccountCircle,
  ArrowDropDown,
  InsertPhotoOutlined,
  FileDownloadOutlined,
  CheckOutlined,
  Apps,
  List as ListIcon,
} from "@mui/icons-material"

import { Scope, SearchAIType } from "../../util/types"
import LoadingIndicator from "../LoadingIndicator"
import Button from "../Button"
import { isSuccess } from "../../util/apiClient"
import Dropdown from "../Dropdown"
import StartsWithInput from "./StartsWithInput"
import { TYPOGRAPHY } from "../../style/mui-styles"
import SearchAIAccountsTable from "../SearchAIAccountsTable"
import SearchAIContentTable from "../SearchAIContentTable"
import {
  clearSearchState,
  fetchAccountSearchResults,
  fetchContentSearchResults,
  setSearchInput,
  setSelectedAccounts,
  setSelectedPosts,
} from "../../state/searchAISlice"
import { cloneSearchInput, RESULT_VIEW_MODE } from "../../state/searchAISlice/helper"
import { setSelectedAccountIds, setSelectedNetwork } from "../../state/modalAddToLists"
import { useDispatch, useSelector } from "../../state/hooks"
import csvHelper from "./csvHelper"
import * as API from "../../util/apiClient"

import "./style.sass"
import IconButton from "../IconButton"

function renderResultTypeIcon(resultType: SearchAIType): JSX.Element {
  return (
    <div className="cp_component_search-ai-results-type-dropdown">
      { resultType === SearchAIType.Accounts ? <AccountCircle />
        : <InsertPhotoOutlined /> }
      <ArrowDropDown />
    </div>
  )
}

export default function SearchResults(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResults" })
  const addAccountInfo = useSelector((state) => state.listAddAccount)
  const dispatch = useDispatch()
  const {
    searchAIInput,
    searchResultsAccounts,
    searchResultsContent,
    selectedAccounts,
    selectedPosts,
    searchLatency,
  } = useSelector(({ searchAI }) => searchAI)
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(clearSearchState())
  }, [])

  const handleQueueAccountsForLists = () => {
    // Queuing accounts opens Add To Lists modal
    if (selectedAccounts.length === 0 && searchAIInput.type === SearchAIType.Accounts) return
    if (selectedPosts.length === 0 && searchAIInput.type === SearchAIType.Content) return

    // Find network based on CONTENT result type and selected rows
    // Post Searches yield postIds in selectedAccountIds NOT accountIds
    if (searchAIInput.type === SearchAIType.Content) {
      const selectedAccountIdsFromPosts = Array.from(new Set(selectedPosts.map((post) => post.networkAccountId)))

      // eslint-disable-next-line no-debugger
      // debugger
      dispatch(setSelectedAccountIds(selectedAccountIdsFromPosts))

      const selectedNetwork = selectedPosts[0]?.network || null

      dispatch(setSelectedNetwork(selectedNetwork))
    }

    // Find network based on ACCOUNT result type and selected rows
    if (searchAIInput.type === SearchAIType.Accounts) {
      const selectedAccountIdsFromPosts = Array.from(new Set(selectedAccounts.map(({ id }) => id)))
      dispatch(setSelectedAccountIds(selectedAccountIdsFromPosts))

      const selectedNetwork = selectedAccounts[0]?.network || null
      dispatch(setSelectedNetwork(selectedNetwork))
    }
  }

  const resultCount = useMemo(() => {
    let count = 0
    if (searchAIInput.type === SearchAIType.Accounts && isSuccess(searchResultsAccounts)) count = searchResultsAccounts.payload.searchAccounts.total
    if (searchAIInput.type === SearchAIType.Content && isSuccess(searchResultsContent)) count = searchResultsContent.payload.searchPosts.total
    return count
  }, [ searchResultsAccounts, searchResultsContent, searchAIInput.type ])

  const startCsvDownload = () => {
    // Determine if accounts or content
    if (searchAIInput.type === SearchAIType.Accounts) {
      if (API.isSuccess(searchResultsAccounts)) {
        const data = csvHelper.generateAccountCsvData(searchResultsAccounts.payload.searchAccounts.accounts, scopes)
        csvHelper.downloadCsv(data, `search-account-results-${ dayjs().unix() }.csv`, "text/csv")
      }
    } else if (searchAIInput.type === SearchAIType.Content) {
      if (API.isSuccess(searchResultsContent)) {
        const data = csvHelper.generateContentCsvData(searchResultsContent.payload.searchPosts.posts)
        csvHelper.downloadCsv(data, `search-content-results-${ dayjs().unix() }.csv`, "text/csv")
      }
    }
  }

  return (
    <div className="cp_component_search-ai-results">
      <Stack direction="row" justifyContent="space-between" marginBottom="1rem">
        <Stack direction="row" alignItems="center" gap={ 2 }>
          <StartsWithInput />
          <Typography
            className="cp_component_search-ai-results-totals"
            fontSize={ TYPOGRAPHY.FONT_SIZE.NORMAL }
            fontWeight={ TYPOGRAPHY.FONT_WEIGHT.NORMAL }
          >
            { translate("RESULTS", {
              total: resultCount,
              latency: searchLatency,
            }) }
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={ 2 }>
          { scopes.includes(Scope.FEATURE_INFLUENCER_LIST) && (
            <Button
              isEnabled={
                addAccountInfo.addAccountStatus !== "loading"
                && (
                  (selectedAccounts.length > 0 && searchAIInput.type === SearchAIType.Accounts)
                  || (selectedPosts.length > 0 && searchAIInput.type === SearchAIType.Content)
                )
              }
              isPrimary={ true }
              label={
                addAccountInfo.addAccountStatus === "loading"
                  ? <LoadingIndicator />
                  : translate("Add to List")
                }
              onClick={ handleQueueAccountsForLists }
              size="small"
            />
          ) }
          <IconButton
            id="search-results-csv-download-button"
            className={ `cp_component_search-results-csv-download-button${ (resultCount === 0) ? " disabled-csv-icon" : " enabled-csv-icon" }` }
            variant="outlined"
            onClick={ startCsvDownload }
            disabled={ resultCount === 0 }
          >
            <FileDownloadOutlined />
          </IconButton>
          <Dropdown
            buttonType="custom"
            customButtonChildren={
            renderResultTypeIcon(searchAIInput.type)
          }
          >
            <List disablePadding={ true }>
              <ListItem>
                <Typography className="list-title" fontSize={ TYPOGRAPHY.FONT_SIZE.BIGGER } fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLDER }>
                  { translate("VIEW BY") }
                </Typography>
              </ListItem>
              <ListItem className="list-subtitle">
                <Typography fontSize={ TYPOGRAPHY.FONT_SIZE.NORMAL } fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLD }>
                  { translate("SHOW") }
                </Typography>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  className={ `list-item${ searchAIInput.type === SearchAIType.Accounts ? "-checked" : "" }` }
                  onClick={ async () => {
                    const newSearchInput = cloneSearchInput(searchAIInput)
                    newSearchInput.type = SearchAIType.Accounts
                    await dispatch(setSearchInput(newSearchInput))
                    dispatch(setSelectedAccounts([]))
                    dispatch(setSelectedPosts([]))
                    dispatch(fetchAccountSearchResults())
                  } }
                >
                  <ListItemIcon>
                    <CheckOutlined className="list-item-icon-check" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Accounts") } />
                  <ListItemIcon>
                    <AccountCircle className="list-item-icon" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  className={ `list-item${ searchAIInput.type === SearchAIType.Content ? "-checked" : "" }` }
                  onClick={ async () => {
                    const newSearchInput = cloneSearchInput(searchAIInput)
                    newSearchInput.type = SearchAIType.Content
                    await dispatch(setSearchInput(newSearchInput))
                    dispatch(setSelectedAccounts([]))
                    dispatch(setSelectedPosts([]))
                    dispatch(fetchContentSearchResults())
                  } }
                >
                  <ListItemIcon>
                    <CheckOutlined className="list-item-icon-check" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Content") } />
                  <ListItemIcon>
                    <InsertPhotoOutlined className="list-item-icon" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem className="list-subtitle">
                <Typography fontSize={ TYPOGRAPHY.FONT_SIZE.NORMAL } fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLD }>
                  { translate("VIEW MODE") }
                </Typography>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  className={ `list-item${ searchAIInput.viewMode === RESULT_VIEW_MODE.LIST ? "-checked" : "" }` }
                  onClick={ async () => {
                    const newSearchInput = cloneSearchInput(searchAIInput)
                    newSearchInput.viewMode = RESULT_VIEW_MODE.LIST
                    await dispatch(setSearchInput(newSearchInput))
                  } }
                >
                  <ListItemIcon>
                    <CheckOutlined className="list-item-icon-check" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("List") } />
                  <ListItemIcon>
                    <ListIcon className="list-item-icon" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    const newSearchInput = cloneSearchInput(searchAIInput)
                    newSearchInput.viewMode = RESULT_VIEW_MODE.GRID
                    await dispatch(setSearchInput(newSearchInput))
                  } }
                  className={ `list-item${ searchAIInput.viewMode === RESULT_VIEW_MODE.GRID ? "-checked" : "" }` }
                >
                  <ListItemIcon>
                    <CheckOutlined className="list-item-icon-check" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Grid") } />
                  <ListItemIcon>
                    <Apps className="list-item-icon" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
        </Stack>
      </Stack>
      <section className="cp_component_search-ai-results-table-container">
        { searchAIInput.type === SearchAIType.Accounts && <SearchAIAccountsTable /> }
        { searchAIInput.type === SearchAIType.Content && <SearchAIContentTable /> }
      </section>
    </div>
  )
}
