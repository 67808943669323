import React from "react"

import {
  debounce,
  FormControl,
  FormLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { AudienceMetricFormDataProps } from "../AudienceMetricsForm"
import Autocomplete from "../../../../Autocomplete"
import LoadingIndicator from "../../../../LoadingIndicator"
import Input, { Props as InputProps } from "../../../../Input"
import { Affinity } from "../../../constants"
import * as API from "../../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../../util/constant"

/**
 * AudienceAffinitiesForm: Form for selecting an affinity for the audience metrics
 * @param props Properies passed to form
 * @returns A react element
 */
export default function AudienceAffinitiesForm({ label, setSelected }: AudienceMetricFormDataProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })

  // Local state
  const [ affinityTags, setAffinityTags ] = React.useState<Affinity[]>([])
  const [ selectedAffinity, setSelectedAffinity ] = React.useState<Affinity>()

  // Affinities
  const searchAffinities = async (startsWith: string) => {
    const result = await API.fetchAffinitiesForList(startsWith)

    if (API.isSuccess(result)) {
      setAffinityTags(result.payload.searchAffinities.rows)
    } else {
      setAffinityTags([ {
        id: "",
        name: translate("Affinity not found"),
        code: "",
      } ])
    }
  }

  const debouncedSearchAffinities = React.useCallback(debounce((e, inputValue) => {
    searchAffinities(inputValue)
  }, DEFAULT_DEBOUNCE_WAIT), [ searchAffinities ])

  const onAutoCompleteChange = (
    event: React.SyntheticEvent,
    arAffinity: NonNullable<string | Affinity> | (string | Affinity)[] | null,
  ) => {
    if (!arAffinity) return
    if (typeof arAffinity === "string") return
    if (!Array.isArray(arAffinity)) {
      setSelected(arAffinity)
      setSelectedAffinity(arAffinity)
    } else {
      arAffinity.forEach((affinity) => {
        if (typeof affinity === "string") return
        setSelected(affinity)
        setSelectedAffinity(affinity)
      })
    }
  }

  React.useEffect(() => {
    searchAffinities("")
  }, [])

  return (
    <FormControl
      className="audience-metrics-form-metric-form"
      component="fieldset"
      fullWidth={ true }
    >
      <FormLabel component="legend" className="section-label">
        { translate(label) }
      </FormLabel>
      <Autocomplete
        filterSelectedOptions={ true }
        value={ selectedAffinity }
        disableClearable={ true }
        forcePopupIcon={ true }
        getOptionLabel={ (affinity) => {
        // Value selected with enter, right from the input
          if (typeof affinity === "string") return affinity
          return affinity.name
        } }
        ListboxProps={ {
          className: "cp_component_autocomplete_tags-list",
        } }
        onInputChange={ debouncedSearchAffinities }
        renderTags={ () => <></> }
        onChange={ onAutoCompleteChange }
        selectOnFocus={ true }
        handleHomeEndKeys={ true }
        id="affinities-autocomplete"
        options={ [ ...affinityTags ].sort() }
        loading={ false }
        loadingText={ <LoadingIndicator size={ 20 } /> }
        renderOption={ (props, { name, id }, state, ownerState) => {
          if (ownerState.loading && state.index > 0) return (<></>)
          if (ownerState.loading) {
            return (
              <li
                { ...props }
              >
                <LoadingIndicator size={ 20 } />
              </li>
            )
          }
          return (
            <li
              { ...props }
              key={ id }
              id={ `affinities-autocomplete-${ name.toLowerCase().replaceAll(" ", "_") }` }
            >
              { name }
            </li>
          )
        } }
        freeSolo={ true }
        renderInput={ (params) => (
          <Input
            { ...params as InputProps }
            className="auto-complete-affinity-input"
            placeholder={ translate("Select Affinity") }
          />
        ) }
      />
    </FormControl>
  )
}
