import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { isNumber } from "highcharts"
import * as GraphQL from "../../../../graphql"

import "./native-posts.sass"
import {
  IsToggleEnabled,
  commifyNumber,
  getToggleSettings,
} from "./reportingHelper"
import DataGrid from "../../../DataGrid"
import EmptyElement from "../../../EmptyElement"

interface Props {
  deliverables: GraphQL.DeliverableOverviewV2Fragment[]
  toggles: GraphQL.CampaignReportToggleSetting[]
}

export default function CampaignNativeInstagramStories({ deliverables, toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignReporting" })
  const mappedSettings = getToggleSettings(toggles)
  const isToggleEnabled = new IsToggleEnabled(mappedSettings, GraphQL.Network.Instagram)

  const isNativeStoriesActive = isToggleEnabled.toggleCheck("instagram-media_posts_native-show_posts")
  const filteredDeliverables = deliverables.filter((deliverable) => (
    deliverable.enhancedContentStatus === GraphQL.EnhancedDeliverableStatus.Live
      && deliverable.postType === GraphQL.DeliverablePostType.Story
      && deliverable.campaignNetworkAccount?.socialAccount?.network === GraphQL.Network.Instagram
  ))

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "username",
      sortable: false,
      headerName: translate("Username"),
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        const { row } = params
        const { userName } = row.campaignNetworkAccount.socialAccount
        return (
          <p className="cp_story-row-username">{ userName }</p>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 190,
    },
    {
      field: "gross-impressions",
      headerName: translate("Gross Impressions"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { followerCount } = row.metrics
        if (!isNumber(followerCount)) return ""
        return (
          <span>{ commifyNumber(followerCount) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 180,
    },
    {
      field: "reach",
      headerName: translate("Reach"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { reach } = row.metrics
        if (!isNumber(reach)) return ""
        return (
          <span>{ commifyNumber(reach) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "views",
      headerName: translate("Views"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { views } = row.metrics
        if (!isNumber(views)) return ""
        return (
          <span>{ commifyNumber(views) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "ctr",
      headerName: translate("CTR"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { views, linkClicks } = row.metrics
        let ctr: null | number = null
        if (linkClicks != null && views != null && views !== 0) {
          ctr = linkClicks / views
        }
        if (ctr === null || !isNumber(ctr)) return ""
        return (
          <span>{ `${ (ctr * 100).toFixed(2) }%` }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 100,
    },
    {
      field: "sticker-taps",
      headerName: translate("Sticker Taps"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { stickerTaps } = row.metrics
        if (!isNumber(stickerTaps)) return ""
        return (
          <span>{ commifyNumber(stickerTaps) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "link-clicks",
      headerName: translate("Link Clicks"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { linkClicks } = row.metrics
        if (!isNumber(linkClicks)) return ""
        return (
          <span>{ commifyNumber(linkClicks) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "likes",
      headerName: translate("Likes"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { likes } = row.metrics
        if (!isNumber(likes)) return ""
        return (
          <span>{ commifyNumber(likes) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "shares",
      headerName: translate("Shares"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { shares } = row.metrics
        if (!isNumber(shares)) return ""
        return (
          <span>{ commifyNumber(shares) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "replies",
      headerName: translate("Replies"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { replies } = row.metrics
        if (!isNumber(replies)) return ""
        return (
          <span>{ commifyNumber(replies) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 150,
    },
    {
      field: "total-engagement",
      headerName: translate("Total Engagement"),
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const { row } = params
        const { totalEngagement } = row.metrics
        if (!isNumber(totalEngagement)) return ""
        return (
          <span>{ commifyNumber(totalEngagement) }</span>
        )
      },
      disableColumnMenu: true,
      resizable: false,
      width: 200,
    },
  ], [ filteredDeliverables ])

  function generateSelectVisualRows(): GridRowsProp {
    const rows = filteredDeliverables.map((deliverable) => deliverable)
    return rows
  }

  // check if there is native story posts
  if (isNativeStoriesActive === false || !filteredDeliverables.length) return null

  return (
    <div className="cp_report-native-posts stories">
      <h2 className="section-heading">{ `${ translate("Instagram") } ${ translate("Native Stories") }` }</h2>
      <div className="cp_report-native-stories-table">
        <DataGrid
          getRowId={ (row) => row.id }
          disableRowSelectionOnClick={ true }
          pinnedColumns={ {
            left: [ "username" ],
          } }
          rowHeight={ 50 }
          columnHeaderHeight={ 40 }
          columns={ COLUMNS }
          disableColumnReorder={ true }
          hideFooter={ true }
          rows={ generateSelectVisualRows() }
          slots={ {
            noRowsOverlay: EmptyElement,
            noResultsOverlay: EmptyElement,
          } }
          columnVisibilityModel={ {
            "gross-impressions": isToggleEnabled.showGrossImpressions(),
            reach: isToggleEnabled.showReach(),
            views: isToggleEnabled.showStoryViews(),
            ctr: isToggleEnabled.showCTR(),
            "sticker-taps": isToggleEnabled.showStickerTaps(),
            "link-clicks": isToggleEnabled.showLinkClicks(),
            likes: isToggleEnabled.showStoryLikes(),
            shares: isToggleEnabled.showStoryShares(),
            replies: isToggleEnabled.showStoryReplies(),
            "total-engagement": isToggleEnabled.showStoryTotalEngagements(),
          } }
        />
      </div>
    </div>
  )
}
