import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { DeliverableStatus } from "../campaignDeliverableContentModalV2Slice/types"

export interface CampaignDeliverableV2UpdateStatusModalState {
  open: boolean
  deliverableId?: string
  deliverableStatus?: DeliverableStatus
}

const initialState: CampaignDeliverableV2UpdateStatusModalState = {
  open: false,
}

// Deliverable Content Modal Slice
export const CampaignDeliverableV2UpdateStatusModal = createSlice({
  name: "CampaignDeliverableV2UpdateStatusModal",
  initialState,
  reducers: {
    openDeliverableUpdateStatusModal: (
      state,
      action: PayloadAction<{
        deliverableId: string,
        deliverableStatus: DeliverableStatus
      }>,
    ) => ({
      ...state,
      open: true,
      deliverableId: action.payload.deliverableId,
      deliverableStatus: action.payload.deliverableStatus,
    }),
    closeDeliverableUpdateStatusModal: (
      state,
    ) => ({
      ...state,
      open: false,
      deliverableId: undefined,
      deliverableStatus: undefined,
    }),
  },
})

export const {
  openDeliverableUpdateStatusModal,
  closeDeliverableUpdateStatusModal,
} = CampaignDeliverableV2UpdateStatusModal.actions
export default CampaignDeliverableV2UpdateStatusModal.reducer
