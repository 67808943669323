import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { ReviewerGroupUserAssignment } from "../../graphqlV2/types/assignReviewerTypes"

export interface AssignReviewersModal {
  modalOpen: boolean
  isAssignReviewerGroup?: boolean
  isEditGroup?: boolean
  isEditUser?: boolean,
  data?:{
    selectedUsers?: ReviewerGroupUserAssignment[],
    groupNm: string,
    groupId: string
  }
}

const initialState: AssignReviewersModal = {
  modalOpen: false,
  isAssignReviewerGroup: false,
  isEditGroup: false,
  isEditUser: false,
}

export const AssignReviewersModalSlice = createSlice({
  name: "AssignReviewersModalSlice",
  initialState,
  reducers: {
    setModalOpen: (
      state,
      action: PayloadAction<{
        modalOpen: boolean,
        isAssignReviewerGroup?: boolean,
        isEditGroup?: boolean,
        isEditUser?: boolean,
        data?:{
          selectedUsers?: ReviewerGroupUserAssignment[],
          groupNm: string,
          groupId: string
        }
      }>,
    ) => ({
      ...state,
      modalOpen: action.payload.modalOpen,
      isAssignReviewerGroup: action.payload.isAssignReviewerGroup,
      isEditGroup: action.payload.isEditGroup,
      isEditUser: action.payload.isEditUser,
      data: action.payload.data,
    }),
  },
})

export const {
  setModalOpen,
} = AssignReviewersModalSlice.actions
export default AssignReviewersModalSlice.reducer
