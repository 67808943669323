import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "../../../state/hooks"

import "./style.sass"
import LoadingIndicator from "../../LoadingIndicator"
import AccountContentDetailsV2 from "../../ProfileTabs/PublicListTabs/AccountContentDetails/AccountContentDetailsV2"

interface ContentV2Props {
  displayImageTagPosts: boolean
  displayKeywordPosts: boolean
  displayTopPosts: boolean
}

export default function ContentV2({
  displayImageTagPosts,
  displayKeywordPosts,
  displayTopPosts,
}: ContentV2Props) {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicListAccountTabsV2" })
  const { list } = useSelector(({ publicList }) => publicList)

  if (list === "loading" || list === "init") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 48 } />
    )
  }
  if (list.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  return (
    <div className="content-v2">
      <h2 className="cp_account-page-tabs-content-heading">{ translate("Content") }</h2>
      <AccountContentDetailsV2
        displayImageTagPosts={ displayImageTagPosts }
        displayKeywordPosts={ displayKeywordPosts }
        displayTopPosts={ displayTopPosts }
      />
    </div>
  )
}
